import React from 'react'
import { useTranslation } from 'react-i18next';
import AnimatedPage from '../AnimatedPage'
import ScrollToTop from '../scrollToTop';
// import ReCAPTCHA from "react-google-recaptcha";
export default function RegisrterBuisnessAccStepFour({formData,handleInputChange , error, errorId }) {
    const {t} = useTranslation() 

  return (
    <AnimatedPage>
        <ScrollToTop/>
    <div>
                    <div className='row '   >
                        <div className='d-flex justify-content-center'>
                        <img loading='lazy' alt="Vr Line" src="../../../images/b4.svg" className='imgResponsive upperImgRegister px-5 hrSmHide'/>
                        </div>

                        <h2 className='SignupHeadings'>
                        {t('BACC.S4.H')}
                        </h2>
                        <img loading='lazy' alt="Vr Line" src="../../../images/Vector 4.svg" className='imgResponsive'/>
                    </div>
                    <div className='row pt-5'   >
                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                        <div className="mb-5 px-3">
                        <label htmlFor="email" className="form-label contactusLabels pb-2"> {t('BACC.S4.lb.1')} </label>
                        <input
                            type="text"
                            className="form-control formInputs"
                            id="estimatedMonthlyBusinessRevenue"
                            placeholder='1235'
                            value={formData.estimatedMonthlyBusinessRevenue}
                            onChange={(e) => handleInputChange('estimatedMonthlyBusinessRevenue', e.target)}
                            required
                        />
                        {errorId === 'estimatedMonthlyBusinessRevenue' ? <span className='inputFiledError pt-1'>{error}</span> : ''}
                        </div>
                    </div>


                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                        <div className="mb-5 px-3">
                        <label htmlFor="email" className="form-label contactusLabels pb-2"> {t('BACC.S4.lb.2')} </label>
                        <input
                            type="text"
                            className="form-control formInputs"
                            id="estimatedMonthlyExpenses"
                            placeholder='1236'
                            value={formData.estimatedMonthlyExpenses}
                            onChange={(e) => handleInputChange('estimatedMonthlyExpenses', e.target)}
                            required
                        />
                        {errorId === 'estimatedMonthlyExpenses' ? <span className='inputFiledError pt-1'>{error}</span> : ''}
                        </div>
                    </div>

                    <p className='textinfo pt-3 ps-4 ms-2 mb-5' >  {t('BACC.S4.lb.3')}
                        </p>

                        <div className="form-check">
                            <label className="sq-radio textinfo" id="termsConditon" value={formData.agreeToTerms}
                                onChange={(e) => handleInputChange('agreeToTerms', e.target)}>
                                <input type="checkbox"  name="radio-one" id="news_letter" /> {t('BACC.S4.lb.4')}
                                <span className="checkmark"></span>
                            </label>
                        </div>

                        {/* <div className='capthcaDiv mt-5 ps-4 ms-1'>
                        <ReCAPTCHA
        sitekey="6LcPYxcoAAAAAGvHLZC0u3S010Dh8_ROz1wa_b7B" // Replace with your site key
        onChange={(e) => handleRecaptchaChange(e.target.value)} // Callback function when the user completes reCAPTCHA
      />
                        </div> */}
                    </div>
    </div>
    </AnimatedPage>
  )
}
