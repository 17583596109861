import React,{useState} from 'react'
import UpperNav from './UpperNav'
import EditRecipients from './EditRecipients'
import AddRecipients from './AddRecipients'
function RecipientsManagment() {
    const [currentPage,setCurrentStep] = useState('details') 
    const [accountHolderName , setaccountHolderName ] = useState('Steve Thompson')
    const [bankName , setbankName ] = useState('Clydesdale Bank')
    const [acoountNumber , setacoountNumber ] = useState('23456789876')
    const [sortCode , setsortCode ] = useState('23645235454345')
    const [iban , setiban ] = useState('234')

    const [naccountHolderName , setnaccountHolderName ] = useState('')
    const [nbankName , setnbankName ] = useState('')
    const [nacoountNumber , setnacoountNumber ] = useState('')
    const [nsortCode , setnsortCode ] = useState('')
    const [niban , setniban ] = useState('')


    return (
        <>
        {
            currentPage === 'details' ? 
                <div className='sideBarMainContent '>
                <div className='WMainDiv '>
                <UpperNav title={`Recipients Management`} symbol={" "}/>
            
                
                <div className='row mt-5 justify-content-center'>
                <div className='col-xxl-5 col-lg-8 col-12'>
                    <div className='fiatTransferCard mt-md-5 mt-2 ' >
                        <div className='cardUpperRow mb-3'>
                            <div className='col-9'>
                            <h3>
                            Save Recipients
                            </h3>
                            </div>
                            <div className='col-3 d-flex justify-content-end mb-2' style={{cursor:"pointer"}}>
                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/addReciepent.svg" } alt="Logo"  className="" onClick={()=>{setCurrentStep('add')}}/>

                            </div>
                        </div>

                        <div className='reciepentDetails d-flex justify-content-between align-items-center mb-3'>
                            <div className='textDiv'>
                                <h3>
                                Steve Thompson
                                </h3>
                                <p> 
                                Clydesdale Bank
                                </p>
                                <p>
                                23456789876
                                </p>
                            </div>

                            <div className='imgDiv d-flex gap-4 '>
                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/pencil 1.svg" } alt="Logo"  className="" onClick={()=>{setCurrentStep('edit')}}/>

                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/trash (1) 1.svg" } alt="Logo"  className="" />
                            </div>
                        </div>

                        <div className='reciepentDetails d-flex justify-content-between align-items-center mb-3'>
                            <div className='textDiv'>
                                <h3>
                                Steve Thompson
                                </h3>
                                <p> 
                                Clydesdale Bank
                                </p>
                                <p>
                                23456789876
                                </p>
                            </div>

                            <div className='imgDiv d-flex gap-4 '>
                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/pencil 1.svg" } alt="Logo"  className="" onClick={()=>{setCurrentStep('edit')}}/>

                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/trash (1) 1.svg" } alt="Logo"  className="" />
                            </div>
                        </div>


                        <div className='reciepentDetails d-flex justify-content-between align-items-center mb-3'>
                            <div className='textDiv'>
                                <h3>
                                Steve Thompson
                                </h3>
                                <p> 
                                Clydesdale Bank
                                </p>
                                <p>
                                23456789876
                                </p>
                            </div>

                            <div className='imgDiv d-flex gap-4 '>
                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/pencil 1.svg" } alt="Logo"  className="" onClick={()=>{setCurrentStep('edit')}}/>

                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/trash (1) 1.svg" } alt="Logo"  className="" />
                            </div>
                        </div>


                        <div className='reciepentDetails d-flex justify-content-between align-items-center mb-3'>
                            <div className='textDiv'>
                                <h3>
                                Steve Thompson
                                </h3>
                                <p> 
                                Clydesdale Bank
                                </p>
                                <p>
                                23456789876
                                </p>
                            </div>

                            <div className='imgDiv d-flex gap-4 '>
                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/pencil 1.svg" } alt="Logo"  className="" onClick={()=>{setCurrentStep('edit')}}/>

                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/trash (1) 1.svg" } alt="Logo"  className="" />
                            </div>
                        </div>

                        
            
                </div>
            
            
                </div>
                </div>
                </div>
                </div>
            :   currentPage === 'edit' ?
                    <EditRecipients
                        currentPage={setCurrentStep}
                        accountHolderName={accountHolderName}
                        bankName={bankName}
                        acoountNumber={acoountNumber}
                        sortCode={sortCode}
                        iban={iban}
                        setaccountHolderName={setaccountHolderName}
                        setbankName={setbankName}
                        setacoountNumber={setacoountNumber}
                        setsortCode={setsortCode}
                        setiban={setiban}
                    /> :
                    currentPage === 'add' ?
                    <AddRecipients
                        currentPage={setCurrentStep}
                        accountHolderName={naccountHolderName}
                        bankName={nbankName}
                        acoountNumber={nacoountNumber}
                        sortCode={nsortCode}
                        iban={niban}
                        setaccountHolderName={setnaccountHolderName}
                        setbankName={setnbankName}
                        setacoountNumber={setnacoountNumber}
                        setsortCode={setnsortCode}
                        setiban={setniban}
                    /> : ""

        }
        </>
      )
}

export default RecipientsManagment