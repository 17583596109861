import React,{useEffect} from 'react'
import StepOne from './StepOne'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
function PersonalAcc() {
    const location = useLocation();
    const { email } = location.state || {};
    const navigate = useNavigate()

    // console.log(email,'email')
    useEffect(() => {
      if (!email){
        navigate('/sign-up')
      }
    
    },)
    
    return (
        <div>
         
            <StepOne email={email} ></StepOne>
        </div>
    )
}

export default PersonalAcc