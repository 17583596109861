import React from 'react'
import AnimatedPage from '../AnimatedPage'
import { useTranslation } from 'react-i18next'


export default function TermsOfUse() {
  const {t} = useTranslation()
  return (
    <AnimatedPage>
<div className='homepageContainerP2 py-3 mt-5' >
        <div className='container-fluid mt-5'>
            <div className='row justify-content-center px-0'>
                <h3 className='detailsPageH text-center '>
                {t('TOU.h.3')}
                </h3> 
                <b className="detailsPageTxtb text-center ps-4 pe-md-5 pe-0 mt-md-4 mt-0"> {t('TOU.h.4')}</b>

            <div className='col-md-12 col-12 py-3 ps-0 pe-md-5 pe-1'>
                    <p className="detailsPageTxt  " >
                    
                    </p>


                   

                    <h3 className='detailsPageHBlack '>
                    {t('TOU.hb.1')} 
                    </h3>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    {t('TOU.p.1')}
                    </p>


                    <p className="detailsPageTxt  ps-4 pe-2" >
                    <b className="detailsPageTxtb">{t('TOU.hb.2')} :</b> {t('TOU.p.2')}
                    </p>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    <b className="detailsPageTxtb">{t('TOU.hb.3')} :</b> {t('TOU.p.3')}
                    </p>
                    
                    <p className="detailsPageTxt  ps-4 pe-2" >
                    <b className="detailsPageTxtb">{t('TOU.hb.4')} :</b> {t('TOU.p.4')}
                    </p> 

                    <h3 className='detailsPageHBlack '>
                    {t('TOU.hb.5')} 
                    </h3>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    {t('TOU.p.5')}
                    </p>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    <b className="detailsPageTxtb">{t('TOU.hb.6')} :</b> {t('TOU.p.6')}
                    </p>



                    


                    <h3 className='detailsPageHBlack '>
                    {t('TOU.hb.7')}
                    </h3>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    {t('TOU.p.7')}
                    </p>


                </div>



        </div>

        </div>
        </div>




    </AnimatedPage>
  )
}
