import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';


function AccountSelection() {
    const [loading,setLoading] = useState(false)
    const [loadings,setLoadings] = useState(false)

    const location = useLocation();
    const { email } = location.state || {};

    // console.log(email,'email',location)
    const navigate = useNavigate();

    const handleNextStep = () => {
        setLoading(true)
        setTimeout(() => {
            navigate('/personal/', {
                replace: true,
                state: { email }
              });      
        },1000);
    }
    const handleNextSteps = () => {
        setLoadings(true)
        setTimeout(() => {
            navigate('/buisness/', {
                replace: true,
                state: { email }
              });      
        },1000);
    }

    const goBack = () => {
        navigate('/sign-up')
    }
  return (
    <div className=''>
    <nav className="navbar  py-0 navbarContainer fixed-top d-flex justify-content-center position-relative" >
    <Link className="" to="/">
      <img loading='lazy' src={process.env.PUBLIC_URL + "/images/logo.svg" } alt="Logo" width="30" height="30" className="navbarLogo00 mx-auto my-lg-0 my-2" />
    </Link>
    </nav>

    <div className='container-fluid d-flex flex-column justify-content-start gap-4 fixedHeight001 px-lg-auto px-4'>
        <div className='d-flex gap-3 mx-auto  mt-lg-5 mt-3'>

            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_back_iconss.svg" } alt="Logo" width="30" height="30" className="" onClick={goBack}             style={{cursor:"pointer"}}/>

            <h1 className='acc_type_h'>  
            Account Type
            </h1>
        </div>
        <p className='acc_type_h2 text-center'>
        Pick your desired Bank account type to proceed
        </p>
        <div className='row justify-content-center'>
            <div className="col-xxl-4 col-lg-5 col-12 mb-4">
                <div className="new_acc_type_card d-flex flex-column gap-3">
                    <h1>
                    Evistia Personal Account
                    </h1>
                    <p className='mb-0'>
                    Explore modern saving with Evistia. The advanced account boasts competitive interest rates, user-friendly fund management, and top-tier security.
                    </p>
                    <button type="submit" className={`button ${loading ? 'loading' : ''}`} style={{"border":"none"}} onClick={handleNextStep}>
                        {loading ? (
                        <div className="spinner"></div>
                        ) : (
                        <span>I want an account for my personal use</span>
                        )}
                    </button>
                </div>
            </div>

            <div className="col-xxl-4 col-lg-5 col-12 mb-4">
                <div className="new_acc_type_card d-flex flex-column gap-3">
                    <h1>
                    Evistia Business Account
                    </h1>
                    <p className='mb-0'>
                    Optimize your business with tailored solutions from Evistia. Benefit from efficient
financial management, streamlined transactions, and comprehensive tools for growth.
                    </p>
                    <button type="submit" className={`button ${loadings ? 'loading' : ''}`} style={{"border":"none"}} onClick={handleNextSteps}>
                        {loadings ? (
                        <div className="spinner"></div>
                        ) : (
                        <span>I want an account for my business</span>
                        )}
                    </button>
                </div>
            </div>
        </div>
        
    </div>

    <div className="row px-0 bottom-fixed zIndex w-100">
          <div className='row px-0 '>
            <div className="col-3 px-lg-0 ps-4 mb-lg-3 mb-1 d-flex gap-1 justify-content-center ">
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/check-mark 1.svg" } alt="Logo" className="checkImg" />
            
              <h1 className='bottom_steps_h_black mb-0'>
                Account Setup
              </h1>
            </div>
            <div className="col-3 px-0 mb-lg-3 mb-1">
        
              <h1 className='bottom_steps_h_blue'>
              Bank Account 
              </h1>
            </div>



          </div>
          <div className='row px-0 buttom_steps_row'>
            <div className="col-3 px-0 fill_up">
            </div>
            <div className="col-3 px-0 fill_up">
            </div>
          </div>
        </div>
    </div>

    
  )
}

export default AccountSelection