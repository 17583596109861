import React, { useState, useEffect } from 'react';
import UpperNav from './UpperNav';
import { getToken } from '../../services/LocalStorageService';
import { useNavigate } from 'react-router-dom';

function DropdownItem({ text, onSelect }) {
    return (
        <button className="dropdown-item" onClick={() => onSelect(text)}>
            {text}
        </button>
    );
}

function TransactionCryptoHistory() {
    const { access_token } = getToken();
    const navigate = useNavigate();
    const [apiData, setApiData] = useState([]);
    const [selectedTypeOption, setSelectedTypeOption] = useState({ text: 'All', type: '' });
    const [selectedTokenOption, setSelectedTokenOption] = useState({ text: 'All', type: '' });

    const [transactions, setTransactions] = useState([]);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [dropDown, showDropDown] = useState(false);
    const [dropDown1, showDropDown1] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch('https://evistialab.com/api/v1/transaction-history/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`,
            }
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        navigate("/login");
                    }
                }
                return response.json();
            })
            .then(data => {
                console.log(data)
                if (Array.isArray(data.transactions)) {
                    setTransactions(data.transactions);
                    setFilteredTransactions(data.transactions);
                } else {
                    console.error('Expected array but got:', data);
                    setTransactions([]);
                    setFilteredTransactions([]);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, [access_token]);

    const handleTypeSelect = (optionText) => {
        setLoading(true);
        const option = { text: optionText, type: optionText === 'All' ? '' : optionText };
        setSelectedTypeOption(option);
        showDropDown(false);
        applyFilters(option, selectedTokenOption);
    };

    const handleTokenSelect = (optionText) => {
        setLoading(true);
        const option = { text: optionText, type: optionText === 'All' ? '' : optionText };
        setSelectedTokenOption(option);
        showDropDown1(false);
        applyFilters(selectedTypeOption, option);
    };

    const applyFilters = (typeOption, tokenOption) => {
        let filtered = transactions;
        if (typeOption.type) {
            filtered = filtered.filter(tx => tx.transaction_type === typeOption.type);
        }
        if (tokenOption.type) {
            filtered = filtered.filter(tx => tx.token === tokenOption.type);
        }
        setFilteredTransactions(filtered);
        setLoading(false);
    };

    return (
        <div className='sideBarMainContent '>
            <div className='WMainDiv '>
                <UpperNav title={`Transactions History`} symbol={" "} />

                <div className='row mt-xxl-4 mt-1 justify-content-center'>
                    <div className='col-xxl-11 col-lg-12 col-12'>
                        <div className='fiatTransferCard mt-md-5 mt-2 '>
                            <div className='cardUpperRow mb-3 justify-content-end gap-4'>
                                <div className='col-xxl-4 col-xl-5 col-md-4 col-6 d-flex justify-content-start mb-2'>
                                    <h4 className='ps-5 ms-3'>
                                        Transactions History
                                    </h4>
                                </div>
                                <div className='col-xxl-3 col-xl-1 col-md-12 col-12  d-flex justify-content-start mb-2'></div>
                                <div className='col-xxl-2 col-xl-3 col-md-4 col-6 col-6 d-flex justify-content-start mb-2' style={{ cursor: "pointer" }}>
                                    <div className='blogddDiv1 d-flex gap-2 align-items-center justify-content-between' onClick={() => showDropDown1(!dropDown1)}>
                                        <h3 className='text-white mb-0 ps-3'>
                                            Coin :
                                        </h3>
                                        <div className='d-flex gap-2'>
                                            <h4 className='mb-0'>{selectedTokenOption?.text || 'All'}</h4>
                                        </div>
                                        <img loading='lazy' src="../images/Vector 26.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow" />
                                        <div className={`dropdown-menu blogwapDropDown ${dropDown1 ? 'show' : ''}`} onMouseLeave={() => showDropDown1(false)}>
                                            <DropdownItem text="All" onSelect={handleTokenSelect} />
                                            <DropdownItem text="BTC" onSelect={handleTokenSelect} />
                                            <DropdownItem text="ETH" onSelect={handleTokenSelect} />
                                            <DropdownItem text="USDT" onSelect={handleTokenSelect} />
                                            <DropdownItem text="BNB" onSelect={handleTokenSelect} />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-3 col-md-4 col-6 col-6 d-flex justify-content-start mb-2' style={{ cursor: "pointer" }}>
                                    <div className='blogddDiv1 d-flex gap-2 align-items-center justify-content-between' onClick={() => showDropDown(!dropDown)}>
                                        <h3 className='text-white mb-0 ps-3'>
                                            Type :
                                        </h3>
                                        <div className='d-flex gap-2'>
                                            <h4 className='mb-0'>{selectedTypeOption?.text || 'All'}</h4>
                                        </div>
                                        <img loading='lazy' src="../images/Vector 26.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow" />
                                        <div className={`dropdown-menu blogwapDropDown ${dropDown ? 'show' : ''}`} onMouseLeave={() => showDropDown(false)}>
                                            <DropdownItem text="All" onSelect={handleTypeSelect} />
                                            <DropdownItem text="Deposit" onSelect={handleTypeSelect} />
                                            <DropdownItem text="Withdraw" onSelect={handleTypeSelect} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {loading ? (
                                 <p className='text-center'>Loading transactions...</p>
                            ) : (
                                filteredTransactions.length > 0 ? filteredTransactions.map((transaction, index) => (
                                    <div key={index} className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                                        <div className='d-flex gap-3 '>
                                            {
                                                transaction.transaction_type === "Deposit" ? 
                                                <img loading='lazy' src="../images/t7.svg" alt="Section One Right Side Images" className="img-fluid " />
                                                :
                                                <img loading='lazy' src="../images/t5.svg" alt="Section One Right Side Images" className="img-fluid " />
                                            }
                                            
                                            
                                            <div className='d-flex flex-column justify-content-center '>
                                                <p className='mb-1'>
                                                {transaction.value} {transaction.token}
                                                
                                                </p>
                                                <span>
                                                    {transaction.created_at}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column justify-content-center align-items-end '>
                                            <p className='mb-1 text-right'>
                                            From : {transaction.from_address}
                                            <br></br>
                                            

                                            </p>
                                            <span>
                                            TX ID : {transaction.tx_id}
                                            </span>
                                        </div>
                                    </div>
                                )) : (
                                    <p className='text-center'>No transactions available</p>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TransactionCryptoHistory;
