import React,{useState} from 'react'
import { useLocation } from 'react-router-dom';
import UpperNav from './UpperNav';
import { useNavigate } from 'react-router-dom';
import FiatTransferStepThree from './FiatTransferStepThree';
import MobileOTP from './MobileOTP';
function SendViaEmailPhoneId() {
  const location = useLocation();
  const name = location?.state?.name;
  const sign = location.state.sign;
  const image = location.state.img;
  const page = location.state.pageName;
  const pageName = 'Crypto'
  const navigate = useNavigate()

  const [stepCount, setStepCount] = useState(1)
  const [email, setemail] = useState('')
  const [amount, setamount] = useState('')

  const handleNextStep = () => {
          setStepCount(stepCount + 1)
    }
  return (
      <div className='sideBarMainContent '>
      <div className='WMainDiv '>
      <UpperNav title={`${page} Network`} symbol={" "}/>
  
      
      <div className='row mt-5 justify-content-center'>
        <div className='col-xxl-5 col-lg-8 col-12'>
            <div className='fiatTransferCard mt-md-5 mt-2 ' >
            <div className='cardUpperRow mb-3'>
                  <div className='col-12'>
                  <h3>
                  Send {sign}
                  </h3>
                  </div>
              </div>

            <div className='col-12 px-5 ' >
             {
              stepCount === 1 ? 
              <>
             <div className='FDInputDiv mb-4'>
                  <h6 className='ps-1 inputTitle mb-3'>
                  Email
                  </h6>
                  <input type='email' placeholder='Email Address' className='form-control fTransferInput' value={email} onChange={(e)=>{setemail(e.target.result)}} required/>
              </div>
              <div className='FDInputDiv mb-2'>
                  <h6 className='ps-1 inputTitle mb-2'>
                   Amount
                  </h6>
                  <input type='number' placeholder='Minimum 1' className='form-control fTransferInput' onChange={(e)=>setamount(e.target.result)} value={amount} required/>
              </div>
              <div className='recievedDetailDiv d-flex justify-content-between '>
                  <h6>
                  Available
                  </h6>
                  <h5>
                  3,234.45566 USDT
                  </h5>
              </div>
              <p className='SVCNBottomText mt-5'>
              Do not send directly to a crowdfund or ICO. We will no credit your account with tokens from that sale.
              </p>
              </> :
              stepCount === 2 ? 
              <FiatTransferStepThree />
              : stepCount === 3 ? 
              <MobileOTP/>
              : navigate('/wallet/payment-confirmation', {state : {amount,sign,pageName}})
             }
            </div>

            <div className='row justify-content-center'>
              <div className='col-8'>
                      <div className='WHPS3bottomDiv row '>              
                          <a onClick={handleNextStep}> Next </a>
                      </div>
              </div>
          </div>
  
      </div>
  
  
    </div>
  </div>
  </div>
  </div>
    )
}

export default SendViaEmailPhoneId