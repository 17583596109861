import React , {useState} from 'react'
import UpperNav from './UpperNav'
import FiatTransferStepOne from './FiatTransferStepOne'
import FiatTransferStepTwo from './FiatTransferStepTwo'
import FiatTransferStepThree from './FiatTransferStepThree'
import { useNavigate } from 'react-router-dom'
import MobileOTP from './MobileOTP';

export default function FiatTransfer() {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [active, setActive] = useState('saved');

    const pageName = 'Fiat'
    const [sign,setSign] = useState('GBP')

    const [accountHolderName , setaccountHolderName ] = useState()
    const [bankName , setbankName ] = useState()
    const [acoountNumber , setacoountNumber ] = useState()
    const [sortCode , setsortCode ] = useState()
    const [iban , setiban ] = useState()
    const [amount , setamount ] = useState()



    const handleNextStep = () => {
      // get User id and send Data to next Step number 2 
      // Limit the currentStep to a maximum of 3
      if (currentStep < 4) {
        setCurrentStep(currentStep + 1);
      }
    };

    const confirmPay = () => {
      setCurrentStep(1)
      navigate('/wallet/payment-confirmation', {state : {amount,sign,pageName,accountHolderName}})
    }
  return (
    <div className='sideBarMainContent '>
    <div className='WMainDiv '>
      <UpperNav />

      
      <div className='row mt-5 justify-content-center'>
        <div className='col-xxl-5 col-lg-8 col-12'>
            <div className='fiatTransferCard mt-md-5 mt-2 ' >
                <div className='cardUpperRow mb-3'>
                    <div className='col-12'>
                    <h3>
                    {
                        currentStep === 1 ?
                        'Enter payment details' 
                        : "Enter Amount"
                    }
                    </h3>
                    </div>
                </div>
                <div className='row justify-content-center'>


                    
                    {
                        currentStep === 1 ? 
                        <FiatTransferStepOne
                          activeStatus={active} 
                          activeFunc={setActive}
                          handleNextStep={handleNextStep}
                          accountHolderName={accountHolderName}
                          bankName={bankName}
                          acoountNumber={acoountNumber}
                          sortCode={sortCode}
                          iban={iban}
                          setaccountHolderName={setaccountHolderName}
                          setbankName={setbankName}
                          setacoountNumber={setacoountNumber}
                          setsortCode={setsortCode}
                          setiban={setiban}
                          />
                        : currentStep === 2 ? 
                        <FiatTransferStepTwo 
                        accName={accountHolderName}
                        bankName={bankName}
                        acoountNumber={acoountNumber}
                        sortCode={sortCode} iban={iban} 
                        setSign={setSign}
                        amount={amount}
                        setamount={setamount}
                        />
                        : currentStep === 3 ?
                        <FiatTransferStepThree />
                        : currentStep === 4 ?
                        <MobileOTP/>
                        : navigate('/wallet/payment-confirmation', {state : {amount,sign,pageName,accountHolderName}})
                    }

                    {
                        active === 'new' || currentStep >= 2 ?
                            <div className='row justify-content-center'>
                                <div className='col-8'>
                                        <div className='WHPS3bottomDiv row '>
                                        {currentStep <4 ?
                                        <a onClick={handleNextStep}> Next </a>
                                        :
                                        <a onClick={confirmPay}> Pay </a>
                                        }
                                                            
                                        </div>
                                </div>
                            </div>
                        : ""
                    }

                </div>
            </div>
        </div>
      </div>


    </div>
  </div>
  )
}
