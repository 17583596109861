import React from "react";
import "../../static/css/footter.css";
import "../../static/css/responsive.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { currencyMapping } from "../../constants/currencyMapping";
// import fotterBackgroundImage from '../../static/images/Footer.svg'
// import fotterwebLogo from '../../static/images/evistia alt@4x 2.png'
// import fotterFbLogo from '../../static/images/Group.svg'
// import fotterXLogo from '../../static/images/Group (1).svg'
// import fotterInstaLogo from '../../static/images/instagram (3) 1.svg'
// import fotterLindinLogo from '../../static/images/linkedin (1) 1.svg'
// import fotterapApplePlayLogo from '../../static/images/image 22.png'
// import fotterapGooglePlayLogo from '../../static/images/shutter_102_3 1.png'
// import ftrFirstStepHr from '../../static/images/Vector 9.svg'
// import ftrSecondStepHr from '../../static/images/Vector 5.svg'









export default function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate()


  const handleExPage = (firstCurrency, secondCurrency) => {

    // Retrieve currency information from the mapping
    const firstCurrencyInfo = currencyMapping[firstCurrency];
    const secondCurrencyInfo = currencyMapping[secondCurrency];

  
    // Check if currencyInfo exists before using it
    if (firstCurrencyInfo && secondCurrencyInfo) {
      const { sign: firstSign, shortName: firstShortName, apiShortName : firstApiShortName, imgSrc: firstImgSrc, } = firstCurrencyInfo;
      const { sign: secondSign, shortName: secondShortName, apiShortName : secondApiShortName,  imgSrc: secondImgSrc } = secondCurrencyInfo;
  

      
      // Navigate to the exchange rates page with currency information
      navigate(`/exchange-rates/${firstApiShortName}/to/${secondApiShortName}/`, {
        state: {
          first: { currency: firstShortName, sign: firstSign, apiShortName : firstApiShortName, imgSrc: firstImgSrc , shortName:firstShortName},
          second: { currency: secondShortName, sign: secondSign, secondShortName, apiShortName : secondApiShortName, imgSrc: secondImgSrc , shortName: secondShortName },
        },
      });
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // for smooth scrolling
      });
    }
  };
  
  const handleCountryPage = (firstCurrency,secondCurrency) => {
   // Retrieve currency information from the mapping
   const firstCurrencyInfo = currencyMapping[firstCurrency];
   const secondCurrencyInfo = currencyMapping[secondCurrency];
 
   // Check if currencyInfo exists before using it
   if (firstCurrencyInfo && secondCurrencyInfo) {
     const { sign: firstSign, shortName: firstShortName, apiShortName : firstApiShortName, imgSrc: firstImgSrc ,country : firstCountryName} = firstCurrencyInfo;
     const { sign: secondSign, shortName: secondShortName, apiShortName : secondApiShortName,  imgSrc: secondImgSrc,country : secondCountryName } = secondCurrencyInfo;
 
 
     // Navigate to the exchange rates page with currency information
     navigate(`send-to-country/${firstApiShortName}/to/${secondApiShortName}/`, {
       state: {
         first: { currency: firstShortName, sign: firstSign, apiShortName : firstApiShortName, imgSrc: firstImgSrc ,country : firstCountryName , shortName:firstShortName},
         second: { currency: secondShortName, sign: secondSign, apiShortName : secondApiShortName, imgSrc: secondImgSrc ,country : secondCountryName , shortName:secondShortName},
       },
     });
     window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smooth scrolling
    });
   }
  }

  const handleNextPage = (pageLink) => {
      console.log('click',pageLink)
      navigate(pageLink)
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // for smooth scrolling
      });
  }




  const accordionData = [
    {
      title: t("ftr01.h.1"),
      links: [
        { text: "Security", to: "security/" },
        { text: t("ftr01.p.1"), to: "security-bugs/" },

        // Add more link objects as needed
      ],
    },
    {
      title: t("ftr01.h.2"),
      links: [
        { text: t("ftr01.p.2"), to: "personal-account/" },
        { text: t("ftr01.p.3"), to: "buisness-account/" },
        // Add more link objects as needed
      ],
    },
    {
      title: t("ftr01.h.3"),
      links: [
        { text: t("ftr01.p.4"), to: "crypto/" },
        { text: t("ftr01.p.5"), to: "digital-wallet/" },
        // Add more link objects as needed
      ],
    },
    {
      title: t("ftr01.h.4"),
      links: [
        { text: t("ftr01.p.6"), to: "cards/" },
        { text: t("ftr01.p.7"), to: "send-to-country/gbp/to/eur/" },
        // Add more link objects as needed
      ],
    },
    {
      title: t("ftr01.h.5"),
      links: [
        { text: t("ftr01.p.8"), to: "contact-us/" },
        { text: t("ftr01.p.9"), to: "privacy-policy/" },
        { text: t("ftr01.p.10"), to: "terms-of-use/" },
        // Add more link objects as needed
      ],
    },
    {
      title: t("ftr01.h.6"),
      links: [
        { text: t("ftr01.p.11"), to: "about-us/" },
        { text: t("ftr01.p.12"), to: "contact-us/" },
        { text: t("ftr01.p.13"), to: "blog/" },
        { text: t("ftr01.p.14"), to: "careers/" },
        // Add more link objects as needed
      ],
    },
  ];

  const accordionItems = accordionData.map((item, index) => (
    <div className="accordion-item accordionItem" key={index}>
      <h2 className="accordion-header">
        <button
          className="accordion-button accordionButton collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#flush-collapse${index + 1}`}
          aria-expanded="false"
          aria-controls={`flush-collapse${index + 1}`}
        >
          <p className="ftrTitle ftrTitle01">{item.title}</p>
        </button>
      </h2>
      <div
        id={`flush-collapse${index + 1}`}
        className="accordion-collapse collapse"
        data-bs-parent="#accordionFlushExample"
      >
        <div className="accordion-body">
          {item.links.map((link, linkIndex) => (
            <p className="ftrSubTitle" key={linkIndex}>
              {
                link.text !== "Currency Convertor" ? <a onClick={()=>{handleNextPage(link.to)}}>{link.text}</a> : 
                <a onClick={()=>{handleExPage('GBP','EUR')}} style={{cursor:"pointer"}}>{t("ftr01.p.7")}</a>
              }
             
            </p>
          ))}
        </div>
      </div>
    </div>
  ));







  return (
    <div className="ftrFirstSection FotterBg">
      <div className="footerMain">
        <div className="footerTxtDiv">
          <h4>{t("newFooter.p.1")}</h4>
          <p>
          {t("newFooter.p.2")}
          </p>
          <div className="row">
            <div className="col-6 px-0">
              <img
                loading="lazy"
                alt="Fotter Images"
                className="playLogos "
                src={
                  process.env.PUBLIC_URL +
                  "/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                }
              />
            </div>
            <div className="col-6 px-0">
              <img
                loading="lazy"
                alt="Fotter Images"
                className="playLogos"
                src={process.env.PUBLIC_URL + "/images/shutter_102_3 1.png"}
              />
            </div>
          </div>
        </div>
        <img
          loading="lazy"
          alt="Fotter Images"
          className="ftrLogo"
          src={process.env.PUBLIC_URL + "/images/evistia alt@4x 2.png"}
        />
        <div className="container-fluid containerFluid">
          <div className="ftrLinkHide hideFooter">
            <div className="footerLinkCol">
              <p className="ftrTitle">{t("ftr01.h.1")}</p>
              <p className="ftrSubTitle">
                <a onClick={()=>{handleNextPage('security/')}}>Security</a>
              </p>
              <p className="ftrSubTitle">
                <a onClick={()=>{handleNextPage('security-bugs/')}}>{t("ftr01.p.1")}</a>
              </p>
            </div>
            <div className="footerLinkCol">
              <p className="ftrTitle">{t("ftr01.h.2")}</p>
              <p className="ftrSubTitle">
                <a onClick={()=>{handleNextPage('personal-account/')}}>{t("ftr01.p.2")}</a>
              </p>
              <p className="ftrSubTitle">
                <a onClick={()=>{handleNextPage('buisness-account/')}}>{t("ftr01.p.3")}</a>
              </p>
            </div>
            <div className="footerLinkCol">
              <p className="ftrTitle">{t("ftr01.h.3")}</p>
              <p className="ftrSubTitle">
                <a onClick={()=>{handleNextPage('crypto/')}}>{t("ftr01.p.4")}</a>
              </p>
              <p className="ftrSubTitle">
                <a onClick={()=>{handleNextPage('digital-wallet/')}}>{t("ftr01.p.5")}</a>
              </p>
            </div>
            <div className="footerLinkCol">
              <p className="ftrTitle">{t("ftr01.h.4")}</p>
              <p className="ftrSubTitle">
                <a onClick={()=>{handleNextPage('cards/')}}>{t("ftr01.p.6")}</a>
              </p>
              <p className="ftrSubTitle">
                <a onClick={()=>{handleExPage('GBP','EUR')}} style={{cursor:"pointer"}}>{t("ftr01.p.7")}</a>
              </p>
            </div>
            <div className="footerLinkCol">
              <p className="ftrTitle">{t("ftr01.h.5")}</p>
              <p className="ftrSubTitle">
                <a onClick={()=>{handleNextPage('contact-us/')}}>{t("ftr01.p.8")}</a>
              </p>
              <p className="ftrSubTitle">
                <a onClick={()=>{handleNextPage('privacy-policy/')}}>{t("ftr01.p.9")}</a>
              </p>
              <p className="ftrSubTitle">
                <a onClick={()=>{handleNextPage('terms-of-use/')}}>{t("ftr01.p.10")}</a>
              </p>
            </div>
            <div className="footerLinkCol">
              <p className="ftrTitle">{t("ftr01.h.6")}</p>
              <p className="ftrSubTitle">
                <a onClick={()=>{handleNextPage('about-us/')}}>{t("ftr01.p.11")}</a>
              </p>
              <p className="ftrSubTitle">
                <a onClick={()=>{handleNextPage('contact-us/')}}>{t("ftr01.p.12")}</a>
              </p>
              <p className="ftrSubTitle">
                <a onClick={()=>{handleNextPage('blog/')}}>{t("ftr01.p.13")}</a>
              </p>
              <p className="ftrSubTitle">
                <a onClick={()=>{handleNextPage('careers/')}}>{t("ftr01.p.14")}</a>
              </p>
            </div>
          </div>
          <div className="showFooter">
          <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              {accordionItems}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8 col-6 pb-sm-0 px-1">
              {/* <p className="copyRightText ">{t("ftr02.p.8")}</p> */}
              <img
                loading="lazy"
                className=""
                src={process.env.PUBLIC_URL + "/images/evistia icon@4x 21.svg"}
              />
            </div>
            <div className="socialIcons col-sm-4 col-6 d-flex  ftrMbIcon">
              <a href="https://www.facebook.com/profile.php?id=61559167886078&mibextid=LQQJ4d" target="_blank">
              <img
                loading="lazy"
                alt="Fotter Images"
                className=""
                src={process.env.PUBLIC_URL + "/images/Group.svg"}
              />
              </a>
              <a href="https://www.instagram.com/evistiaapp/" target="_blank">
                <img
                  loading="lazy"
                  alt="Fotter Images"
                  className=""
                  src={process.env.PUBLIC_URL + "/images/instagram (3) 1.svg"}
                />
              </a>

              <a href="https://x.com/evistia?s=21" target="_blank">
                <img
                  loading="lazy"
                  alt="Fotter Images"
                  className=""
                  src={process.env.PUBLIC_URL + "/images/Group (1).svg"}
                />
              </a>
              <a href="https://www.linkedin.com/company/evistia" target="_blank">
              <img
                loading="lazy"
                alt="Fotter Images"
                className=""
                src={process.env.PUBLIC_URL + "/images/linkedin (1) 1.svg"}
              />
              </a>
            </div>
          </div>
          <div className="firstStephr row pb-3 ftrLinkHide borderTop">
            <img

              loading="lazy"
              alt="Fotter Images"
              className="noneImg"
              src={process.env.PUBLIC_URL + "/images/Vector 9.svg"}
            />{" "}
            
          </div>
          {/* <div className="bottomFooterDiv"> */}
            {/* <div className="bottomIcon kingdomTxt">
              <img
                className=""
                src={process.env.PUBLIC_URL + "/images/gbpIcon.svg"}
              />
              <p>{t("newFooter.p.3")}</p>
            </div> */}
            {/* <div className="bottomIcon">
              <Link to="legal-terms-policy/">
                <p>{t("newFooter.p.4")}</p>
              </Link>
            </div>
            <div className="bottomIcon">
              <Link to="legal-terms-policy/">
                {" "}
                <p>{t("newFooter.p.5")}</p>
              </Link>
            </div>
            <div className="bottomIcon">
              <Link to="complaints/">
                <p>{t("newFooter.p.6")}</p>
              </Link>
            </div>
            <div className="bottomIcon">
              <Link to="legal-terms-policy/">
                {" "}
                <p>{t("newFooter.p.7")}</p>
              </Link>
            </div> */}
          {/* </div> */}
          <div className="bottomTxt ">
            <p className="">{t("newFooter.p.8")} FINVISE PAY LTD. 
            License Num: M23541215</p>
          </div>
          <div className="bottomTxt bottomTxt01">
            <p className="pb-2">
            {t("newFooter.p.9")}{" "}
            </p>
          </div>
          <div className="bottomTxt bottomTxt01">
            <p className="pb-2">
            {t("newFooter.p.10")}{" "}
              <Link to="/legal-terms-policy/"> {t("newFooter.p.11")}</Link>
            </p>
          </div>
          <div className="bottomMoneyDiv">
            <h6>{t("newFooter.link.1")}: </h6>
            <a onClick={()=>{handleCountryPage('GBP','EUR')}} > {t("newFooter.link.2")}</a> <span> |</span>
            <a onClick={()=>{handleCountryPage('GBP','JPY')}}> {t("newFooter.link.3")}</a> <span> |</span>
            <a onClick={()=>{handleCountryPage('GBP','AED')}}> {t("newFooter.link.4")}</a> <span> |</span>
            <a onClick={()=>{handleCountryPage('GBP','THB')}}> {t("newFooter.link.5")} </a> <span> |</span>
            {/* <a onClick={()=>{handleCountryPage('GBP','PKR')}}> {t("newFooter.link.6")}</a> <span> | </span> */}
            <a onClick={()=>{handleCountryPage('GBP','CHF')}} > {t("newFooter.link.02")}</a> <span> |</span>
            <a onClick={()=>{handleCountryPage('GBP','SEK')}}> {t("newFooter.link.03")}</a> <span> |</span>
            <a onClick={()=>{handleCountryPage('GBP','SAR')}}> {t("newFooter.link.04")}</a> <span> |</span>
            <a onClick={()=>{handleCountryPage('GBP','PLN')}}> {t("newFooter.link.05")} </a> <span> |</span>
            <a onClick={()=>{handleCountryPage('GBP','PHP')}}> {t("newFooter.link.06")}</a> <span> </span>
            {/* <a onClick={()=>{handleCountryPage('pln')}}> Send money to Poland</a> <span> |</span>
            <a onClick={()=>{handleCountryPage('pln')}}> Send money to the UAE</a> */}
          </div>
          <div className="bottomMoneyDiv">
            <h6>{t("newFooter.link.7")}:  </h6>
            <a onClick={()=>{handleExPage('GBP','EUR')}}> {t("newFooter.link.8")}</a> <span> |</span>
            <a onClick={()=>{handleExPage('GBP','AED')}}> {t("newFooter.link.9")} </a> <span> |</span>
            <a onClick={()=>{handleExPage('EUR','GBP')}}> {t("newFooter.link.10")}</a> <span> |</span>
            <a onClick={()=>{handleExPage('EUR','GBP')}}>{t("newFooter.link.11")}</a> <span> |</span>
            <a onClick={()=>{handleExPage('THB','GBP')}}> {t("newFooter.link.12")}</a> <span> |</span>
            {/* <a onClick={()=>{handleExPage('PKR','GBP')}}> {t("newFooter.link.13")}</a> <span> |</span> */}
            <a onClick={()=>{handleExPage('JPY','EUR')}}> {t("newFooter.link.08")}</a> <span> |</span>
            {/* <a onClick={()=>{handleExPage('PKR','AED')}}> {t("newFooter.link.09")} </a> <span> |</span> */}
            <a onClick={()=>{handleExPage('CHF','GBP')}}> {t("newFooter.link.010")}</a> <span> |</span>
            <a onClick={()=>{handleExPage('EUR','CHF')}}>{t("newFooter.link.011")}</a> <span> |</span>
            <a onClick={()=>{handleExPage('THB','INR')}}> {t("newFooter.link.012")}</a> 
            {/* <a onClick={()=>{handleExPage('PKR','INR')}}> {t("newFooter.link.013")}</a> <span> |</span> */}
          </div>
        </div>
      </div>
    </div>
  );
}
