import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLoginUserMutation } from '../../services/userAuthApi';
import { storeToken } from '../../services/LocalStorageService';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import logo from '../../static/images/logo.svg'


export default function Login() {
  const [loading,setLoading] = useState(false)
    

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setrememberMe] = useState('');
  const [error, showErorr] = useState(false)
  const [errorDetails, showErorrDetails] = useState('')

  const [loginUser ] = useLoginUserMutation() 


  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (email && password){
      setLoading(true)
      const loginData = {'email':email, 'password':password}
      console.log('Form submitted with:', loginData);
      const res = await loginUser(loginData)

      if (res) {
        console.log(res,'res is here')
      }

      if (res.error){

          showErorr(!error)
          console.log(res.error.data.message)
          showErorrDetails(res.error.data.message)
          setLoading(false)

      }else if(res.data) {
        // console.log(res,'response is here')
        // Clear the form fields after successful submission
        setEmail('');
        setPassword('');
        showErorr('')
        storeToken(res.data)
        navigate('/account-verification/', { replace: true });
      }
    }
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    showErorr(false); // Clear the error message when email changes
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    showErorr(false); // Clear the error message when password changes
  };


  const [eyeIcon, setEyeIcon] = useState(true)
  const eyeIconHandler = () => {
      setEyeIcon(!eyeIcon)
  }

  return (
    <div>
      <div className='container-fluid LoginMainDiv ps-0' >
        <div className='row ps-0'>
          <div className='col-6 px-0 LoginMainDivBg d-lg-block d-none ps-0'  >
              <div className='d-flex flex-column justify-content-between new_login_main_div h-100'>
               <Link to="/">
               <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_back_icon.svg" } alt="new IMg" className="back_icon" />
               </Link>

                <div className=''>
                  <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_login_logo.svg" } alt="new IMg" className="new_login_logo mb-3" />

                  <h1 className='new_heading'>
                  The Future of Banking
                  </h1>
                </div>
              </div>


          </div>
          <div className='col-lg-6 col-12 rightSideDivLogin py-md-5 py-0'  >
              <div className='d-flex justify-content-center '>
              <Link to="/" style={{margin:"auto",textAlign:"center"}}>
              <img loading='lazy' alt='Logo' src="../images/ev-logo.png"  className='manageLogoW pt-lg-3 pt-3'/>
              </Link>
                </div>
              <h2 className='loginTitle'>
              {t('LOGIN.H')}
              </h2>
              {
                error ? 
                  <div className='error_login_div d-flex gap-3 justify-content-center align-items-center mx-auto mb-4'>
                  <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg" } alt="new IMg" className="back_icon" />
                    <span className='new_error_text'>
                      {errorDetails}
                    </span>
                  </div> :
                  ""
              }
              {/* <p className='formError pt-3' >
                {error}
              </p> */}
              <form onSubmit={handleFormSubmit} className='SignInForm mt-lg-4 mt-3'>
              <div className="mb-5">
                  <label htmlFor="email" className="new_input_feilds_label"> {t('LOGIN.lb.1')}</label>
                  <input
                    type="email"
                    className="new_input_feilds"
                    id="email"
                    placeholder={t('LOGIN.ph.1')}
                    value={email}
                    onChange={handleEmailChange} // Use the new event handler
                    required
                    maxLength="50"
                  />
                </div>

              <div className="mb-2" style={{position:"relative"}}>
                <label htmlFor="message" className="new_input_feilds_label">{t('LOGIN.lb.2')}</label>
                <input
                    type={`${eyeIcon === true ? "password" : "text"}`}
                    className="new_input_feilds"
                    id="Password"
                    placeholder={t('LOGIN.ph.2')}
                    value={password}
                    onChange={handlePasswordChange} // Use the new event handler
                    required
                    maxLength="50"
                  />
                    {
                      eyeIcon === true ? 
                      <img loading='lazy' src={process.env.PUBLIC_URL + "/images/eye.svg" } alt="new IMg" className="eyeIcon" onClick={eyeIconHandler}/>
                      : 
                      <img loading='lazy' src={process.env.PUBLIC_URL + "/images/eye-crossed.svg" } alt="new IMg" className="eyeIcon" onClick={eyeIconHandler} />
                    }
                  
              </div>

              <div className="form-check mb-lg-2 mb-5 checkBoxDiv">
                <input className="form-check-input rememberMeText " type="checkbox"  id="flexCheckChecked"/>
                <label className="form-check-label rememberMeText" htmlFor="flexCheckChecked" value={rememberMe}
                    onChange={(e) => setrememberMe(e.target.value)}>
                 {t('LOGIN.lb.3')}
                </label>
              </div>
              <div className="authSubmitBtn mt-5 " style={{width:'100%'}}>
                <button type="submit" className={`button ${loading ? 'loading' : ''}`} style={{ "border": "none" }} >
                  {loading ? (
                    <div className="spinner"></div>
                  ) : (
                    <span>{t('LOGIN.lb.4')}</span>
                  )}
                </button>
                {/* <button className='s5BtnLogin'> <span className='s5Btntxt'> {t('LOGIN.lb.4')} </span> 
                </button>  */}
              </div>
              <div className='BottomTextDiv'>
              <p className='BottomText mb-0' style={{color:'#2645E0'}}>
              <Link to="/forgot-password" style={{cursor:'pointer',color:'#2645E0'}}>
              {t('LOGIN.p.1')}
              </Link>
              </p>
              <p className='BottomText' >
              {t('LOGIN.p.2')} <span ><Link to="/sign-up" style={{cursor:'pointer',color:'#2645E0'}}>{t('LOGIN.p.3')}</Link>  </span> 
              </p>
              </div>
      </form>
          </div>
        </div>
      </div>



    </div>
  )
}
