import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetLoggedUserQuery } from '../../services/userAuthApi'; // Adjust the import path
import { getToken } from '../../services/LocalStorageService';

const useAuthStatus = () => {
  const navigate = useNavigate();
  const { access_token } = getToken(); // Replace 'auth' with your actual state slice name

  // Call the query hook with the access token
  const { data: userData, isFetching, isError } = useGetLoggedUserQuery(access_token);

  const isAuthenticated = !!access_token; // User is authenticated if the token exists
  // const hasAccess = isAuthenticated && userData?.is_access; // Check for access based on user data
  const userProfileData = userData
  const alreadyVerified = userData?.is_verified

  useEffect(() => {
    // Redirect to login if the token is expired or invalid (indicated by isError)
    if (!isAuthenticated || isError) {
      navigate('/login'); // Change to your login route
    }
  }, [isAuthenticated, isError, navigate]);

  return { userProfileData, isAuthenticated, alreadyVerified, isFetching, isError };
};

export default useAuthStatus;





