import React ,{useState} from 'react'
function DropdownItem({ text,  onSelect }) {
    return (
      <button className="dropdown-item" onClick={() => onSelect(text)}>
         {text}
      </button>
    );
  }
export default function FiatTransferStepOne(props) {
    //consolelog('my props will be here', props.activeStatus )
    const [dropDown,showDropDown] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
    const dropDownHandle = () => {
        showDropDown(!dropDown)
    }

    const handleOptionSelect = (optionText) => {
      setSelectedOption({ text: optionText });
      showDropDown(false);
    };

    const sendDataToNextStep = (holderName,bankName,accnumber) => {
        props.handleNextStep('dataGet')
        props.setaccountHolderName(holderName)
        props.setbankName(bankName)
        props.setacoountNumber(accnumber)
    }
  return (
    <div className='cardContentDiv d-flex flex-column align-items-center mb-5'>
    <div className='col-11 btnDiv d-flex justify-content-between gap-4 mb-5'>
        <a className={`${props.activeStatus === 'saved' ? 'active' : "" }`} onClick={(e) => {props.activeFunc('saved')}}>
            Saved Recipients
        </a>
        <a className={`${props.activeStatus === 'new' ? 'active' : "" }`} onClick={(e) => {props.activeFunc('new')}}>
            New Recipient
        </a>
    </div>

   { props.activeStatus === 'saved' ? 

    <>
    <div className='col-12 mb-3' onClick={()=>{sendDataToNextStep('Steve Thompson','Clydesdale Bank','3042158-131434')}}>
        <div className='savedPaymentCard d-flex justify-content-between align-items-center'>
            <div className='rightSideContent'>
                <p className='name mb-2'>
                Steve Thompson
                </p>
                <div className='d-flex gap-1'>
                    <p className='accTitle mb-0'>
                    ACCOUNT NUMBER:
                    </p>
                    <p className='accNo mb-0'>
                    3042158-131434
                    </p>
                </div>
            </div>
            <div className='leftSideContent'>
                <div className='lastTransactionCard'>
                <p className='trTitle mb-1'>
                Recent Transaction
                </p>
                <div className='d-flex justify-content-between align-items-center gap-3'>
                    <p className='trAmount mb-0'>
                        $300  {''}
                         <span> 
                         Received
                        </span>
                    </p>
                    <p className='trDate mb-0'>
                        Aug 10
                    </p>
                </div>
                </div>
            </div>
        </div>
    </div>
    <div className='col-12 mb-3' onClick={()=>{sendDataToNextStep('Steve Thompson','Clydesdale Bank','3042158-131434')}}>
    <div className='savedPaymentCard d-flex justify-content-between align-items-center'>
        <div className='rightSideContent'>
            <p className='name mb-2'>
            Steve Thompson
            </p>
            <div className='d-flex gap-1'>
                <p className='accTitle mb-0'>
                ACCOUNT NUMBER:
                </p>
                <p className='accNo mb-0'>
                3042158-131434
                </p>
            </div>
        </div>
        <div className='leftSideContent'>
            <div className='lastTransactionCard'>
            <p className='trTitle mb-1'>
            Recent Transaction
            </p>
            <div className='d-flex justify-content-between align-items-center gap-3'>
                <p className='trAmount mb-0'>
                    $300  {''}
                     <span> 
                     Received
                    </span>
                </p>
                <p className='trDate mb-0'>
                    Aug 10
                </p>
            </div>
            </div>
        </div>
    </div>
    </div>
    <div className='col-12 mb-3' onClick={()=>{sendDataToNextStep('Steve Thompson','Clydesdale Bank','3042158-131434')}}>
    <div className='savedPaymentCard d-flex justify-content-between align-items-center'>
        <div className='rightSideContent'>
            <p className='name mb-2'>
            Steve Thompson
            </p>
            <div className='d-flex gap-1'>
                <p className='accTitle mb-0'>
                ACCOUNT NUMBER:
                </p>
                <p className='accNo mb-0'>
                3042158-131434
                </p>
            </div>
        </div>
    </div>
    </div>
    </>

   : 
   <div className='col-10 newPaymentDiv mt-4'>
        <div className='FDInputDiv mb-4'>
            <h6 className='ps-1 inputTitle mb-3'>
            Bank Name
            </h6>
            <input type='text' placeholder='Bank Name' className='form-control fTransferInput' value={props.bankName} onChange={(e)=>{props.setbankName(e.target.value)}}/>
        </div>
        <div className='FDInputDiv mb-4'>
            <h6 className='ps-1 inputTitle mb-3'>
            Recipients Name
            </h6>
            <input type='text' placeholder='Steve Thompson' className='form-control fTransferInput' value={props.accountHolderName} onChange={(e)=>{props.setaccountHolderName(e.target.value)}}/>
        </div>
        <div className='FDInputDiv mb-4'>
            <h6 className='ps-1 inputTitle mb-3'>
            Account Number
            </h6>
            <input type='number' placeholder='Account Number' className='form-control fTransferInput' value={props.acoountNumber} onChange={(e)=>{props.setacoountNumber(e.target.value)}}/>
        </div>
        <div className='FDInputDiv mb-4'>
            <h6 className='ps-1 inputTitle mb-3'>
            Sort Code
            </h6>
            <input type='number' placeholder='123' className='form-control fTransferInput' value={props.sortCode} onChange={(e)=>{props.setsortCode(e.target.value)}}/>
        </div>
        <div className='FDInputDiv '>
            <h6 className='ps-1 inputTitle mb-3'>
            IBAN
            </h6>
            <input type='number' placeholder='345511000111123214' className='form-control fTransferInput' value={props.iban} onChange={(e)=>{props.setiban(e.target.value)}}/>
        </div>

   </div>

   }
</div>
  )
}
