import React from 'react'
import '../../static/css/common.css'
function CookieModal({ isOpen, onClose }) {
    return (
        <div>
          <div className={`overlay ${isOpen ? 'open' : ''}`} ></div>
          <div className={`modal-container ${isOpen ? 'open' : ''}`}>
            <div className='upperBorder'></div>
            <div className="modal-contents">
                <h3 className=''>
                Cookies on Evistia.com
                </h3>
                <p className=''>
                We use some essential cookies to make this website work.
                <br></br>
                <br></br>

                We’d like to set additional cookies to understand how you use our website, remember your settings and improve our banking services.
                <br></br>
                <br></br>

                We also use cookies set by other sites to help us deliver content from their services.
                </p>
                <div className='btns d-flex gap-4 my-3'>
                    <a className='acceptBtn' onClick={onClose}>
                    Accept Cookies
                    </a>
                    <a className='rejectBtn' data-bs-toggle="modal" data-bs-target="#staticBackdrop" >
                    Manage Cookies
                    </a>
                </div>
            </div>
          </div>




<div className="modal fade cookeisModal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      {/* <div className="modal-header">
        <h1 className="modal-title fs-5" id="staticBackdropLabel">Modal title</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> */}
      <div className="modal-body p-4">
      
                <p className='cookieModalPara text-center'>
                We use cookies to improve the functionality of our site, while personalizing content and
                ads. You can enable or disable optional cookies as desired. For more detailed
                information about the cookies we use, see our <span className='cookieModalPara01'>Cookie Policy</span>.
                </p>
                <p className='cookieModalPara02'>
                Manage Cookies
                </p>
                <p className='cookieModalPara02'>
                Required cookies are always on.
                </p>

                <div className="form-check mb-lg-2 mb-md-5 mb-2 checkBoxDiv">
                  <input className="form-check-input rememberMeText " type="checkbox"  id="flexCheckChecked" style={{width:"1.5em",height:"1.5em"}} defaultChecked/>
                  <label className="form-check-label cookieModalPara03 ps-2 mt-1" htmlFor="flexCheckChecked"> 
                  Necessary cookies (Always enabled)
                  </label>
                </div>
                  <p className='cookieModalPara ps-4'>
                  Required cookies are key to the operation of our site. They enable you to navigate our website and use its features. They cannot be turned off. Without these cookies, the website cannot function properly. Please be aware that these don't collect information that can identify you.
                  </p>

                  <div className="form-check mb-lg-2 mb-md-5 mb-2 checkBoxDiv">
                  <input className="form-check-input rememberMeText " type="checkbox"  id="flexCheckChecked01" style={{width:"1.5em",height:"1.5em"}}/>
                  <label className="form-check-label cookieModalPara03 ps-2 mt-1" htmlFor="flexCheckChecked01"> 
                  Analytics cookies
                  </label>
                </div>
                  <p className='cookieModalPara ps-4'>
                  These cookies help to improve our website by collecting information about your interactions with it. They show us which pages are most frequently visited and help us to record the difficulties that you may have while using our website.
                  </p>


                  <div className="form-check mb-lg-2 mb-md-5 mb-2 checkBoxDiv">
                  <input className="form-check-input rememberMeText " type="checkbox"  id="flexCheckChecked02" style={{width:"1.5em",height:"1.5em"}}/>
                  <label className="form-check-label cookieModalPara03 ps-2 mt-1" htmlFor="flexCheckChecked02"> 
                  Performance/functionality cookies
                  </label>
                </div>
                  <p className='cookieModalPara ps-4'>
                  These cookies are used to enhance the performance and functionality Of our website but are nan-essential. However, without these cookies, certain functionalities like videos may become unavailable, or you would be required to enter your login details every time you visit our website as we would not be able to remember that you had logged in previously.
                  </p>

                  <div className="form-check mb-lg-2 mb-md-5 mb-2 checkBoxDiv">
                  <input className="form-check-input rememberMeText " type="checkbox"  id="flexCheckChecked03" style={{width:"1.5em",height:"1.5em"}}/>
                  <label className="form-check-label cookieModalPara03 ps-2 mt-1" htmlFor="flexCheckChecked03"> 
                  Advertisement cookies
                  </label>
                </div>
                  <p className='cookieModalPara ps-4'>
                  These cookies collect information about your online activities over time, in order to personalize advertisements. They also allow us to limit the number of times you see our ads across your devices and measure the effectiveness of our marketing campaigns.
                  </p>
                <div className='btns w-100 d-flex flex-md-row flex-column justify-content-center gap-4 my-md-3'>
                    <a className='cookieModalRejectBtn' data-bs-dismiss="modal"  >
                    Browse with Selected Cookies
                    </a>
                    <a className='cookieModalAcceptBtn' data-bs-dismiss="modal"  onClick={onClose}>
                    Accept All Cookies
                    </a>
              </div>
      </div>
      {/* <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Understood</button>
      </div> */}
    </div>
  </div>
</div>
        </div>
      );
}

export default CookieModal