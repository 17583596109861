import React, { useState } from 'react';
import { FaGlobe } from 'react-icons/fa';
import navBarIcon from '../../static/images/navIcon.svg'
import navX from '../../static/images/navX.svg'

import '../../static/css/navbar.css';
import '../../static/css/common.css';
import '../../static/css/responsive.css'
import { Link } from 'react-router-dom';
import { getToken } from '../../services/LocalStorageService';
import { useTranslation } from 'react-i18next';




export default function NavBar() {
  const { t, i18n } = useTranslation();
  const [navIcon,setNavIcon] = useState(navBarIcon)
  const handleChangeLanguage = (e) => {
    //consolelog('click on change lang btn ' ,  'clicked lang i here')
    i18n.changeLanguage(e.target.lang);
    // e.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.remove('show')

  }




  // const navigate = useNavigate()
  const {access_token} = getToken()

  console.log(access_token,'my access Token is here')
  // const logOutUser = () => {
  //   //consolelog('click on Logout Btn')
  //   removeToken()
  //   navigate('/login')
  // }

  const [dropdownStates, setDropdownStates] = useState({
    personal: false,
    products: false,
    more: false,
  });

  const showDropdown = (dropdownName) => {
    setDropdownStates((prevState) => {
      const updatedStates = {};
  
      // Set the target dropdown to true
      updatedStates[dropdownName] = true;
  
      // Set all other dropdowns to false
      Object.keys(prevState).forEach((name) => {
        if (name !== dropdownName) {
          updatedStates[name] = false;
        }
      });
  
      return updatedStates;
    });
  };

  const hideDropdown = (dropdownName) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [dropdownName]: false,
    }));
  };

  const hideDropdownNb = (dropdownO , dropdown1 , dropdown2) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [dropdownO]: false,
      [dropdown1]: false,
      [dropdown2]: false,

    }));
  };
  
  const [navupperIcon,setNavUpperIcon] = useState(true)
  const navIconHandler = (e) => {
    //consolelog(e)
    setNavUpperIcon(false)
    setNavIcon('')
  }

  const closeNavBarHandler = (e) => {
    //consolelog('click on clodse')
    const navBarToggle = e.parentNode.parentNode.parentNode.classList
    navBarToggle.remove('show')
    //consolelog(navBarToggle, 'navBarToggle')
    setNavUpperIcon(true)
    setNavIcon(navBarIcon)
  }

  // const handlleOnClickSignUp = (e)=>{
  //   e.preventdefault()
  //   navigate('/sign-up/')
  // }

  

  return (
    // <nav className="navbar navbar-expand-lg navbar-light bg-light  pb-0">
    //   {/* Logo */}
    //   <div className='container-fluid ps-5 pe-2 navbarContainer'>
    //     <Link className="navbar-brand" to="/">
          
    //     </Link>
    //     <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    //   <span className="navbar-toggler-icon"></span>
    // </button>
    //     {/* Center Content */}
    //     <div className="collapse navbar-collapse" id="navbarSupportedContent"></div>
    //     <ul className="navbar-nav  align-items-center  ">
    //       {/* Personal Dropdown */}
          
    //     </ul>

    //     {/* Right-side Buttons */}
    //     <div className="navbar-nav ml-auto align-items-center pe-5">
        
    //     </div>
    //   </div>
    // </nav>






  <nav className="navbar navbar-expand-lg bg-body-tertiary  py-0 navbarContainer fixed-top" onMouseLeave={() => hideDropdownNb('more' , 'personal' , 'products' )} >
    <Link className="navbar-brand m-md-auto m-sm-auto m-auto d-sm-block d-none" to="/">
      <img loading='lazy' src={process.env.PUBLIC_URL + "/images/logo.svg" } alt="Logo" width="30" height="30" className="navbarLogo" /></Link>
    <Link className="navbar-brand m-md-auto m-sm-auto m-auto d-sm-none d-block" to="/">
    <img loading='lazy' src={process.env.PUBLIC_URL + "/images/evistia logo@4x.png" } alt="Logo" width="30" height="30" className="navbarLogo" />
    </Link>
    {
      navupperIcon === true ? 
      <button 
      className="navbar-toggler" 
      type="button" 
      data-bs-toggle="collapse" 
      data-bs-target="#navbarSupportedContent" 
      aria-controls="navbarSupportedContent" 
      aria-expanded="false" 
      aria-label="Toggle navigation" 
      onClick={(e) => navIconHandler(e.target)} 
      style={{ border: "none", outline: "none", boxShadow: "none" }}
    >

      <img loading='lazy' src={navIcon} alt='nav Icon'/>
    </button>
    :""
    }
    <h3 className='px-3 hrSmShow' data-bs-toggle="modal" data-bs-target="#exampleModal" style={{cursor:"pointer"}}>
          <FaGlobe></FaGlobe>
    </h3>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0 ml-auto navBarCenterdLinks" >
      <li className="custom-dropdown text-end navMb">
      <img loading='lazy' src={navX} onClick={(e) => closeNavBarHandler(e.target)} alt='nav Icon'/>
      </li>

      {/* <li className="custom-dropdown pt-md-4 pt-0 pb-4 ">
            <span className='removeStyleA navbar_titles ' onMouseEnter={() => showDropdown('personal')}  >
            {t('navbar.link_1')}     {'\u00a0'} {dropdownStates.personal ? <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M0.229222 0.236534C0.534852 -0.0788445 1.03037 -0.0788445 1.336 0.236534L6.00001 5.05008L10.664 0.236534C10.9696 -0.0788445 11.4652 -0.0788445 11.7708 0.236534C12.0764 0.551966 12.0764 1.0634 11.7708 1.37883L6.55342 6.76347C6.24778 7.07885 5.75223 7.07885 5.4466 6.76347L0.229222 1.37883C-0.0764075 1.0634 -0.0764075 0.551966 0.229222 0.236534Z" fill="black"/>
                </svg> : <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M0.229222 0.236534C0.534852 -0.0788445 1.03037 -0.0788445 1.336 0.236534L6.00001 5.05008L10.664 0.236534C10.9696 -0.0788445 11.4652 -0.0788445 11.7708 0.236534C12.0764 0.551966 12.0764 1.0634 11.7708 1.37883L6.55342 6.76347C6.24778 7.07885 5.75223 7.07885 5.4466 6.76347L0.229222 1.37883C-0.0764075 1.0634 -0.0764075 0.551966 0.229222 0.236534Z" fill="black"/>
                </svg>}
            </span>
            <div className={`navBarDropDowm dropdown-menu ${dropdownStates.personal ? 'show' : ''}`}  onMouseLeave={() => hideDropdown('personal')}>
              <Link className="dropdown-item" to="personal-account/" onClick={() => hideDropdown('personal')}>{t('navbar.dd.1.1')}</Link>
              <Link className="dropdown-item" to="savings-account/"  onClick={() => hideDropdown('personal')}>{t('navbar.dd.1.2')}</Link>
            </div>
      </li> */}

      {/* Business Link */}
          <li className='navbar_titles py-4'>
            <Link className="nav-item nav-link navbar_titles" to="personal-account/">{t('navbar.link_1')} </Link>
          </li>
 
          {/* Business Link */}
          <li className='navbar_titles py-4'>
          <Link className="nav-item nav-link navbar_titles" to="buisness-account/">{t('navbar.link_2')} </Link>
          </li>

          {/* Products Dropdown */}
          <li className="custom-dropdown py-4">
            <span className='removeStyleA navbar_titles'  onMouseEnter={() => showDropdown('products')} >
            {t('navbar.link_3')}  {'\u00a0'} {dropdownStates.products ? <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M0.229222 0.236534C0.534852 -0.0788445 1.03037 -0.0788445 1.336 0.236534L6.00001 5.05008L10.664 0.236534C10.9696 -0.0788445 11.4652 -0.0788445 11.7708 0.236534C12.0764 0.551966 12.0764 1.0634 11.7708 1.37883L6.55342 6.76347C6.24778 7.07885 5.75223 7.07885 5.4466 6.76347L0.229222 1.37883C-0.0764075 1.0634 -0.0764075 0.551966 0.229222 0.236534Z" fill="black"/>
                </svg> :  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M0.229222 0.236534C0.534852 -0.0788445 1.03037 -0.0788445 1.336 0.236534L6.00001 5.05008L10.664 0.236534C10.9696 -0.0788445 11.4652 -0.0788445 11.7708 0.236534C12.0764 0.551966 12.0764 1.0634 11.7708 1.37883L6.55342 6.76347C6.24778 7.07885 5.75223 7.07885 5.4466 6.76347L0.229222 1.37883C-0.0764075 1.0634 -0.0764075 0.551966 0.229222 0.236534Z" fill="black"/>
                </svg>}
            </span>
            <div className={`navBarDropDowm dropdown-menu ${dropdownStates.products ? 'show' : ''}`} onMouseLeave={() => hideDropdown('products')}>
              <Link className="dropdown-item" to="crypto/">{t('navbar.dd.2.1')}</Link>
              <Link className="dropdown-item" to="digital-wallet/">{t('navbar.dd.2.2')}</Link>
              <Link className="dropdown-item" to="cards/">{t('navbar.dd.2.3')}</Link>
            </div>
          </li>

          {/* More Dropdown */}
          <li className="custom-dropdown py-4">
            <span className='removeStyleA navbar_titles'  onMouseEnter={() => showDropdown('more')} >
            {t('navbar.link_4')}  {'\u00a0'}
              {dropdownStates.more ? <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M0.229222 0.236534C0.534852 -0.0788445 1.03037 -0.0788445 1.336 0.236534L6.00001 5.05008L10.664 0.236534C10.9696 -0.0788445 11.4652 -0.0788445 11.7708 0.236534C12.0764 0.551966 12.0764 1.0634 11.7708 1.37883L6.55342 6.76347C6.24778 7.07885 5.75223 7.07885 5.4466 6.76347L0.229222 1.37883C-0.0764075 1.0634 -0.0764075 0.551966 0.229222 0.236534Z" fill="black"/>
                </svg> : <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M0.229222 0.236534C0.534852 -0.0788445 1.03037 -0.0788445 1.336 0.236534L6.00001 5.05008L10.664 0.236534C10.9696 -0.0788445 11.4652 -0.0788445 11.7708 0.236534C12.0764 0.551966 12.0764 1.0634 11.7708 1.37883L6.55342 6.76347C6.24778 7.07885 5.75223 7.07885 5.4466 6.76347L0.229222 1.37883C-0.0764075 1.0634 -0.0764075 0.551966 0.229222 0.236534Z" fill="black"/>
                </svg>}
            </span>
            <div className={`navBarDropDowm dropdown-menu ${dropdownStates.more ? 'show' : ''}`} onMouseLeave={() => hideDropdown('more')}>
              <Link className="dropdown-item" to="about-us/">{t('navbar.dd.3.1')}</Link>
              <Link className="dropdown-item" to="careers/">{t('navbar.dd.3.2')}</Link>
            </div>
          </li>

          {/* Blog Link */}
          <li className='navbar_titles py-4'>
          <Link className="nav-item nav-link navbar_titles" to="blog/">{t('navbar.link_5')} </Link>
          </li>
      </ul>
      <form className="d-flex flex-xxl-row flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column align-items-xxl-center align-items-xl-center align-items-xl-center align-items-lg-center  align-items-start" role="search">
      {/* {access_token ?  <span className="navbar_titles_rightSide py-md-4 py-sm-4 py-4" onClick={()=>{navigate('/wallet/index')}} style={{cursor:'pointer'}}> {t('navbar.link_6.2')} </span> : <Link className="navbar_titles_rightSide py-md-4 py-sm-4 py-4" to="/login" > {t('navbar.link_6.1')} </Link> } */}
      
          {/* Transparent Gradient Border Button */}
          {
            access_token ? <Link className="navbar_titles_rightSide py-md-4 py-sm-4 py-4" to="/wallet/index" > 
                              <div className="transparent-gradient-border-btn-nav  hrSmHide">
                                  <button className="button-content">
                                    {" "}
                                    <span className='gradient-text'>{t('navbar.link_6.2')} </span>
                                  </button>{" "}
                              </div>
                            </Link> : 
                            
                            <Link to="/sign-up/">
                                    <div className="transparent-gradient-border-btn-nav  hrSmHide">
                                      <button className="button-content">
                                        {" "}
                                        <span className='gradient-text'>{t('navbar.link_6.3')} </span>
                                      </button>{" "}
                                    </div>
                              </Link>
          }
          {/* <div className="transparent-gradient-border-btn-nav  hrSmHide">
           <button className='button-content' onClick={(e)=>{handlleOnClickSignUp(e)}}> <span className='gradient-text'> {t('navbar.link_6.3')} </span> 
            </button>  </div> */}
          <h3 className='px-3 hrSmHide' data-bs-toggle="modal" data-bs-target="#exampleModal" style={{cursor:"pointer"}}>
          <FaGlobe></FaGlobe>
          </h3>
      </form>
    </div>


  <>

<div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{top:'5rem'}}>
  <div className="modal-dialog modal-xl">
    <div className="modal-content">
      {/* <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> */}
      <div className="modal-body">
      <div className="row">

                {/* row  1 */}
                <div className="language-selector col-4">
                    <div className="language-option" onClick={(e) => {handleChangeLanguage(e)}} lang="en" data-bs-dismiss="modal">English</div>
                </div>
               

                 {/* row 2  */}
                 <div className="language-selector col-4">
                    <div className="language-option" onClick={(e) => {handleChangeLanguage(e)}} lang="pl" data-bs-dismiss="modal">Polish</div>
                </div>




                {/* row 3 */}
                <div className="language-selector col-4">
                    <div className="language-option" onClick={(e) => {handleChangeLanguage(e)}} lang="zh" data-bs-dismiss="modal">Chinese</div>
                </div>
               


                <div className="language-selector col-4">
                    <div className="language-option" onClick={(e) => {handleChangeLanguage(e)}} lang="fr" data-bs-dismiss="modal">French</div>
                </div>

                <div className="language-selector col-4">
                    <div className="language-option" onClick={(e) => {handleChangeLanguage(e)}} lang="rum" data-bs-dismiss="modal">Romanian</div>
                </div>

                <div className="language-selector col-4">
                    <div className="language-option" onClick={(e) => {handleChangeLanguage(e)}} lang="ja" data-bs-dismiss="modal">Japanese</div>
                </div>



               
                <div className="language-selector col-4">
                    <div className="language-option" onClick={(e) => {handleChangeLanguage(e)}} lang="de" data-bs-dismiss="modal">German</div>
                </div>

                <div className="language-selector col-4">
                    <div className="language-option" onClick={(e) => {handleChangeLanguage(e)}} lang="ar" data-bs-dismiss="modal">Arabic</div>
                </div>

                <div className="language-selector col-4">
                    <div className="language-option" onClick={(e) => {handleChangeLanguage(e)}} lang="th" data-bs-dismiss="modal">Thai</div>
                </div>

                <div className="language-selector col-4">
                    <div className="language-option" onClick={(e) => {handleChangeLanguage(e)}} lang="es" data-bs-dismiss="modal">Spanish</div>
                </div>
                <div className="language-selector col-4">
                    <div className="language-option" onClick={(e) => {handleChangeLanguage(e)}} lang="hi" data-bs-dismiss="modal">Hindi</div>
                </div>
                <div className="language-selector col-4">
                    <div className="language-option" onClick={(e) => {handleChangeLanguage(e)}} lang="vi" data-bs-dismiss="modal">Vietnamese</div>
                </div>
                <div className="language-selector col-4">
                    <div className="language-option" onClick={(e) => {handleChangeLanguage(e)}} lang="swe" data-bs-dismiss="modal">Swedish</div>
                </div>
                
                <div className="language-selector col-4">
                    
                </div>

                <div className="language-selector col-4">
                    <div className="language-option" onClick={(e) => {handleChangeLanguage(e)}} lang="ru" data-bs-dismiss="modal">Russian</div>
                </div>
                
                
                
                
                
                {/* <div className="language-selector col-4">
                    <div className="language-option" onClick={(e) => {handleChangeLanguage(e)}} lang="fa" data-bs-dismiss="modal">Persian</div>
                </div> */}

                </div>
            </div>

      {/* <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div> */}
    </div>
  </div>
</div>
    </>
</nav>
  );
}
