import React , {useState,useEffect} from 'react'
import AnimatedPage from '../AnimatedPage';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import globeImg from '../../static/images/Ice Globenew2.jpg'
import { CurrencyDropdownItems } from '../../constants/AllCurencies';
import { currencyMapping } from '../../constants/currencyMapping';
import { useTranslation } from 'react-i18next'
import { getToken } from '../../services/LocalStorageService';
function DropdownItem({ text,code, imgSrc, onSelect }) {
    return (
      <button className="dropdown-item d-flex gap-3 align-items-center" onClick={() => onSelect(text,code, imgSrc)}>
        <img loading='lazy' src={process.env.PUBLIC_URL + imgSrc} alt="Currency Image" className="currencySwapOptionImg" />
        <div className='d-flex flex-column'>
        {text} 
        <span>{code}</span>
        </div>
      </button>
    );
  }
  
  function DropdownItem1({ text,code, imgSrc, onSelect }) {
    return (
      <button className="dropdown-item d-flex gap-3 align-items-center" onClick={() => onSelect(text,code, imgSrc)}>
        <img loading='lazy' src={process.env.PUBLIC_URL + imgSrc} alt="Currency Image" className="currencySwapOptionImg" /> 
        <div className='d-flex flex-column'>
        {text} 
        <span>{code}</span>
        </div>
      </button>
    );
  }





function SendToCountry() {
    const {access_token} = getToken();
    const {t} = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const [apiVal ,setapiVal ] = useState()
    const [firstCurrencySign ,setFirstCurrencySign ] = useState()
    const [secondCurrencySign ,setSecondCurrencySign ] = useState() 

    const [firstCurrencyVal ,setFirstCurrencyVal ] = useState(1000)


    const firstCurrency = location?.state?.first?.apiShortName
    const secondCurrency = location?.state?.second?.apiShortName

    const shortName1 = location?.state?.first?.shortName
    const shortName2 = location?.state?.second?.shortName

    const firstLogo = location?.state?.first?.imgSrc
    const secondLogo = location?.state?.second?.imgSrc

    const firstCurrencyNames = location?.state?.first?.currency
    const secondCurrencyNames = location?.state?.second?.currency


    const firstCurrencysigns = location?.state?.first?.sign
    const secondCurrencysigns = location?.state?.second?.sign

    const secondCountry = location?.state?.second?.country
    const firstCountry = location?.state?.first?.country

    useEffect(() => {
        handleOptionSelect(secondCurrencyNames,secondCurrencyNames,secondLogo)
        handleOptionSelect1(firstCurrencyNames,firstCurrencyNames,firstLogo)
        setFirstCurrencySign(firstCurrencysigns)
        setSecondCurrencySign(secondCurrencysigns)
    }, [])

    const handleFirstCurrencyInput = (e) => {
        setFirstCurrencyVal(e)
    }

    // const apiData = { from_currency: firstCurrency.toUpperCase(), to_currency: secondCurrency.toUpperCase(), amount: Number(firstCurrencyVal) }
    // setApiError(``)
    // console.log('conersion api data', apiData)
    // // Fetch API call
    // fetch('https://evistialab.com/api/v1/convert-fiat-price/',

    useEffect(() => {

        if (firstCurrencyVal !== 0) {
            // Prepare the form data to be sent in the request
            const apiData = { from_currency: firstCurrency.toUpperCase(), to_currency: secondCurrency.toUpperCase(), amount: Number(firstCurrencyVal) }
            console.log('conersion api data', apiData)
            // Fetch API call
            fetch('https://evistialab.com/api/v1/convert-fiat-price/', {
                method: 'POST',  // Assuming it's a POST request
                headers: {
                    'Content-Type': 'application/json',  // Assuming the API expects JSONrequired
                },
                body: JSON.stringify(apiData)  // Convert the JavaScript object to a JSON string
            })
                .then(response => {
                    if (!response.ok) {

                        // if (response.status === 401) {
                        //     navigate('/login')
                        // }
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(data,'data is here')
                    const formattedAmount = Number(data.exchange_rate).toFixed(2);
                    setapiVal(formattedAmount)
                })
                .catch(error => console.error('Error fetching data:', error));
        }

    }, [apiVal]);





    // useEffect(() => {
    //     fetch(`https://evistialab.com/api/user/convert/fiat-to-fiat/?amount=${1}&from_fiat=${firstCurrency}&to_fiat=${secondCurrency}`)
    //       .then(response => response.json())
    //       .then(data => {
    //           const formattedAmount = Number(data.converted_amount).toFixed(2);
    //           setapiVal(formattedAmount)
    //       })
    //       .catch(error => console.error('Error fetching data:', error));
    //   }, []); 


 





    const handleCountryPage = (firstCurrency, secondCurrency) => {
    // Retrieve currency information from the mapping
    const firstCurrencyInfo = currencyMapping[firstCurrency];
    const secondCurrencyInfo = currencyMapping[secondCurrency];
    
    // Check if currencyInfo exists before using it
    if (firstCurrencyInfo && secondCurrencyInfo) {
        const { sign: firstSign, shortName: firstShortName, apiShortName : firstApiShortName, imgSrc: firstImgSrc , country : firstCountry } = firstCurrencyInfo;
        const { sign: secondSign, shortName: secondShortName, apiShortName : secondApiShortName,  imgSrc: secondImgSrc , country :secondCountry ,} = secondCurrencyInfo;

    
        // Navigate to the exchange rates page with currency information
        navigate(`/send-to-country/${firstApiShortName}/to/${secondApiShortName}/`, {
        state: {
            first: { currency: firstShortName, sign: firstSign, apiShortName : firstApiShortName, imgSrc: firstImgSrc , country : firstCountry},
            second: { currency: secondShortName, sign: secondSign, secondShortName, apiShortName : secondApiShortName, imgSrc: secondImgSrc ,country :secondCountry },
        },
        });
    }
    };



    const [dropDown,showDropDown] = useState(false)
    const [dropDown1,showDropDown1] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOption1, setSelectedOption1] = useState(null);




    const dropDownHandle = () => {
        showDropDown(!dropDown)
    }
    const dropDownHandle1 = () => {
        showDropDown1(!dropDown1)
    }

    const handleOptionSelect = (optionText,optionCode, optionImgSrc) => {
      setSelectedOption({ text: optionText,code:optionCode, imgSrc: optionImgSrc });
      showDropDown(false);
      handleCountryPage(firstCurrencyNames,optionCode)
  };
  
  const handleOptionSelect1 = (optionText,optionCode, optionImgSrc) => {
    setSelectedOption1({ text: optionText,code:optionCode, imgSrc: optionImgSrc });
    showDropDown1(false);
    handleCountryPage(optionCode,secondCurrencyNames)
  };



    return (
        <AnimatedPage>
          <div >
            {/* Section One Start  */}
              <div className='homepageContainerP3 pt-5 mt-5 pb-0' >
                  <div className='container-fluid'>
                      <div className='row sendMoneyRow' style={{position:"relative"}}>
                        <div className='col-md-5 col-12 pt-sm-5 pt-3'>
                            <h3 className='exHeading'  >
                            {t('SC.s1.h.1')} <br></br> {secondCountry}
                            </h3>
                            <p className='exPara ps-1'  >
                            {t('SC.s1.p.1')} {firstCountry} {`(${firstCurrencyNames})`} {t('SC.s1.sw.1')}{secondCountry}  {`(${secondCurrencyNames})`}  {t('SC.s1.p.2')}
                            </p>
                        </div>
                        <div className='col-md-7 col-12 exRightDiv01'  >
                        <img src={globeImg} alt="Section Side Images" className="exImgbottomImg01" style={{width:"75%"}}/>

                        <div className='exCard01 '>
                            <div className='detailRow'>
                                <p>
                                {t('SC.s1.p.3')}
                                </p>
                                <div className='row '>
                                    <div className='col-6'>
                                    <input type='number' className='form-control exInput01' placeholder='1,000' value={firstCurrencyVal} onChange={(e)=>{handleFirstCurrencyInput(e.target.value)}}/>
                                    </div>
                                    <div className=' col-6 exSwap  d-flex gap-3 align-items-start justify-content-end' onClick={dropDownHandle1}>
                                        <div className='d-flex align-items-start gap-2'>
                                            <img loading='lazy' src={process.env.PUBLIC_URL + selectedOption1?.imgSrc || process.env.PUBLIC_URL + '/images/g.svg'} alt="Currency Image" className="currencyLogo" />
                                            <h3 className='mb-0 gradinatTxt'>{selectedOption1?.code || 'GBP'}</h3>
                                            <img loading='lazy' src="../../../../images/WHPArrowD.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow mt-2"/>
                                        </div>
                                        <div className={`dropdown-menu CurrencySwapDropDown ${dropDown1 ? 'show' : ''}`} onMouseLeave={() => showDropDown1(false)}>
                                            {CurrencyDropdownItems.map((item, index) => (
                                                <DropdownItem1 key={index} text={item.name} code={item.currency} imgSrc={item.imgSrc}  onSelect={handleOptionSelect1} />
                                            ))}
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </div>

                            <div className='detailRow  w-100'>
                                <div className='d-flex justify-content-between w-100'>
                                    <h5 className='feeHeading mb-0'>
                                    - {firstCurrencySign} 1.50
                                    </h5>
                                    <h6 className='feeTxt mb-0'>
                                    {t('SC.s1.p.4')} <img loading='lazy' src="../../../../images/WHPArrowD.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow "/>
                                    </h6>
                                </div>

                                <div className='d-flex justify-content-between w-100'>
                                    <h5 className='feeHeading mb-0'>
                                     = {firstCurrencySign} {firstCurrencyVal > 0 ? firstCurrencyVal- 1.50 : 0}
                                    </h5>
                                    <h6 className='feeTxt mb-0'>
                                    {t('SC.s1.p.5')}
                                    </h6>
                                </div>

                                <div className='d-flex justify-content-between w-100'>
                                    <h5 className='feeHeading mb-0'>
                                     x {apiVal}
                                    </h5>
                                    <h6 className='feeTxt mb-0'>
                                    {t('SC.s1.p.6')}
                                    </h6>
                                </div>
                            </div>

                            <div className='detailRow'>
                                <p>
                                {t('SC.s1.p.7')}
                                </p>
                                <div className='row '>
                                    <div className='col-6'>
                                        <input type='number' className='form-control exInput01' placeholder={`${secondCurrencySign}${firstCurrencyVal * apiVal}`} value={`${secondCurrencySign}${firstCurrencyVal * apiVal}`} readOnly />
                                    </div>
                                    <div className=' col-6 exSwap  d-flex gap-3 align-items-start justify-content-end' onClick={dropDownHandle}>
                                        <div className='d-flex align-items-start gap-2'>
                                            <img loading='lazy' src={process.env.PUBLIC_URL + selectedOption?.imgSrc || process.env.PUBLIC_URL + '/images/eur.svg'} alt="Currency Image" className="currencyLogo" />
                                            <h3 className='mb-0 gradinatTxt'>{selectedOption?.code || 'EUR'}</h3>
                                            <img loading='lazy' src="../../../../images/WHPArrowD.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow mt-2"/>
                                        </div>

                                        <div className={`dropdown-menu CurrencySwapDropDown ${dropDown ? 'show' : ''}`} onMouseLeave={() => showDropDown(false)}>
                                            {CurrencyDropdownItems.map((item, index) => (
                                                <DropdownItem key={index} text={item.name} code={item.currency} imgSrc={item.imgSrc} onSelect={handleOptionSelect} />
                                            ))}
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>


                                <a onClick={()=>{navigate('/login')}} className='convertBtn mt-3'>
                                {t('SC.s1.sw.2')}
                                </a>
                        </div>

                        
                        </div>
                        <img loading='lazy' src="../../../../../images/Vector 5 (1).png" alt="Section One Right Side Images" className="downLineCm" style={{bottom:"5%"}}/>
                      </div>
                      
                  </div>
              </div>
  
            {/* Section One End  */}


             {/* Section Two Start  */}
             <div className='homepageContainerP3' >
                  <div className='container-fluid'>
                      <div className='row px-md-5 px-0'>
                            <p className='exHeadingH1 mb-5'  >
                                
                                <b
                                className='exHeadingB ps-2'  >
                                    {t('SC.s1.p.8')} {secondCountry}, <br className='d-sm-block d-none'></br>  {t('SC.s1.p.9')}
                                
                                </b>
                               
                            </p>


                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <div className='exDetailsCardBg'>
                                    <div className='exDetailsCardCover'>
                                        <img loading='lazy' src="../../../../../images/01.svg" alt="Section One Right Side Images" className=""/>
                                        <h3 className='mb-0'>
                                        {t('SC.s1.p.10')}
                                        </h3>
                                        <p>
                                        {t('SC.s1.p.11')} {secondCountry}  {t('SC.s1.p.12')}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <div className='exDetailsCardBg'>
                                    <div className='exDetailsCardCover'>
                                        <img loading='lazy' src="../../../../../images/02.svg" alt="Section One Right Side Images" className=""/>
                                        <h3 className='mb-0'>
                                        {t('SC.s1.p.13')}
                                        </h3>
                                        <p>
                                        {t('SC.s1.p.14')}
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <div className='exDetailsCardBg'>
                                    <div className='exDetailsCardCover'>
                                        <img loading='lazy' src="../../../../../images/03.svg" alt="Section One Right Side Images" className=""/>
                                        <h3 className='mb-0'>
                                        {t('SC.s1.p.15')}
                                        </h3>
                                        <p>
                                        {t('SC.s1.p.16')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                      </div>
                      
                  </div>
              </div>
            {/* Section Two End  */}



      


    {/* Section Five Start  */}
      <div className='homepageContainerP3 pt-2 pb-0' >
                  <div className='container-fluid'>
                      <div className='row mt-5 mx-0' >
                      <div className='col-md-6 col-12 d-flex ps-sm-4 coleageImg'  >
                        <img loading='lazy' src="../../../../../images/portrait-man-opening-beer-bottle-cap (2).png" alt="Section Five Right Side Images" className=""/>
                        </div>
                        <div className='col-md-6 col-12 pt-1'>
                            <p className='exSectionMainHeading '  >
                            {t('SC.s1.p.17')} {secondCountry}?
                            </p>
                            <p className='exSectionMainPara pb-4'  >
                            {t('SC.s1.p.18')} {secondCountry} {`(${secondCurrencyNames})`}  {t('SC.s1.p.19')} {firstCountry} {`(${firstCurrencyNames})`},  {t('SC.s1.p.20')}
                            <br></br>
                            <br></br>
                            {t('SC.s1.p.21')}
                            </p>
                            <Link to="/sign-up/">
                                <div className=" divBtnMedium ">
                                <button className=" btnMedium">
                                    {" "}
                                    <span className="btnMediumText mt-sm-0 mt-3">
                                    {t('SC.s1.p.22')} 
                                    </span>
                                </button>{" "}
                                </div>
                            </Link>
                        </div>
                        

                      </div>
                      
                  </div>
    </div>
  
    {/* Section Five End  */}


    {/* Section Six Start  */}
    <div className='homepageContainerP3 pt-5 ' >
                  <div className='container-fluid'>
                      <div className='row mt-sm-5 mt-3' >
                     
                        <div className='col-md-6 col-12 pt-sm-5 pt-0 ps-4'>
                            <p className='exSectionMainHeading mt-0'  >
                            {t('SC.s1.p.23')} {firstCountry}?
                            </p>
                            <p className='exSectionMainPara'  >
                            {t('SC.s1.p.24')} {secondCountry} 
                            {t('SC.s1.p.25')} {firstCountry}  {t('SC.s1.p.26')} 
                            </p>
                            <br></br>
 
                            <p className='exSectionMainPara mt-sm-2 mt-0 mb-sm-2 mb-5'  >
                            {t('SC.s1.p.27')} 
                            </p>
                            
                        </div>

                        <div className='col-md-6 col-12 coleageImg d-flex justify-content-center'  >
                        <img loading='lazy' src="../../../../../images/portrait-man-opening-beer-bottle-cap (3).png" alt="Section Six Right Side Images" className=""/>
                        </div>
                        

                      </div>
                      
                  </div>
    </div>
    {/* Section Six End  */}

    {/* Section Seven Start  */}
    <div className='homepageContainerP3 ' >
                  <div className='container-fluid'>
                      <div className='row px-sm-5 px-0'>
                            <p className='exHeadingH1 mb-5'  >
                                
                                <b
                                className='gradinatHeadingTxt ps-2'  >
                                   {t('SC.s1.p.28')} <br className='d-sm-block d-none'></br> {t('SC.s1.p.028')}
                                </b>
                               
                            </p>


                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <div className='exDetailsCardBg'>
                                    <div className='exDetailsCardCover'>
                                        <img loading='lazy' src="../../../../../images/01.svg" alt="Section One Right Side Images" className=""/>
                                        <h3 className='mb-0'>
                                        {t('SC.s1.p.29')} 
                                        </h3>
                                        <p>
                                        {t('SC.s1.p.30')} 
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <div className='exDetailsCardBg'>
                                    <div className='exDetailsCardCover'>
                                        <img loading='lazy' src="../../../../../images/02.svg" alt="Section One Right Side Images" className=""/>
                                        <h3 className='mb-0'>
                                        {t('SC.s1.p.35')} 
                                        </h3>
                                        <p>
                                        {t('SC.s1.p.31')} <br></br> {t('SC.s1.p.031')}
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className='col-lg-4 col-md-4 col-sm-6 col-12'>
                                <div className='exDetailsCardBg'>
                                    <div className='exDetailsCardCover'>
                                        <img loading='lazy' src="../../../../../images/03.svg" alt="Section One Right Side Images" className=""/>
                                        <h3 className='mb-0'>
                                        {t('SC.s1.p.32')} 
                                        </h3>
                                        <p>
                                        {t('SC.s1.p.33')}  {secondCountry} 
                                        {t('SC.s1.p.34')} 
                                        </p>
                                    </div>
                                </div>
                            </div>
                      </div>
                      
                  </div>
              </div>
    {/* Section Seven End  */}
      
          </div>
          </AnimatedPage>
        )
}

export default SendToCountry