// NotFound.js
import React from 'react';

const NotFound = () => {
  return (
    <div>
      <div className="not-found-container">
      <h2 className="not-found-heading">404 Not Found</h2>
      <p className="not-found-message">The requested page does not exist.</p>
    </div>
    </div>
  );
};

export default NotFound;
