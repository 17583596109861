import React, { useState } from 'react'
import UpperNav from './UpperNav'
import MobileOTP from './MobileOTP'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
function ChangeCardPin() {
    const [currentPin,setCurrentPin] = useState('1234')
    const [newPin,setnewPin] = useState()
    const [repeatPin,setrepeatPin] = useState()

    const [step, setStep] = useState(1)
    const navigate = useNavigate()
    const naxtPageHandler = ()=>{
        if (step === 1){ 
            if(currentPin !== "" || newPin !==  undefined || repeatPin !==  undefined){
                setStep(step + 1)
            }
        }else if (step === 2){
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: "Your Pin has been changed successfully", // Change to your data property
                }).then(() => {
                    navigate('/wallet/index'); // Redirect to another page
            })
        }

    }
    return (
        <div className='sideBarMainContent '>
        <div className='WMainDiv '>
          <UpperNav />
    
          <div className='row  justify-content-center'>

           {
            step === 1 ? 
            <div className='col-xxl-11 col-12 '>
            <div className='cardDetailsCard mt-5' >
                <div className='cardUpperRow mb-3'>
                    <div className='col-12 '>
                    <h1 className='text-center'>
                    Standard Blue Card
                    </h1>
                    </div>
                </div>
                <div className='row justify-content-center py-3'>
                    <div className='col-md-6 col-11'>
                        <div className='row justify-content-center py-3'>
                            <div className='FDInputDiv mb-4'>
                                <h6 className='ps-1 inputTitle mb-2'>
                                Current PIN
                                </h6>
                                <input type='password' placeholder='****' maxLength="4" className='form-control fTransferInput' onChange={(e)=>setCurrentPin(e.target.result)} value={currentPin} required/>
                            </div>
                            <div className='FDInputDiv mb-4'>
                                <h6 className='ps-1 inputTitle mb-2'>
                                New PIN
                                </h6>
                                <input type='number' placeholder='****' maxLength="4" className='form-control fTransferInput' onChange={(e)=>setnewPin(e.target.result)} value={newPin} required/>
                            </div>
                            <div className='FDInputDiv mb-4'>
                                <h6 className='ps-1 inputTitle mb-2'>
                                Repeat PIN
                                </h6>
                                <input type='number' placeholder='****' maxLength="4" className='form-control fTransferInput' onChange={(e)=>setrepeatPin(e.target.result)} value={repeatPin} required/>
                            </div>
                            <div className='col-md-6 col-9'>
                                <div className='WHPS3bottomDiv row'  onClick={naxtPageHandler}>
                                    <a> Change PIN </a>                  
                                </div>
                            </div>
                        </div>
                    </div>
                    

                </div>
            </div>
            </div> :
              <div className='row mt-5 justify-content-center'>
                    <div className='col-xxl-5 col-lg-8 col-12'>
                        <div className='fiatTransferCard mt-md-5 mt-2 ' >
                            <MobileOTP />
                            <div className='row justify-content-center'>
                                <div className='col-md-6 col-9'>
                                    <div className='WHPS3bottomDiv row' onClick={naxtPageHandler}>
                                        <a > Change PIN </a>                  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           }
          </div>
    
    
        </div>
      </div>
  )
}

export default ChangeCardPin