import React from 'react'
import UpperNav from './UpperNav'
function EditBillDetails(props) {
    return (
<div className='sideBarMainContent '>
                <div className='WMainDiv '>
                <UpperNav title={`Edit Bill`} symbol={" "}/>
            
                
                <div className='row mt-5 justify-content-center'>
                <div className='col-xxl-5 col-lg-8 col-12'>
                    <div className='fiatTransferCard mt-md-5 mt-2 ' >
                        <div className='cardUpperRow mb-3'>
                            <div className='col-12'>
                            <h3>
                            Edit Bill
                            </h3>
                            </div>

                        </div>
                        <div className='row py-3 justify-content-center'>
                            <div className='col-11 FDInputDiv mb-4'>
                                <h6 className='ps-1 inputTitle mb-3'>
                                Consumer Reference Number
                                </h6>
                                <input type='number' placeholder='2345678903457' className='form-control fTransferInput' value={props.consumerNumber} onChange={(e)=> {props.setconsumerNumber(e.target.value)}}/>
                                <p className='SVCNBottomText mt-1 ' >
                                    Do not send directly to a crowdfund or ICO. We will no credit your  account with tokens from that sale.
                                </p>
                            </div>
                        </div>

                        <div className='row justify-content-center '>
                            <div className='col-8'>
                                    <div className='WHPS3bottomDiv row '>              
                                        <a onClick={()=>{props.currentPage('details')}}> Save Changes </a>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>








        
      )
}

export default EditBillDetails