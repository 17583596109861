import React,{useState} from 'react'
import AnimatedPage from '../AnimatedPage'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// import s1img from '../../static/images/p7s1.svg'
// import rightSideS2Hr from '../../static/images/p7hr.svg'
// import s2img from '../../static/images/p7s2.svg'
// import s3C1img from '../../static/images/p7s3c1.svg'
// import s3C2img from '../../static/images/p7s3c2.svg'
// import s3C3img from '../../static/images/p7s3c3.svg'
// import s4circleImg from '../../static/images/p7s3o.svg'
// import s5img from '../../static/images/p7s5.svg'



export default function Cards() {
    const { t, i18n } = useTranslation();
    const [activeCardName,setActiveCardName] = useState('Classic Silver Card')




  return (
    <AnimatedPage>
    <div >
    {/* Section One Start  */}
    <div className='homepageContainerP2 S1PATopPaddingP3 pb-lg-0 pb-md-0 pb-5 manageImmg01' >
                <div className='container-fluid'>
                    <div className='row jusify-content-center '>
                        <h3 className='CdS1SubHeading text-center pr_rem4'  >
                        
                        {t('CD.S1.h.1')} <br></br>
                        {t('CD.S1.h.2')}
                        </h3>
                    <img loading='lazy' src="../images/cardscene 1.png" alt="Section One Right Side Images" className="p7S1Img imgResponsive manageImage1"  />
                    </div>
                    <div className='row py-3 pb-5'>
                    <div className='col-md-6 col-12 homepageS1MainHeadingDiv' >

                        <h3 className='pb-3 pt-sm-5 pt-0 CdS1SubHeading'>
                        {t('CD.S1.h.3')}  <br></br> {t('CD.S1.h.4')}   <b className='s2HeadingBold'> {t('CD.S1.h.5')}    <br></br> {t('CD.S1.h.6')} </b> 
                        </h3>
                    </div>

                    <div className='col-md-6 col-12 homepageS1MainHeadingDiv'  >
                    <p className='homePageS3SubHeading pt-sm-5 pt-3'>
                    {t('CD.S1.p.1')}
                        </p>
                        <div className=" divBtnMedium btnHide mt-5" >
                        <button className=' btnMedium' > <Link to='/sign-up/' className='btnMediumText'>{t('btnING.4')}  </Link> 
                        </button>   </div>
                    </div>
                    </div>
                    <div className=" divBtnMedium btnShow mt-3" >
                        <button className=' btnMedium' > <Link to='/sign-up/' className='btnMediumText mt-sm-0 mt-3'>{t('btnING.4')}  </Link> 
                        </button>   </div>

                    
                </div>
    </div>
    {/* Section One End  */}


    {/* Section Two Start  */}
    <div className='homepageContainerP2 ' >
                <div className='container-fluid mb-3'>
                <div className='row '>
                  <img loading='lazy' src="../images/p7hr.svg" alt="Section Two Right Side HR" className="rightSideHrS2 imgResponsive pr_rem5 hrSmHide"/>
                  <img loading='lazy' src="../images/hrSmShow.svg" alt="Section Two Right Side HR" className="rightSideHrS2 imgResponsive hrSmShow " />
                <div className='row p7s2BtnDiv'>
                <div className='col-md-6 col-12 d-flex justify-content-center align-items-start' >
                  <img loading='lazy' src="../images/Group 1.png" alt="Section Twp Left Side Images" className="rightSideHrS6P4 imgResponsive manageImage1"/>
                </div>
                <div className='col-md-6 col-12 d-flex flex-column mt-md-4 mt-0' >
                    <h3 className='py-3  mt-md-5 mt-0 CdS1SubHeading'>
                    {t('CD.S2.h.1')}
                    </h3>
                    <p className='p7s2Para pt-sm-4 pt-3'>
                    {t('CD.S2.p.1')}
                    </p>
                  </div>
                   <div className='d-flex justify-content-center mb-4 d-none'>
                   <div className="transparent-gradient-border-btn mt-5   justify-content-center" >
                            <button className='button-content ' > <Link to='/sign-up/' className='gradient-text'> {t('btnING.4')} </Link> 
                            </button>   
                    </div>
                   </div>
                </div>
                </div>
            </div>
    {/* Section Two End  */}


    {/* Section Three Start  */}
    <div className='pe-lg-5 pe-auto' >
            <div className='container-fluid'>
                    <div className='row pt-3'>
                        {activeCardName === 'Premium Black Card'
                        ?
                        <div className='col-lg-4 col-12 px-4 pb-4' >
                            <div className='cardActiveBg'>
                            <div className='cardActivecover d-flex flex-column justify-content-center align-items-center'>
                            <h1 className='p7s3Heading'>
                            {t('CD.S3.h.1')}
                            </h1>
                            <img loading='lazy' src="../images/Blackfull (2).png" alt="Section One Right Side Images" className="p7S3Img imgResponsive d-sm-block d-none"/>
                            <img loading='lazy' src="../images/Blackfull (2).png" alt="Section One Right Side Images" className="p7S3Img imgResponsive d-sm-none d-block"/>
                            <div className="transparent-gradient-border-btn" >
                            <button className='button-content '  onClick={()=>{setActiveCardName('Premium Black Card')}}> <a className='gradient-text' href='#cardDetails'> {t('btnING.8')} {'\u00a0'}  > </a> 
                            </button>   </div>
                            </div>
                            </div> 
                        </div> : 
                        <div className='col-lg-4 col-12 d-flex flex-column justify-content-start align-items-center  pb-4'  >
                            <h1 className='p7s3Heading'>
                            {t('CD.S3.h.1')}
                            </h1>
                            <img loading='lazy' src="../images/Blackfull (2).png" alt="Section One Right Side Images" className="p7S3Img imgResponsive"/>
                            <div className="transparent-gradient-border-btn" >
                            <button className='button-content ' onClick={()=>{setActiveCardName('Premium Black Card')}}> <a className='gradient-text' href='#cardDetails'> {t('btnING.8')} {'\u00a0'} > </a> 
                            </button>   </div>
                        </div>
                        }

                        

                        {activeCardName === 'Standard Blue Card'
                            ?
                            <div className='col-lg-4 col-12 px-4 pb-4' >
                            <div className='cardActiveBg'>
                            <div className='cardActivecover d-flex flex-column justify-content-center align-items-center'>
                            <h1 className='p7s3Heading'>
                            {t('CD.S3.h.2')}
                            </h1>
                            <img loading='lazy' src="../images/Blackfull (1).png" alt="Section One Right Side Images" className="p7S3Img imgResponsive d-sm-block d-none"/>
                            <img loading='lazy' src="../images/Blackfull (1).png" alt="Section One Right Side Images" className="p7S3Img imgResponsive d-sm-none d-block"/>
                            <div className="transparent-gradient-border-btn" >
                            <button className='button-content '  onClick={()=>{setActiveCardName('Standard Blue Card')}}> <a className='gradient-text' href='#cardDetails'> {t('btnING.8')} {'\u00a0'}  > </a> 
                            </button>   </div>
                            </div>
                            </div> 
                        </div> :
                        <div className='col-lg-4 col-12 d-flex flex-column justify-content-start align-items-center  pb-4'  >
                            <h1 className='p7s3Heading'>
                            {t('CD.S3.h.2')}
                            </h1>
                            <img loading='lazy' src="../images/Blackfull (1).png" alt="Section One Right Side Images" className="p7S3Img imgResponsive"/>
                            <div className="transparent-gradient-border-btn">
                            <button className='button-content ' onClick={()=>{setActiveCardName('Standard Blue Card')}}> <a className='gradient-text' href='#cardDetails'> {t('btnING.8')} {'\u00a0'} > </a> 
                            </button>   </div>
                        </div>
                        }

                        

                        {activeCardName === 'Classic Silver Card'
                            ?
                            <div className='col-lg-4 col-12 px-4 pb-4' >
                            <div className='cardActiveBg'>
                            <div className='cardActivecover d-flex flex-column justify-content-center align-items-center'>
                            <h1 className='p7s3Heading'>
                            {t('CD.S3.h.3')}
                            </h1>
                            <img loading='lazy' src="../images/Blackfull.png" alt="Section One Right Side Images" className="p7S3Img imgResponsive d-sm-block d-none"/>
                            <img loading='lazy' src="../images/Blackfull.png" alt="Section One Right Side Images" className="p7S3Img imgResponsive d-sm-none d-block"/>
                            <div className="transparent-gradient-border-btn" >
                            <button className='button-content '  onClick={()=>{setActiveCardName('Classic Silver Card')}}> <a className='gradient-text' href='#cardDetails'> {t('btnING.8')} {'\u00a0'}  > </a> 
                            </button>   </div>
                            </div>
                            </div> 
                            </div>  :
                            <div className='col-lg-4 col-12 d-flex flex-column justify-content-start align-items-center pb-4'  >
                                <h1 className='p7s3Heading'>
                                {t('CD.S3.h.3')}
                                </h1>
                                <img loading='lazy' src="../images/Blackfull.png" alt="Section One Right Side Images" className="p7S3Img imgResponsive"/>
                                <div className="transparent-gradient-border-btn">
                                <button className='button-content ' onClick={()=>{setActiveCardName('Classic Silver Card')}}> <a className='gradient-text' href='#cardDetails'> {t('btnING.8')} {'\u00a0'} > </a> 
                                </button>   </div>
                            </div>
                        }

                        
                    </div>
            </div>
        </div>
    {/* Section Three End  */}

    {/* Section Four Start */}
    <div className='pe-lg-5 pe-auto' id='cardDetails'>
            <div className='container-fluid'>
                    <div className='row px-0'>
                    <div className='rowActiveBg px-0'  >
                            <div className='rowActivecover'>
                            <div className='col-12 px-4'>
                                <div className='row '>
                                    <div className='col-sm-6 col-12'>
                                    <h1 className='Ps4GradientHeading'>
                                    {activeCardName}
                                    </h1>
                                    <p className='Ps4GradientSubHeading'>
                                    { activeCardName === 'Classic Silver Card' ? t('CD.S4.h.2') :
                                        activeCardName === "Standard Blue Card" ? t('CD.S401.h.2') : 
                                        t('CD.S402.h.2')
                                    }
                                    </p>
                                    </div>
                                    <div className='col-sm-6 col-12 d-flex flex-column align-items-end px-4'>
                                    <p className='Ps4GradientRightSubHeading'>
                                    { activeCardName === 'Classic Silver Card' ? t('CD.S4.h.3') :
                                        activeCardName === "Standard Blue Card" ? t('CD.S401.h.3') : 
                                        t('CD.S402.h.3')
                                    }
                                    
                                    </p>
                                    <h1 className='Ps4GradientRightHeading'>
                                    { activeCardName === 'Classic Silver Card' ? t('CD.S4.h.4') :
                                        activeCardName === "Standard Blue Card" ? t('CD.S401.h.4') : 
                                        t('CD.S402.h.4')
                                    }
                                   
                                    </h1>
                                    </div>
                                </div>
                                <div className='row justify-content-between pb-4 px-lg-0 px-md-0'>
                                <div className='p7s5ImgTextDiv'>
                                <img loading='lazy' src="../images/p7s3o.svg" alt="Section One Right Side Images" className="p7S3CirlceImg imgResponsive"/>
                                <p className='Ps7s6optionText'> <b className='Ps7s6optionTextBold'>  
                                { activeCardName === 'Classic Silver Card' ? t('CD.S4.hb.1') :
                                        activeCardName === "Standard Blue Card" ? t('CD.S401.hb.1') : 
                                        t('CD.S402.hb.1')
                                }
                                
                                </b> 
                                { activeCardName === 'Classic Silver Card' ? t('CD.S4.p.1') :
                                    activeCardName === "Standard Blue Card" ? t('CD.S401.p.1') : 
                                    t('CD.S402.p.1')
                                }
                                
                                </p>
                                </div>

                                <div className='p7s5ImgTextDiv'>
                                <img loading='lazy' src="../images/p7s3o.svg" alt="Section One Right Side Images" className="p7S3CirlceImg imgResponsive"/>
                                <p className='Ps7s6optionText'> <b className='Ps7s6optionTextBold'>  
                                { activeCardName === 'Classic Silver Card' ? t('CD.S4.hb.2') :
                                        activeCardName === "Standard Blue Card" ? t('CD.S401.hb.2') : 
                                        t('CD.S402.hb.2')
                                }
                                </b> 
                                { activeCardName === 'Classic Silver Card' ? t('CD.S4.p.2') :
                                    activeCardName === "Standard Blue Card" ? t('CD.S401.p.2') : 
                                    t('CD.S402.p.2')
                                } 
                                 </p>
                                </div>

                                <div className='p7s5ImgTextDiv'>
                                <img loading='lazy' src="../images/p7s3o.svg" alt="Section One Right Side Images" className="p7S3CirlceImg imgResponsive"/>
                                <p className='Ps7s6optionText'> <b className='Ps7s6optionTextBold'> 
                                { activeCardName === 'Classic Silver Card' ? t('CD.S4.hb.3') :
                                        activeCardName === "Standard Blue Card" ? t('CD.S401.hb.3') : 
                                        t('CD.S402.hb.3')
                                }
                                 </b>  
                                 { activeCardName === 'Classic Silver Card' ? t('CD.S4.p.3') :
                                    activeCardName === "Standard Blue Card" ? t('CD.S401.p.3') : 
                                    t('CD.S402.p.3')
                                } 
                                </p>
                                </div>

                                <div className='p7s5ImgTextDiv'>
                                <img loading='lazy' src="../images/p7s3o.svg" alt="Section One Right Side Images" className="p7S3CirlceImg imgResponsive"/>
                                <p className='Ps7s6optionText'> <b className='Ps7s6optionTextBold'> 
                                { activeCardName === 'Classic Silver Card' ? t('CD.S4.hb.4') :
                                        activeCardName === "Standard Blue Card" ? t('CD.S401.hb.4') : 
                                        t('CD.S402.hb.4')
                                }
                                 </b> 
                                 { activeCardName === 'Classic Silver Card' ? t('CD.S4.p.4') :
                                    activeCardName === "Standard Blue Card" ? t('CD.S401.p.4') : 
                                    t('CD.S402.p.4')
                                } 
                                 </p>
                                </div>

                                <div className='p7s5ImgTextDiv'>
                                <img loading='lazy' src="../images/p7s3o.svg" alt="Section One Right Side Images" className="p7S3CirlceImg imgResponsive"/>
                                <p className='Ps7s6optionText'> <b className='Ps7s6optionTextBold'> 
                                { activeCardName === 'Classic Silver Card' ? t('CD.S4.hb.5') :
                                        activeCardName === "Standard Blue Card" ? t('CD.S401.hb.5') : 
                                        t('CD.S402.hb.5')
                                }
                                </b>
                                { activeCardName === 'Classic Silver Card' ? t('CD.S4.p.5') :
                                    activeCardName === "Standard Blue Card" ? t('CD.S401.p.5') : 
                                    t('CD.S402.p.5')
                                } 
                                  </p>
                                </div>
                                </div>
                            </div>
                            </div>
                            </div>
                    </div>
                </div>
            </div>
    {/* Section Four End */}
    

    {/* Section Five Start */}
    <div className='homepageContainer cardsS5' >
            <div className='container-fluid'>
                    <div className='row '  >
                        <h3 className='p5S7Heading pb-5' style={{color:'#00051A'}}>
                        {t('CD.S5.h.1')}  <b className='p5S7HeadingBold'></b> {t('CD.S5.h.2')}
                        </h3>
                        <p className='S3BottomCenterHeading mt-sm-4 mt-0 ' style={{color:'#00051A'}}>
                        {t('CD.S5.p.1')} <br></br>   {t('CD.S5.p.2')}
                        </p>
                    </div>
            </div>
    </div>
        <div className='container-fluid'>
        <div className='row'>
            <img loading='lazy' src="../images/p7s5.svg" alt="Section One Right Side Images" className="p7s6Img  imgResponsive manageImage1"  />
        </div>
    </div>

    
    {/* Section Five End */}

    </div>
</div>
    </AnimatedPage>
  )
}
 









 

