import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
function BlogStructureNew() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [cat, setCat] = useState();
    const [latestBlog, setlatestBlog] = useState([]);
    const [latestBlog2, setlatestBlog2] = useState([]);
    const [allBlogArr, setallBlogArr] = useState();
    const [activeLink, setactiveLink] = useState('All Category');

    const BlogDetailsHandle = (blogObj) => {
        navigate(`/blog-details/${blogObj.title}`, { state: { blogObj } });
    };
    useEffect(() => {
        const fetchBlogsCat = async () => {
            setIsLoading(true);
            setIsError(false);
            try {
                const response = await fetch('https://evnewsss.com/evistia/categories/');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log(data,'data is here')
                setCat(data);
            } catch (error) {
                setIsError(true);
                console.error('Fetch error:', error);
            }
            setIsLoading(false);
        };

        fetchBlogsCat();
    }, []);

    useEffect(() => {
        const fetchBlogs = async () => {
            setIsLoading(true);
            setIsError(false);
            try {
                if(activeLink === 'All Category'){
                    const response = await fetch('https://evnewsss.com/evistia/all-news/');
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    console.log('data is here', data)
                    setallBlogArr(data);
                    setlatestBlog(data[0])
                    setlatestBlog2(data.slice(1,3));
                }else{
                    const response = await fetch(`https://evnewsss.com/evistia/all-news/?category=${activeLink}`);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setallBlogArr(data);
                }
                
            } catch (error) {
                setIsError(true);
                console.error('Fetch error:', error);
            }
            setIsLoading(false);
        };

        fetchBlogs();
    }, [activeLink]);


//   if(isLoading){
//     <p style={{textAlign:"center"}}>Loading....</p>
//   }else if(isError){
//     <p style={{textAlign:"center"}}>Something Went Wrong Please Visit After Some Time.</p>
//   }else{
    return (
        <div className='homepageContainerP2 mt-5 pt-md-5 pt-2  p-0 ' >
            <div className='container-fluid '>
                {latestBlog ? 
                <div className='row jusify-content-center align-items-center px-md-5 px-3'>
    
                <div className='col-md-12 col-12'>
                    <h3 className='bH1 py-md-4 py-2'>
                        Evistia Blog
                    </h3>
                    <div className='allBlogCatBtn gap-md-5 gap-3'>
                        {activeLink === 'All Category' ? 
                            <div className='blogUpperBtnRow'>
                            <button>
                                <span className='gradient-text10'>
                                   All Category
                                </span>
                            </button>
                            </div> : 
                            <a className='simpleBtn' href='#catDetail' onClick={()=>setactiveLink('All Category')}>
                                 All Category
                            </a>
                        }
                        
    
                        { cat?.length > 0 ? cat.map((element) => ( 
                            <>
                                {activeLink === element.name ? 
                                    <div className='blogUpperBtnRow' key={element.id} >
                                    <button>
                                        <a className='gradient-text10' >
                                        {element.name}
                                        </a>
                                    </button>
                                    </div> : 
                                     <a className='simpleBtn' href='#catDetail' onClick={()=>setactiveLink(element.name)} key={element.id}>
                                     {element.name} 
                                    </a>
                                }
                               
                            </>
                        )) : null }
    
                    </div>
                </div>
    
                <div className='row mt-5 d-md-flex d-none'>
                        <h3 className='bSubH1'>
                        Featured
                        </h3>
                    
                    { latestBlog ? 
                        <div className='col-md-6 col-12' key={latestBlog.id}>
                            <img loading='lazy' src={latestBlog.image} alt="Blog IMG" className="blogMainImg mt-4" />
    
                            <p className='blogDate my-2'>
                            {latestBlog.author} • {latestBlog.published_date}
                            </p>
    
                            <p className='blogHeading mb-2'>
                            {latestBlog.title}
                            </p>
    
                            <div className='blogPara05'  dangerouslySetInnerHTML={{ __html: latestBlog?.sub_title }} />
                            
                            <a className='blogBtn mt-3' onClick={()=>BlogDetailsHandle(latestBlog)}>
                            Read more
                            </a>
                        </div>
                         : null
                    }
    
                    <div className='col-md-6 col-12'>
                    { latestBlog2 ? latestBlog2.map((element) => ( 
                        <div className='sideBlogDiv d-flex gap-md-4 gap-1 mb-md-0 mb-3' key={element.id}>
                            <img loading='lazy' src={element.image} alt="Blog IMG" className="blogSubMainImg mt-4"/>
    
                            <div className='blogOtherDetailDIv mt-md-4 mt-0 pt-2'>
                                <p className='blogDate mb-2'>
                                {element.author} • {element.published_date}
                                </p>
    
                                <p className='blogSubHeading mb-2'>
                                {element.title}
                                </p>
    
                                <div className='blogSubPara ' dangerouslySetInnerHTML={{ __html: element?.sub_title }} />
                                
                                
                                <a className='blogBtn mt-3' onClick={()=>BlogDetailsHandle(element)}>
                                Read more
                                </a>
                            </div>
                        </div>
                     )) : null
                    }
    
    
                        
                    </div>
                </div>
                <div id="catDetail" className='mb-5  pt-md-5 pt-2'>
                </div>
                <div className='row  pt-1 justify-content-between' >
                    <div className='col-md-6 col-12'>
                        <h3 className='bSubH1'>
                        {activeLink === 'All Category' ? "All blog posts" : activeLink}
                        </h3>
                    </div>
    
                    {/* <div className='col-md-6 col-12 d-flex justify-content-end pe-0'>
                        <div className='blogUpperBtnRow1'>
                            <button>
                                <span className='gradient-text'>
                                    View all
                                </span>
                                <img loading='lazy' src="../images/Icon.svg" alt="arrow" className="mt-1"/>
                            </button>
                        </div>
                    </div> */}
                </div>
    
                <div className='row mt-2'>
                { allBlogArr?.length > 0 ? allBlogArr.map((element) => ( 
                    <div className='col-md-4 col-12 mb-4 ' key={element.id} >
                        <img loading='lazy' src={element.image} alt="Blog IMG" className="blogMainImg01 mt-4"/>
                        <p className='blogDate my-2'>
                        {element.author} • {element.published_date}
                        </p>
    
                        <p className='blogSubHeading mb-1'>
                        {element.title}
                        </p>
    
                        <div className='blogSubPara pe-2' dangerouslySetInnerHTML={{ __html: element?.sub_title }} />
                        <a className='blogBtn mt-2' onClick={()=>BlogDetailsHandle(element)}>
                        Read more
                        </a>
                    </div>
                )) : <p style={{textAlign:"center"}}>No blog available for {activeLink}. Please select another category</p> }
    
    
                </div>
    
            </div> : 
           <p style={{textAlign:"center"}}>No blog available at this time. Please check back later.</p>}
            </div>
        </div>
      )
//   }
}

export default BlogStructureNew