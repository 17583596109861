import { useTranslation } from 'react-i18next';

const useCurrentLanguage = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  // Extracting the last two characters
  const languageCode = language.slice(-2);

  return languageCode;
};

export default useCurrentLanguage;

