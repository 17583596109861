import React, { useState } from "react";
import { Link } from "react-router-dom";
import AnimatedPage from "../AnimatedPage";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
// import { useTranslation } from "react-i18next";
// import upperBg from '../../static/images/Group 460.svg'
// import rightSideS1 from '../../static/images/Untitled.svg'
// import leftSideS2Img from '../../static/images/close-up-hand-holding-phone 2.svg'
// import rightSideS2Hr from '../../static/images/Vector 6.svg'
// import S2EndHr from '../../static/images/Vector 7.svg'
// import S3mMinBanner from '../../static/images/Why Evistia.svg'
// import S4RightSideImg from '../../static/images/couple-friends-laughing-funny-having-fun-with-smart-phone-big-city-street 1.svg'
// import S5RightSideImg from '../../static/images/portrait-man-opening-beer-bottle-cap.svg'
// import S7RightSideImg from '../../static/images/newcards 1.svg'
// import S8RightSideImg from '../../static/images/Untitled (4).svg'
// import S9LeftSideImg from '../../static/images/close-up-hand-holding-phone 1.svg'
// import s10One from '../../static/images/wallet (1) 1.svg'
// import s10Two from '../../static/images/swap (3) 1.svg'
// import s10Three from '../../static/images/send (2) 1.svg'
// import s10Four from '../../static/images/shopping-bag (2) 1.svg'

import "../../static/css/index.css";
import "../../static/css/common.css";
import "../../static/css/navbar.css";

export default function HomePage() {
  const { t, i18n } = useTranslation();
  const showAlert = (e) => {
    e.preventDefault();
    //consolelog(e,'event')
    Swal.fire({
      icon: "warning",
      title: "warning",
      text: "Comming Soon!", // Change to your data property
    });
  };

  // const { t, i18n: {changeLanguage, language} } = useTranslation();
  // const [currentLanguage, setCurrentLanguage] = useState(language)
  return (
    <AnimatedPage>
      <div>
        {/* Section One Start  */}
        <img
          loading="lazy"
          src="../images/Group 460.svg"
          alt="Section One Background"
          className="homePageS1Bg"
        />
        <div className="homepageContainer">
          <div className="container-fluid mt-md-0 mt-5" >
            <div className="row pb-lg-0 pb-md-0 pb-5 ">
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-8 col-12 homepageS1MainHeadingDiv"
                
                
              >
                <h3 className="S1Heading pt-lg-5 pt-0">
                  {" "}
                  {t("HP.S1.h")}
                  {/* Your Finance <br className='d-sm-none d-block'></br> Gateway: <br className='d-sm-block d-none'></br> Traditional <br className='d-sm-none d-block'></br> and Cryptocurrency <br className='d-sm-none d-block'></br> Combined */}
                </h3>
                <p className="homePageS1SubHeading paraWidth" >
                  {" "}
                  {t("HP.S1.p")}
                  {/* Experience a seamless finance <br className='d-sm-none d-block'></br>  where traditional <br className='d-sm-block d-none'></br> meets  <br className='d-sm-none d-block'></br> cryptocurrency. Manage funds, <br className='d-sm-none d-block'></br> explore <br className='d-sm-block d-none'></br> assets, and shape your future. */}
                </p>
                <div
                  className="transparent-gradient-border-btn mt-3 btnHide"
                  onClick={(e) => {
                    showAlert(e);
                  }}
                >
                  <button className="button-content ">
                    <span className="gradient-text"> {t("btnING.1")}</span>
                  </button>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12 d-flex  justify-content-xl-end justify-content-center my-sm-0 my-4">
                <img
                  loading="lazy"
                  src="../images/Group 640.png"
                  alt="Section One Right Side Images"
                  className="rightSideS1Img imgResponsivesWidth S1HpImg"
                />
              </div>
            <div className="btnForMB">
            <div
                  className="transparent-gradient-border-btn"
                  style={{ marginTop: "2rem" }}
                  onClick={(e) => {
                    showAlert(e);
                  }}
                  
                  
                >
                  <button className="button-content ">
                    {" "}
                    <span className="gradient-text"> {t("btnING.1")} </span>
                  </button>
            </div>
            </div>
            </div>
          </div>
        </div>
        {/* Section One End  */}


        {/* Section Two Start  */}
        <div className="homepageContainer">
          <div className="container-fluid">
            <div className="row">
              <img
                loading="lazy"
                src="../images/Vector 6.svg"
                alt="Section Two Right Side HR"
                className="rightSideHrS2 imgResponsive hrSmShow"
                
               
              />
            </div>
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-5 col-12">
                <img
                  loading="lazy"
                  src="../images/newImgHP.png"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img imgResponsivesWidth mt-sm-0 mt-4"
                  
                 
                />
              </div>

              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-7 col-12 homepageS1MainHeadingDiv ">
                <div className="row">
                  <img
                    loading="lazy"
                    src="../images/Vector 6.svg"
                    alt="Section Two Right Side HR"
                    className="rightSideHrS2 imgResponsive hrSmHide"
                  />
                </div>

                <h3
                  className="py-lg-4  py-md-0 py-0 pt-lg-0 pt-md-4 pt-4 pb-lg-0 pb-md-0 pb-0   mt-lg-5 mt-0"
                  
                  
                >
                  {t("HP.S2.h.1")} <br className="d-sm-none d-block"></br>{" "}
                  {" "}
                  <b className="s2HeadingBold">
                    {t("HP.S2.hb")}
                  </b>
                </h3>
                <p
                  className="homePageS2SubHeading "
                  
                  
                >
                  {t("HP.S2.p")}
                </p>
              </div>
                  </div>
                  
                </div>
            </div>
        {/* Section Two End  */}


        {/* Section Three 1 Start  */}
        <div className="homepageContainer pb-md-5 pb-0">
          <div className="container-fluid">
            <div className="row">
              <img
                loading="lazy"
                src="../images/Vector 7.svg"
                alt="Section Two END Hr"
                className="imgResponsive"
              />
            </div>

            <div className="row pt-lg-4 pt-md-4 pt-4 ">
              <div className="col-6 homepageS1MainHeadingDiv col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <h3
                  className="py-3 pt-sm-5 pt-2"
                  
                  
                >
                  <b className="s2HeadingBold"> {t("HP.S3.hb")} </b> <br></br>
                  {t("HP.S3.h.1")} <br></br>
                </h3>
              </div>

              <div className="col-6 homepageS1MainHeadingDiv col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <p
                  className="homePageS3SubHeading pt-lg-5 pt-md-3 pt-2 pb-lg-0 pb-md-5 pb-5"
                  
                  
                >
                  {t("HP.S3.p")}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Section Three 1 End  */}

        {/* Section Three 2 Start  */}

        <div
          className="homepageContainer HMS3BgMb"
          
         
        >
          <div className="container-fluid">
            <div className="row HMS3Bg">
            <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1 col-12" ></div>
              <div
                className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 columnBorder columnBorderMb  s7P2RightsideColumnp3"
                
               
              >
                <h3 className="S3BottomHeading">{t("HP.S03.h.1")}</h3>
                <p className="s4BottomSubHeading pt-lg-3 pt-md-1 pt-1">
                  {t("HP.S03.p.1")}
                </p>
              </div>

              <div
                className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12  columnBorderMb s7P2RightsideColumnp3 "
                
                
              >
                <h3 className="S3BottomHeading">{t("HP.S03.h.2")}</h3>
                <p className="s4BottomSubHeading pt-lg-3 pt-md-1 pt-1 ">
                  {t("HP.S03.p.2")}
                </p>
              </div>
            <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1 col-12" ></div>

              {/* <div
                className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 columnBorderMb s7P2RightsideColumnp3 mb-lg-0 mb-md-0 mb-5"
                
               
              >
                <h3 className="S3BottomHeading ">{t("HP.S03.h.3")}</h3>
                <p className="s4BottomSubHeading pt-lg-3 pt-md-1 pt-1 ">
                  {t("HP.S03.p.3")}
                </p>
              </div> */}
            </div>
          </div>
        </div>

        {/* Section Three 2 End  */}

        {/* Section Four Start  */}
        <div className="homepageContainer IndexS4Bg">
          <div className="container-fluid s4Banner">
            <div className="row">
              <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 homepageS3MainHeadingDiv ">
                <div className="row">
                  <img
                    loading="lazy"
                    src="../images/Vector 6.svg"
                    alt="Section Two Right Side HR"
                    className="rightSideHrS2 imgResponsive"
                  />
                </div>
                <h3
                  className="py-3"
                  
                 
                >
                  {t("HP.S4.h.1")} <br></br>{" "}
                  <b className="s2HeadingBold"> {t("HP.S4.hb")} </b>
                </h3>
                <p
                  className="homePageS4SubHeading"
                  
                 
                >
                  {t("HP.S4.p")}
                </p>
              </div>

              <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 d-flex justify-content-lg-end justify-content-md-end justify-content-center px-lg-0 px-md-0 px-4">
                <img
                  loading="lazy"
                  src="../images/home4.png"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img imgResponsive  mt-sm-0 mt-4"
                  
                  
                />
              </div>

              <div className="row pt-5 px-lg-0 px-md-0 px-4">
                <div
                  className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-sm-5 pt-0"
                  
                 
                >
                  <p className=" pt-lg-5 pt-md-5 pt-0 s4BottomHeadingAbove">
                    {t("HP.S04.h.1")}
                  </p>

                  <h3 className="pb-3 s4BottomHeading">{t("HP.S04.sh.1")}</h3>
                  <p className="s4BottomSubHeading pt-lg-3 pt-md-3 pt-0  paddingS4HpParaLeft manageParaHeight">
                    {t("HP.S04.p.1")}
                  </p>
                  <Link to="buisness-account/">
                    <div className="S4BtnDiv">
                      <button className="s4Btn">
                        {" "}
                        <Link to="personal-account/" className="gradient-text">
                          {" "}
                          {t("btnING.2")}{" "}
                        </Link>
                      </button>
                    </div>
                  </Link>
                </div>

                <div
                  className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-lg-5 pt-md-5 pt-0 pb-lg-0 pb-md-0 pb-5"
                  
                 
                >
                  <p className=" pt-5 s4BottomHeadingAbove">
                    {t("HP.S04.h.2")}
                  </p>

                  <h3 className="pb-3 s4BottomHeading">{t("HP.S04.sh.2")}</h3>
                  <p className="s4BottomSubHeading pt-lg-3 pt-md-3 pt-0  paddingS4HpParaRight manageParaHeight">
                    {t("HP.S04.p.2")}
                  </p>
                  <Link to="buisness-account/">
                    <div className="S4BtnDiv">
                      <button className="s4Btn">
                        {" "}
                        <Link to="buisness-account/" className="gradient-text">
                          {" "}
                          {t("btnING.2")}{" "}
                        </Link>
                      </button>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section Four End  */}

        {/* Section Five Start  */}
        <div className="homepageContainer mt-5 pt-sm-5 pt-0">
          <div className="container-fluid">
            <div className="row sm-5 pt-0 reverseColumn">
              <div
                className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 homepageS1MainHeadingDiv"
                
                
              >
                <div className="row ">
                  {/* <img loading='lazy'  src="../images/Vector 6.svg" alt="Section Two Right Side HR" className="rightSideHrS2 imgResponsive hrSmHide"/> */}
                </div>

                <h3 className="py-3 pt-5 mt-lg-5 mt-0 ">
                  {t("HP.S5.h.1")} <br></br>
                </h3>
                <p className="homePageS2SubHeading mt-md-3 mt-sm-0">
                  {t("HP.S5.p")}
                </p>
                <Link to="digital-wallet/">
                  <div className="S5BtnDiv mt-xl-3 mt-lg-0  mt-0 ">
                    <button className="s5Btn">
                      {" "}
                      <Link to="digital-wallet/" className="s5Btntxt">
                        {" "}
                        {t("btnING.2")}{" "}
                      </Link>
                    </button>
                  </div>
                </Link>
              </div>
              <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 d-flex justify-content-end ">
                <img
                  loading="lazy"
                  src="../images/home5.png"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img  pt-sm-0 pt-4 imgResponsivesWidth"
                 
                />
              </div>
              <img
                  loading="lazy"
                  src="../images/hrSmShow.svg"
                  alt="Section Two END Hr"
                  className="imgResponsive hrSmShow mt-sm-0 mt-5"
                />
            </div>
          </div>
        </div>
        {/* Section Five End  */}

        {/* Section Six Start  */}
        <div className="homepageContainer">
          <div className="container-fluid">
            <div className="row">
              <img
                loading="lazy"
                src="../images/Vector 7.svg"
                alt="Section Two END Hr"
                className="imgResponsive hrSmHide"
              />
            </div>
            <div className="reverseColumnS6">
              <div className="row pt-3 ">
                <div
                  className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv"
                  
                  
                >
                  <h3 className="py-3 pt-sm-5 pt-0 paraWidth" >
                    {t("HP.S6.h.1")} <br></br> {t("HP.S6.h.2")} {t("HP.S6.h.3")}
                  </h3>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv pb-lg-0 pb-md-0 pb-5">
                  <p
                    className="homePageS3SubHeading pt-sm-5 pt-0 mb-5"
                    
                    
                  >
                    {t("HP.S6.p")}
                  </p>
                  <div
                    className="transparent-gradient-border-btn"
                    style={{ marginTop: "2rem" }}
                    onClick={(e) => {
                      showAlert(e);
                    }}
                    
                    
                  >
                    <button className="button-content ">
                      {" "}
                      <span className="gradient-text"> {t("btnING.1")} </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <img
                  loading="lazy"
                  src="../images/hrSmShow.svg"
                  alt="Section Two END Hr"
                  className="imgResponsive hrSmShow mt-sm-0 mt-5"
                />

                <div
                  className="col-12 p-0 IndexS6Bg mt-4"
                  
                  
                >
                  <div className="row s5PopUpDiv ps-sm-5 ps-0">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-10 col-12 ">
                      <div
                        className="S5PopUpBtnDiv"
                      >
                        <img
                          loading="lazy"
                          src="../images/laughing girl overlay picture with graph.png"
                          alt="Section One Right Side Images"
                          className="rightSideS1Img imgResponsive"
                        />
                        {/* <img
                          loading="lazy"
                          src="../images/buttons.png"
                          alt="Section One Right Side Images"
                          className="rightSideS1Img imgResponsive w-100 px-3 m-auto"
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section Six End  */}

        {/* Section Seven Start  */}
        <div className="homepageContainer IndexS7Bg">
          <div className="container-fluid s4Banner">
            <div className="row">
              <div
                className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 homepageS3MainHeadingDiv "
                
                
              >
                <div className="row">
                  <img
                    loading="lazy"
                    src="../images/Vector 6.svg"
                    alt="Section Two Right Side HR"
                    className="rightSideHrS2 imgResponsive"
                  />
                </div>
                <h3 className="py-3">
                  {t("HP.S7.h.1")}
                  <br></br> <b className="s2HeadingBold"> {t("HP.S7.hb")} </b>
                </h3>
                <p className="homePageS4SubHeading ">{t("HP.S7.p")}</p>
                <Link to="cards/">
                  <div className="S4BtnDiv btnHide">
                    <button className="s4Btn HPS7Btn">
                      {" "}
                      <Link to="cards/" className="gradient-text">
                        {t("btnING.3")}{" "}
                      </Link>
                    </button>
                  </div>
                </Link>
              </div>

              <div
                className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12  d-flex justify-content-end pe-4 py-sm-0 py-5"
                
               
              >
                <img
                  loading="lazy"
                  src="../images/home7.png"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img imgResponsive"
                />
              </div>
              <Link to="cards/">
                <div className="S4BtnDiv btnShow">
                  <button className="s4Btn HPS7Btn">
                    {" "}
                    <Link
                      to="cards/"
                      className="gradient-text"
                      style={{ fontSize: "16px" }}
                    >
                      {" "}
                      {t("btnING.3")}{" "}
                    </Link>
                  </button>
                </div>
              </Link>

              <div className="row S7BottomColumns px-lg-0 px-md-0 px-5 ">
                <div
                  className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 columnBorder columnBorderMb columnSettingsS7 ps-2"
                  
                 
                >
                  <h3 className="S3BottomHeading">{t("HP.S07.h.1")}</h3>
                  <p className="s4BottomSubHeading pt-lg-3 pt-md-3 pt-0">{t("HP.S07.p.1")}</p>
                  {/* <img loading='lazy'  src={../S7BorderSideImg} alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive"/> */}
                </div>

                <div
                  className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 columnBorder columnBorderMb columnSettingsS7"
                  
                  
                >
                  <h3 className="S3BottomHeading">{t("HP.S07.h.2")}</h3>
                  <p className="s4BottomSubHeading pt-lg-3 pt-md-3 pt-0">{t("HP.S07.p.2")}</p>
                  {/* <img loading='lazy'  src={../S7BorderSideImg} alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive"/> */}
                </div>

                <div
                  className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 columnBorder columnBorderMb columnSettingsS7"
                  
                  
                >
                  <h3 className="S3BottomHeading">{t("HP.S07.h.3")}</h3>
                  <p className="s4BottomSubHeading pt-lg-3 pt-md-3 pt-0">{t("HP.S07.p.3")}</p>
                  {/* <img loading='lazy'  src={../S7BorderSideImg} alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive"/> */}
                </div>

                <div
                  className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 columnBorderMb columnSettingsS7 mb-lg-0 mb-md-0 mb-5"
                  
                 
                >
                  <h3 className="S3BottomHeading ">{t("HP.S07.h.4")}</h3>
                  <p className="s4BottomSubHeading pt-lg-3 pt-md-3 pt-0">{t("HP.S07.p.4")}</p>
                  {/* <img loading='lazy'  src={../S7BorderSideImg} alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive"/> */}
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* Section Nine Start  */}
        <div className="homepageContainer ">
          <div className="container-fluid">
            <div className="row mt-5">
              <img
                loading="lazy"
                src="../images/mbHr.svg"
                alt="Section Two END Hr"
                className="imgResponsive hrSmShow mb-sm-0 mb-5"
              />
              <div

                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-5 d-flex justify-content-start "

              >
                <img
                  loading="lazy"
                  src="../images/home9.png"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img  imgResponsivesWidth"
                />
              </div>

              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-5 homepageS1MainHeadingDiv "

              >
                <div className="row">
                  <img
                    loading="lazy"
                    src="../images/Vector 6.svg"
                    alt="Section Two Right Side HR"
                    className="rightSideHrS2 hrSmHide imgResponsive"
                  />
                </div>

                <h3 className="pt-5">
                  {t("HP.S9.h.1")} <br></br>{" "}
                  <b className="s2HeadingBold"> {t("HP.S9.hb.1")} </b>
                </h3>
                <p className="homePageS2SubHeading pt-4  HPS9RSPara">
                  {t("HP.S9.p.1")}
                </p>
                <Link to="crypto/">
                  <div className="S5BtnDiv  ">
                    <button className="s5Btn">
                      {" "}
                      <Link to="crypto/" className="s5Btntxt">
                        {" "}
                        {t("btnING.2")}{" "}
                      </Link>
                    </button>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Section Nine End  */}

        {/* Section Ten Start  */}
        <div className="homepageContainer IndexS10">
          <div className="container-fluid ">
            <div
              className="row py-lg-5 py-md-5 py-0  pt-lg-0 pt-md-0 pt-5 pb-lg-0 pb-md-0 pb-0 "
              
             
            >
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6  mb-4 d-flex justify-content-sm-center justify-content-start  align-items-center ">
                <img
                  loading="lazy"
                  src="../images/wallet (1) 1.svg"
                  alt="Section Twp Left Side Images"
                  className="s10ImgsSize1 imgResponsive"
                />

                <p className="s10Text  pt-3 ">
                  {t("HP.S10.h.1")} <br></br> {t("HP.S10.h.2")}
                </p>
                {/* <img loading='lazy'  src={../S7BorderSideImg} alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive"/> */}
              </div>

              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6  mb-4 d-flex d-flex justify-content-sm-center justify-content-end  align-items-center ">
                <img
                  loading="lazy"
                  src="../images/swap (3) 1.svg"
                  alt="Section Twp Left Side Images"
                  className="s10ImgsSize2 imgResponsive"
                />

                <p className="s10Text p pt-3 ">
                  {t("HP.S10.h.3")} <br></br> {t("HP.S10.h.4")}
                </p>
                {/* <img loading='lazy'  src={../S7BorderSideImg} alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive"/> */}
              </div>

              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6  mb-4  d-flex d-flex justify-content-sm-center justify-content-start  align-items-center">
                <img
                  loading="lazy"
                  src="../images/send (2) 1.svg"
                  alt="Section Twp Left Side Images"
                  className="s10ImgsSize3 imgResponsive"
                />

                <p className="s10Text pt-3">
                  {t("HP.S10.h.5")} <br></br> {t("HP.S10.h.6")}
                </p>
                {/* <img loading='lazy'  src={../S7BorderSideImg} alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive"/> */}
              </div>

              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6  mb-4  d-flex d-flex justify-content-sm-center justify-content-end  align-items-center">
                <img
                  loading="lazy"
                  src="../images/shopping-bag (2) 1.svg"
                  alt="Section Twp Left Side Images"
                  className="s10ImgsSize4 imgResponsive"
                />

                <p className="s10Text  pt-3 ">
                  {t("HP.S10.h.7")} <br></br> {t("HP.S10.h.8")}
                </p>
                {/* <img loading='lazy'  src={../S7BorderSideImg} alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive"/> */}
              </div>
            </div>
          </div>
        </div>
        {/* Section Ten End  */}
      </div>
    </AnimatedPage>
  );
}
