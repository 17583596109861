import React , {useState,useEffect} from 'react'
import AnimatedPage from '../AnimatedPage';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { CurrencyDropdownItems } from '../../constants/AllCurencies';
import { currencyMapping } from '../../constants/currencyMapping';
import { useTranslation } from 'react-i18next'
import { getToken } from '../../services/LocalStorageService';
function DropdownItem({ text,code, imgSrc, onSelect }) {
    return (
      <button className="dropdown-item d-flex gap-3 align-items-center" onClick={() => onSelect(text,code, imgSrc)}>
        <img loading='lazy' src={imgSrc} alt="Currency Image" className="currencySwapOptionImg" />
        <div className='d-flex flex-column'>
        {text} 
        <span>{code}</span>
        </div>
      </button>
    );
  }
  
  function DropdownItem1({ text,code, imgSrc, onSelect }) {
    return (
      <button className="dropdown-item d-flex gap-3 align-items-center" onClick={() => onSelect(text,code, imgSrc)}>
        <img loading='lazy' src={imgSrc} alt="Currency Image" className="currencySwapOptionImg" /> 
        <div className='d-flex flex-column'>
        {text} 
        <span>{code}</span>
        </div>
      </button>
    );
  }




function ExchangeRate() {
    const {access_token} = getToken()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const [apiVal ,setapiVal ] = useState()
    const [secondApiVal ,setSecondApiVal ] = useState()
    const [firstCurrencySign ,setFirstCurrencySign ] = useState()
    const [secondCurrencySign ,setSecondCurrencySign ] = useState() 

    const [firstCurrencyVal ,setFirstCurrencyVal ] = useState(1)
    const [secondCurrencyVal ,setSecondCurrencyVal ] = useState(0) 


    const firstCurrency = location?.state?.first?.apiShortName
    const secondCurrency = location?.state?.second?.apiShortName

    const shortName1 = location?.state?.first?.shortName
    const shortName2 = location?.state?.second?.shortName


    const firstLogo = location?.state?.first?.imgSrc
    const secondLogo = location?.state?.second?.imgSrc

    const firstCurrencyNames = location?.state?.first?.currency
    const secondCurrencyNames = location?.state?.second?.currency

    const firstCurrencysigns = location?.state?.first?.sign
    const secondCurrencysigns = location?.state?.second?.sign


    const secondCountry = location?.state?.second?.country
    const firstCountry = location?.state?.first?.country




    useEffect(() => {
        handleOptionSelect(secondCurrencyNames,secondCurrencyNames,secondLogo)
        handleOptionSelect1(firstCurrencyNames,firstCurrencyNames,firstLogo)
        setFirstCurrencySign(firstCurrencysigns)
        setSecondCurrencySign(secondCurrencysigns)
    }, [])

    const handleFirstCurrencyInput = (e) => {
        setFirstCurrencyVal(e)
    }
    const handleSecondCurrencyInput = (e) => {
        setSecondCurrencyVal(e)
    }



    // useEffect(() => {
    //     fetch(`https://evistialab.com/api/user/convert/fiat-to-fiat/?amount=${1}&from_fiat=${firstCurrency}&to_fiat=${secondCurrency}`)
    //       .then(response => response.json())
    //       .then(data => {
    //           const formattedAmount = Number(data.converted_amount).toFixed(2);
    //           setapiVal(formattedAmount)
    //       })
    //       .catch(error => console.error('Error fetching data:', error));
    //   }, [firstCurrencyVal]); 


    //   useEffect(() => {
    //     fetch(`https://evistialab.com/api/user/convert/fiat-to-fiat/?amount=${1}&from_fiat=${secondCurrency}&to_fiat=${firstCurrency}`)
    //       .then(response => response.json())
    //       .then(data => {
    //           const formattedAmount = Number(data.converted_amount).toFixed(2);
    //           setSecondApiVal(formattedAmount)
    //       })
    //       .catch(error => console.error('Error fetching data:', error));
    //   }, []); 

    useEffect(() => {
        if (firstCurrencyVal !== 0) {
            // Prepare the form data to be sent in the request
            const apiData = { from_currency: firstCurrency.toUpperCase(), to_currency: secondCurrency.toUpperCase(), amount: Number(firstCurrencyVal) }
            console.log('conersion api data', apiData)
            // Fetch API call
            fetch('https://evistialab.com/api/v1/convert-fiat-price/', {
                method: 'POST',  // Assuming it's a POST request
                headers: {
                    'Content-Type': 'application/json',  // Assuming the API expects JSON
                },
                body: JSON.stringify(apiData)  // Convert the JavaScript object to a JSON string
            }).then(response => {
                return response.json();
            }).then(data => {
                console.log('data is here',data,'e2')
                const formattedAmount = Number(data.exchange_rate).toFixed(2);
                setapiVal(formattedAmount)
            }).catch(error => console.error('Error fetching data:', error));
        }
    }, [apiVal]);


    useEffect(() => {
        if (firstCurrencyVal !== 0) {
            // Prepare the form data to be sent in the request
            const apiData = { from_currency: secondCurrency.toUpperCase(), to_currency: firstCurrency.toUpperCase(), amount: Number(1) }
            console.log('conersion api data', apiData)
            // Fetch API call
            fetch('https://evistialab.com/api/v1/convert-fiat-price/', {
                method: 'POST',  // Assuming it's a POST request
                headers: {
                    'Content-Type': 'application/json',  // Assuming the API expects JSON
                },
                body: JSON.stringify(apiData)  // Convert the JavaScript object to a JSON string
            }).then(response => {
                return response.json();
            }).then(data => {
                console.log('data is here',data,'e23')
                const formattedAmount = Number(data.exchange_rate).toFixed(2);
                setSecondApiVal(formattedAmount)
            }).catch(error => console.error('Error fetching data:', error));
        }
    }, []);



    const handleExPage = (firstCurrency, secondCurrency) => {
    // Retrieve currency information from the mapping
    const firstCurrencyInfo = currencyMapping[firstCurrency];
    const secondCurrencyInfo = currencyMapping[secondCurrency];

    
    // Check if currencyInfo exists before using it
    if (firstCurrencyInfo && secondCurrencyInfo) {
        const { sign: firstSign, shortName: firstShortName, apiShortName : firstApiShortName, imgSrc: firstImgSrc , country :firstCountry } = firstCurrencyInfo;
        const { sign: secondSign, shortName: secondShortName, apiShortName : secondApiShortName,  imgSrc: secondImgSrc , country :secondCountry } = secondCurrencyInfo;

    
        // Navigate to the exchange rates page with currency information
        navigate(`/exchange-rates/${firstApiShortName}/to/${secondApiShortName}/`, {
        state: {
            first: { currency: firstShortName, sign: firstSign, apiShortName : firstApiShortName, imgSrc: firstImgSrc,shortName:firstShortName , country :firstCountry},
            second: { currency: secondShortName, sign: secondSign, secondShortName, apiShortName : secondApiShortName, imgSrc: secondImgSrc ,shortName:secondShortName , country :secondCountry },
        },
        });
    }
    };



    const [dropDown,showDropDown] = useState(false)
    const [dropDown1,showDropDown1] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOption1, setSelectedOption1] = useState(null);




    const dropDownHandle = () => {
        showDropDown(!dropDown)
    }
    const dropDownHandle1 = () => {
        showDropDown1(!dropDown1)
    }

    const handleOptionSelect = (optionText,optionCode, optionImgSrc) => {
        setSelectedOption({ text: optionText,code:optionCode, imgSrc: optionImgSrc });
        showDropDown(false);
        handleExPage(firstCurrencyNames,optionCode)
    };
  
  const handleOptionSelect1 = (optionText,optionCode, optionImgSrc) => {

    setSelectedOption1({ text: optionText,code:optionCode, imgSrc: optionImgSrc });
    showDropDown1(false);
    handleExPage(optionCode,secondCurrencyNames)
  };




    return (
        <AnimatedPage>
          <div >
            {/* Section One Start  */}
              <div className='homepageContainerP3 pt-5 mt-md-5 mt-2 pb-0' >
                  <div className='container-fluid'>
                      <div className='row mt-5 sendMoneyRow' style={{position:"relative"}}>
                        <div className='col-md-5 col-12 pt-sm-5 pt-0'>
                            <p className='exSubHeading ps-md-2 ps-1 hrSmHide'  >
                                {firstCountry} to {secondCountry}
                            </p>
                            <h3 className='exHeading'  >
                                {t('EX.h.1')} {firstCurrencyNames}  {t('EX.sw.2')} <br></br> {secondCurrencyNames}  {t('EX.h.2')}.
                            </h3>
                            <p className='exPara ps-1'  >
                            {t('EX.p.1')} {firstCountry}  {t('EX.sw.1')} {secondCountry}  {t('EX.p.2')}
                            </p>
                        </div>
                        <div className='col-md-7 col-12 exRightDiv'  >
                        <img loading='lazy'  src={process.env.PUBLIC_URL + "/images/Group 654.png"} alt="Section One Right Side Images" className="exImgbottomImg"/>

                        <div className='exCard '>
                            <div className='detailRow'>
                                <p>
                                {t('EX.p.3')}
                                </p>
                                <div className='row '>
                                <div className=' col-6 exSwap  d-flex gap-3 align-items-start justify-content-start' onClick={dropDownHandle1}>
                                        <div className='d-flex align-items-start gap-2'>
                                            <img loading='lazy' src={process.env.PUBLIC_URL + selectedOption1?.imgSrc || process.env.PUBLIC_URL + '/images/g.svg'} alt="Currency Image" className="currencyLogo" />
                                            <h3 className='mb-0 gradinatTxt'>{selectedOption1?.code || 'GBP'}</h3>
                                            <img loading='lazy' src="../../../../images/WHPArrowD.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow mt-2"/>
                                        </div>
                                        <div className={`dropdown-menu CurrencySwapDropDown0007 ${dropDown1 ? 'show' : ''}`} onMouseLeave={() => showDropDown1(false)}>
                                            {CurrencyDropdownItems.map((item, index) => (
                                                <DropdownItem1 key={index} text={item.name} code={item.currency} imgSrc={item.imgSrc}  onSelect={handleOptionSelect1} />
                                            ))}
                                        </div>
                                        
                                    </div>
                                    <div className='col-6'>
                                        <input type='number' className='form-control exInput' placeholder='0' value={firstCurrencyVal} onChange={(e)=>{handleFirstCurrencyInput(e.target.value)}}/>
                                    </div>
                                </div>
                            </div>


                            <div className='detailRow'>
                                <p>
                                {t('EX.p.4')}
                                </p>
                                <div className='row '>
                                <div className=' col-6 exSwap  d-flex gap-3 align-items-start justify-content-start' onClick={dropDownHandle}>
                                        <div className='d-flex align-items-start gap-2'>
                                            <img loading='lazy' src={process.env.PUBLIC_URL + selectedOption?.imgSrc || process.env.PUBLIC_URL + '/images/eur.svg'} alt="Currency Image" className="currencyLogo" />
                                            <h3 className='mb-0 gradinatTxt'>{selectedOption?.code || 'EUR'}</h3>
                                            <img loading='lazy' src="../../../../images/WHPArrowD.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow mt-2"/>
                                        </div>

                                        <div className={`dropdown-menu CurrencySwapDropDown0007 ${dropDown ? 'show' : ''}`} onMouseLeave={() => showDropDown(false)}>
                                            {CurrencyDropdownItems.map((item, index) => (
                                                <DropdownItem key={index} text={item.name} code={item.currency} imgSrc={item.imgSrc} onSelect={handleOptionSelect} />
                                            ))}
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <input type='number' className='form-control exInput' placeholder='0' value={firstCurrencyVal * apiVal} onChange={(e)=>{
                                            handleSecondCurrencyInput(e.target.value)
                                        }}
                                        readOnly/>
                                    </div>
                                </div>
                            </div>

                            <div className='detailRow  w-100'>
                                <div className='d-flex justify-content-between w-100'>
                                    <h5 className='feeHeading'>
                                    {t('EX.p.5')}
                                    </h5>
                                    <h6 className='feeTxt'>
                                        {firstCurrencySign}1 = {secondCurrencySign}{apiVal}
                                    </h6>
                                </div>

                                <div className='d-flex justify-content-between w-100'>
                                    <h5 className='feeHeading mb-0'>
                                    {t('EX.p.6')}
                                    </h5>
                                    <h6 className='feeTxt mb-0'>
                                        £0.00
                                    </h6>
                                </div>
                            </div>

                                <a onClick={()=>{navigate('/login')}} className='convertBtn '>
                                {t('EX.sw.3')}
                                </a>
                        </div>

                        
                        </div>
                        <img loading='lazy' src="../../../../../../images/Vector 5 (1).png" alt="Section One Right Side Images" className="downLineCm"/>
                      </div>
                      
                  </div>
              </div>
  
            {/* Section One End  */}


             {/* Section Two Start  */}
             <div className='homepageContainerP3' >
                  <div className='container-fluid'>
                      <div className='row px-md-5 px-0'>
                            <p className='exHeadingH1 mb-5'  >
                                
                                <b
                                className='exHeadingB ps-2'  >
                                    {t('EX.p.7')} 
                                
                                </b>
                                {t('EX.p.8')}
                            </p>


                            <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                                <div className='exDetailsCardBg'>
                                    <div className='exDetailsCardCover'>
                                        <img loading='lazy' src="../../../../../../images/01.svg" alt="Section One Right Side Images" className=""/>
                                        <h3 className='mb-0'>
                                        {t('EX.p.9')}
                                        </h3>
                                        <p>
                                        {t('EX.p.10')}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                                <div className='exDetailsCardBg'>
                                    <div className='exDetailsCardCover'>
                                        <img loading='lazy' src="../../../../../../images/02.svg" alt="Section One Right Side Images" className=""/>
                                        <h3 className='mb-0'>
                                        {t('EX.p.11')}
                                        </h3>
                                        <p>
                                        {t('EX.p.12')}
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                                <div className='exDetailsCardBg'>
                                    <div className='exDetailsCardCover'>
                                        <img loading='lazy' src="../../../../../../images/03.svg" alt="Section One Right Side Images" className=""/>
                                        <h3 className='mb-0'>
                                        {t('EX.p.13')}
                                        </h3>
                                        <p>
                                        {t('EX.p.14')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                      </div>
                      
                  </div>
              </div>
            {/* Section Two End  */}


            {/* Section Three Start  */}
            <div className='homepageContainerP3 pt-3' >
                  <div className='container-fluid'>
                      <div className='row px-md-5 px-0'>
                            <p className='exHeadingH1 mb-5'  >
                                <b
                                className='exHeadingB ps-2'  >
                                    {firstCountry} {t('EX.sw.1')} {secondCountry} <br></br>
                                    {t('EX.p.15')}
                                </b>
                            </p>


                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                <div className='upperRow pb-md-0 pb-3'>
                                    <div className='d-flex gap-2 align-items-center' >
                                       
                                        <img loading='lazy' src={`${firstLogo}` || "../../../../../images/g.svg"} alt="Section One Right Side Images" className=""/>
                                        <p className='mb-0'>
                                        {firstCurrencyNames}
                                        </p>
                                    </div>
                                    <div className='d-flex gap-2 align-items-center' >
                                    
                                    <img loading='lazy' src={`${secondLogo}` || "../../../../../images/eur.svg"} alt="Section One Right Side Images" className=""/>
                                    <p className='mb-0'>
                                        {secondCurrencyNames}
                                    </p>
                                    </div>
                                </div>
                                <div className='row1'>
                                    <p className='mb-0'>
                                        1 {firstCurrencyNames}
                                    </p>
                                    <span>
                                        {apiVal} {secondCurrencyNames}
                                    </span>
                                </div>
                                <div className='row2'>
                                    <p className='mb-0'>
                                        5 {firstCurrencyNames}
                                    </p>
                                    <span>
                                        {apiVal * 5} {secondCurrencyNames}
                                    </span>
                                </div>
                                <div className='row1'>
                                    <p className='mb-0'>
                                        10 {firstCurrencyNames}
                                    </p>
                                    <span>
                                    {apiVal * 10} {secondCurrencyNames}
                                    </span>
                                </div>
                                <div className='row2'>
                                    <p className='mb-0'>
                                        25 {firstCurrencyNames}
                                    </p>
                                    <span>
                                    {apiVal * 25} {secondCurrencyNames}
                                    </span>
                                </div>
                                <div className='row1'>
                                    <p className='mb-0'>
                                        50 {firstCurrencyNames}
                                    </p>
                                    <span>
                                    {apiVal * 50} {secondCurrencyNames}
                                    </span>
                                </div>
                                <div className='row2'>
                                    <p className='mb-0'>
                                        100 {firstCurrencyNames}
                                    </p>
                                    <span>
                                    {apiVal * 100} {secondCurrencyNames}
                                    </span>
                                </div>
                                <div className='row1'>
                                    <p className='mb-0'>
                                        500 {firstCurrencyNames}
                                    </p>
                                    <span>
                                    {apiVal * 500} {secondCurrencyNames}
                                    </span>
                                </div>
                                <div className='row2'>
                                    <p className='mb-0'>
                                        1000 {firstCurrencyNames}
                                    </p>
                                    <span>
                                    {apiVal * 1000} {secondCurrencyNames}
                                    </span>
                                </div>
                                <div className='row1'>
                                    <p className='mb-0'>
                                        5000 {firstCurrencyNames}
                                    </p>
                                    <span>
                                    {apiVal * 5000} {secondCurrencyNames}
                                    </span>
                                </div>
                                <div className='row2'>
                                    <p className='mb-0'>
                                        10000 {firstCurrencyNames}
                                    </p>
                                    <span>
                                    {apiVal * 10000} {secondCurrencyNames}
                                    </span>
                                </div>
                                <div className='row1'>
                                    <p className='mb-0'>
                                        50000 {firstCurrencyNames}
                                    </p>
                                    <span>
                                    {apiVal * 50000} {secondCurrencyNames}
                                    </span>
                                </div>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-5 mt-md-0'>
                                <div className='upperRow pb-md-0 pb-3'>
                                    <div className='d-flex gap-2 align-items-center' >
                                    
                                    <img loading='lazy' src={`${secondLogo}` || "../../../../../images/eur.svg"} alt="Section One Right Side Images" className=""/>
                                    <p className='mb-0'>
                                        {secondCurrencyNames}
                                    </p>
                                    </div>
                                    <div className='d-flex gap-2 align-items-center' >
                                       
                                       <img loading='lazy' src={`${firstLogo}` || "../../../../../images/eur.svg"} alt="Section One Right Side Images" className=""/>
                                       <p className='mb-0'>
                                       {firstCurrencyNames}
                                       </p>
                                   </div>
                                </div>
                                <div className='row1'>
                                    <p className='mb-0'>
                                        1 {secondCurrencyNames}
                                    </p>
                                    <span>
                                    {secondApiVal} {firstCurrencyNames}
                                    </span>
                                </div>
                                <div className='row2'>
                                    <p className='mb-0'>
                                        5 {secondCurrencyNames}
                                    </p>
                                    <span>
                                    {secondApiVal * 5} {firstCurrencyNames}
                                    </span>
                                </div>
                                <div className='row1'>
                                    <p className='mb-0'>
                                   10 {secondCurrencyNames}
                                    </p>
                                    <span>
                                    {secondApiVal * 10} {firstCurrencyNames}
                                    </span>
                                </div>
                                <div className='row2'>
                                    <p className='mb-0'>
                                        25 {secondCurrencyNames}
                                    </p>
                                    <span>
                                    {secondApiVal * 25} {firstCurrencyNames}
                                    </span>
                                </div>
                                <div className='row1'>
                                    <p className='mb-0'>
                                        50 {secondCurrencyNames}
                                    </p>
                                    <span>
                                    {secondApiVal * 50} {firstCurrencyNames}
                                    </span>
                                </div>
                                <div className='row2'>
                                    <p className='mb-0'>
                                        100 {secondCurrencyNames}
                                    </p>
                                    <span>
                                    {secondApiVal * 100} {firstCurrencyNames}
                                    </span>
                                </div>
                                <div className='row1'>
                                    <p className='mb-0'>
                                        500 {secondCurrencyNames}
                                    </p>
                                    <span>
                                    {secondApiVal * 500} {firstCurrencyNames}
                                    </span>
                                </div>
                                <div className='row2'>
                                    <p className='mb-0'>
                                        1000 {secondCurrencyNames}
                                    </p>
                                    <span>
                                    {secondApiVal * 1000} {firstCurrencyNames}
                                    </span>
                                </div>
                                <div className='row1'>
                                    <p className='mb-0'>
                                        5000 {secondCurrencyNames}
                                    </p>
                                    <span>
                                    {secondApiVal * 5000} {firstCurrencyNames}
                                    </span>
                                </div>
                                <div className='row2'>
                                    <p className='mb-0'>
                                        10000 {secondCurrencyNames}
                                    </p>
                                    <span>
                                    {secondApiVal * 10000} {firstCurrencyNames}
                                    </span>
                                </div>
                                <div className='row1'>
                                    <p className='mb-0'>
                                        50000 {secondCurrencyNames}
                                    </p>
                                    <span>
                                    {secondApiVal * 50000} {firstCurrencyNames}
                                    </span>
                                </div>
                            </div>

                            <p className='exInfoTxt mt-4'>
                            {t('EX.p.16')}
                            </p>
                      </div>
                      
                  </div>
              </div>
            {/* Section Three End  */}


             {/* Section Four Start  */}
             <div className='homepageContainerP3 pb-5' >
                  <div className='container-fluid'>
                      <div className='row px-md-5 px-0'>
                            <p className='exHeadingH1 mb-5'  >
                                
                                <b
                                className='exHeadingB ps-2'  >
                                 {t('EX.p.17')} <br>
                                 </br>
                                 {firstCurrency} {t('EX.sw.1')} {secondCountry}?
                                </b>
                               
                            </p>


                            <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                                <div className='exDetailsCardBg'>
                                    <div className='exDetailsCardCover'>
                                        <img loading='lazy' src="../../../../../../images/01.svg" alt="Section One Right Side Images" className=""/>
                                        <h3 className='mb-0'>
                                        {t('EX.p.18')}
                                        </h3>
                                        <p>
                                        {t('EX.p.19')}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                                <div className='exDetailsCardBg'>
                                    <div className='exDetailsCardCover'>
                                        <img loading='lazy' src="../../../../../../images/02.svg" alt="Section One Right Side Images" className=""/>
                                        <h3 className='mb-0'>
                                        {t('EX.p.20')}
                                        </h3>
                                        <p>
                                        {t('EX.p.21')} {firstCurrencyNames} {t('EX.p.22')} {secondCurrencyNames}.
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                                <div className='exDetailsCardBg'>
                                    <div className='exDetailsCardCover'>
                                        <img loading='lazy' src="../../../../../../images/03.svg" alt="Section One Right Side Images" className=""/>
                                        <h3 className='mb-0'>
                                        {t('EX.p.23')}
                                        </h3>
                                        <p>
                                        {t('EX.p.24')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                      </div>
                      
                  </div>
              </div>
            {/* Section Four End  */}
      


    {/* Section Five Start  */}
      <div className='homepageContainerP3 pt-2 pb-0' >
                  <div className='container-fluid'>
                      <div className='row mt-md-5 mt-0 reverseRow' >
                      <div className='col-md-6 col-12 d-flex ps-sm-4 coleageImg mt-md-0 mt-5'  >
                        <img loading='lazy' src="../../../../../../images/portrait-man-opening-beer-bottle-cap.png" alt="Section Five Right Side Images" className=""/>
                        </div>
                        <div className='col-md-6 col-12 pt-md-5 pt-0'>
                            <p className='exSectionMainHeading '  >
                            {t('EX.p.25')}
                            {t('EX.p.26')} {firstCountry} {t('EX.sw.1')} {secondCountry}?
                            </p>
                            <p className='exSectionMainPara pb-4'  >
                            {t('EX.p.27')} {firstCurrencyNames} {t('EX.sw.1')} {secondCurrencyNames} {t('EX.p.28')}
                            </p>
                            <Link to="/sign-up/">
                                <div className=" divBtnMedium ">
                                <button className=" btnMedium">
                                    {" "}
                                    <span className="btnMediumText mt-sm-0 mt-3">
                                    {t('navbar.link_6.3')}   
                                    </span>
                                </button>{" "}
                                </div>
                            </Link>
                        </div>
                        

                      </div>
                      
                  </div>
    </div>
  
    {/* Section Five End  */}


    {/* Section Six Start  */}
    <div className='homepageContainerP3 pt-md-5 pt-3 pb-0 mb-4' >
                  <div className='container-fluid'>
                      <div className='row mt-md-5 mt-0 ' >
                     
                        <div className='col-md-6 col-12 pt-md-5 pt-0 ps-md-4 ps-2'>
                            <p className='exSectionMainHeading'  >
                            {t('EX.p.30')}
                            {t('EX.p.31')} {firstCountry} {t('EX.sw.1')} {secondCountry}?
                            </p>
                            <p className='exSectionMainPara'  >
                            {t('EX.p.32')}
                            </p>
                            <p className='exSectionMainPara'  >
                            {t('EX.p.33')}
                            </p>
                            <p className='exSectionMainPara'  >
                            {t('EX.p.34')}
                            </p>
                            <p className='exSectionMainPara'  >
                            {t('EX.p.35')}
                            </p>
                            
                        </div>

                        <div className='col-md-6 col-12 coleageImg mt-md-0 mt-5 d-flex justify-content-center'  >
                        <img loading='lazy' src="../../../../../../images/portrait-man-opening-beer-bottle-cap (1).png" alt="Section Six Right Side Images" className=""/>
                        </div>
                        

                      </div>
                      
                  </div>
    </div>
    {/* Section Six End  */}

    {/* Section Seven Start  */}
    {/* <div className='homepageContainerP3 pt-5 ' >
        <div className='container-fluid'>
            <p className='exbottomheading mt-4'  >
                {firstCurrency} {t('EX.p.36')}
            </p>
            <div className='row mt-2 px-md-4 px-1' >
                <div className='col-md-3 col-6 pt-2 ps-md-4 ps-2 d-flex justify-content-center align-items-center gap-2'>
                <img loading='lazy' src="../../../../../../images/gbp_eur.svg" alt="Section Seven Right Side Images" className="exBottomImg"/>
                    <p className='exSectionMainPara mb-0 mt-1'>
                    {firstCurrencyNames} to EUR
                    </p>
                </div>

                <div className='col-md-3 col-6 pt-2 ps-md-4 ps-2 d-flex justify-content-center align-items-center gap-2'>
                <img loading='lazy' src="../../../../../../images/gbp_tr.svg" alt="Section Seven Right Side Images" className="exBottomImg"/>
                    <p className='exSectionMainPara mb-0 mt-1'>
                    {firstCurrencyNames} to TRY
                    </p>
                </div>

                <div className='col-md-3 col-6 pt-2 ps-md-4 ps-2 d-flex justify-content-center align-items-center gap-2'>
                <img loading='lazy' src="../../../../../../images/gbp_ind.svg" alt="Section Seven Right Side Images" className="exBottomImg"/>
                    <p className='exSectionMainPara mb-0 mt-1'>
                    {firstCurrencyNames} to IND
                    </p>
                </div>

                <div className='col-md-3 col-6 pt-2 ps-md-4 ps-2 d-flex justify-content-center align-items-center gap-2'>
                <img loading='lazy' src="../../../../../../images/gbp_pln.svg" alt="Section Seven Right Side Images" className="exBottomImg"/>
                    <p className='exSectionMainPara mb-0 mt-1'>
                    {firstCurrencyNames} to PLN
                    </p>
                </div>
            </div>

            <p className='exbottomheading mt-md-5 mt-3 pt-3'  >
            {t('EX.p.37')} {firstCurrencyNames}
            </p>
            <div className='row mt-2 px-md-4 px-1' >
                <div className='col-md-4 col-6 pt-2 ps-md-4 ps-2 d-flex justify-content-center align-items-center gap-2'>
                <img loading='lazy' src="../../../../../../images/eur_gbp.svg" alt="Section Seven Right Side Images" className="exBottomImg"/>
                    <p className='exSectionMainPara mb-0 mt-1'>
                    EUR to {firstCurrencyNames}
                    </p>
                </div>

                <div className='col-md-4 col-6 pt-2 ps-md-4 ps-2 d-flex justify-content-center align-items-center gap-2'>
                <img loading='lazy' src="../../../../../../images/thb_gbp.svg" alt="Section Seven Right Side Images" className="exBottomImg"/>
                    <p className='exSectionMainPara mb-0 mt-1'>
                    THB to {firstCurrencyNames}
                    </p>
                </div>


                <div className='col-md-4 col-6 pt-2 ps-md-4 ps-2 d-flex justify-content-center align-items-center gap-2'>
                <img loading='lazy' src="../../../../../../images/isk_gbp.svg" alt="Section Seven Right Side Images" className="exBottomImg"/>
                    <p className='exSectionMainPara mb-0 mt-1'>
                    ISK to {firstCurrencyNames}
                    </p>
                </div>
            </div>

            <p className='exbottomheading mt-md-5 mt-3 pt-3'  >
               Other popular conversions
            </p>

            <div className='row mt-2 px-md-4 px-1' >
            <div className='col-md-4 col-6 pt-2 ps-md-4 ps-2 d-flex justify-content-center align-items-center gap-2'>
            <img loading='lazy' src="../../../../../../images/eur_ind.svg" alt="Section Seven Right Side Images" className="exBottomImg"/>
                <p className='exSectionMainPara mb-0 mt-1'>
                EUR to INR
                </p>
            </div>

            <div className='col-md-4 col-6 pt-2 ps-md-4 ps-2 d-flex justify-content-center align-items-center gap-2'>
            <img loading='lazy' src="../../../../../../images/mx_eur.png" alt="Section Seven Right Side Images" className="exBottomImg"/>
                <p className='exSectionMainPara mb-0 mt-1'>
                MXN to EUR
                </p>
            </div>


            <div className='col-md-4 col-6 pt-2 ps-md-4 ps-2 d-flex justify-content-center align-items-center gap-2'>
            <img loading='lazy' src="../../../../../../images/pln_eur.svg" alt="Section Seven Right Side Images" className="exBottomImg"/>
                <p className='exSectionMainPara mb-0 mt-1'>
                PLN to EUR
                </p>
            </div>
            

            </div>
            
        </div>
    </div> */}
    {/* Section Seven End  */}
      
          </div>
          </AnimatedPage>
        )
}

export default ExchangeRate