import React,{useState,useEffect} from 'react'
import AnimatedPage from '../AnimatedPage'
import { useNavigate } from 'react-router-dom';


export default function Careers() {

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [cat, setCat] = useState();

    const [allBlogArr, setallBlogArr] = useState();
    const [activeLink, setactiveLink] = useState('All Open Positions');

    const BlogDetailsHandle = (blogObj) => {
        navigate(`/blog-details/${blogObj.title}`, { state: { blogObj } });
    };
    useEffect(() => {
        const fetchBlogsCat = async () => {
            setIsLoading(true);
            setIsError(false);
            try {
                const response = await fetch('https://evistialab.com/api/user/job-categories/');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setCat(data);
            } catch (error) {
                setIsError(true);
                console.error('Fetch error:', error);
            }
            setIsLoading(false);
        };

        fetchBlogsCat();
    }, []);

    useEffect(() => {
        const fetchBlogs = async () => {
            setIsLoading(true);
            setIsError(false);
            try {
                if(activeLink === 'All Open Positions'){
                    const response = await fetch('https://evistialab.com/api/user/jobs/');
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setallBlogArr(data);
                    console.log(data)
                }else{
                    const response = await fetch(`https://evistialab.com/api/user/jobs/?category=${activeLink}`);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setallBlogArr(data);
                }
                
            } catch (error) {
                setIsError(true);
                console.error('Fetch error:', error);
            }
            setIsLoading(false);
        };

        fetchBlogs();
    }, [activeLink]);



    const [active,setActive] = useState(false)

    const data = [
        {id : 1,
        name : "name" ,
        content : "content"}
    ]

    const handleOnCLick =()=>{
        // setActive(!active)
    }
  return (
    <AnimatedPage>
    {isError ? (
        <p style={{paddingTop:"7rem",textAlign:"center"}}>No Job available at this time. Please check back later.</p>
      ) : allBlogArr?.length > 0 ? ( 
    <>
        {/* Section One Start  */}
    <div className='homepageContainerP2 py-3 mt-5' >
                <div className='container-fluid mt-5'>
                    <div className='row jusify-content-center pr_rem4'>
                        <h3 className='S1SubHeading text-center '>
                        Careers at Evistia
                        </h3>
                        <p className="S1Para text-center" >
                            We're committed to creating an inclusive environment that nurtures
                            personal and professional growth. Explore exciting career opportunities
                            below, and take the next big step in shaping the future of finance with us
                            at Evistia.
                        </p>
                    </div>
                </div>
    </div>
    {/* Section One End  */}


    {/* Section Two Start  */}
    <div className='ps-3 pe-md-5 pe-3 mb-5 pb-md-5 pb-2' >
        <div className='container-fluid'>
            <div className='row jusify-content-center '>
            <div className='col-lg-3 col-md-4 col-sm-12 col-12'>
                {/* <div className='searchSection'>
                    <div className="search-container">
                        <input type="text" className="form-control search-input" placeholder="Search Wallet" aria-label="Search" />
                        <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Vector (2).svg" } alt="Search Icon" className='search-icon' />
                    </div>
                </div> */}
                <div className='sidebarLinkCareers ps-md-4 ps-0'>
                    <p className=''>
                    Filter by department
                    </p>
                    <div className='sideBar'>
                        <a className={`${activeLink === 'All Open Positions' ? 'active' : ""}`} onClick={()=>setactiveLink('All Open Positions')}>
                    All Open Positions
                    </a>

                    { cat?.length > 0 ? cat.map((element) => ( 
                            <>
                               <a className={`${activeLink === element.name ? 'active' : ""}`} key={element.id} onClick={()=>setactiveLink(element.name)} >
                                {element.name}
                            </a>

                            </>
                    )) : null }

                    </div>
                </div>
            </div>
            <div className='col-lg-9 col-md-8 col-sm-12 col-12 pe-md-5 pe-2'>
            { allBlogArr?.length > 0 ? allBlogArr.map((element) => (
            <div className='row'>
            <img loading='lazy' src="../../images/Vector 5 (1).png" alt="Section One Right Side Images" className="mb-md-3 mb-0"/>
            <div className="accordion " id="accordionPanelsStayOpenExample" key={element.id}>
                <div className="accordion-item" >
                    <h2 className="accordion-header accordionHeader accordionCustomColor "  id={`heading${element.id}`}>
                    <button className="accordion-button careerAccordionButton flex-column align-items-start accordionCustomColor"  type="button" data-bs-toggle="collapse"   
                        data-bs-target={`#collapse${element.id}`}
                        aria-expanded="true"
                        aria-controls={`collapse${element.id}`}>
                       <p className='py-1 accordian_heading'>  
                       {element.title}
                       </p>
                        {/* <p className='jobLocation'>
                            {element.location} {'\u00a0'} - {'\u00a0'} {element.time}
                        </p> */}
                    </button>
                    </h2>
                    <div  className="accordion-collapse collapse" id={`collapse${element.id}`} aria-labelledby={`heading${element.id}`}>
                    <div className="accordion-body accordionCustomColorBody">
                        <row className="accordionBodyTextDiv">
                            {/* <p className='jobLocation'>
                                <b className='p14S1ParaBold'>
                                Department:  {'\u00a0'}
                                </b> 
                                {element.department}
                            </p>
                            <p className='jobLocation'>
                                <b className='p14S1ParaBold'>
                                Location:  {'\u00a0'}
                                </b>
                                {element.location}
                            </p> */}

                            <h5 className='p9s1HeadingBold py-md-1 py-1 '>
                            About the opportunity
                            </h5>

                            {/* <p className='jobLocation'> */}
                            <div className='httpTxtDiv' dangerouslySetInnerHTML={{ __html: element.description }} />
                           {/* {element.descritption} */}
                            {/* </p> */}

                            {/* <h5 className='p9s1HeadingBold py-3 mt-5'>
                            Who we are
                            </h5> */}

                            {/* <p className='jobLocation'> */}
                            <div className='httpTxtDiv' dangerouslySetInnerHTML={{ __html: element.about }} />
{/* 
                            {element.about}
                            </p> */}
                            {/* <div className="transparent-gradient-border-btn mt-md-5 my-5 mb-md-4 mb-1" style={{width:'234px'}}>
            <button className='button-content ' style={{width:'234px'}}> <span className='gradient-text'> Apply for this position </span> 
            </button>   </div> */}
                        </row>
                    </div>
                    </div>
                </div>
            </div>
            </div>
            )) : null }
            </div>
            </div>
        </div>
    </div>









    
    {/* Section Two End  */}



    </>
    ) : <p style={{paddingTop:"7rem",textAlign:"center"}}>No job available at this time. Please check back later.</p>
    }
    </AnimatedPage>
 
 )
}
