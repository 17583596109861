// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuthStatus from './useAuthStatus'; // Import the custom hook

function ProtectedRoute({ children }) {
  const { isAuthenticated,alreadyVerified, isFetching, isError } = useAuthStatus();

  if (isFetching) {
    return <div></div>; // Or some other loading indicator
  }

  if (!isAuthenticated || isError) {
    return <Navigate to="/login" />; // Redirect to login if not authenticated or no access
  }
  // if ( alreadyVerified ) {
  //   return <Navigate to="/account-verification" />; // Redirect to login if not authenticated or no access
  // }
  if ( !alreadyVerified || isError) {
    return <Navigate to="/account-verification" />; // Redirect to login if not authenticated or no access
  }

  
  return children;
}


export default ProtectedRoute;