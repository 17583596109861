import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import UpperNav from './UpperNav';
import { getToken } from '../../services/LocalStorageService';
import { useNavigate } from 'react-router-dom';

function DropdownItem({ text, onSelect }) {
    return (
        <button className="dropdown-item" onClick={() => onSelect(text)}>
            {text}
        </button>
    );
}

DropdownItem.propTypes = {
    text: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
};

function TransactionItem({ transaction, type }) {
    const isFiatToFiat = type === 'fiat_to_fiat';

    return (
        <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
            <div className='d-flex gap-3 '>
                <img
                    loading='lazy'
                    src="../images/Group 680.svg"
                    alt="Section One Right Side Images"
                    className="img-fluid"
                    style={{ rotate: transaction.transaction_type === "Deposit" ? "90deg" : "65deg" }}
                />
                <div className='d-flex flex-column justify-content-center '>
                    <p className='mb-1'>
                        {isFiatToFiat
                            ? `${transaction.from_currency_code} To ${transaction.to_currency_code}`
                            : transaction.direction === "fiat_to_crypto"
                                ? `${transaction.fiat_currency} To ${transaction.crypto_currency}`
                                : transaction.direction === "crypto_to_fiat"
                                    ? `${transaction.crypto_currency} To ${transaction.fiat_currency}`
                                    : ""
                        }
                    </p>
                    <span>{transaction.transaction_date}</span>
                </div>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-end '>
                <p className='mb-1 text-right'>
                    {isFiatToFiat
                        ? `${transaction.from_currency_code} ${transaction.from_amount} ≈ ${transaction.to_currency_code} ${transaction.to_amount}`
                        : transaction.direction === "fiat_to_crypto"
                            ? `${transaction.fiat_amount} ${transaction.fiat_currency} ≈ ${transaction.crypto_amount} ${transaction.crypto_currency}`
                            : transaction.direction === "crypto_to_fiat"
                                ? `${transaction.crypto_amount} ${transaction.crypto_currency} ≈  ${transaction.fiat_amount} ${transaction.fiat_currency}`
                                : ""
                    }
                </p>
                <span>
                    {isFiatToFiat ? `Fee Amount : ${transaction.fee_amount}` : `Exchange Rate : ${transaction.exchange_rate}`}
                </span>
            </div>
        </div>
    );
}

TransactionItem.propTypes = {
    transaction: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
};

function ConvertHistory() {
    const { access_token } = getToken();
    const navigate = useNavigate();
    const [selectedTypeOption, setSelectedTypeOption] = useState({ text: 'All', type: '' });
    const [selectedTokenOption, setSelectedTokenOption] = useState({ text: 'All', type: '' });

    const [transactions, setTransactions] = useState([]);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [dropDown, showDropDown] = useState(false);
    const [dropDown1, showDropDown1] = useState(false);
    const [loading, setLoading] = useState(false);

    const typeMapping = {
        'All': '',
        'Fiat to Crypto': 'fiat_to_crypto',
        'Crypto to Fiat': 'crypto_to_fiat',
        'Fiat to Fiat': 'fiat_to_fiat'
    };

    useEffect(() => {
        fetchTransactions();
    }, [access_token, selectedTypeOption]);

    const fetchTransactions = () => {
        setLoading(true);
        const endpoint = selectedTypeOption.type === 'fiat_to_fiat'
            ? 'https://evistialab.com/api/v1/fiat-history/'
            : 'https://evistialab.com/api/v1/crypto-fiat-history/';

        fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`,
            }
        })
            .then(response => {
                setLoading(false);
                if (!response.ok) {
                    if (response.status === 401) {
                        navigate("/login");
                    }
                }
                return response.json();
            })
            .then(data => {
                const transactionsData = selectedTypeOption.type === 'fiat_to_fiat' ? data.fiat_to_fiat_transactions : data.swaps;
                if (Array.isArray(transactionsData)) {
                    setTransactions(transactionsData);
                    applyFilters(selectedTypeOption, selectedTokenOption, transactionsData);
                } else {
                    console.error('Expected array but got:', data);
                    setTransactions([]);
                    setFilteredTransactions([]);
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error fetching data:', error);
            });
    };

    const handleTypeSelect = (optionText) => {
        const option = { text: optionText, type: typeMapping[optionText] };
        setSelectedTypeOption(option);
        showDropDown(false);
        applyFilters(option, selectedTokenOption, transactions);
    };

    const handleTokenSelect = (optionText) => {
        const option = { text: optionText, type: optionText === 'All' ? '' : optionText };
        setSelectedTokenOption(option);
        showDropDown1(false);
        applyFilters(selectedTypeOption, option, transactions);
    };

    const applyFilters = (typeOption, tokenOption, allTransactions) => {
        let filtered = allTransactions;
        if (typeOption.type) {
            if (typeOption.type === 'fiat_to_fiat') {
                if (tokenOption.type) {
                    filtered = filtered.filter(tx => tx.from_currency_code === tokenOption.type || tx.to_currency_code === tokenOption.type);
                }
            } else {
                filtered = filtered.filter(tx => tx.direction === typeOption.type);
                if (tokenOption.type) {
                    filtered = filtered.filter(tx => tx.fiat_currency === tokenOption.type || tx.crypto_currency === tokenOption.type);
                }
            }
        } else if (tokenOption.type) {
            filtered = filtered.filter(tx => tx.fiat_currency === tokenOption.type || tx.crypto_currency === tokenOption.type || tx.from_currency_code === tokenOption.type || tx.to_currency_code === tokenOption.type);
        }
        setFilteredTransactions(filtered);
    };

    return (
        <div className='sideBarMainContent '>
            <div className='WMainDiv '>
                <UpperNav title={`Convert History`} symbol={" "} />

                <div className='row mt-xxl-4 mt-1 justify-content-center'>
                    <div className='col-xxl-11 col-lg-12 col-12'>
                        <div className='fiatTransferCard mt-md-5 mt-2 '>
                            <div className='cardUpperRow mb-3 justify-content-end gap-4'>
                                <div className='col-xxl-5 col-xl-5 col-md-4 col-6 d-flex justify-content-start mb-2'>
                                    <h4 className='ps-5 ms-3'>
                                        Convert History
                                    </h4>
                                </div>
                                <div className='col-xxl-3 col-xl-1 col-md-12 col-12  d-flex justify-content-start mb-2'></div>
                                <div className='col-xxl-2 col-xl-3 col-md-4 col-6 col-6 d-flex justify-content-start mb-2' style={{ cursor: "pointer" }}>
                                    <div className='blogddDiv1 d-flex gap-2 align-items-center justify-content-between' onClick={() => showDropDown1(!dropDown1)}>
                                        <h3 className='text-white mb-0 ps-3'>
                                            Currency :
                                        </h3>
                                        <div className='d-flex gap-2'>
                                            <h4 className='mb-0'>{selectedTokenOption?.text || 'All'}</h4>
                                        </div>
                                        <img loading='lazy' src="../images/Vector 26.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow" />
                                        <div className={`dropdown-menu blogwapDropDown ${dropDown1 ? 'show' : ''}`} onMouseLeave={() => showDropDown1(false)}>
                                            <DropdownItem text="All" onSelect={handleTokenSelect} />
                                            <DropdownItem text="GBP" onSelect={handleTokenSelect} />
                                            <DropdownItem text="EUR" onSelect={handleTokenSelect} />
                                            <DropdownItem text="AED" onSelect={handleTokenSelect} />
                                            <DropdownItem text="KRW" onSelect={handleTokenSelect} />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xxl-2 col-xl-3 col-md-4 col-6 col-6 d-flex justify-content-start mb-2' style={{ cursor: "pointer" }}>
                                    <div className='blogddDiv1 d-flex gap-2 align-items-center justify-content-between' onClick={() => showDropDown(!dropDown)}>
                                        <h3 className='text-white mb-0 ps-3'>
                                            Type :
                                        </h3>
                                        <div className='d-flex gap-2'>
                                            <h4 className='mb-0'>{selectedTypeOption?.text || 'All'}</h4>
                                        </div>
                                        <img loading='lazy' src="../images/Vector 26.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow" />
                                        <div className={`dropdown-menu blogwapDropDown ${dropDown ? 'show' : ''}`} onMouseLeave={() => showDropDown(false)}>
                                            <DropdownItem text="All" onSelect={handleTypeSelect} />
                                            <DropdownItem text="Fiat to Crypto" onSelect={handleTypeSelect} />
                                            <DropdownItem text="Crypto to Fiat" onSelect={handleTypeSelect} />
                                            <DropdownItem text="Fiat to Fiat" onSelect={handleTypeSelect} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {loading ? (
                                <p className='text-center'>Loading transactions...</p>
                            ) : filteredTransactions.length > 0 ? (
                                filteredTransactions.map((transaction, index) => (
                                    <TransactionItem key={index} transaction={transaction} type={selectedTypeOption.type} />
                                ))
                            ) : (
                                <p>No transactions available</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConvertHistory;
