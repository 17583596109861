import React from "react";
import AnimatedPage from "../AnimatedPage";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// Images
// import S4BannerBg from '../../static/images/s4BannerBg.svg'
// import S1RightSideImg from '../../static/images/S1RightSide.svg'
// import S2RightSideImg from '../../static/images/s2RightSide.svg'
// import S3LeftSideImg from '../../static/images/s3LeftSide.svg'
// import S6RightSideImg from '../../static/images/s5RightSide.svg'
// import s7RightSideimg from '../../static/images/s7RightSideimg.svg'
// import s7C1 from '../../static/images/s7p2c1.svg'
// import s7C2 from '../../static/images/s7p2c2.svg'
// import s7C3 from '../../static/images/s7p2c3.svg'
// import s7C4 from '../../static/images/s7p2c4.svg'
// import rightSideS2Hr from '../../static/images/Vector 6.svg'
// import S2EndHr from '../../static/images/Vector 7.svg'

// CSS
import "../../static/css/index.css";
import "../../static/css/common.css";
import "../../static/css/navbar.css";

export default function PersonalAccount() {
  const { t, i18n } = useTranslation();
  return (
    <AnimatedPage>
      <div>
        {/* Section One Start  */}
        <div className="homepageContainerP2 S1PATopPadding">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-8 col-12"
                
                
              >
                <h3 className="S1SubHeading S1Heading">
                  {t("PA.S1.h.1")}
                  <br></br> <b className="S1Heading">{t("PA.S1.hb.1")}</b>
                </h3>
                <p className="S1Para  textCenterMb PMB1rem mt-lg-0 mt-md-0 mt-5 mb-lg-0 mb-md-0 mb-3">
                  {t("PA.S1.p.1")}
                </p>
                <Link to="/sign-up/">
                  <div className=" divBtnMedium btnHide">
                    <button className=" btnMedium">
                      {" "}
                      <span className="btnMediumText">{t("btnING.4")} </span>
                    </button>{" "}
                  </div>
                </Link>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12 d-flex justify-content-center">
                <img
                  loading="lazy"
                  src="../images/personal1.png"
                  alt="Section One Right Side Images"
                  className="rightSideS1Img imgResponsive"
                  
                  
                />
              </div>
              <Link to="/sign-up/">
                <div className=" divBtnMedium btnShow">
                  <button className=" btnMedium">
                    {" "}
                    <span className="btnMediumText mt-sm-0 mt-3">
                      {t("btnING.4")}{" "}
                    </span>
                  </button>{" "}
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* Section One End  */}

        {/* Section Two Start  */}
        <div className="homepageContainerP2">
          <div className="container-fluid">
            <div className="row justify-content-between reverseColumn">
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-5 col-12 homepageS1MainHeadingDiv "
                
                
              >
                <div className="row">
                  <img
                    loading="lazy"
                    src="../images/Vector 6.svg"
                    alt="Section Two Right Side HR"
                    className="rightSideHrS2 imgResponsive pr_rem10 hrSmHide rRemoveP1370"
                  />
                </div>

                <h3 className="pt-sm-5 pt-5 mt-sm-4 mt-0">
                  <b className="s2HeadingBold pRemovMb"> {t("PA.S2.hb.1")} </b>{" "}
                  {t("PA.S2.h.1")}
                </h3>
                <p className="S1Para ">{t("PA.S2.p.1")}</p>
              </div>
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-7 col-12 d-flex justify-content-center"
                
                
              >
                <img
                  loading="lazy"
                  src="../images/personal2.png"
                  alt="Section Twp Left Side Images"
                  className="rightSideS2ImgP2 imgResponsivesWidth mt-sm-0 mt-4"
                />
              </div>
              {/* <div className='row'> */}
              <img
                loading="lazy"
                src="../images/hrSmShow.svg"
                alt="Section Two Right Side HR"
                className="rightSideHrS2 imgResponsive hrSmShow "
              />
              {/* </div> */}
            </div>
          </div>
        </div>
        {/* Section Two End  */}

        {/* Section Three Start  */}
        <div className="homepageContainerP2 PAS3Bg">
          <div className="container-fluid s3InsidePadding">
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                <div
                  className="row "
                  
                  
                >
                  <div className="firstDiv">
                    <h3 className=" S3Heading ps-sm-0 ps-0">
                      {t("PA.S3.hb.1")}
                    </h3>
                    <p className="S3Para ">{t("PA.S3.p.1")}</p>
                  </div>
                  <div className="firstDiv">
                    <h3 className="S3Heading ps-sm-0 ps-0">
                      {t("PA.S3.hb.2")}
                    </h3>
                    <p className="S3Para ">{t("PA.S3.p.2")}</p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 PAS3Padding  ">
                <div
                  className="row"
                  
                  
                >
                  <div className="firstDiv">
                    <h3 className=" S3Heading ">{t("PA.S3.hb.3")}</h3>
                    <p className="S3Para prMb">{t("PA.S3.p.3")}</p>
                  </div>

                  <div className="firstDiv">
                    <h3 className="S3Heading ps-0 mt-sm-4 mt-0 pt-sm-3 pt-0">{t("PA.S3.hb.4")}</h3>
                    <p className="S3Para prMb">{t("PA.S3.p.4")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section Three End  */}




      {/* Section Four Start  */}
      <div className='homepageContainerP2 S4StartP' >
            <div className='container-fluid'>
                <img loading='lazy'  src="../images/hrSmShow.svg" alt="Section Two Right Side HR" className="S4LeftSideImg rightSideHrS2 imgResponsive hrSmShow pb-sm-0 pb-5 " />
                <div className='row justify-content-between '  >
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 '>
                  <img loading='lazy'  src="../images/personal3.png" alt="Section Twp Left Side Images" className="S4LeftSideImg imgResponsivesWidth"/>

                </div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv'  >
                  <div className='row'>
                  <img loading='lazy'  src="../images/Vector 6.svg" alt="Section Two Right Side HR" className="rightSideHrS2 imgResponsive hrSmHide pr_rem5" />
                  </div>
                    <h3 className='pt-4 mt-lg-4'>
                    {t('PA.S4.h.1')}<br></br>
                    {t('PA.S4.h.2')}
                       <b className='s2HeadingBold '> {t('PA.S4.hb.1')} </b> 
                    </h3>

                    <p className='S4Para '>
                    {t('PA.S4.p.1')}
                    </p>
                  </div>
                </div>
              </div>
      </div>
        {/* Section Four End  */}



      {/* Section Five Start  */}
      <div className='homepageContainerP2' >
           <div className='container-fluid'>
           <div className='row'>
           <img loading='lazy'  src="../images/Vector 7.svg" alt="Section Two END Hr" className="imgResponsive hrSmHide"/>
           </div>
              <div className='reverseColumnS6'>
                <div className='row pt-5'>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv'  >
                    <h3 className='py-3 pt-sm-3 pt-0'>
                   {t('PA.S5.h.1')}  <br></br>  {t('PA.S5.h.2')}

                    </h3>
                    <Link to="/digital-wallet/">
                    <div className="divBtnMedium mt-4 btnHide" >
                    <button className='btnMedium ' > <span className='btnMediumText ' > {t('btnING.6')} {"\u00a0"} > </span> 
                    </button>  
                    </div>
                  </Link>
                </div>
                <div
                  className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv"
                  
                  
                >
                  <p className="homePageS3SubHeading pt-sm-3 pt-0">
                    {t("PA.S5.p.1")}
                  </p>
                </div>
              </div>
              <div className="row justify-content-center px-0">
                <img
                  loading="lazy"
                  src="../images/hrSmShow.svg"
                  alt="Section Two END Hr"
                  className="imgResponsive hrSmShow mt-sm-0 my-4 px-0"
                />

                <div
                  className="col-12 p-0 PAS5Bg mt-sm-4 mt-0"
                  
                  
                >
                  <div className="row HPs5PopUpDiv ps-5 mt-sm-0 ">
                    <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1 col-0 "></div>
                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-3 col-7 " >
                      <img
                        loading="lazy"
                        src="../images/Newoverlay2.png"
                        alt="Section One Right Side Images"
                        className="rightSideS1Img imgResponsive"
                        
                       
                      />
                    </div>
                    {/* <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-5 col-8 '>
                    <div className="S5PopUpBtnDiv">
                      <button className='s5PopUpBtn'> <span className='s5PopUpBtntxt'>{t('popupSS.1')} </span> 
                      </button> 
                    </div>
                    </div> */}
                  </div>
                </div>
              </div>

            </div>
            <Link to="/digital-wallet/">
              <div className="divBtnMedium mt-5 btnShow" >
                    <button className='btnMedium ' > <span className='btnMediumText ' > {t('btnING.6')} {"\u00a0"} > </span> 
                    </button>  
                    </div>
                    </Link>
            </div>


      </div>
      {/* Section Five End  */}

        {/* Section Six Start  */}
        <div className="homepageContainerP2 PAS6Bg">
          <div className="container-fluid">
            <div className="row mt-5 reverseColumn ">
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv "
                
                
              >
                <div className="row mt-lg-0">
                  <img
                    loading="lazy"
                    src="../images/Vector 6.svg"
                    alt="Section Two Right Side HR"
                    className="rightSideHrS2 hrSmHide  imgResponsive"
                  />
                </div>

                <h3 className="s6P2heading paraWidth" >
                  {t("PA.S6.h.1")}{" "}
                  <b className="s2HeadingBold"> {t("PA.S6.hb.1")} </b>
                </h3>
                <p
                  className="homePageS2SubHeading pt-lg-5 pt-md-5 pt-4 pb-lg-0 pb-md-0 pb-4  "
                  style={{ paddingRight: "1rem" }}
                >
                  {t("PA.S6.p.1")}
                </p>
              </div>
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 d-flex justify-content-sm-end justify-content-center PSS6RImg"
                
                
              >
                <img
                  loading="lazy"
                  src="../images/personalaccountphonechange.png"
                  alt="Section Twp Left Side Images  "
                  className="rightSideS1Img imgResponsive imgHide"
                />
                <img
                  loading="lazy"
                  src="../images/personalaccountphonechange.png"
                  alt="Section Twp Left Side Images "
                  className="rightSideS1Img imgResponsive imgShow"
                />
              </div>
              <img
                loading="lazy"
                src="../images/Vector 6.svg"
                alt="Section Two Right Side HR"
                className="rightSideHrS2 hrSmShow mb-sm-0 mb-5 imgResponsive"
              />
            </div>
          </div>
        </div>
        {/* Section Six End  */}

        {/* Section Seven Start  */}
        <div className="homepageContainer PAS7Bg">
          <div className="container-fluid s4Banner">
            <div className="row pt-5 imgHide">
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12   s7P2LeftsideColumnImg"
                
                
              >
                <img
                  loading="lazy"
                  src="../images/s7p2c1.svg"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img imgResponsive "
                />
              </div>
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12   s7P2RightsideColumnImg"
                
                
              >
                <img
                  loading="lazy"
                  src="../images/s7p2c2.svg"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img imgResponsive "
                />
              </div>
            </div>
            <div className="row mt-sm-0 mt-lg-5 mt-md-5 mt-3">
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 columnBorder  s7P2LeftsideColumn"
                
                
              >
                <img
                  loading="lazy"
                  src="../images/s7p2c1.svg"
                  alt="Section Twp Left Side Images imgShow"
                  className="rightSideS1Img imgResponsive "
                />
                <h3 className="S3BottomHeading pt-sm-0 pt-4">
                  {t("PA.S7.hb.1")}
                </h3>
                <p className="s4BottomSubHeading pt-3 ">{t("PA.S7.p.1")}</p>
                {/* <img loading='lazy'  src={../S7BorderSideImg} alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive"/> */}
              </div>

              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  s7P2RightsideColumn"
                
                
              >
                <img
                  loading="lazy"
                  src="../images/s7p2c2.svg"
                  alt="Section Twp Left Side Images hrSmShow imgShow"
                  className="rightSideS1Img imgResponsive "
                />
                <h3 className="S3BottomHeading pt-sm-0 pt-4">
                  {t("PA.S7.hb.2")}
                </h3>
                <p className="s4BottomSubHeading pt-3 ">{t("PA.S7.p.2")}</p>
                {/* <img loading='lazy'  src={../S7BorderSideImg} alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive"/> */}
              </div>
            </div>
            <div className="row imgHide">
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12   s7P2LeftsideColumnImg"
                
                
              >
                <img
                  loading="lazy"
                  src="../images/s7p2c3.svg"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img imgResponsive "
                />
              </div>
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12   s7P2RightsideColumnImg"
                
               
              >
                <img
                  loading="lazy"
                  src="../images/s7p2c4.svg"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img imgResponsive"
                />
              </div>
            </div>
            <div className="row">
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  columnBorder s7P2LeftsideColumn"
                
                
              >
                <img
                  loading="lazy"
                  src="../images/s7p2c3.svg"
                  alt="Section Twp Left Side Images imgShow"
                  className="rightSideS1Img imgResponsive "
                />

                <h3 className="S3BottomHeading pt-sm-0 pt-4">
                  {t("PA.S7.hb.3")}
                </h3>
                <p className="s4BottomSubHeading pt-3 ">{t("PA.S7.p.3")}</p>
                {/* <img loading='lazy'  src={../S7BorderSideImg} alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive"/> */}
              </div>
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  s7P2RightsideColumn"
                
               
              >
                <img
                  loading="lazy"
                  src="../images/s7p2c4.svg"
                  alt="Section Twp Left Side Images imgShow"
                  className="rightSideS1Img imgResponsive "
                />

                <h3 className="S3BottomHeading pt-sm-0 pt-4">
                  {t("PA.S7.hb.4")}
                </h3>
                <p className="s4BottomSubHeading pt-3 ">{t("PA.S7.p.4")}</p>
                {/* <img loading='lazy'  src={../S7BorderSideImg} alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive"/> */}
              </div>
            </div>

            <div className="row pt-sm-5 pt-0 mt-sm-5 mt-0 reverseColumn">
              <div
                className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 homepageS3MainHeadingDiv pt-sm-0 pt-4"
                
                
              >
                <h3 className=" py-lg-3 py-md-3 py-0 pt-lg-0 pt-md-0 pt-5 pb-lg-0 pb-md-0 pb-3 ">
                  {t("PA.S7.h.1")} <br></br> {t("PA.S7.h.2")}
                </h3>
                <p className="homePageS4SubHeading ">
                  {t("PA.S7.p.5")} <br></br> {t("PA.S7.p.6")} <br></br>{" "}
                  {t("PA.S7.p.7")}
                </p>
                <Link to="/cards/">
                  <div className="S4BtnDiv divBtnLarge">
                    <button className="s4Btn btnLarge">
                      {" "}
                      <span className="gradient-text btnLargeText">
                        {" "}
                        {t("btnING.7")}{" "}
                      </span>
                    </button>
                  </div>
                </Link>
              </div>

              <div
                className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 d-flex justify-content-end pe-4"
                
                
              >
                <img
                  loading="lazy"
                  src="../images/personal6.png"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img imgResponsive"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Section Seven End  */}
      </div>
    </AnimatedPage>
  );
}
