import React, { useState } from 'react';
import StepOne from './RegisrterPersonalAccStepOne'; // Import your step components
import StepTwo from './RegisrterPersonalAccStepTwo';
import StepThree from './RegisrterPersonalAccStepThree';
import { useRegisterUserMutation } from '../../services/userAuthApi';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const SavingsAccRegisterForm = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()


  // const [serverError , setServerError ] = useState()
  const [registerUser,{isLoading}] = useRegisterUserMutation()
  //consolelog(registerUser, isLoading, 'allApiData')


  // password Checker Function 
  function isStrongPassword(password) {
    // password criteria
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasDigit = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*()_+{}:;<>,.?~]/.test(password);
  
    // Check if all criteria are met
    return (
      password.length >= minLength &&
      hasUppercase &&
      hasLowercase &&
      hasDigit &&
      hasSpecialChar
    );
  }


  const [error, showErorr] = useState('')
  const [errorId, setErrorId] = useState('')
  const [emptyFiledError, setEmptyFieldError] = useState('')
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    accountType : 'Savings' ,
    firstName: '',
    lastName: '',
    dob: '',
    maritalStatus: '',
    phoneNumber: '',
    email: '',
    password : '',
    confirmPassword : '' ,
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    citizenship: '',
    agreeToTerms: false,
  });



  const handleNextStep = async () => {
    const password = isStrongPassword(formData.password)
    const checkConfirmpassword = formData.password === formData.confirmPassword
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(formData.email);

    // Validate current step data before proceeding
    if (!validateStepData(formData, currentStep)) {
      const fieldError = t('PACC.E.1')
      setEmptyFieldError(fieldError)
      // alert('Please fill in all fields before proceeding.'); // You can display an error message here
      return;
    }else if(currentStep === 2){
      if (emailPattern === false) {
       showErorr(t('PACC.E.2'))
       return;
      }
      if ( password === false ){
        showErorr(t('PACC.E.3'))
        return;
      }
      if ( checkConfirmpassword === false) {
       showErorr(t('PACC.E.4'))
       return;
      }
      setCurrentStep(currentStep + 1)
    }
    else if (validateStepData(formData, currentStep) && currentStep === 3){
      //consolelog('Your Form Has Been Submitted SuccessFully The Data is',formData)
      formData.accountType = 'Savings'
      const res = await registerUser(formData)
      //consolelog(res,'my Api response is here')
      if (res.error){
        // API call returned an error
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred: ' + res.error.data.errors.email, // Change to your error property
        }).then(() => {
          navigate('/sign-up' ); // Redirect to another page
        })
        
    }
      if (res.data){
        //consolelog('my Data is here ',)
         // API call was successful
         Swal.fire({
          icon: 'success',
          title: 'Success',
          text: res.data.msg, // Change to your data property
      }).then(() => {
        navigate('/login'); // Redirect to another page
      })
      }
    }

    setCurrentStep(currentStep + 1);
  };

  // const handlePreviousStep = () => {
  //   setCurrentStep(currentStep - 1);
  // };

  const validateStepData = (data, step) => {
    
    //consolelog('we are in validate steps and our data',data, 'and step is ',step)
    // Implement validation logic based on the step and data
    // Return true if data is valid, otherwise false
    // For example, you can check if required fields are not empty
    if (step === 1) {
      return data.firstName && data.lastName && data.dob && data.maritalStatus;
    } else if (step === 2) {
      return data.phoneNumber && data.email;
    } else if (step === 3) {
      return (
        data.streetAddress &&
        data.city &&
        data.state &&
        data.zipCode &&
        data.country &&
        data.citizenship &&
        data.agreeToTerms
      );
    }
    return false;
  };

  const handleInputChange = (field, el) => {
    setEmptyFieldError('')
    //consolelog('Function is Called and ',el.id)
    if (el.id === 'firstName' || el.id === 'lastName' || el.id === 'city' || el.id === 'myState' || el.id === 'country' ){
      const noChecker = /^[0-9]+$/.test(el.value)
      if (noChecker){
        //consolelog('You Are Passing a No. but name is can not allowed in no. only ', el.style)
        el.style.setProperty('border-color', 'brown', 'important')
        showErorr('')
        setErrorId(el.id)
      }else{
        showErorr('')
        el.style.setProperty('border-color', '#00051A', 'important')
        setFormData((prevData) => ({
          ...prevData,
          [field]: el.value,
        }));
      }
    }


    if (el.id === 'phNo' || el.id === 'zipCode'  ){
      const noChecker = /^[0-9]+$/.test(el.value)
      if (noChecker === false){
        //consolelog(noChecker,'noChecker')
        el.style.setProperty('border-color', 'brown', 'important')
        showErorr(t('PACC.E.5'))
        setErrorId(el.id)
      }else{
        showErorr('')
        el.style.setProperty('border-color', '#00051A', 'important')
        setFormData((prevData) => ({
          ...prevData,
          [field]: el.value,
        }));
      }
    }

    // Email Validation 
    if (el.id === 'email' ){
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(el.value);
      //consolelog(emailPattern,'emailPattern')
      if (emailPattern === false){
        el.style.setProperty('border-color',  'brown', 'important')
        showErorr(t('PACC.E.6'))
        setErrorId(el.id)
        setFormData((prevData) => ({
          ...prevData,
          [field]: el.value,
        }));
      }else{
        showErorr('')
        el.style.setProperty('border-color', '#00051A', 'important')
        setFormData((prevData) => ({
          ...prevData,
          [field]: el.value,
        }));
      }
    }


    if (el.id === 'password' ){
      const Strong = isStrongPassword(el.value)
      if (Strong === false){
          el.style.setProperty('border-color',  'brown', 'important')

          showErorr(t('PACC.E.3'))
        setErrorId(el.id)
          setFormData((prevData) => ({
            ...prevData,
            [field]: el.value,
        }));
        }
        else{
          showErorr('')
        el.style.setProperty('border-color', '#00051A', 'important')
        setFormData((prevData) => ({
          ...prevData,
          [field]: el.value,
        }));
      }
    }


    if (el.id === 'confirmPassword' ){
      if (!(el.value === formData.password)){
          el.style.setProperty('border-color',  'brown', 'important')
          showErorr(t('PACC.E.4'))
          setErrorId(el.id)
          setFormData((prevData) => ({
            ...prevData,
            [field]: el.value,
        }));
        }
        else{
          showErorr('')
        el.style.setProperty('border-color', '#00051A', 'important')
        setFormData((prevData) => ({
          ...prevData,
          [field]: el.value,
        }));
      }
    }



    if (el.id === 'dob' || el.id === 'address'){
      setFormData((prevData) => ({
        ...prevData,
        [field]: el.value,
      }));
    }

    if (el.id === 'termsConditon'){
      //consolelog('values', el.value)
    const radioVal = el.value
      setFormData((prevData) => ({
        ...prevData,
        [field]: radioVal,
      }));
  };
  }


  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        //consolelog('we are in step one')
        return (
          <StepOne
            formData={formData}
            handleInputChange={handleInputChange}
            error={error}
            errorId={errorId}
          />
        );
      case 2:
        //consolelog('we are in step Two')
        return (
          <StepTwo
            formData={formData}
            handleInputChangeInStepTwo={handleInputChange}
            error={error}
            errorId={errorId}
          />
        );
      case 3:
        //consolelog('we are in step Three')
        return (
          <StepThree
            formData={formData}
            handleInputChangeInThreeStep={handleInputChange}
            error={error}
            errorId={errorId}
          />
        );
      default:
        return null;
    }
  };


  return (
      <div className='container-fluid'>
      <div className='mainContainerSignups'>
      {/* <h1> This Is Multi Form Page </h1> */}
      {renderCurrentStep()}
      <div className="button-container">
      <div className='row justify-content-between pt-0'>
        {currentStep > 1 && (
          <></>
        //   <div className="S5BtnDiv  ps-4 ms-1 mb-5 pb-5">
        //    <button className='s5Btn' onClick={handlePreviousStep}> <span className='s5Btntxt'> Previus </span> 
        //    </button> 
        //  </div>
        )}
        <p className='formError pt-3' >
          {emptyFiledError}
        </p>
        {currentStep < 3 && ( 
           <div className="S5BtnDiv  ps-4 ms-1 mb-5 pb-5">
           <button className='s5Btn' onClick={handleNextStep}> <span className='s5Btntxt'> Next </span> 
           </button> 
         </div>
        )}
        {currentStep === 3 && ( 
           <div className="  ps-4 ms-1 mb-5 pb-5">
           <button className='s5Btn' onClick={handleNextStep}> <span className='s5Btntxt'> Submit </span> 
           </button> 
         </div>
        )}
        </div>
      </div>
    </div>
    </div>
  );
};

export default SavingsAccRegisterForm;
