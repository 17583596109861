import React from 'react'

function FInalBillDetails(props) {
  return (
    <div className='row justify-content-center'>
        <div className='col-11'>
            <div className='recievedDetailDiv d-flex justify-content-between '>
                <h6>
                Company:
                </h6>
                <p>
                {props?.name}
                </p>
            </div>
            <div className='recievedDetailDiv d-flex justify-content-between '>
                <h6>
                Consumer No:
                </h6>
                <p>
                {props?.consumerNumber}
                </p>
            </div>
            <div className='recievedDetailDiv d-flex justify-content-between '>
                <h6>
                Consumer Name:
                </h6>
                <p>
                Thomson
                </p>
            </div>
            <div className='recievedDetailDiv d-flex justify-content-between '>
                <h6>
                Billing Month:
                </h6>
                <p>
                Nov-2023
                </p>
            </div>
            <div className='recievedDetailDiv d-flex justify-content-between '>
                <h6>
                Due Date:
                </h6>
                <p>
                27-Nov-2023
                </p>
            </div>
            <div className='recievedDetailDiv d-flex justify-content-between '>
                <h6>
                Payable By Due Date:
                </h6>
                <p>
                2819
                </p>
            </div>
            <div className='recievedDetailDiv d-flex justify-content-between '>
                <h6>
                Payable After Due Date:
                </h6>
                <p>
                3044
                </p>
            </div>
            <div className='recievedDetailDiv d-flex justify-content-between '>
                <h6>
                Bill Status:
                </h6>
                <p>
                This bill is already paid
                </p>
            </div>
            <div className='billTotalDetails my-3'>
                <h6>
                Total Payment
                </h6>
                <p>
               {props.amount}
                </p>
            </div>
        </div>
    </div>
  )
}

export default FInalBillDetails