import React from 'react'

function Security() {
  return (
    <div className='homepageContainerP2 py-3 mt-5' >
        <div className='container-fluid mt-5'>
            <div className='row justify-content-center px-md-5 px-2'>
                <h3 className='S1SubHeading text-center '>
                Evistia - fast and secure way <br className='d-md-block d-none'></br> to make transitions
                </h3>

                <div className="col-md-5 col-11 my-5">
                    <h3 className=" S3Heading ps-sm-0 ps-0 text-center">
                    Custodial solution
                    </h3>
                    <p className="S3Para text-center pe-0">Evistia Blockchain wallets that lets you be your own bank. 
                    This implies that our users have full control over their funds.</p>
                  </div>


            </div>
            <div className='row'>
                <div className='col-md-4 col-12 d-flex flex-column gap-md-5 gap-0'>
                    <div className='securityCard mb-5'>
                        <div className='d-flex gap-3'>
                          <img loading='lazy'  src="../images/Symbol.svg" alt="Section Twp Left Side Images" className="ps-2"/>
                          <h1 className='mb-0'>
                            KYT
                          </h1>
                        </div>
                        <p className='ps-5 mb-0 mt-1'>
                        Know Your Transaction in B2B2C
                        sectors
                        </p>
                    </div>

                    <div className='securityCard '>
                        <div className='d-flex gap-3'>
                          <img loading='lazy'  src="../images/Symbol.svg" alt="Section Twp Left Side Images" className="ps-2"/>
                          <h1 className='mb-0'>
                          Know Your Crypto
                          </h1>
                        </div>
                        <p className='ps-5 mb-0 mt-1 pe-2'>
                        The framework determines a
                        regulated environment for the
                        safe buying and selling of crypto
                        assets
                        </p>
                    </div>
                </div>

                <div className='col-md-4 col-12 d-flex justify-content-center px-0'>
                <img loading='lazy'  src="../images/div.elementor-widget-wrap.svg" alt="Section Twp Left Side Images" className="ps-2 securityImg" />
                </div>


                <div className='col-md-4 col-12 d-flex flex-column gap-md-3 gap-0'>
                    <div className='securityCard mb-md-5 mb-0'>
                        <div className='d-flex gap-3'>
                          <img loading='lazy'  src="../images/Symbol.svg" alt="Section Twp Left Side Images" className="ps-2"/>
                          <h1 className='mb-0'>
                          KYC
                          </h1>
                        </div>
                        <p className='ps-5 mb-0 mt-1'>
                        Know Your Customer standards
                        </p>
                    </div>

                    <div className='securityCard mt-5'>
                        <div className='d-flex gap-3'>
                          <img loading='lazy'  src="../images/Symbol.svg" alt="Section Twp Left Side Images" className="ps-2"/>
                          <h1 className='mb-0'>
                          AML
                          </h1>
                        </div>
                        <p className='ps-5 mb-0 mt-1'>
                        Anti money laundering politics
                        </p>
                    </div>
                </div>
            </div>


        </div>
    </div>
  )
}

export default Security