export const CurrencyDropdownItems = [
        { name: "United Kingdom", currency: "GBP", imgSrc: '/images/g.svg' },
        { name: "European Union", currency: "EUR", imgSrc: '/images/eur.svg' },
        { name: "Dubai", currency: "AED", imgSrc: '/images/aed.svg' },
        { name: "Japan", currency: "JPY", imgSrc: '/images/yen.svg' },
        { name: "Thailand", currency: "THB", imgSrc: '/images/bht.svg' },
        // { name: "Pakistan", currency: "PKR", imgSrc: '/images/pkr.svg' },
        { name: "Moldova", currency: "MDL", imgSrc: '/images/flag-for-moldova-svgrepo-com.svg' },
        { name: "Russia", currency: "RUB", imgSrc: '/images/rub.svg' },
        { name: "Switzerland", currency: "CHF", imgSrc: '/images/Switzerland.svg' },
        { name: "Israel", currency: "ILS", imgSrc: '/images/Israel.svg' },
        { name: "Hungary", currency: "HUF", imgSrc: '/images/Hungary.svg' },
        { name: "Sweden", currency: "SEK", imgSrc: '/images/Sweden.svg' },
        { name: "Czech Republic", currency: "CZK", imgSrc: '/images/Czech republic.svg' },
        { name: "Sri Lanka", currency: "LKR", imgSrc: '/images/Sri Lanka.svg' },
        { name: "Malaysia", currency: "MYR", imgSrc: '/images/Malaysia.svg' },
        { name: "Saudi Arabia", currency: "SAR", imgSrc: '/images/Saudi Arabia.svg' },
        { name: "Chile", currency: "CLP", imgSrc: '/images/Chile.svg' },
        { name: "Poland", currency: "PLN", imgSrc: '/images/Poland.svg' },
        { name: "Ukraine", currency: "UAH", imgSrc: '/images/Ukraine.svg' },
        { name: "Argentina", currency: "ARS", imgSrc: '/images/Argentina.svg' },
        { name: "South Korea", currency: "KRW", imgSrc: '/images/South Korea.svg' },
        { name: "Myanmar", currency: "MMK", imgSrc: '/images/Myanmar.svg' },
        { name: "South Africa", currency: "ZAR", imgSrc: '/images/South Africa.svg' },
        { name: "Turkey", currency: "TRY", imgSrc: '/images/Turkey.svg' },
        { name: "Vietnam", currency: "VND", imgSrc: '/images/Vietnam.svg' },
        { name: "Philippines", currency: "PHP", imgSrc: '/images/Philippines.svg' },
        { name: "Mexico", currency: "MXN", imgSrc: '/images/Mexico.svg' },
        { name: "Bangladesh", currency: "BDT", imgSrc: '/images/Bangladesh.svg' },
        { name: "Nigeria", currency: "NGN", imgSrc: '/images/Nigeria.svg' },
        { name: "Brazil", currency: "BRL", imgSrc: '/images/Brazil.svg' },
        { name: "Indonesia", currency: "IDR", imgSrc: '/images/Indonesia.svg' },
        { name: "India", currency: "INR", imgSrc: '/images/India.svg' },
        { name: "China", currency: "CNY", imgSrc: '/images/China.svg' }
    ]


    // { name: "Kazakhstan", currency: "KZT", imgSrc: '/images/Kazakhstan.svg' },
        // { name: "Malawi", currency: "MWK", imgSrc: '/images/Malawi.svg' },
    // { name: "Dominican Republic", currency: "DOP", imgSrc: '/images/Dominican Republic.svg' },
// { name: "Azerbaijan", currency: "AZN", imgSrc: '/images/Azerbaijan.svg' },
// { name: "Papua New Guinea", currency: "PGK", imgSrc: '/images/Papua New Guinea.svg' },
    // { name: "Honduras", currency: "HNL", imgSrc: '/images/Honduras.svg' },
        // { name: "Tajikistan", currency: "TJS", imgSrc: '/images/Tajikistan.svg' },
        // { name: "United Arab Emirates", currency: "AED", imgSrc: '/images/United Arab Emirates.svg' },
        // { name: "Belarus", currency: "BYN", imgSrc: '/images/Belarus.svg' },
 // { name: "Bulgaria", currency: "BGN", imgSrc: '/images/Bulgaria.svg' },
        // { name: "Paraguay", currency: "PYG", imgSrc: '/images/Paraguay.svg' },
        // { name: "Laos", currency: "LAK", imgSrc: '/images/Laos.svg' },
        // { name: "Togo", currency: "XOF", imgSrc: '/images/Togo.svg' },
        // { name: "Sierra Leone", currency: "SLL", imgSrc: '/images/Sierra Leone.svg' },
// { name: "Burundi", currency: "BIF", imgSrc: '/images/Burundi.svg' },
        // { name: "Cuba", currency: "CUP", imgSrc: '/images/Cuba.svg' },
        // { name: "Bolivia", currency: "BOB", imgSrc: '/images/Bolivia.svg' },
        // { name: "Tunisia", currency: "TND", imgSrc: '/images/Tunisia.svg' },
        // { name: "Benin", currency: "XOF", imgSrc: '/images/Benin.svg' },
        // { name: "Rwanda", currency: "RWF", imgSrc: '/images/Rwanda.svg' },
        // { name: "South Sudan", currency: "SSP", imgSrc: '/images/South Sudan.svg' },
        // { name: "Guinea", currency: "GNF", imgSrc: '/images/Guinea.svg' },
        // { name: "Zimbabwe", currency: "ZWL", imgSrc: '/images/Zimbabwe.svg' },
        // { name: "Somalia", currency: "SOS", imgSrc: '/images/Somalia.svg' },
        // { name: "Chad", currency: "XAF", imgSrc: '/images/Chad.svg' },
        // { name: "Cambodia", currency: "KHR", imgSrc: '/images/Cambodia.svg' },
        // { name: "Senegal", currency: "XOF", imgSrc: '/images/Senegal.svg' },
        // { name: "Syria", currency: "SYP", imgSrc: '/images/Syria.svg' },
        // { name: "Romania", currency: "RON", imgSrc: '/images/Romania.svg' },
        
// { name: "Niger", currency: "XOF", imgSrc: '/images/Niger.svg' },
        // { name: "Ivory Coast", currency: "XOF", imgSrc: '/images/Ivory Coast.svg' },
        // { name: "Cameroon", currency: "XAF", imgSrc: '/images/Cameroon.svg' },
        // { name: "Madagascar", currency: "MGA", imgSrc: '/images/Madagascar.svg' },
        // { name: "Venezuela", currency: "VES", imgSrc: '/images/Venezuela.svg' },
        // { name: "Yemen", currency: "YER", imgSrc: '/images/Yemen.svg' },
        // { name: "Ghana", currency: "GHS", imgSrc: '/images/Ghana.svg' },
        // { name: "Mozambique", currency: "MZN", imgSrc: '/images/Mozambique.svg' },



    // { name: "Angola", currency: "AOA", imgSrc: '/images/Angola.svg' },
        // { name: "Peru", currency: "PEN", imgSrc: '/images/Peru.svg' },
        // { name: "Uzbekistan", currency: "UZS", imgSrc: '/images/Uzbekistan.svg' },
    // { name: "Morocco", currency: "MAD", imgSrc: '/images/Morocco.svg' },
        // { name: "Canada", currency: "CAD", imgSrc: '/images/Canada.svg' },
        // { name: "Afghanistan", currency: "AFN", imgSrc: '/images/Afghanistan.svg' },

        // { name: "Iraq", currency: "IQD", imgSrc: '/images/Iraq.svg' },

        // { name: "Sudan", currency: "SDG", imgSrc: '/images/Sudan.svg' },

        // { name: "Algeria", currency: "DZD", imgSrc: '/images/Algeria.svg' },

        // { name: "Kenya", currency: "KES", imgSrc: '/images/Kenya.svg' },

        // { name: "Colombia", currency: "COP", imgSrc: '/images/Colombia.svg' },

        // { name: "Germany", currency: "EUR", imgSrc: '/images/Germany.svg' },

        // { name: "Iran", currency: "IRR", imgSrc: '/images/Iran.svg' },

        // { name: "Democratic Republic of Congo", currency: "CDF", imgSrc: '/images/Democratic republic of congo.svg' },

        // { name: "Egypt", currency: "EGP", imgSrc: '/images/Egypt.svg' },

        // { name: "Ethiopia", currency: "ETB", imgSrc: '/images/Ethiopia.svg' },

            // { name: "Pakistan", currency: "PKR", imgSrc: '/images/Pakistan.svg' },
    