import React ,{useState} from 'react'
import { CurrencyDropdownItems } from '../../constants/AllCurencies';
import { currencyMapping } from '../../constants/currencyMapping';



function DropdownItem({ text,code, imgSrc, onSelect }) {
  return (
    <button className="dropdown-item d-flex gap-3 align-items-center" onClick={() => onSelect(text,code, imgSrc)}>
      <img loading='lazy' src={process.env.PUBLIC_URL + imgSrc} alt="Currency Image" className="currencySwapOptionImg" />
      <div className='d-flex flex-column'>
      {text} 
      <span>{code}</span>
      </div>
    </button>
  );
}


export default function FiatTransferStepTwo(props) {
    const [dropDown,showDropDown] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);

    const dropDownHandle = () => {
        showDropDown(!dropDown)
    }

    const handleOptionSelect = (optionText,optionCode, optionImgSrc) => {
      setSelectedOption({ text: optionText,code:optionCode, imgSrc: optionImgSrc });
      showDropDown(false);
      props.setSign(optionCode)
    };
  


  return (
    <>
    <div className='col-11 stepTwoContentDiv'>
      <div className='recievedDetailDiv d-flex justify-content-between pt-3'>
        <h6>
          Bank Name:
        </h6>
        <p>
          {props.bankName}
        </p>
      </div>  
      <div className='recievedDetailDiv d-flex justify-content-between pt-3'>
        <h6>
        Sending To
        </h6>
        <p>
          {props.accName}
        </p>
      </div>  
      <div className='recievedDetailDiv d-flex justify-content-between pt-3'>
        <h6>
        Account Number
        </h6>
        <p>
          {props.acoountNumber}
        </p>
      </div>  
      <div className='recievedDetailDiv d-flex justify-content-between pt-3'>
        <h6>
        Sort Code
        </h6>
        <p>
          {props.sortCode}
        </p>
      </div> 
      <div className='recievedDetailDiv d-flex justify-content-between pt-3'>
        <h6>
        IBAN
        </h6>
        <p>
          {props.iban}
        </p>
      </div> 


      
    </div>
    <div className='col-11 stepTwoContentDiv mt-5'>
        <p className='h mb-1'>
        Amount 
        </p>
        <div className='FDetailsDiv'>
            <div className='row  '>
                <div className='col-md-6 col-6 AmountInputDiv'>
                    <input type='number' placeholder='Enter Amount' className='form-control fTransferInput' value={props.amount} onChange={(e)=>{props.setamount(e.target.value)}}/>
                </div>
                <div className='col-md-6 col-6 cSwapRightSide1  d-flex gap-3 align-items-center justify-content-between' onClick={dropDownHandle}>
                    
                    <div className='d-flex align-items-center gap-2'>
                        { selectedOption?.imgSrc ?
                           <img src={process.env.PUBLIC_URL + selectedOption?.imgSrc ||  process.env.PUBLIC_URL + '/images/eur.svg'} alt="Currency Image" className="cLogo" /> : 
                           <img loading='lazy' src={'../images/g.svg'} alt="Currency Image" className="cLogo" /> 
                        }

                        <p className='mb-0 mt-1'>{selectedOption?.code || 'GBP'}</p>
                    </div>
                    <img loading='lazy' src="../images/WHPArrowD.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow"/>
                    

                    <div className={`dropdown-menu CurrencySwapDropDown ${dropDown ? 'show' : ''}`} onMouseLeave={() => showDropDown(false)}>
                        {CurrencyDropdownItems.map((item, index) => (
                            <DropdownItem key={index} text={item.name} code={item.currency} imgSrc={item.imgSrc} onSelect={handleOptionSelect} />
                        ))}
                    </div>
                </div>
            </div>     
       </div>
    </div>
    </>
  )
}
