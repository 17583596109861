import React from 'react'

function ConsumerNumberForBills(props) {
  return (
    <div className='row py-3 justify-content-center'>
        <div className='col-11 FDInputDiv mb-4'>
            <h6 className='ps-1 inputTitle mb-3'>
            Consumer Reference Number
            </h6>
            <input type='number' placeholder='2345678903457' className='form-control fTransferInput' value={props.consumerNumber} onChange={(e)=> {props.setconsumerNumber(e.target.value)}}/>
            <p className='SVCNBottomText mt-1 ' >
                Do not send directly to a crowdfund or ICO. We will no credit your  account with tokens from that sale.
            </p>
        </div>
    </div>
  )
}

export default ConsumerNumberForBills