import React from "react";
import AnimatedPage from "../AnimatedPage";
import { useTranslation } from "react-i18next";
import useCurrentLanguage from "../../customHooks/useCurrentLanguage";


export default function Crypto() {
  const { t } = useTranslation();
  const currentLanguage = useCurrentLanguage();

  return (
    <AnimatedPage>
      <div>
        {/* Section One Start  */}
        <div className="homepageContainerP3 S1PATopPadding pb-0">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv"
                
                
              >
                <h3 className="pt-lg-5 pt-md-5 pt-0 mt-lg-3 mt-md-3 mt-0 text-sm-start text-center paraWidth01">
                  {t("CW.S1.h.1")}
                  {
                    currentLanguage === 'ru' ? 
                    <br></br> 
                    : ""
                  }
                  <b className="s2HeadingBold"> {t("CW.S1.hb")} </b>
                </h3>
                <p className="S1Para text-sm-start text-center">{t("CW.S1.p")}</p>
              </div>
              {/* <div className='col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12'></div> */}
              <div
                className="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-sm-6 col-12 d-flex justify-content-sm-end justify-content-center pt-sm-0 pt-lg-5 pt-md-5 pt-0 mt-sm-0 mt-3"
                
                
              >
                <img
                  loading="lazy"
                  src="../images/crypto1.png"
                  alt="Section One Right Side Images"
                  className="rightSideS1Img imgResponsivesWidth"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Section One End  */}

        {/* Section Two Start  */}
        <div className="homepageContainerP2 rPBCS2">
          <div className="container-fluid rPBCS2">
            <div className="row  ">
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12"
                
                
              >
                <img
                  loading="lazy"
                  src="../images/crypto2.png"
                  alt="Section Twp Left Side Images"
                  className="leftSideS2ImgP4 imgResponsivesWidth"
                />
              </div>
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv "
                
                
              >
                <h3 className="pt-5 mt-md-5 mt-3">
                  {t("CW.S2.h.1")}
                  <b className="s2HeadingBold"> {t("CW.S2.hb.1")} </b>
                  <br></br>
                  {t("CW.S2.h.2")}
                </h3>
                <p className="S1Para ">
                  {t("CW.S2.p.1")}
                  <br></br>
                  <br></br>
                  {/* {t('CW.S2.p.2')} */}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Section Two End  */}

        {/* Section Three Start  */}
        <div className="homepageContainer  mt-0  pt-0 pb-5">
          <div className="container-fluid">
            <div className="row  reverseColumn">
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv "
                
                
              >
                <div className="row">
                  <img
                    loading="lazy"
                    src="../images/Vector 6.svg"
                    alt="Section Two Right Side HR"
                    className="rightSideHrS2 imgResponsive pr_rem2 hrSmHide"
                  />
                </div>

                <h3 className="mt-3 pt-5">
                  {" "}
                  {t("CW.S3.h.1")}
                  <b className="s2HeadingBold">
                    {" "}
                    {t("CW.S3.hb.1")}
                  </b> <br></br> {t("CW.S3.h.2")}
                </h3>
                <p className="homePageS2SubHeading pt-4 ">{t("CW.S3.p.1")}</p>
              </div>
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  "
                
                
              >
                <img
                  loading="lazy"
                  src="../images/crypto3.png"
                  alt="Section Twp Left Side Images"
                  className=" imgResponsive mt-5"
                />
              </div>

              <img
                loading="lazy"
                src="../images/hrSmShow.svg"
                alt="Section Two Right Side HR"
                className="rightSideHrS2 imgResponsive hrSmShow"
              />
            </div>
          </div>
        </div>
        {/* Section Three End  */}


        {/* Section Four Start  */}
        <div className="homepageContainerP2 ">
          <div className="container-fluid">
            <div className="row">
              <img
                loading="lazy"
                src="../images/Vector 7.svg"
                alt="Section Two END Hr"
                className="imgResponsive hrSmHide"
              />
            </div>
            <div className="reverseColumnS6">
              <div className="row pt-3">
                <div
                  className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv "
                  
                  
                >
                  <h3 className="py-3 pt-sm-5 pt-0">
                    {t("CW.S4.h.1")} <br></br>{" "}
                    <b className="s2HeadingBold"> {t("CW.S4.h.2")} </b>
                  </h3>
                </div>
                <div
                  className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv"
                  
                  
                >
                  <p className="homePageS3SubHeading pt-sm-5 pt-0 mt-md-3 mt-0">
                    {t("CW.S4.p.1")}
                  </p>
                </div>
              </div>
              <div className="row justify-content-center">
                <img
                  loading="lazy"
                  src="../images/hrSmShow.svg"
                  alt="Section Two END Hr"
                  className="imgResponsive hrSmShow mt-sm-0 mt-5"
                />

                <div
                  className="col-12 p-0 CS4Bg"
                  
                  
                >
                  <div className="row CWS5PopUpBtnDiv ps-5">
                    <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-6 col-sm-6 col-7 hidePopUp"></div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-5 col-10">
                      <div className="CWS5PopUpBtnDiv">
                        <img
                          loading="lazy"
                          src="../images/CWS4pop.svg"
                          alt="Section One Right Side Images"
                          className="rightSideS1Img imgResponsive"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section Four End  */}

        {/* Section Five Start  */}
        <div className="homepageContainerP2">
          <div className="container-fluid">
            <img
              loading="lazy"
              src="../images/hrSmShow.svg"
              alt="Section Two Right Side HR"
              className="S4LeftSideImg rightSideHrS2 imgResponsive hrSmShow pb-sm-0 pb-5 "
            />
            <div
              className="row justify-content-between "
              
              
            >
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 ">
                <img
                  loading="lazy"
                  src="../images/crypto5.png"
                  alt="Section Twp Left Side Images"
                  className="S4LeftSideImg imgResponsivesWidth"
                />
              </div>
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv "
                
                
              >
                <div className="row">
                  <img
                    loading="lazy"
                    src="../images/Vector 6.svg"
                    alt="Section Two Right Side HR"
                    className="rightSideHrS2 imgResponsive hrSmHide pr_rem5"
                  />
                </div>
                <h3 className="pt-5 mt-md-3 mt-0">
                  {t("CW.S5.h.1")} <br></br>
                  <b className="s2HeadingBold "> {t("CW.S5.hb.1")} </b>
                </h3>

                <p className="S4Para ">{t("CW.S5.p.1")}</p>
              </div>
            </div>
          </div>
        </div>
        {/* Section Five End  */}

        {/* Section Six Start  */}
        <div className="homepageContainer mt-0 pt-0">
          <div className="container-fluid">
            <div className="row justify-content-between reverseColumn">
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-5 col-12 homepageS1MainHeadingDiv "
                
                
              >
                <div className="row">
                  <img
                    loading="lazy"
                    src="../images/Vector 6.svg"
                    alt="Section Two Right Side HR"
                    className="rightSideHrS2 imgResponsive pr_rem10 hrSmHide rRemoveP1370"
                  />
                </div>

                <h3 className="pt-sm-5 pt-5">
                  {" "}
                  {t("CW.S6.h.1")} {t("CW.S6.h.2")}
                  {
                    currentLanguage === 'ru' ?
                    <br></br>
                    : ""
                  }
                  <b className="s2HeadingBold"> {t("CW.S6.hb.1")} </b> <br></br>
                </h3>
                <p className={`S1Para ${currentLanguage === 'ru' ? 'paraWidth' : ""}`}>{t("CW.S6.p.1")}</p>
              </div>
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-7 col-12 d-flex justify-content-center"
                
                
              >
                <img
                  loading="lazy"
                  src="../images/crypto6.png"
                  alt="Section Twp Left Side Images"
                  className="rightSideS2ImgP2 imgResponsivesWidth mt-sm-0 mt-lg-4 mt-md-4 mt-5"
                />
              </div>
              {/* <div className='row'> */}
              <img
                loading="lazy"
                src="../images/hrSmShow.svg"
                alt="Section Two Right Side HR"
                className="rightSideHrS2 imgResponsive hrSmShow "
              />
              {/* </div> */}
            </div>
          </div>
        </div>
        {/* Section Six End  */}

        {/* Section Seven Start  */}
        <div className="homepageContainer pt-5 CryptoS7Bg ">
          <h3
            className="p5S7Heading01 pb-xxl5 pb-0 px-lg-0 px-md-0 px-5"
            
            
          >
            {t("CW.S7.h.1")} <b className="s2HeadingBold"> {t("CW.S7.h.2")} </b>
          </h3>
          <p
            className="S3BottomCenterHeading py-xxl-5 py-0 mt-5 px-lg-0 px-md-0 px-2 "
          >
            {t("CW.S7.p.6")} {
              currentLanguage === 'ru' ? "" : <br className="d-xxl-block d-none"></br>
            }
            {t("CW.S7.p.7")}{
              currentLanguage === 'ru' ? "" : <br className="d-xxl-block d-none"></br>
            }
            {t("CW.S7.p.8")}
          </p>

          <h3
            className="p5S7Heading mt-xxl-5 mt-0"
            
           
          >
            {t("CW.S7.h.3")} <b className="p5S7HeadingB"> {t("CW.S7.h.4")} </b>
          </h3>
          <div className="container-fluid s4BannerP4s3">
            <div
              className="row mt-2 pt-5 ps-0 hrSmHide"
              
              
            >
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12   s7P2RightsideColumnImgP3">
                <img
                  loading="lazy"
                  src="../images/s7p2c1.svg"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img imgResponsive "
                />
              </div>
              <div className="col-4   s7P2RightsideColumnImgP3">
                <img
                  loading="lazy"
                  src="../images/s7p2c2.svg"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img imgResponsive "
                />
              </div>
              <div className="col-4   s7P2RightsideColumnImgP3">
                <img
                  loading="lazy"
                  src="../images/s7p2c3.svg"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img imgResponsive "
                />
              </div>
            </div>
            <div className="row "  >
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12  columnBorder  s7P2RightsideColumnp3">
                <img
                  loading="lazy"
                  src="../images/s7p2c1.svg"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img imgResponsive hrSmShow pb-sm-0 pb-4 mt-sm-0 mt-lg-5 mt-md-5 mt-2 pt-sm-0 pt-3"
                />

                <h3 className="S3BottomHeading">{t("CW.S7.hb.1")}</h3>
                <p className="s4BottomSubHeading pt-lg-3 pt-md-3 pt-0  ">
                  {t("CW.S7.p.1.1")} <br className="d-xxl-block d-none"></br>{" "}
                  {t("CW.S7.p.1.2")} <br className="d-xxl-block d-none"></br>{" "}
                  {t("CW.S7.p.1.3")} <br className="d-xxl-block d-none"></br>{" "}
                  {t("CW.S7.p.1.4")}
                </p>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12   columnBorder s7P2RightsideColumnp3 ">
                <img
                  loading="lazy"
                  src="../images/s7p2c2.svg"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img imgResponsive hrSmShow pb-sm-0 pb-4 mt-sm-0 mt-lg-5 mt-md-5 mt-2 pt-sm-0 pt-3"
                />

                <h3 className="S3BottomHeading">{t("CW.S7.hb.2")}</h3>
                <p className="s4BottomSubHeading pt-lg-3 pt-md-3 pt-0 ">{t("CW.S7.p.2")}</p>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12   s7P2RightsideColumnp3 ">
                <img
                  loading="lazy"
                  src="../images/s7p2c3.svg"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img imgResponsive hrSmShow pb-sm-0 pb-4 mt-sm-0 mt-lg-5 mt-md-5 mt-2 pt-sm-0 pt-3"
                />

                <h3 className="S3BottomHeading">{t("CW.S7.hb.3")}</h3>
                <p className="s4BottomSubHeading pt-lg-3 pt-md-3 pt-0 ">{t("CW.S7.p.3")}</p>
              </div>
            </div>

            <div
              className="row mt-5 pt-5 ps-0 hrSmHide"
              
              
            >
              <div className="col-4   s7P2RightsideColumnImgP3">
                <img
                  loading="lazy"
                  src="../images/s7p2c4.svg"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img imgResponsive "
                />
              </div>
              <div className="col-4   s7P2RightsideColumnImgP3">
                <img
                  loading="lazy"
                  src="..//images/s7C2.svg"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img imgResponsive "
                />
              </div>
            </div>
            <div className="row " >
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12  columnBorder  s7P2RightsideColumnp3">
                <img
                  loading="lazy"
                  src="../images/s7p2c4.svg"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img imgResponsive hrSmShow pb-sm-0 pb-4 mt-sm-0 mt-lg-5 mt-md-5 mt-2 pt-sm-0 pt-3"
                />
                <h3 className="S3BottomHeading">{t("CW.S7.hb.4")}</h3>
                <p className="s4BottomSubHeading pt-lg-3 pt-md-3 pt-0  ">{t("CW.S7.p.4")}</p>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12   columnBorder s7P2RightsideColumnp3 ">
                <img
                  loading="lazy"
                  src="../images/s7C2.svg"
                  alt="Section Twp Left Side Images"
                  className="rightSideS1Img imgResponsive hrSmShow pb-sm-0 pb-4 mt-sm-0 mt-lg-5 mt-md-5 mt-2 pt-sm-0 pt-3"
                />
                <h3 className="S3BottomHeading">{t("CW.S7.hb.5")}</h3>
                <p className="s4BottomSubHeading pt-lg-3 pt-md-3 pt-0 ">{t("CW.S7.p.5")}</p>
              </div>
            </div>
          </div>
        </div>
        {/* Section Seven End  */}
      </div>
    </AnimatedPage>
  );
}
