import React from 'react'

function SavedNewBillDetails(props) {
  return (
    <div className='row justify-content-center'>
    <div className='col-11'>
        <div className='recievedDetailDiv d-flex justify-content-between '>
            <h6>
            Company:
            </h6>
            <p>
            {props?.name}
            </p>
        </div>
        <div className='recievedDetailDiv d-flex justify-content-between '>
            <h6>
            Consumer No:
            </h6>
            <p>
            {props?.consumerNumber}
            </p>
        </div>
        <div className='recievedDetailDiv d-flex justify-content-between '>
            <h6>
            Consumer Name:
            </h6>
            <p>
            Thomson
            </p>
        </div>
    </div>
</div>
  )
}

export default SavedNewBillDetails