import React,{useState,useEffect} from 'react'
import UpperNav from './UpperNav';
import { getToken } from '../../services/LocalStorageService';
import { useNavigate } from 'react-router-dom';
function DropdownItem({ text, onSelect }) {
    return (
      <button className="dropdown-item" onClick={() => onSelect(text)}>
        {text}
      </button>
    );
  }
function TransactionsHistory() {
    const { access_token } = getToken();
    const navigate = useNavigate();
    const [apiData, setApiData] = useState([]);
    const [selectedOption, setSelectedOption] = useState({ text: 'All Transactions', type: '' });
    const [transactions, setTransactions] = useState([]);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [dropDown, showDropDown] = useState(false);

    useEffect(() => {
        fetch('https://evistialab.com/api/v1/transaction-history/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`,
            }
        })
            .then(response => {
                if (!response.ok) {
                    console.log('response', response);
                }
                return response.json();
            })
            .then(data => {
                
                if (Array.isArray(data.transactions)) {
                    setTransactions(data.transactions);
                    setFilteredTransactions(data.transactions);
                } else {
                    console.error('Expected array but got:', data);
                    setTransactions([]);
                    setFilteredTransactions([]);
                }
            })
            .catch(error => console.error('Error fetching data:', error));
    }, [access_token]);

    const handleOptionSelect = (optionText) => {
        const option = { text: optionText, type: optionText === 'All Transactions' ? '' : optionText };
        setSelectedOption(option);
        showDropDown(false);

        if (option.type) {
            setFilteredTransactions(transactions.filter(tx => tx.transaction_type === option.type));
        } else {
            setFilteredTransactions(transactions);
        }
    };
  return (
        <div className='sideBarMainContent '>
            <div className='WMainDiv '>
            <UpperNav title={`Payment History`} symbol={" "}/>
        
            
            <div className='row justify-content-center'>
            <div className='col-xxl-12 col-lg-12 col-12'>
            <div className='fiatTransferCard mt-md-5 mt-2 ' >
                    <div className='cardUpperRow mb-3'>
                        <div className='col-xl-9 col-md-9 col-6'>
                        <h3>Transactions History
                        </h3>
                        </div>
                        <div className='col-xl-3 col-md-4 col-6 col-6 d-flex justify-content-end mb-2' style={{cursor:"pointer"}}>
                            <div className='blogddDiv1  d-flex gap-3 align-items-center justify-content-between'  onClick={() => showDropDown(!dropDown)}>
                                <div className='d-flex gap-2'>
                                    <h4 className='mb-0'>{selectedOption?.text || 'All Transactions'}</h4>
                                </div>
                                <img loading='lazy' src="../images/Vector 26.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow"/>

                                <div className={`dropdown-menu blogwapDropDown ${dropDown ? 'show' : ''}`}  onMouseLeave={() => showDropDown(false)}>
                                   
                                    <DropdownItem text="All Transaction"  onSelect={handleOptionSelect} />
                                    <DropdownItem text="Send"  onSelect={handleOptionSelect} />
                                    <DropdownItem text="Recieve"  onSelect={handleOptionSelect} />
                                    <DropdownItem text="Food & Groceries"  onSelect={handleOptionSelect} />
                                    <DropdownItem text="Traveling"  onSelect={handleOptionSelect} />
                                    <DropdownItem text="E-Commerce"  onSelect={handleOptionSelect} />
                                    <DropdownItem text="Entertainment"  onSelect={handleOptionSelect} />
                                    <DropdownItem text="Shopping"  onSelect={handleOptionSelect} />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/t1.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            Food & Groceries
                            </p>
                            <span>
                            Jul 3 12:15PM
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            400 USD
                            </p>
                            <span>
                            -400 USD USD
                            </span>
                        </div>
                    </div>


                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/t2.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            Shopping
                            </p>
                            <span>
                            Jul 3 12:15PM
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            400 USD
                            </p>
                            <span>
                            -400 USD USD
                            </span>
                        </div>
                    </div>

                    
                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/inbox.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            NETFLIX Premium Subscription
                            </p>
                            <span>
                            Jul 3 12:15PM
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            10 USD
                            </p>
                            <span>
                            -10.00 USD
                            </span>
                        </div>
                    </div>


                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/t4.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            Traveling
                            </p>
                            <span>
                            Jul 3 12:15PM
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            400 USD
                            </p>
                            <span>
                            -400 USD USD
                            </span>
                        </div>
                    </div>


                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/t5.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            Luke Wilson
                            </p>
                            <span>
                            Jul 3 12:15PM
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            400 USD
                            </p>
                            <span>
                            -400 USD USD
                            </span>
                        </div>
                    </div>


                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/inbox.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            NETFLIX Premium Subscription
                            </p>
                            <span>
                            Jul 3 12:15PM
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            10 USD
                            </p>
                            <span>
                            -10.00 USD
                            </span>
                        </div>
                    </div>

                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/t6.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            Traveling
                            </p>
                            <span>
                            Jul 3 12:15PM
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            400 USD
                            </p>
                            <span>
                            -400 USD USD
                            </span>
                        </div>
                    </div>


                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/t7.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            Luke Wilson
                            </p>
                            <span>
                            Jul 3 12:15PM
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            400 USD
                            </p>
                            <span>
                            +400 USD USD
                            </span>
                        </div>
                    </div>


                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/t8.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            E-Commerce
                            </p>
                            <span>
                            Jul 3 12:15PM
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            400 USD
                            </p>
                            <span>
                            +400 USD USD
                            </span>
                        </div>
                    </div>


                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/t1.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            Food & Groceries
                            </p>
                            <span>
                            Jul 3 12:15PM
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            400 USD
                            </p>
                            <span>
                            -400 USD USD
                            </span>
                        </div>
                    </div>

                    
        
            </div>
        
        
            </div>
        
        
            </div>
            </div>
            </div>
  )
}

export default TransactionsHistory