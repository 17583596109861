import React,{useState,useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import UpperNav from './UpperNav';
import { getToken } from '../../services/LocalStorageService';
function ConfirmSwap() {
    const {access_token} = getToken();
    const location = useLocation();
    const cryptoSymbol = location.state?.cryptoSymbol;
    const cryptoTotal = location.state?.cryptoTotal;
    const cryptoIconeUrl = location.state?.cryptoIconeUrl;
    const currencySymbol = location.state?.currencySymbol;
    const currencyTotal = location.state?.currencyTotal;
    const currencyIconUrl = location.state?.currencyIconUrl;
    const currencyResultTotal = location.state?.currencyResultTotal;
    const cryptoResultTotal = location.state?.cryptoResultTotal;
    const direction = location.state?.direction;
    const [apiError,setApiError] = useState()
    const [apiData,setapiData] = useState({})
    


    useEffect(() => {
      
      if (direction === "crypto_to_fiat"){
        setapiData({
          direction: "crypto_to_fiat",
          fiat: currencySymbol,
          coin: cryptoSymbol,
          amount: cryptoTotal,
        })
      }else{
        setapiData({
          direction: "fiat_to_crypto",
          fiat: currencySymbol,
          coin: cryptoSymbol,
          amount: currencyTotal,
        })
      }

      console.log('function cvalled ',direction,'direction is',apiData)
    }, [direction]);




    const navigate = useNavigate()

    const hanleSwapComplete = async () => {
      setApiError(``)
      const apiUrl = "https://evistialab.com/api/v1/crypto-fiat/";
      console.log(direction,'direction')
      // Prepare the form data to be sent in the request
      
      
      console.log(apiData, "api data before call");

      
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify(apiData),
      });
  
      if (response.status === 401) {
        navigate("/login");
      }
      if (response.status === 400) {
        console.log(response, "response");
      }
  


    const data = await response.json();
    if (data) {
        console.log(data,'success',data.fiat_amount_credited, data.fee_deducted)
        setApiError(``)
        if(data.message){
          navigate('/wallet/swap-success',{state:{
            cryptoSymbol:cryptoSymbol,
            currencySymbol:currencySymbol,
            message : data.message,
            fee : data.fee_deducted,
            amount_credit : data.fiat_amount_credited
          }})
        }
    }
    if (data.error){
        setApiError(`${data.error}`)
    }
    }

    const handleConfirmSwap = () => {
      navigate('/wallet/swap')
    }

    return (
        <div className='sideBarMainContent '>
        <div className='WMainDiv '>
        <UpperNav title={`Confirm Swap`} symbol={" "}/>
    
        
        <div className='row mt-5 justify-content-center'>
          <div className='col-xxl-6 col-lg-8 col-12'>
              <div className='fiatTransferCard mt-5' >
              <div className='cardUpperRow mb-3'>
                    <div className='col-12'>
                    <h3>
                    Confirm Swap
                    </h3>
                    </div>
                </div>
  
              <div className='col-12  'style={{position:"relative"}} >
                <div className='confirmSwapDiv mb-4'>
                    <div className='swapRightSideDiv d-flex gap-3'>
                        <img loading='lazy' src={`${cryptoIconeUrl}`} alt="Section One Right Side Images" className="logo"/>
                        <p>
                            {cryptoSymbol}
                        </p>
                    </div>

                    <div className='swapRightSideDiv d-flex gap-3'>
                        <img loading='lazy' src={process.env.PUBLIC_URL + currencyIconUrl} alt="Section One Right Side Images" className="logo"/>
                        <p>
                            {currencySymbol}
                        </p>
                    </div>
                </div>
                <img loading='lazy' src="../images/Group 611.svg" alt="Section One Right Side Images" className="swapBtn"/>
                <div className='recievedDetailDiv d-flex justify-content-between px-3'>
                    <h6>
                    {cryptoSymbol}
                    </h6>
                    <p>
                    {
                      direction === 'crypto_to_fiat' ? 
                      cryptoTotal
                      : cryptoResultTotal
                    }
                    </p>
                </div>
                <div className='recievedDetailDiv d-flex justify-content-between px-3'>
                    <h6>
                    {currencySymbol}
                    </h6>
                    <p>
                    {
                      direction === 'crypto_to_fiat' ? 
                       currencyResultTotal
                      : currencyTotal
                    }
                    </p>
                </div>

              </div>
              <div className='row'>
                    <p className='swapTotal my-3'>
                    {
                      direction === 'crypto_to_fiat' ? 
                      cryptoTotal 
                      : currencyTotal 
                    }
                    {'\u00a0'}
                     {
                      direction === 'crypto_to_fiat' ? 
                      cryptoSymbol 
                      : currencySymbol 
                    }
                    {'\u00a0'}
                       ≈ 
                    {'\u00a0'}
                    {
                      direction === 'crypto_to_fiat' ? 
                      currencyResultTotal
                      : cryptoResultTotal
                    }
                    {'\u00a0'}
                     {
                      direction === 'crypto_to_fiat' ? 
                      currencySymbol 
                      : cryptoSymbol 
                    }
                    </p>
                </div>
              <div className='row justify-content-center mt-3'>
                        <span className='mb-3' style={{color:'red',textAlign:"center"}}>
                            {
                                apiError
                            }
                        </span>
                <div className='col-6 pe-4 '>
                        <div className='WHPS3bottomDiv2 row justify-content-center'>              
                            <a onClick={()=>{hanleSwapComplete()}}> Swap </a>
                        </div>
                </div>
                <div className='col-6 ps-sm-4 ps-2'>
                        <div className='WHPS3bottomDiv1 row  justify-content-center' onClick={()=>{handleConfirmSwap()}}>              
                            <a > Cancel </a>
                        </div>
                </div>
            </div>
    
        </div>
    
    
      </div>
    </div>
    </div>
    </div>
      )
}

export default ConfirmSwap