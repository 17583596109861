import React,{useState} from 'react'
import AnimatedPage from '../AnimatedPage'
import { useTranslation } from 'react-i18next';
import ScrollToTop from '../scrollToTop';



export default function RegisrterPersonalAccStepOTwo({formData, handleInputChangeInStepTwo ,error,errorId}) {
  const {t} = useTranslation()
    //consolelog('Form Data', formData)
    const [eyeIcon, setEyeIcon] = useState(true)
    const eyeIconHandler = () => {
        setEyeIcon(!eyeIcon)
    }
    const [eyeIcons, setEyeIcons] = useState(true)
    const eyeIconsHandler = () => {
        setEyeIcons(!eyeIcons)
    }
  return (
    <>
      <ScrollToTop/>
    <AnimatedPage>
                    <div className='row '   >
                        <div className='d-flex justify-content-center'>
                        <img loading='lazy' alt="Vr Line" src="../../../images/p2.svg" className='imgResponsive px-5 upperImgRegister hrSmHide'/>
                        </div>

                        <h2 className='SignupHeadings'>
                        {t('PACC.S2.H')}
                        </h2>
                        <img loading='lazy' alt="Vr Line" src="../../../images/Vector 4.svg" className='imgResponsive'/>
                    </div>
                    <div className='row pt-5'   >
                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                        <div className="mb-5 px-3">
                        <label htmlFor="email" className="form-label contactusLabels pb-2">{t('PACC.S2.lb.1')}</label>
                        <input
                            type="text"
                            className="form-control formInputs"
                            id="phNo"
                            placeholder='12456789'
                            value={formData.phoneNumber}
                            onChange={(e) => handleInputChangeInStepTwo('phoneNumber', e.target)}
                            required    
                        />
                        {errorId === 'phNo' ? <span className='inputFiledError pt-1'>{error}</span> : ''}
                        </div>
                        </div>

                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                        <div className="mb-5 px-3">
                        <label htmlFor="email" className="form-label contactusLabels pb-2">{t('PACC.S2.lb.2')} </label>
                        <input
                            type="text"
                            className="form-control formInputs"
                            id="email"
                            placeholder={t('PACC.S2.ph.1')}
                            value={formData.email}
                            onChange={(e) => handleInputChangeInStepTwo('email', e.target)}
                            required    
                        />
                        {errorId === 'email' ? <span className='inputFiledError pt-1'>{error}</span> : ''}
                        </div>
                        </div>

                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                        <div className="mb-5 px-3" style={{position:"relative"}}>
                        <label htmlFor="email" className="form-label contactusLabels pb-2"> {t('PACC.S2.lb.3')} </label>
                        <input

                             type={`${eyeIcon === true ? "password" : "text"}`}
                            className="form-control formInputs"
                            id="password"
                            placeholder='***********'
                            value={formData.password}
                            onChange={(e) => handleInputChangeInStepTwo('password', e.target)}
                            required  
                        />
                         {
                          eyeIcon === true ? 
                          <img loading='lazy' src={process.env.PUBLIC_URL + "/images/eye.svg" } alt="new IMg" className="eyeIcons" onClick={eyeIconHandler}/>
                          : 
                          <img loading='lazy' src={process.env.PUBLIC_URL + "/images/eye-crossed.svg" } alt="new IMg" className="eyeIcons" onClick={eyeIconHandler} />
                        }
                        </div>
                        {errorId === 'password' ? <span className='inputFiledError pt-1' style={{wordBreak:"break-all",position:"relative",bottom:"20%"}}>{error}</span> : ''}
                        </div>

                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                        <div className="mb-5 px-3" style={{position:"relative"}}>
                        <label htmlFor="email" className="form-label contactusLabels pb-2">{t('PACC.S2.lb.4')} </label>
                        <input
                            type={`${eyeIcons === true ? "password" : "text"}`}
                            className="form-control formInputs"
                            id="confirmPassword"
                            placeholder='***********'
                            value={formData.confirmPassword}
                            onChange={(e) => handleInputChangeInStepTwo('confirmPassword', e.target)}
                            required  
                        />
                          {
                            eyeIcons === true ? 
                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/eye.svg" } alt="new IMg" className="eyeIcons" onClick={eyeIconsHandler}/>
                            : 
                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/eye-crossed.svg" } alt="new IMg" className="eyeIcons" onClick={eyeIconsHandler} />
                          }
                        </div>
                        {errorId === 'confirmPassword' ? <span className='inputFiledError pt-1' style={{wordBreak:"break-all",position:"relative",bottom:"20%"}}>{error}</span> : ''}
                        </div>

                        


                        {/* <div className="S5BtnDiv  ps-4 ms-1 mb-5 pb-5">
                      <button className='s5Btn'> <span className='s5Btntxt'> Next </span> 
                      </button> 
                    </div> */}

                    </div>
    </AnimatedPage>
    </>

  )
}
