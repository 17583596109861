import React from 'react'
import UpperNav from './UpperNav'
import { useNavigate } from 'react-router-dom';
export default function Payment() {
  const navigate = useNavigate()
  const handleNextPage = () => {
    // Navigate to the details page and pass the clicked card's data as props
    navigate('/wallet/fiat-transfer');
  };
  return (
    <div className='sideBarMainContent '>
    <div className='WMainDiv '>
      <UpperNav />

      
      <div className='row mt-5 '>
            <div className='col-xxl-3 col-xl-4 col-md-6 col-12 d-flex justify-content-center mb-4'>
                <div className='paymentCard' onClick={handleNextPage}>
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Group 670.svg" } alt="User Image" className="" />
                <p> 
                    Fiat Transfer
                </p>
                </div>
            </div>
            <div className='col-xxl-3 col-xl-4 col-md-6 col-12 d-flex justify-content-center mb-4' onClick={()=>{navigate('/wallet/bills-and-payments')}}>
                <div className='paymentCard'>
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Group 671.svg" } alt="User Image" className="" />
                <p> 
                Bills & Payments
                </p>
                </div>
            </div>
      </div>


    </div>
  </div>
  )
}
