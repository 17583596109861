import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import UpperNav from './UpperNav'
import MobileOTP from './MobileOTP'
import Swal from 'sweetalert2'
import { getToken } from '../../services/LocalStorageService';
import { useGetUserAccountDetailsQuery } from '../../services/userAuthApi'
import PdfUploader from './PdfUpload'
import { useGetUserCardUpgradeAplicationMutation } from '../../services/userAuthApi'

function DropdownItem({ text, imgSrc, onSelect }) {
    return (
      <button className="dropdown-item" onClick={() => onSelect(text)}>
        {text}
      </button>
    );
  }



function ApplyForUpgrade() {

    const { access_token } = getToken(); 
    const { data, isFetching, isError } = useGetUserAccountDetailsQuery(access_token);
    const [cardApplicationApi, {isLoading, errorAccur}] = useGetUserCardUpgradeAplicationMutation();



    const [step, setStep] = useState(1)
    const [fullName, setFullName] = useState()
    const [dob, setDob] = useState(data?.dob)
    const [country, setCountry] = useState()
    const [city, setCity] = useState()
    const [state, setState] = useState()
    const [zipCode, setZipCode] = useState()
    const [emplyer, setEmployer] = useState()
    const [lengthEmplyment, setLengthEmplyment] = useState()
    const [annualInacome, setAnnualInacome] = useState()
    const [FiatBalance, setFiatBalance] = useState()
    const [digitalAssets, setDigitalAssets] = useState()
    const [reasonForUpgrade, setreasonForUpgrade] = useState()
    const [bankStatement, setbankStatement] = useState()


    

      // State for showing/hiding the dropdown
      const [dropDown, showDropDown] = useState(false);
      const [selectedOption, setSelectedOption] = useState({ text: 'Standard Blue Card' });
    
      const dropDownHandle = () => {
        showDropDown(!dropDown);
      };
    
      const handleOptionSelect = (optionText) => {
        setSelectedOption({ text: optionText });
        showDropDown(false); 
      };

    const [address, setAddress] = useState()


    useEffect(() => {
      if (data){
        setFullName(data?.user?.firstName,data?.user?.lastName)
        setDob(data?.dob)
      }
    }, [data])
    



    const navigate = useNavigate()

    


    const naxtPageHandler = async ()=>{
        if (step === 1){

            // Check if all required fields are filled
            const isFormValid = fullName && dob && country && address && city && state && zipCode && emplyer && lengthEmplyment && annualInacome && FiatBalance && digitalAssets && reasonForUpgrade && bankStatement;
             
            if (!isFormValid) {
                Swal.fire({
                    icon: 'error',
                    title: 'Missing Information',
                    text: 'Please fill in all required fields.',
                });
                return; // Stop the function if form is not valid
            }else{
                setStep(step + 1)
            }
        }else if (step === 2){

            const dataObj = {
                'fullname' : fullName,
                'dob' : dob,
                'country' : country ,
                'address' : address,
                'city' : city ,
                'state' : state ,
                'zipCode' : zipCode ,
                'employer' : emplyer ,
                'lengthEmplyment' : lengthEmplyment ,
                'fiatBalance' : FiatBalance ,
                'digitalAssets' : digitalAssets ,
                'reasonForUpgrade' : reasonForUpgrade ,
                'employer' : emplyer ,
                'lengthEmployment' : lengthEmplyment ,
                'annualIncome' : annualInacome ,
                'cardType' : selectedOption.text ,
            }

            const formData = new FormData();

            // Append text fields from dataObj
            Object.keys(dataObj).forEach(key => {
                formData.append(key, dataObj[key]);
            });

            // Append file if it exists
            if (bankStatement) {
                formData.append('bank_statement', bankStatement,);
            }

            try {
                const response = await cardApplicationApi({ userData: formData, access_token }).unwrap();
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: "Your Card Upgrade Request application has been submitted successfully",
                }).then(() => {
                    navigate('/wallet/index');
                });
            } catch (err) {
                if (err?.data?.code === 'token_not_valid') {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: `Your Login Session has been expired`,
                   }).then(() => {
                    navigate('/login');
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: `There was an error processing your request`,
                   });
                }
                
            }
        }
    }



    return (
        <div className='sideBarMainContent px-sm-0 px-3'>
        <div className='WMainDiv '>
          <UpperNav title={'Apply For Upgrade'} symbol={''} />
          {
                step === 1 ? 
          <>
            <div className='dropdownDiv01'>
                <p className='upgradeCardHeading ps-0 mt-4'>
                Upgrading from Classic Silver Card to Standard Blue Card  
                </p>
                <div>
                    <label htmlFor="fullName" className="form-label AcPwLabels ">Chose your Card</label>
                    <div className='AcCurrencyddDiv AcCurrencyddDivApply d-flex gap-3 align-items-center justify-content-between' onClick={dropDownHandle}>
                        <div className='d-flex gap-2'>
                            <h3 className='mb-0 swapTitle'>{selectedOption.text}</h3>
                        </div>
                        <img loading='lazy' src="../images/WHPArrowD.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow"/>

                        <div className={`dropdown-menu cardSelection ${dropDown ? 'show' : ''}`} onMouseLeave={() => showDropDown(false)}>
                            <DropdownItem text="Premium Black Card" onSelect={handleOptionSelect} />
                            <DropdownItem text="Classic Silver Card" onSelect={handleOptionSelect} />
                        </div>
                </div>
                </div>
            </div>
          <div className='row mt-4 justify-content-center bottomLineAC mx-1'>
           
            <h2 className='ACHeading ps-0'>
            Personal Identification Information
            </h2>
          </div>
        <div className='row mt-3'>
            <div className='col-xxl-7 col-xl-9 col-12'>
                <div className='row'>
                    <div className="col-md-4 col-12 mb-4">
                        <label htmlFor="fullName" className="form-label AcPwLabels "> Full Name</label>
                        <input
                            type="text"
                            className="form-control formInputs"
                            id="fullName"
                            placeholder='Johathan'
                            value={fullName}
                            onChange={(e)=>setFullName(e.target.value)} 
                            // Use the new event handler
                            required
                        />
                    </div>

                    <div className="col-md-4 col-12 mb-4">
                        <label htmlFor="fullName" className="form-label AcPwLabels ">Date of Birth</label>
                        <input
                            type="date"
                            className="form-control formInputs"
                            id="fullName"
                            placeholder='01/01/1980'
                            value={dob}
                            onChange={(e)=>setDob(e.target.value)} 
                            // Use the new event handler
                            required
                            maxLength={10}
                        />
                    </div>


                    {/* <div className="col-md-4 col-12 mb-4">
                        <label htmlFor="fullName" className="form-label AcPwLabels "> Social Security Number</label>
                        <input
                            type="number"
                            className="form-control formInputs"
                            id="fullName"
                            placeholder='123-45-6789'
                            value={securityNumber}
                            onChange={(e)=>setSecurityNumber(e.target.value)} 
                            // Use the new event handler
                            required
                        />
                    </div> */}
                </div>
            </div>
        </div>

        {/* <div className='row mt-5 justify-content-center bottomLineAC mx-1'>
            <h2 className='ACHeading ps-0'>
            Current Card Information
            </h2>
        </div> */}



        {/* <div className='row mt-3'>
            <div className='col-xxl-7 col-xl-9 col-12'>
                <div className='row'>
                    <div className='col-lg-4 col-12  mb-3'>
                        <b>
                            <h6 className='ps-1 AcPwLabels'>
                            Card Number 
                            </h6>
                        </b>
                        <div className='FDetailsDiv'>
                            <span className='info'>
                            1111 2222 3333 4444
                            </span>
                        </div>
                    </div>

                    <div className='col-lg-4 col-12  mb-3'>
                        <b>
                            <h6 className='ps-1 AcPwLabels'>
                                CVC
                            </h6>
                        </b>
                        <div className='FDetailsDiv '>
                            <span className='info'>
                                123
                            </span>
                        </div>
                    </div>


                    <div className='col-lg-4 col-12  mb-3'>
                        <b>
                            <h6 className='ps-1 AcPwLabels'>
                                Expiry Date
                            </h6>
                        </b>
                        <div className='FDetailsDiv'>
                            <span className='info'>
                                12/23
                            </span>
                        </div>
                    </div>
                </div>  
            </div>
        </div> */}
    

        <div className='row mt-5 justify-content-center bottomLineAC mx-1'>
            <h2 className='ACHeading ps-0'>
            Contact Information
            </h2>
        </div>
        <div className='row mt-3'>
            <div className='col-xxl-7 col-xl-9 col-12'>
                <div className='row'>
                    <div className='col-md-6 col-12 mb-4'>
                            <label htmlFor="fullName" className="form-label AcPwLabels ">Country</label>
                            <input
                                type="text"
                                className="form-control formInputs"
                                id="Country"
                                placeholder='UK'
                                value={country}
                                onChange={(e)=>setCountry(e.target.value)} 
                                // Use the new event handler
                                required
                            />
                    </div>
                    <div className='col-md-6 col-12 mb-4'>
                            <label htmlFor="fullName" className="form-label AcPwLabels ">Street Address</label>
                            <input
                                type="text"
                                className="form-control formInputs"
                                id="address"
                                placeholder='123 Main St, Anytown, USA'
                                value={address}
                                onChange={(e)=>setAddress(e.target.value)} 
                                // Use the new event handler
                                required
                            />
                    </div>
                    <div className='col-md-4 col-12 '>
                            <label htmlFor="fullName" className="form-label AcPwLabels ">City</label>
                            <input
                                type="text"
                                className="form-control formInputs"
                                id="address"
                                placeholder='City'
                                value={city}
                                onChange={(e)=>setCity(e.target.value)} 
                                // Use the new event handler
                                required
                            />
                    </div>
                    <div className='col-md-4 col-12 '>
                            <label htmlFor="fullName" className="form-label AcPwLabels ">State</label>
                            <input
                                type="text"
                                className="form-control formInputs"
                                id="address"
                                placeholder='State'
                                value={state}
                                onChange={(e)=>setState(e.target.value)} 
                                // Use the new event handler
                                required
                            />
                    </div>
                    <div className='col-md-4 col-12 '>
                            <label htmlFor="fullName" className="form-label AcPwLabels ">Zip Code</label>
                            <input
                                type="number"
                                className="form-control formInputs"
                                id="address"
                                placeholder='193765'
                                value={zipCode}
                                onChange={(e)=>setZipCode(e.target.value)} 
                                // Use the new event handler
                                required
                            />
                    </div>
                    

                    {/* <div className='col-lg-4 col-12  my-3'>
                        <b>
                            <h6 className='ps-1 AcPwLabels'>
                            Phone Number
                            </h6>
                        </b>
                        <div className='FDetailsDiv'>
                            <span className='info'>
                            (555) 123-4567
                            </span>
                        </div>
                    </div>


                    <div className='col-lg-4 col-12  my-3'>
                        <b>
                            <h6 className='ps-1 AcPwLabels'>
                                Email
                            </h6>
                        </b>
                        <div className='FDetailsDiv'>
                            <span className='info'>
                                johndoe@example.com
                            </span>
                        </div>
                    </div> */}
                </div>  
            </div>
        </div>


        <div className='row mt-5 justify-content-center bottomLineAC mx-1'>
            <h2 className='ACHeading ps-0'>
            Employment and Income Information
            </h2>
        </div>
        <div className='row mt-3'>
            <div className='col-xxl-7 col-xl-9 col-12'>
                {/* <p className='applyforUpgradeInfoTextBold'>
                    Employer: {'\u00a0'} 
                    <span className='applyforUpgradeInfoText'>
                        ABC Corporation
                    </span>
                </p> */}
                <div className='row'>
                <div className='col-md-4 col-12 '>
                            <label htmlFor="fullName" className="form-label AcPwLabels ">Employer</label>
                            <input
                                type="text"
                                className="form-control formInputs"
                                id="address"
                                placeholder=' ABC Corporation'
                                value={emplyer}
                                onChange={(e)=>setEmployer(e.target.value)} 
                                // Use the new event handler
                                required
                            />
                    </div>
                   
                {/* <p className='applyforUpgradeInfoTextBold'>
                    Annual Income: {'\u00a0'} 
                    <span className='applyforUpgradeInfoText'>
                    $50,000
                    </span>
                </p> */}
                <div className='col-md-4 col-12 '>
                            <label htmlFor="fullName" className="form-label AcPwLabels ">Annual Income</label>
                            <input
                                type="number"
                                className="form-control formInputs"
                                id="address"
                                placeholder='50,000'
                                value={annualInacome}
                                onChange={(e)=>setAnnualInacome(e.target.value)} 
                                // Use the new event handler
                                required
                            />
                    </div>
                    
                {/* <p className='applyforUpgradeInfoTextBold'>
                    Length of Employment: {'\u00a0'} 
                    <span className='applyforUpgradeInfoText'>
                         3 years
                    </span>
                </p> */}
                <div className='col-md-4 col-12 '>
                            <label htmlFor="fullName" className="form-label AcPwLabels "> Length of Employment</label>
                            <input
                                type="number"
                                className="form-control formInputs"
                                id="address"
                                placeholder='3 Years'
                                value={lengthEmplyment}
                                onChange={(e)=>setLengthEmplyment(e.target.value)} 
                                // Use the new event handler
                                required
                            />
                    </div>
                    </div>
            </div>
            <div className='col-xxl-5 col-xl-3 col-12'> 
            <label htmlFor="fullName" className="form-label AcPwLabels ">Upload Your Bank Statment</label>
                <PdfUploader setbankStatement={setbankStatement}/>
            </div>
        </div>



        {/* <div className='row mt-5 justify-content-center bottomLineAC mx-1'>
            <h2 className='ACHeading ps-0'>
            Credit Information
            </h2>
        </div>
        <div className='row mt-3'>
            <div className='col-xxl-7 col-xl-9 col-12'>
            <p className='applyforUpgradeInfoTextBold'>
            Current Credit Score: {'\u00a0'} 
                    <span className='applyforUpgradeInfoText'>
                    720 
                    </span>
                </p>
                <p className='applyforUpgradeInfoTextBold'>
                    Outstanding Debts:  {'\u00a0'} 
                    <span className='applyforUpgradeInfoText'>
                    $2,000
                    </span>
                </p>
            </div>
        </div> */}

        <div className='row mt-5 justify-content-center bottomLineAC mx-1'>
            <h2 className='ACHeading ps-0'>
            Financial Assets
            </h2>
        </div>
        <div className='row mt-3'>
            <div className='col-xxl-7 col-xl-9 col-12'>
                <div className='row'>
                        <div className='col-md-6 col-12 '>
                            <label htmlFor="fullName" className="form-label AcPwLabels ">Fiat Balance</label>
                            <input
                                type="number"
                                className="form-control formInputs"
                                id="address"
                                placeholder='100,000'
                                value={FiatBalance}
                                onChange={(e)=>setFiatBalance(e.target.value)} 
                                // Use the new event handler
                                required
                            />
                        </div>
                        <div className='col-md-6 col-12 '>
                                <label htmlFor="fullName" className="form-label AcPwLabels ">Digital Assets</label>
                                <input
                                    type="number"
                                    className="form-control formInputs"
                                    id="address"
                                    placeholder='200,000'
                                    value={digitalAssets}
                                    onChange={(e)=>setDigitalAssets(e.target.value)} 
                                    // Use the new event handler
                                    required
                                />
                        </div>
                    </div>
            {/* <p className='applyforUpgradeInfoTextBold'>
                    Fiat Balance:  {'\u00a0'} 
                    <span className='applyforUpgradeInfoText'>
                    $100,000
                    </span> 
                </p> */}
                {/* <p className='applyforUpgradeInfoTextBold'>
                    Digital Assets: $200,000  {'\u00a0'} 
                    <span className='applyforUpgradeInfoText'>
                    $200,000
                    </span>
                </p> */}
            </div>
        </div>
        

        <div className='row mt-5 justify-content-center bottomLineAC mx-1'>
            <h2 className='ACHeading ps-0'>
            Reason for Upgrade
            </h2>
        </div>
  
        <div className='row mt-3'>
            <div className='col-xxl-7 col-xl-9 col-12'>
                <div className='row'>
                    <div className="col-md-10 col-12 mb-4">
                        <textarea
                            rows="7"
                            type="text"
                            className="form-control formInputs"
                            id="reason"
                            placeholder='More travel benefits, higher credit limit, etc.'
                            value={reasonForUpgrade}
                            onChange={(e)=>setreasonForUpgrade(e.target.value)} 
                            // Use the new event handler
                            required
                        />
                    </div>

                </div>
            </div>
        </div>


    
    
          <div className='row mt-1'>
          
            <div className='col-xxl-4 col-xl-5 col-12'>
    
                  <div className='row WHPS3bottomDiv'>
                  <a className='btnChange' to="/wallet/index" style={{cursor:'pointer',color:'#2645E0'}} onClick={naxtPageHandler}>Apply For Upgrade</a>
                  </div>  

            </div>
          </div>
          </>

          : <div className='row mt-5 justify-content-center'>
            <div className='col-xxl-5 col-lg-8 col-12'>
                <div className='fiatTransferCard mt-md-5 mt-2 ' >
                    <MobileOTP />
                    <div className='row justify-content-center'>
                        <div className='col-md-6 col-9'>
                        <div className='row WHPS3bottomDiv'>
                        <a className='btnChange' style={{cursor:'pointer',color:'#2645E0'}} onClick={naxtPageHandler}>Apply For Upgrade</a>
                        </div> 
                        </div>
                    </div>
                </div>
            </div>
      </div>
    }
    
    
        </div>
      </div>
      )
}

export default ApplyForUpgrade