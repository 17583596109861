import React from 'react'
import AnimatedPage from '../AnimatedPage'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// CSS 
import '../../static/css/index.css'
import '../../static/css/common.css'
import '../../static/css/navbar.css'


export default function BuisnessAccount() {
  const { t, i18n } = useTranslation();
    return (
      <AnimatedPage>
        <div >
          {/* Section One Start  */}
            <div className='homepageContainerP2 pb-lg-0 pb-md-0 pb-5 S1PATopPadding' >
            <div className='container-fluid '>
                <div className='row '>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-8 col-12'  >
                    <h3 className='S1SubHeading S1Heading'>
                    {t('BA.S1.h.1')} <br className='d-md-block d-none'></br> <b className='S1Heading'> {t('BA.S1.hb.1')}</b>   
                    </h3>
                    <p className='S1Para  textCenterMb PMB1rem mt-lg-0 mt-md-0 mt-5 mb-lg-0 mb-md-0 mb-4'>
                    {t('BA.S1.p.1')}
                    </p>
                    <Link to="/sign-up/">
                    <div className=" divBtnMedium btnHide" >
            <button className=' btnMedium' > <span className='btnMediumText'> {t('btnING.4')} </span> 
            </button>   </div>
            </Link>
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12 d-flex justify-content-center'  >
                  <img loading='lazy' src="../images/business1.png" alt="Section One Right Side Images" className="rightSideS1Img imgResponsivesWidth manageImage1"/>
                  </div>
                  <Link to="/sign-up/">
                  <div className=" divBtnMedium btnShow" >
            <button className=' btnMedium' > <span className='btnMediumText mt-sm-0 mt-3'>{t('btnING.4')} </span> 
            </button>   </div>
            </Link>
                </div>
            </div>
            </div>
          {/* Section One End  */}
    
    
          {/* Section Two Start  */}
          <div className='homepageContainerP2 ' >
            <div className='container-fluid'>
              <img loading='lazy' src="../images/hrSmShow.svg" alt="Section Two Right Side HR" className="S4LeftSideImg rightSideHrS2 imgResponsive hrSmShow pb-sm-0 pb-4 " />
              <div className='row justify-content-between '>
              <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-5' >
                <img loading='lazy' src="../images/business2.png" alt="Section Twp Left Side Images" className="S4LeftSideImg imgResponsivesWidth manageImage1"/>
              </div>
              <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-5 homepageS1MainHeadingDiv '>
                <div className='row'>
                <img loading='lazy' src="../images/Vector 6.svg" alt="Section Two Right Side HR" className="rightSideHrS2 imgResponsive hrSmHide pr_rem5" />
                </div>
                  <h3 className='pt-4 mt-md-4 mt-0'>
                  {t('BA.S2.h.1')} <br></br>
                  <b className='s2HeadingBold'> {t('BA.S2.hb.1')}  </b>
                  </h3>

                  <p className='S4Para '>
                  {t('BA.S2.p.1')}
                  </p>
                </div> 
              </div>
        </div>
          </div>
          {/* Section Two End  */}
          
          
          {/* Section Three Start  */}
          <div className='homepageContainer pt-5 BABgS3'  >
            <h3 className='S3BottomCenterHeading pt-lg-5 pt-md-5 pt-0 px-lg-0 px-md-0 px-3'  >
                 {t('BA.S3.h.1')}
                  </h3>
            <div className='container-fluid s4BannerP4s3'>
            
                <div className='row pt-5 hrSmHide'  >
                <div className='col-6   s7P2LeftsideColumnImg'>
                <img loading='lazy' src="../images/s7p2c1.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive "/>
                </div>
                <div className='col-6   s7P2RightsideColumnImg'>
                <img loading='lazy' src="../images/s7p2c2.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive "/>
                </div>
                </div>
                <div className='row '  >
                
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 columnBorder  s7P2LeftsideColumn'>
                <img loading='lazy' src="../images/s7p2c1.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmShow mb-sm-0 mb-4"/>
                  <h3 className='S3BottomHeading'>
                  {t('BA.S3.hb.1')}
                  </h3>
                <p className='s4BottomSubHeading pt-lg-3 pt-md-3 pt-1 '>
                {t('BA.S3.p.1')}
                </p>
                {/* <img loading='lazy' src={../S7BorderSideImg} alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive"/> */}
                </div>

                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  s7P2RightsideColumn '>
                <img loading='lazy' src="../images/s7p2c2.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmShow mb-sm-0 mb-4"/>
                  <h3 className='S3BottomHeading'>
                  {t('BA.S3.hb.2')}
                  </h3>
                <p className='s4BottomSubHeading pt-lg-3 pt-md-3 pt-1 '>
                {t('BA.S3.p.2')}
                </p>
                {/* <img loading='lazy' src={../S7BorderSideImg} alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive"/> */}
                </div>
                </div>
                <div className='row hrsSmHide'  >
                <div className='col-6   s7P2LeftsideColumnImg'>
                <img loading='lazy' src="../images/s7p2c3.svg"alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmHide"/>
                </div>
                <div className='col-6   s7P2RightsideColumnImg'>
                <img loading='lazy' src="../images/s7p2c4.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmHide"/>
                </div>
                </div>
                <div className='row' >
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  columnBorder s7P2LeftsideColumn'>
                  <img loading='lazy' src="../images/s7p2c3.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmShow mb-sm-0 mb-4"/>
                    <h3 className='S3BottomHeading'>
                    {t('BA.S3.hb.3')}
                    </h3>
                  <p className='s4BottomSubHeading pt-lg-3 pt-md-3 pt-1 '>
                  {t('BA.S3.p.3')}
                  </p>
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  s7P2RightsideColumn'>
                  <img loading='lazy' src="../images/s7p2c4.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmShow mb-sm-0 mb-4"/>
                    <h3 className='S3BottomHeading '>
                    {t('BA.S3.hb.4')}
                    </h3>
                  <p className='s4BottomSubHeading pt-lg-3 pt-md-3 pt-1 '>
                  {t('BA.S3.p.4')}
                  </p>
                  </div>
                </div>



                
            </div>  


          </div>
          {/* Section Three End  */}




          {/* Section Four Start  */}
          <div className='homepageContainer' >
                <div className='container-fluid'>
                  <div className='row mt-md-5 mt-0 pt-md-4 pt-0'>
                  <img loading='lazy' src="../images/Vector 7.svg" alt="Section Two END Hr" className="imgResponsive hrSmHide"/>
                  </div>
                  <div className='reverseColumnS6' >
                    <div className='row pt-3'>
                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv ' >
                        <h3 className='py-3 pt-sm-5 pt-0'>
                         {t('BA.S4.h.1')} <br></br>   {t('BA.S4.h.2')}

                        </h3>
                        <div>
                        <Link to="/digital-wallet/"> 
                        <div className=" divBtnMedium btnHide mt-5" >
                          <button className=' btnMedium' > <span className='btnMediumText'>  {t('btnING.5')} {'\u00a0'}  > </span> 
                          </button>   
                          </div>
                        </Link>
                        </div>
                      </div>
                      <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv'  >
                      <p className='homePageS3SubHeading pt-sm-5 pt-0'>
                      {t('BA.S4.p.1')}
                        </p>
                        <div>
                        <Link to="/digital-wallet/"> 
                        <div className=" divBtnMedium btnShow mt-sm-0 mt-5" >
                          <button className=' btnMedium' > <span className='btnMediumText'>  {t('btnING.5')} {'\u00a0'}  > </span> 
                          </button>   
                          </div>
                        </Link>
                        </div>
                      </div>
                    </div>
                    <div className='row justify-content-center mt-4'>
                    <img loading='lazy' src="../images/hrSmShow.svg" alt="Section Two END Hr" className="imgResponsive hrSmShow mt-sm-0 my-5 px-0" />

                    <div className='col-12 p-0 BAS4Bg'  >
                      <div className='row BAS5PopUpBtnDiv ps-5' >
                        <div className='col-xxl-4 col-xl-3 col-lg-3 col-md-2 col-sm-3 col-7 hidePopUp'>
  
                        </div>
                        <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-5 col-6'>
                        <div className="BAS5PopUpBtnDiv">
                      <img loading='lazy' src="../images/GBP FIAT 1.png" alt="Section One Right Side Images" className="rightSideS1Img imgResponsive  manageImage1 "/>
                          
                        </div>
                        </div> 
                        
                      </div>

                        
                    </div>
                    </div>
                  </div>
                </div>
          </div>
          {/* Section Four End  */}
    
    
        {/* Section Five Start  */}
        <div className='homepageContainer pt-lg-5 pt-md-0 pt-0 BABgS5' >
            <h3 className='S3BottomCenterHeading pt_rem3 pb-lg-0 pb-md-0 pb-5'  >
                {t('BA.S5.h.1')}
            </h3>
            <div className='container-fluid s4BannerP4'>
            
                <div className='row pt-5 hrSmHide'  >
                <div className='col-6   s7P2LeftsideColumnImg'>
                <img loading='lazy' src="../images/s7p2c1.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive "/>
                </div>
                <div className='col-6   s7P2RightsideColumnImg'>
                <img loading='lazy' src="../images/s7p2c2.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive "/>
                </div>
                </div>
                <div className='row '  >
                
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 columnBorder  s7P2LeftsideColumn'>
                <img loading='lazy' src="../images/s7p2c1.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmShow mb-sm-0 mb-4"/>
                  <h3 className='S3BottomHeading'>
                  {t('BA.S5.hb.1')}
                  </h3>
                <p className='s4BottomSubHeading pt-lg-3 pt-md-3 pt-1 '>
                {t('BA.S5.p.1')}
                </p>
                </div>

                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  s7P2RightsideColumn '>
                <img loading='lazy' src="../images/s7p2c2.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmShow mb-sm-0 mb-4"/>
                  <h3 className='S3BottomHeading'>
                  {t('BA.S5.hb.2')}
                  </h3>
                <p className='s4BottomSubHeading pt-lg-3 pt-md-3 pt-1 '>
                {t('BA.S5.p.2')}
                </p>
                </div>
                </div>
                <div className='row hrsSmHide'  >
                <div className='col-6   s7P2LeftsideColumnImg'>
                <img loading='lazy' src="../images/s7p2c3.svg"alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmHide"/>
                </div>
                <div className='col-6   s7P2RightsideColumnImg'>
                <img loading='lazy' src="../images/s7p2c4.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmHide"/>
                </div>
                </div>
                <div className='row' >
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  columnBorder s7P2LeftsideColumn'>
                  <img loading='lazy' src="../images/s7p2c3.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmShow mb-sm-0 mb-4"/>
                    <h3 className='S3BottomHeading'>
                    {t('BA.S5.hb.3')}
                    </h3>
                  <p className='s4BottomSubHeading pt-lg-3 pt-md-3 pt-1 '>
                  {t('BA.S5.p.3')}
                  </p>
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  s7P2RightsideColumn pb-lg-0 pb-md-0 pb-5'>
                  <img loading='lazy' src="../images/s7p2c4.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmShow mb-sm-0 mb-4"/>
                    <h3 className='S3BottomHeading '>
                    {t('BA.S5.hb.4')}
                    </h3>
                  <p className='s4BottomSubHeading pt-lg-3 pt-md-3 pt-1 '>
                  {t('BA.S5.p.4')}
                  </p>
                  </div>
                </div>



                
            </div> 
        </div>

          {/* Section Five End  */}
    
    
          {/* Section Six Start  */}
          <div className='homepageContainer mt-5 pt-5' >
            <div className='container-fluid'>
              <div className='row justify-content-between reverseColumn'>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-5 col-12 homepageS1MainHeadingDiv '  >
                    <div className='row'>
                    <img loading='lazy' src="../images/Vector 6.svg" alt="Section Two Right Side HR" className="rightSideHrS2 imgResponsive pr_rem10 hrSmHide rRemoveP1370" />
                    </div>

                      <h4 className='pt-sm-4  pt-5 paraWidth01 '>
                     {t('BA.S6.h.1')} <br></br><b className='s2HeadingBold05'> {t('BA.S6.hb.1')}  </b>  
                      </h4>
                      <p className='S1Para '>
                      {t('BA.S6.p.1')}
                      </p>
                      <div>
                      <Link to="/sign-up/">
                      <div className=" divBtnMedium mt-3" >
                      <button className=' btnMedium' > <span className='btnMediumText'> {t('btnING.4')} </span> 
                      </button>   </div>
                      </Link>
                      </div>
                    </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-7 col-12 d-flex justify-content-center'  >
                    <img loading='lazy' src="../images/business4.png" alt="Section Twp Left Side Images" className="rightSideS2ImgP2 imgResponsivesWidth manageImage1 mt-sm-0 mt-4"/>
                  </div>
                  {/* <div className='row'> */}
                    <img loading='lazy' src="../images/hrSmShow.svg" alt="Section Two Right Side HR" className="rightSideHrS2 imgResponsive hrSmShow " />
                    {/* </div> */}
                </div>
            </div>
        </div>
          {/* Section Six End  */}
    
    
          {/* Section Seven Start  */}
          <div className='homepageContainerP3 BABgS7' >
              <div className='container-fluid'>
                <img loading='lazy' src="../images/hrSmShow.svg" alt="Section Two Right Side HR" className="S4LeftSideImg rightSideHrS2 imgResponsive hrSmShow pb-sm-0 pb-5 " />
                <div className='row justify-content-between mt-4'>
                <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 ' >
                  <img loading='lazy' src="../images/business5.png" alt="Section Twp Left Side Images" className="S4LeftSideImg imgResponsivesWidth manageImage1"/>

                </div>
                <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1 col-12 '></div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv '  >
                  <div className='row'>
                  <img loading='lazy' src="../images/Vector 6.svg" alt="Section Two Right Side HR" className="rightSideHrS2 imgResponsive hrSmHide pr_rem5" />
                  </div>
                    <h3 className='py-4'>
                    {t('BA.S7.h.1')}
                    <br className=''></br>
                           <b className='s2HeadingBold '>{t('BA.S7.hb.1')} </b> 
                    </h3>

                    <p className='S4Para '>
                    {t('BA.S7.p.1')}
                    </p>
                  </div>
                
                  
                  
                </div>
            </div>
          </div>
          {/* Section Seven End  */}
    
        </div>
        </AnimatedPage>
      )
}
