//PasswordTooltip

import React, { useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const PasswordTooltip = () => {
  const target = useRef(null);

  const tooltipContent = (
    <>
      <div className="pwInfoText">
        <ul>
          <li>Password must be Minimum 8 to Maximum 16 characters in length.</li>
          <li>At least 1 small letter [a-z]</li>
          <li>At least 1 number [0-9]</li>
          <li>To further strengthen your password, you may use any special character (e.g. @ & # $ % etc)</li>
        </ul>
      </div>
    </>
  );

  const popover = (
    <Popover id="password-tooltip">
      <Popover.Header as="h3">Password Policy</Popover.Header>
      <Popover.Body>
        {tooltipContent}
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="mb-1">
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom"
        overlay={popover}
      >
        <i
          ref={target}
          className="bi bi-info-circle-fill"
          style={{ cursor: 'pointer' }}
        ></i>
      </OverlayTrigger>
    </div>
  );
};

export default PasswordTooltip;