import React from 'react'
import AnimatedPage from '../AnimatedPage'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// CSS 
import '../../static/css/index.css'
import '../../static/css/common.css'
import '../../static/css/navbar.css'





export default function SavingsAcoount() {
  const { t, i18n } = useTranslation();
  return (
    <AnimatedPage>
    <div >
      {/* Section One Start  */}
        <div className='homepageContainerP3 S1PATopPaddingP3' >
            <div className='container-fluid'>
                <div className='row mt-md-4 mt-0'>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-8 col-12'  >
                    <h3 className='S1SubHeading S1Heading'>
                    {t('SA.S1.h.1')} <b className='S1Heading'>{t('SA.S1.hb.1')}</b><br></br>   {t('SA.S1.h.2')}  <b className='S1Heading'>{t('SA.S1.hb.2')}</b> 
                    </h3>
                    <p className='S1Para  mt-2 textCenterMb PMB1rem'>
                    {t('SA.S1.p.1')}
                    </p>
                    <Link to="/sign-up/">
                    <div className=" divBtnMedium btnHide" >
            <button className=' btnMedium' > <span className='btnMediumText'> {t('btnING.4')}  </span> 
            </button>   </div>
            </Link>
                </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-8 col-12 d-flex justify-content-center mb-sm-0 mb-3'  >
                  <img loading='lazy' src="../images/BAs1RightsideImg.svg"alt="Section One Right Side Images" className="rightSideS1Img imgResponsivesWidth"/>
                  </div>
                  <Link to="/sign-up/">
                  <div className=" divBtnMedium btnShow mt-sm-0 mt-5" >
            <button className=' btnMedium' > <span className='btnMediumText '> {t('btnING.4')}  </span> 
            </button>   </div>
                  </Link>
                </div>
            </div>
        </div>
      {/* Section One End  */}


      {/* Section Two Start  */}
      <div className='homepageContainerP2' >
            <div className='container-fluid'>
              <div className='row justify-content-between reverseColumn'>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-5 col-12 homepageS1MainHeadingDiv '  >
                  <div className='row'>
                  <img loading='lazy' src="../images/Vector 6.svg" alt="Section Two Right Side HR" className="rightSideHrS2 imgResponsive pr_rem10 hrSmHide rRemoveP1370" />
                  </div>

                    <h3 className='pt-sm-5  pt-5 mt-md-4 mt-0'>
                    {t('SA.S2.h.1')} <br></br>
                       <b className='s2HeadingBold'> {t('SA.S2.hb.1')}  </b>
                    </h3>
                    <p className='S1Para '>
                    {t('SA.S2.p.1')}
                    </p>
                  </div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-7 col-12 d-flex justify-content-center'  >
                  <img loading='lazy' src="../images/BAs2RightsideImg.svg" alt="Section Twp Left Side Images" className="rightSideS2ImgP2 imgResponsivesWidth mt-sm-0 mt-4 ms-md-5 ms-0"/>
                </div>
                {/* <div className='row'> */}
                  <img loading='lazy' src="../images/hrSmShow.svg" alt="Section Two Right Side HR" className="rightSideHrS2 imgResponsive hrSmShow " />
                  {/* </div> */}
                  
                </div>
            </div>
        </div>
      {/* Section Two End  */}
      
      
      {/* Section Three Start  */}
      <div className='homepageContainerP2 SAS3Bg' >
            <div className='container-fluid s3InsidePadding'>



              <div className='row'>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 '>
                <div className='row '  > 
                <div className='firstDiv'>
                <h3 className=' S3Heading ps-sm-0 ps-3'>
                {t('SA.S3.hb.1')}
                    </h3>
                    <p className='S3Para '>
                    {t('SA.S3.p.1.1')} <br></br>    {t('SA.S3.p.1.2')}
                    </p>
               </div>
               <div className='firstDiv'>
                    <h3 className='S3Heading ps-sm-0 ps-3'>
                    {t('SA.S3.hb.2')}
                    </h3>
                    <p className='S3Para '>
                    {t('SA.S3.p.2')}
                    </p>
                  </div>
                </div>


                    
                </div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 PAS3Padding  '>
                <div className='row '  > 
                <div className='firstDiv'>
                <h3 className=' S3Heading '>
                {t('SA.S3.hb.3')}
                    </h3>
                    <p className='S3Para prMb'>
                    {t('SA.S3.p.3')}
                  </p>
               </div>

               <div className='firstDiv'>
                    <h3 className='S3Heading ps-0'>
                    {t('SA.S3.hb.4')}
                    </h3>
                    <p className='S3Para prMb'>
                    {t('SA.S3.p.4')}
                    </p>
                  </div>
                </div>


                    
                </div>

                </div>
            </div>
        </div>
      {/* Section Three End  */}


      {/* Section Four Start  */}
      <div className='homepageContainerP2 mt-5 pt-5' >
            <div className='container-fluid'>
           <div className='row reverseColumn'>
           <img loading='lazy' src="../images/Vector 7.svg" alt="Section Two END Hr" className="imgResponsive pr_rem5 hrSmHide"/>
           </div>
              <div className='row reverseColumn'>
                <div className='row pt-5 '>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 homepageS1MainHeadingDiv'  >
                    <h3 className='py-3 pt-5 mb-5' >
                    {t('SA.S4.h.1')} <br></br>   {t('SA.S4.h.2')}
                    </h3>
                    <div>
                    <Link to="/digital-wallet/">
                    <div className=" divBtnMedium btnHide" >
            <button className=' btnMedium' > <span className='btnMediumText'>  {t('btnING.6')} {'\u00a0'} > </span> 
            </button>   </div>
                    </Link>
                    </div>

                  </div>

                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 '  >
                  <p className='S5Para pt-sm-5 pt-0 mt-sm-3 mt-0'>
                  {t('SA.S4.p.1')}
                    </p>
                    <div>
                    <Link to="/digital-wallet/">
                    <div className=" divBtnMedium btnShow" >       
                      <button className=' btnMedium' > <span className='btnMediumText mt-sm-0 mt-3'>  {t('btnING.6')} {'\u00a0'} > </span> 
                      </button>      
                    </div>
                    </Link>
                    </div>
                  </div>
                </div>
                <div className='row justify-content-center s5BannerBg mt-5'  >
                <div className='col-12 p-0 SAS4Bg' >
                    <div className='s5BannerBgDiv'>
                      
                    </div>
                  <div className='row ps-sm-5 ps-0 ' >
                  <div className='col-sm-6 col-2'>

                  </div>
                    <div className='col-sm-4 col-8 d-flex justify-content-end align-items-center S5PopUpBtnDivP3'>
                      <img loading='lazy' src="../images/SAS5pop.svg" alt="Section One Right Side Images" className="rightSideS1Img imgResponsive"/>
                    </div>
                    
                    
                  </div>

                    
                </div>
                </div>
           <img loading='lazy' src="../images/hrSmShow.svg" alt="Section Two END Hr" className="imgResponsive pe-4 hrSmShow"/>

                </div>
            </div>
        </div>
      {/* Section Four End  */}


    {/* Section Five Start  */}
      <div className='homepageContainerP3 ' >
        <div className='container-fluid'>
                        <img loading='lazy' src="../images/hrSmShow.svg" alt="Section Two Right Side HR" className="S4LeftSideImg rightSideHrS2 imgResponsive hrSmShow pb-sm-0 pb-5 " />
                        <div className='row justify-content-between '>
                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 '  >
                          <img loading='lazy' src="../images/BAs5LefttsideImg.svg" alt="Section Twp Left Side Images" className="S4LeftSideImg imgResponsivesWidth mt-4"/>

                        </div>
                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv'  >
                          <div className='row'>
                          <img loading='lazy' src="../images/Vector 6.svg" alt="Section Two Right Side HR" className="rightSideHrS2 imgResponsive hrSmHide pr_rem5" />
                          </div>
                            <h3 className='pt-4 mt-4'>
                            {t('SA.S5.h.1')}   <br></br> {t('SA.S5.h.2')}
                              <b className='s2HeadingBold '> {t('SA.S5.hb.1')} <br></br>  {t('SA.S5.hb.2')} </b> 
                            </h3>

                            <p className='S4Para '>
                            {t('SA.S5.p.1')}
                            </p>
                          </div> 
                        </div>
        </div>
      </div>
      {/* Section Five End  */}


      {/* Section Six Start  */}
      <div className='homepageContainerP2 SAS6Bg' >
            <div className='container-fluid'>
              <div className='row mt-3 reverseColumn'>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv'  >
                  <div className='row'>
                  <img loading='lazy' src="../images/Vector 6.svg" alt="Section Two Right Side HR" className="rightSideHrS2 hrSmHide imgResponsive"/>
                  </div>

                    <h3 className='s6P2heading'>
                    {t('SA.S6.h.1')} <b className='s2HeadingBold'> <br></br>  {t('SA.S6.hb.1')}   </b>
                    </h3>
                    <p className='homePageS2SubHeading pt-4' style={{paddingRight:'1rem'}}>
                    {t('SA.S6.p.1')}
                    </p>
                  </div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 d-flex justify-content-sm-end justify-content-center PSS6RImg'  >
                  <img loading='lazy' src="../images/SAS6img.svg" alt="Section Twp Left Side Images  " className="rightSideS1Img imgResponsive " />
                  {/* <img loading='lazy' src="../images/PAS6RImg.svg" alt="Section Twp Left Side Images " className="rightSideS1Img imgResponsive " /> */}

                </div>
                <img loading='lazy' src="../images/Vector 6.svg" alt="Section Two Right Side HR" className="rightSideHrS2 hrSmShow mb-sm-0 mb-5 imgResponsive"/>
                  
                </div>
            </div>
        </div>
      {/* Section Six End  */}


      {/* Section Seven Start  */}
      <div className='homepageContainer SAS7Bg' >
            <div className='container-fluid s4Banner '>
                <div className='row pt-5 hrSmHide'  >
                <div className='col-4   s7P2RightsideColumnImgP3'>
                <img loading='lazy' src="../images/p2s7v1.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive "/>
                </div>
                <div className='col-4   s7P2RightsideColumnImgP3'>
                <img loading='lazy' src="../images/p2s7v2.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive "/>
                </div>
                <div className='col-4   s7P2RightsideColumnImgP3'>
                <img loading='lazy' src="../images/p2s7v3.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive "/>
                </div>
                </div>
                <div className='row ' >
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 columnBorder  s7P2RightsideColumnp3'>
                <img loading='lazy' src="../images/p2s7v1.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmShow mt-sm-0 mt-5 pt-sm-0 pt-4 pb-sm-0 pb-4"/>

                  <h3 className='S3BottomHeading'>
                  {t('SA.S7.hb.1')}
                  </h3>
                <p className='s4BottomSubHeading pt-3  '>
                {t('SA.S7.p.1')}
                </p>
                </div>

                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12  columnBorder s7P2RightsideColumnp3 '>
                <img loading='lazy' src="../images/p2s7v2.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmShow mt-sm-0 mt-5 pt-sm-0 pt-4 pb-sm-0 pb-4"/>

                  <h3 className='S3BottomHeading'>
                  {t('SA.S7.hb.2')}
                  </h3>
                <p className='s4BottomSubHeading pt-3 '>
                {t('SA.S7.p.2')}
                </p>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12  s7P2RightsideColumnp3 '>
                <img loading='lazy' src="../images/p2s7v3.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmShow mt-sm-0 mt-5 pt-sm-0 pt-4 pb-sm-0 pb-4"/>

                  <h3 className='S3BottomHeading'>
                  {t('SA.S7.hb.3')}
                  </h3>
                <p className='s4BottomSubHeading pt-3 '>
                {t('SA.S7.p.3')}
                </p>
                </div>

                </div>


              <div className='row pt-sm-5 pt-0 mt-sm-5 mt-0 reverseColumn'>
                    <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 homepageS3MainHeadingDiv pt-sm-0 pt-4'  >
                        <h3 className='py-3 '>
                        {t('SA.S7.h.1')} <br></br> {t('SA.S7.h.2')}
                        </h3>
                        <p className='homePageS4SubHeading '>
                        {t('SA.S7.p.4')}<br></br> {t('SA.S7.p.5')} <br></br> {t('SA.S7.p.6')}
                        </p>
                        <Link to="/cards/">
                        <div className="S4BtnDiv divBtnLarge" >
                          <button className='s4Btn btnLarge'  > <span className='gradient-text btnLargeText' > {t('btnING.7')} </span> 
                          </button> 
                        </div>
                        </Link>
                    </div>

                    <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 d-flex justify-content-end pe-4'  >
                      <img loading='lazy' src="../images/personal6.png" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive"/>
                    </div>
                    </div>
                  </div>     
              </div>
      {/* Section Seven End  */}

    </div>
    </AnimatedPage>
  )
}
