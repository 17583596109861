import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { useAccountCreationMutation } from '../../../../services/userAuthApi';
import { toast } from 'react-toastify';


function StepOne({email}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [accountSetup, setAccountSetup] = useState('stepOne');
  const [errors, setErrors] = useState({});

  // User input states
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [martialStatus, setMartialStatus] = useState('');
  const [citizenship, setCitizenship] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [buisnesName, setbuisnesName] = useState('');
  const [businestype, setbusinestype] = useState('');
  const [countryOfRegistration, setcountryOfRegistration] = useState('');
  const [buisnesNumber, setbuisnesNumber] = useState('');
  const [dateOfEstablishment, setdateOfEstablishment] = useState('');
  const [web, setweb] = useState('');
  const [estimatedMonthlyBusinessRevenue, setestimatedMonthlyBusinessRevenue] = useState('');
  const [estimatedMonthlyExpenses, setestimatedMonthlyExpenses ] = useState('');

  const [agreeOnterms, setagreeOnterms] = useState(false);


  // Dropdown options
  const optionList1 = [t('PACC.S1.lb.DD.1'), t('PACC.S1.lb.DD.2'), t('PACC.S1.lb.DD.3'), t('PACC.S1.lb.DD.4'), t('PACC.S1.lb.DD.5')];
  const optionList2 = [
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo, Democratic Republic of the",
    "Congo, Republic of the",
    "Costa Rica",
    "Côte d'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe"
]
  const optionList3 = [
    'Agriculture', 'Automotive', 'Construction', 'Education', 'Energy', 'Entertainment', 'Financial Services', 'Healthcare', 'Hospitality', 'Information Technology', 'Manufacturing',
    'Media and Publishing', 'Retail', 'Telecommunications', 'Transportation and Logistics', 'Professional Services', 'Non-Profit Organizations', 'Real Estate', 'Wholesale Trade', 'Personal Services'
  ];
  const optionList4 = [
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo, Democratic Republic of the",
    "Congo, Republic of the",
    "Costa Rica",
    "Côte d'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Macedonia",
    "Norway",
    "Oman",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe"
]
  const optionList = [
    t('PACC.S3.lb.DD.1'), t('PACC.S3.lb.DD.2'), t('PACC.S3.lb.DD.3'), t('PACC.S3.lb.DD.4'),
    t('PACC.S3.lb.DD.5'), t('PACC.S3.lb.DD.6'), t('PACC.S3.lb.DD.8')
  ];

  // Dropdown states
  const [isOpen1, setIsOpen1] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState(t('PACC.S1.ph.3'));
  const [isOpen2, setIsOpen2] = useState(false);
  const [selectedOption2, setSelectedOption2] = useState('Select Your Country');
  const [isOpen3, setIsOpen3] = useState(false);
  const [selectedOption3, setSelectedOption3] = useState('Type');
  const [isOpen4, setIsOpen4] = useState(false);
  const [selectedOption4, setSelectedOption4] = useState('Country of Registration');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(t('PACC.S3.ph.3'));

  // Handle dropdown toggles
  const toggleDropdown1 = () => setIsOpen1(!isOpen1);
  const toggleDropdown2 = () => setIsOpen2(!isOpen2);
  const toggleDropdown3 = () => setIsOpen3(!isOpen3);
  const toggleDropdown4 = () => setIsOpen4(!isOpen4);
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Handle dropdown selections
  const selectOption1 = (option) => {
    handleBlur('martialStatus');
    setMartialStatus(option);
    setSelectedOption1(option);
    setIsOpen1(false);
  };

  const selectOption2 = (option) => {
    handleBlur('country');
    setCountry(option);
    setSelectedOption2(option);
    setIsOpen2(false);
  };

  const selectOption3 = (option) => {
    handleBlur('businestype');
    setbusinestype(option);
    setSelectedOption3(option);
    setIsOpen3(false);
  };

  const selectOption4 = (option) => {
    handleBlur('countryOfRegistration');
    setcountryOfRegistration(option);
    setSelectedOption4(option);
    setIsOpen4(false);
  };

  const selectOption = (option) => {
    handleBlur('citizenship');
    setCitizenship(option);
    setSelectedOption(option);
    setIsOpen(false);
  };

  // Handle input changes
  const handleFirstNameChange = (e) => {
    handleBlur('firstName');
    setFirstName(sanitizeInput(e.target.value));
  };

  const handleLastNameChange = (e) => {
    handleBlur('lastName');
    setLastName(sanitizeInput(e.target.value));
  };

  const handleDobChange = (e) => {
    handleBlur('dob');
    const inputDate = new Date(e.target.value);
    const formattedDate = inputDate.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    setDob(formattedDate);
  };
  

  const handleOnChange = (value) => {
    handleBlur('phone');
    setPhone(value);
  };

  const handleAddressChange = (e) => {
    handleBlur('address');
    setAddress(sanitizeInput(e.target.value));
  };

  const handleStateChange = (e) => {
    const value = e.target.value.replace(/[^a-zA-Z ]/g, '');
    handleBlur('state');
    setState(sanitizeInput(value));
  };

  const handleCityChange = (e) => {
    const value = e.target.value.replace(/[^a-zA-Z ]/g, '');
    handleBlur('city');
    setCity(sanitizeInput(value));
  };

  const handleZipCodeChange = (e) => {
    handleBlur('zipCode');
    setZipCode(e.target.value);
  };

  const handleBuisnessNameChange = (e) => {
    handleBlur('buisnesName');
    setbuisnesName(e.target.value);
  };

  // const handleBuisnessTypeChange = (e) => {
  //   handleBlur('businestype');
  //   setbusinestype(e.target.value);
  // };

  const handleestimatedMonthlyBusinessRevenueChange = (e) => {
    handleBlur('estimatedMonthlyBusinessRevenue');
    setestimatedMonthlyBusinessRevenue(e.target.value);
  };

  const handleestimatedMonthlyExpensesChange = (e) => {
    handleBlur('estimatedMonthlyExpenses');
    setestimatedMonthlyExpenses(e.target.value);
  };

  const handleBuisnessNumberChange = (e) => {
    handleBlur('buisnesNumber');
    setbuisnesNumber(e.target.value);
  };

  const handleBuisnessDoaChange = (e) => {
    handleBlur('dateOfEstablishment');
    const inputDate = new Date(e.target.value);
    const formattedDate = inputDate.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    setdateOfEstablishment(formattedDate);
  };

  const handleBuisnessWebChange = (e) => {
    handleBlur('web');
    setweb(e.target.value);
  };

  const handleAgreeOnTermsChange = (e) => {
    handleBlur('agreeOnterms');
    setagreeOnterms(true);
  };

  // Sanitize input to prevent XSS
  const sanitizeInput = (input) => {
    const element = document.createElement('div');
    element.innerText = input;
    return element.innerHTML;
  };

  // Remove error on blur
  const handleBlur = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  // Validate fields
  const validateFields = () => {
    let newErrors = {};
  
    const isOver18 = (dob) => {
      const today = new Date();
      const birthDate = new Date(dob);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age >= 18;
    };
  
    if (accountSetup === 'stepOne') {
      if (!firstName || firstName.length > 40) newErrors.firstName = 'First Name is required and should be less than 40 characters.';
      if (!lastName || lastName.length > 40) newErrors.lastName = 'Last Name is required and should be less than 40 characters.';
      if (!dob) {
        newErrors.dob = 'Date of Birth is required.';
      } else if (!isOver18(dob)) {
        newErrors.dob = 'You must be at least 18 years old.';
      }
      if (!phone || !/^\+?\d+$/.test(phone)) newErrors.phone = 'Phone Number is required and should be a valid number.';
      if (!martialStatus) newErrors.martialStatus = 'Marital Status is required.';
      if (!citizenship) newErrors.citizenship = 'Citizenship is required.';
    } else if (accountSetup === 'stepTwo') {
      if (!buisnesName) newErrors.buisnesName = 'Buisness Name is required.';
      if (!businestype) newErrors.businestype = 'Buisness Type is required.';
      if (!countryOfRegistration) newErrors.countryOfRegistration = 'Country of registration is required.';
      if (!buisnesNumber) newErrors.buisnesNumber = 'Business Registration is required.';
      if (!dateOfEstablishment) newErrors.dateOfEstablishment = 'Date of Establishment is required.';
      // if(!web) newErrors.web = 'Agree on terms and conditions is required'
    } else if (accountSetup === 'stepThree') {
      if (!country) newErrors.country = 'Country is required.';
      if (!address) newErrors.address = 'Address is required.';
      if (!state) newErrors.state = 'State is required.';
      if (!city) newErrors.city = 'City is required.';
      if (!zipCode) newErrors.zipCode = 'Zip Code is required.';
    }else{
      if (!estimatedMonthlyBusinessRevenue) newErrors.estimatedMonthlyBusinessRevenue = ' Estimated Monthly Business Revenue is required.';
      if (!estimatedMonthlyExpenses) newErrors.estimatedMonthlyExpenses = 'Estimated Monthly Expenses is required.';
      if(!agreeOnterms) newErrors.agreeOnterms = 'Agree on terms and conditions is required'
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextStep = () => {
    if (validateFields()) {
        setLoading(true);
        
        setTimeout(() => {
            setAccountSetup('stepTwo');
            setLoading(false);
        }, 2000);
    }
  };

  const handleSecondSteps = () => {
    if (validateFields()) {
        setLoading(true);
        
        setTimeout(() => {
            setAccountSetup('stepThree');
            setLoading(false);
        }, 2000);
    }
  };

  const handleThirdSteps = () => {
    // console.log('clikced')
    if (validateFields()) {
        setLoading(true);
        
        setTimeout(() => {
            setAccountSetup('stepFour');
            setLoading(false);
        }, 2000);
    }
  };



  const [accountCreate] = useAccountCreationMutation()

  const handleSecondStep = async ()  => {

    // console.log(accountSetup,'ac')

    if (validateFields()) {
        setLoading(true);
        
        setTimeout(() => {
            // setAccountSetup('stepTwo');
            setLoading(false);
        }, 2000);

        if (accountSetup === 'stepFour'){
            const formData = {
                "email": email,
                "firstName": firstName,
                "lastName": lastName ,
                "account_type": "business",
                "dob": dob,
                "gender": "male",
                "nationolity": citizenship,
                "phoneNo": phone,
                "businessName": buisnesName,
                "businessType": businestype,
                "businessRegistrationNumber":  buisnesNumber,
                "dateOfEstablishment": dateOfEstablishment,
                "businessWebsite": web,
                "address": address,
                "city": city,
                "state": state,
                "zipCode": zipCode,
                "estimatedMonthlyBusinessRevenue": estimatedMonthlyBusinessRevenue,
                "estimatedMonthlyExpenses": estimatedMonthlyExpenses,
                "agreeToTerms": true
            }
            // console.log(formData,'formData')
            
            const response = await accountCreate(formData)

            
            if (response.error){
              toast.error(response.error.data.error)
              // console.log('first',response,response.OK)
            }else{
            // toast.error(response.error.data.error)
            navigate('/application',{
              state : {
                email : email
              }
            })
            // console.log('have to redirect application recv page')
            }
        }
        
    }
  };

  const goBackToAccountType = () => {
    navigate('/account-type',{
        state : {
            email
        }
    })
  }

  const goBackToPersonalDetails = () => {
    setAccountSetup('stepOne')
  }

  const goBackToBuisnessDetails = () => {
    setAccountSetup('stepTwo')
  }

  const goBackToBuisnessAddress = () => {
    setAccountSetup('stepThree')
  }

  

  return (
    <div className=''>
      <nav className="navbar py-0 navbarContainer fixed-top d-flex justify-content-center position-relative">
        <Link className="" to="/">
          <img loading='lazy' src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="Logo" width="30" height="30" className="navbarLogo00 mx-auto my-lg-0 my-2" />
        </Link>
      </nav>

      <div className='container-fluid d-flex flex-column justify-content-start fixedHeight001 px-lg-auto px-4'>
        <h1 className="newHeadingss pt-5" >
        Evistia Business Account
        </h1>
        <div className='d-flex gap-3 mx-auto mt-3 '>
        <img
            loading='lazy'
            src={process.env.PUBLIC_URL + "/images/new_back_iconss.svg"}
            alt="Logo"
            width="30"
            height="30"
            className=""
            onClick={accountSetup === "stepOne" ? goBackToAccountType : accountSetup === "stepTwo" ? goBackToPersonalDetails : accountSetup === "stepThree" ? goBackToBuisnessDetails : goBackToBuisnessAddress }
            style={{cursor:"pointer"}}
            />
          <h1 className='acc_type_h'>
            {accountSetup === "stepOne" ? "Personal Details" : accountSetup === "stepTwo" ? "Business Details" : accountSetup === "stepTwo" ? "Business Address" : accountSetup === "stepThree" ? "Business Address" : "Financial Information"}
          </h1>
        </div>

        <div className='row justify-content-center mt-4 '>
          <div className="col-lg-7 col-12 mb-4 px-0 centerContent">
            {accountSetup === "stepOne" ? (
              <div className="row justify-content-center">
                <div className="col-lg-6 col-12">
                  <div className="mb-4">
                    <label htmlFor="firstName" className="new_input_feilds_label">First Name</label>
                    <input
                      type="text"
                      className={`new_input_feilds ${errors.firstName ? 'border-danger' : ''}`}
                      id="firstName"
                      placeholder="First Name"
                      value={firstName}
                      onChange={handleFirstNameChange}
                      required
                      maxLength="100"
                      onBlur={() => handleBlur('firstName')}
                    />
                    {errors.firstName && (
                      <>
                        <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                        <span className='new_error_text ps-2 mb-4'>
                          {errors.firstName}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-12">
                  <div className="mb-4">
                    <label htmlFor="lastName" className="new_input_feilds_label">Last Name</label>
                    <input
                      type="text"
                      className={`new_input_feilds ${errors.lastName ? 'border-danger' : ''}`}
                      id="lastName"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={handleLastNameChange}
                      required
                      maxLength="100"
                      onBlur={() => handleBlur('lastName')}
                    />
                    {errors.lastName && (
                      <>
                        <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                        <span className='new_error_text ps-2 mb-4'>
                          {errors.lastName}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-12">
                  <div className="mb-4">
                    <label htmlFor="dob" className="new_input_feilds_label">Date of Birth</label>
                    <input
                      type="date"
                      className={`new_input_feilds ${errors.dob ? 'border-danger' : ''}`}
                      id="dob"
                      placeholder="DD/MM/YY"
                      value={dob}
                      onChange={handleDobChange}
                      required
                      onBlur={() => handleBlur('dob')}
                    />
                    {errors.dob && (
                      <>
                        <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                        <span className='new_error_text ps-2 mb-4'>
                          {errors.dob}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-12">
                  <div className="mb-4">
                    <label htmlFor="phone" className='new_input_feilds_label'>Phone Number</label>
                    <PhoneInput
                      country={'gb'}
                      value={phone}
                      onChange={handleOnChange}
                      inputProps={{
                        name: 'phone',
                        required: true,
                        onBlur: () => handleBlur('phone'),
                        className: `new_input_feilds_00 ${errors.phone ? 'border-danger' : ''}`
                      }}
                    />
                    {errors.phone && (
                      <>
                        <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                        <span className='new_error_text ps-2 mb-4'>
                          {errors.phone}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-12">
                  <div className="mb-4">
                    <label htmlFor="martialStatus" className="new_input_feilds_label">{t('PACC.S1.lb.4')}</label>
                    <div className={`customSelectOptions ${errors.martialStatus ? 'border-danger' : ''}`} onClick={toggleDropdown1}>
                      <p style={{ margin: '0' }} className={`formInputsM ${errors.martialStatus ? 'border-danger' : ''}`}>{selectedOption1} <input type='hidden' />
                        <i className={`fa fa-caret-${isOpen1 ? 'up' : 'down'} `}></i></p>
                      <img loading='lazy' alt="Vr Line" src="../../../images/Vectorfill.svg" className='imgResponsive ' />
                    </div>
                    {errors.martialStatus && (
                      <>
                        <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                        <span className='new_error_text ps-2 mb-4'>
                          {errors.martialStatus}
                        </span>
                      </>
                    )}
                    <div className="container d-flex justify-content-end pe-0 position-relative">
                      {isOpen1 && (
                        <div className="options contactusLabels">
                          {optionList1.map((option, index) => (
                            <div className="option" key={index} onClick={() => selectOption1(option)}>
                              {option}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                  <div className="mb-4">
                    <label htmlFor="citizenship" className="new_input_feilds_label">{t('PACC.S3.lb.6')}</label>
                    <div className={`customSelectOptions ${errors.citizenship ? 'border-danger' : ''}`} onClick={toggleDropdown}>
                      <p style={{ margin: '0' }} className={`formInputsM ${errors.citizenship ? 'border-danger' : ''}`}>{selectedOption} <i className={`fa fa-caret-${isOpen ? 'up' : 'down'} `}></i></p>
                      <img loading='lazy' alt="Vr Line" src="../../../images/Vectorfill.svg" className='imgResponsive ' />
                    </div>
                    {errors.citizenship && (
                      <>
                        <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                        <span className='new_error_text ps-2 mb-4'>
                          {errors.citizenship}
                        </span>
                      </>
                    )}
                    <div className="container d-flex justify-content-end pe-0 position-relative">
                      {isOpen && (
                        <div className="options contactusLabels">
                          {optionList.map((option, index) => (
                            <div className="option" key={index} onClick={() => selectOption(option)}>
                              {option}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-12">
                  <div className="mx-auto mt-3" style={{ width: '80%' }}>
                    <button type="submit" className={`button ${loading ? 'loading' : ''}`} style={{ "border": "none" }} onClick={handleNextStep}>
                      {loading ? (
                        <div className="spinner"></div>
                      ) : (
                        <span>Next</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ) : accountSetup === "stepTwo" ? (
              <div className="row justify-content-center">
                <div className="col-lg-6 col-12">
                  <div className="mb-4">
                    <label htmlFor="firstName" className="new_input_feilds_label">Business name</label>
                    <input
                      type="text"
                      className={`new_input_feilds ${errors.buisnesName ? 'border-danger' : ''}`}
                      id="buisnesName"
                      placeholder="Registered Name"
                      value={buisnesName}
                      onChange={handleBuisnessNameChange}
                      required
                      maxLength="100"
                      onBlur={() => handleBlur('buisnesName')}
                    />
                    {errors.buisnesName && (
                      <>
                        <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                        <span className='new_error_text ps-2 mb-4'>
                          {errors.buisnesName}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                  
                <div className="col-lg-6 col-12">
                  <div className="mb-4">
                    <label htmlFor="businestype" className="new_input_feilds_label">Business Type</label>
                    <div className={`customSelectOptions ${errors.businestype ? 'border-danger' : ''}`} onClick={toggleDropdown3}>
                      <p style={{ margin: '0' }} className={`formInputsM ${errors.businestype ? 'border-danger' : ''}`}>{selectedOption3} <input type='hidden' />
                        <i className={`fa fa-caret-${isOpen3 ? 'up' : 'down'} `}></i></p>
                      <img loading='lazy' alt="Vr Line" src="../../../images/Vectorfill.svg" className='imgResponsive ' />
                    </div>
                    {errors.businestype && (
                      <>
                        <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                        <span className='new_error_text ps-2 mb-4'>
                          {errors.businestype}
                        </span>
                      </>
                    )}
                    <div className="container d-flex justify-content-end pe-0 position-relative">
                      {isOpen3 && (
                        <div className="options contactusLabels">
                          {optionList3.map((option, index) => (
                            <div className="option" key={index} onClick={() => selectOption3(option)}>
                              {option}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-12">
                    <div className="mb-4">
                      <label htmlFor="countryOfRegistration" className="new_input_feilds_label">Country of Registration</label>
                      <div className='customSelectOptions' onClick={toggleDropdown4}>
                        <p style={{ margin: '0' }} className={`formInputsM ${errors.countryOfRegistration ? 'border-danger' : ''}`}>{selectedOption4} <input type='hidden' />
                          <i className={`fa fa-caret-${isOpen4 ? 'up' : 'down'} `}></i></p>
                        <img loading='lazy' alt="Vr Line" src="../../../images/Vectorfill.svg" className='imgResponsive ' />
                      </div>
                      {errors.countryOfRegistration && (
                        <>
                          <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                          <span className='new_error_text ps-2 mb-4'>
                            {errors.countryOfRegistration}
                          </span>
                        </>
                      )}
                      <div className="container d-flex justify-content-end pe-0 position-relative">
                        {isOpen4 && (
                          <div className="options contactusLabels">
                            {optionList4.map((option, index) => (
                              <div className="option" key={index} onClick={() => selectOption4(option)}>
                                {option}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>


                <div className="col-lg-6 col-12">
                  <div className="mb-4">
                    <label htmlFor="buisnesNumber" className="new_input_feilds_label">Business Registration/Taxation Number</label>
                    <input
                      type="number"
                      className={`new_input_feilds ${errors.buisnesNumber ? 'border-danger' : ''}`}
                      id="buisnesNumber"
                      placeholder="345345"
                      value={buisnesNumber}
                      onChange={handleBuisnessNumberChange}
                      required
                      maxLength="100"
                      onBlur={() => handleBlur('buisnesNumber')}
                    />
                    {errors.buisnesNumber && (
                      <>
                        <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                        <span className='new_error_text ps-2 mb-4'>
                          {errors.buisnesNumber}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-12">
                  <div className="mb-4">
                    <label htmlFor="dateOfEstablishment" className="new_input_feilds_label">Date of Establishment</label>
                    <input
                      type="date"
                      className={`new_input_feilds ${errors.dateOfEstablishment ? 'border-danger' : ''}`}
                      id="dateOfEstablishment"
                      placeholder="DD/MM/YY"
                      value={dateOfEstablishment}
                      onChange={handleBuisnessDoaChange}
                      required
                      onBlur={() => handleBlur('dateOfEstablishment')}
                    />
                    {errors.dateOfEstablishment && (
                      <>
                        <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                        <span className='new_error_text ps-2 mb-4'>
                          {errors.dateOfEstablishment}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-12">
                  <div className="mb-4">
                    <label htmlFor="web" className="new_input_feilds_label">Business Website (if applicable)</label>
                    <input
                      type="text"
                      className={`new_input_feilds ${errors.web ? 'border-danger' : ''}`}
                      id="web"
                      placeholder="Business Website"
                      value={web}
                      onChange={handleBuisnessWebChange}
                      required
                      maxLength="100"
                      onBlur={() => handleBlur('web')}
                    />
                    {errors.web && (
                      <>
                        <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                        <span className='new_error_text ps-2 mb-4'>
                          {errors.web}
                        </span>
                      </>
                    )}
                  </div>
                </div>



                <div className="col-lg-6 col-12">
                  <div className="mx-auto mt-3" style={{ width: '80%' }}>
                    <button type="submit" className={`button ${loading ? 'loading' : ''}`} style={{ "border": "none" }} onClick={handleSecondSteps}>
                      {loading ? (
                        <div className="spinner"></div>
                      ) : (
                        <span>Next</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ) : accountSetup === "stepThree" ? (
                <div className="row justify-content-center">
                  <div className="col-12">
                    <div className="mb-4">
                      <label htmlFor="streetAddress" className="new_input_feilds_label">Street Address</label>
                      <input
                        type="text"
                        className={`new_input_feilds ${errors.address ? 'border-danger' : ''}`}
                        id="streetAddress"
                        placeholder="Street Address"
                        required
                        value={address}
                        onChange={handleAddressChange}
                        onBlur={() => handleBlur('address')}
                      />
                      {errors.address && (
                        <>
                          <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                          <span className='new_error_text ps-2 mb-4'>
                            {errors.address}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
    
                  <div className="col-lg-6 col-12">
                    <div className="mb-4">
                      <label htmlFor="martialStatus" className="new_input_feilds_label">Country</label>
                      <div className='customSelectOptions' onClick={toggleDropdown2}>
                        <p style={{ margin: '0' }} className={`formInputsM ${errors.country ? 'border-danger' : ''}`}>{selectedOption2} <input type='hidden' />
                          <i className={`fa fa-caret-${isOpen2 ? 'up' : 'down'} `}></i></p>
                        <img loading='lazy' alt="Vr Line" src="../../../images/Vectorfill.svg" className='imgResponsive ' />
                      </div>
                      {errors.country && (
                        <>
                          <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                          <span className='new_error_text ps-2 mb-4'>
                            {errors.country}
                          </span>
                        </>
                      )}
                      <div className="container d-flex justify-content-end pe-0 position-relative">
                        {isOpen2 && (
                          <div className="options contactusLabels">
                            {optionList2.map((option, index) => (
                              <div className="option" key={index} onClick={() => selectOption2(option)}>
                                {option}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
    
                  <div className="col-lg-6 col-12">
                    <div className="mb-4">
                      <label htmlFor="state" className="new_input_feilds_label">State</label>
                      <input
                        type="text"
                        className={`new_input_feilds ${errors.state ? 'border-danger' : ''}`}
                        id="state"
                        placeholder="State"
                        required
                        value={state}
                        onChange={handleStateChange}
                        onBlur={() => handleBlur('state')}
                      />
                      {errors.state && (
                        <>
                          <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                          <span className='new_error_text ps-2 mb-4'>
                            {errors.state}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
    
                  <div className="col-lg-6 col-12">
                    <div className="mb-4">
                      <label htmlFor="city" className="new_input_feilds_label">City</label>
                      <input
                        type="text"
                        className={`new_input_feilds ${errors.city ? 'border-danger' : ''}`}
                        id="city"
                        placeholder="City"
                        required
                        value={city}
                        onChange={handleCityChange}
                        onBlur={() => handleBlur('city')}
                      />
                      {errors.city && (
                        <>
                          <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                          <span className='new_error_text ps-2 mb-4'>
                            {errors.city}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
    
                  <div className="col-lg-6 col-12">
                    <div className="mb-4">
                      <label htmlFor="zipCode" className="new_input_feilds_label">Zip Code</label>
                      <input
                        type="number"
                        className={`new_input_feilds ${errors.zipCode ? 'border-danger' : ''}`}
                        id="zipCode"
                        placeholder="Zip Code"
                        required
                        value={zipCode}
                        onChange={handleZipCodeChange}
                        onBlur={() => handleBlur('zipCode')}
                      />
                      {errors.zipCode && (
                        <>
                          <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                          <span className='new_error_text ps-2 mb-4'>
                            {errors.zipCode}
                          </span>
                        </>
                      )}
                    </div>
                  </div>

                  
    
                  <div className="col-lg-6 col-12">
                    <div className="mx-auto mt-3" style={{ width: '80%' }}>
                      <button type="submit" className={`button ${loading ? 'loading' : ''}`} style={{ "border": "none" }} onClick={handleThirdSteps}>
                        {loading ? (
                          <div className="spinner"></div>
                        ) : (
                          <span>Next</span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              ) :  (
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-12">
                    <div className="mb-4">
                      <label htmlFor="firstName" className="new_input_feilds_label"> Estimated Monthly Business Revenue</label>
                      <input
                        type="number"
                        className={`new_input_feilds ${errors.estimatedMonthlyBusinessRevenue ? 'border-danger' : ''}`}
                        id="estimatedMonthlyBusinessRevenue"
                        placeholder="4564645"
                        value={estimatedMonthlyBusinessRevenue}
                        onChange={handleestimatedMonthlyBusinessRevenueChange}
                        required
                        maxLength="100"
                        onBlur={() => handleBlur('estimatedMonthlyBusinessRevenue')}
                      />
                      {errors.estimatedMonthlyBusinessRevenue && (
                        <>
                          <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                          <span className='new_error_text ps-2 mb-4'>
                            {errors.estimatedMonthlyBusinessRevenue}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
            
  
  
                  <div className="col-lg-6 col-12">
                    <div className="mb-4">
                      <label htmlFor="estimatedMonthlyExpenses" className="new_input_feilds_label"> Estimated Monthly Expenses</label>
                      <input
                        type="number"
                        className={`new_input_feilds ${errors.estimatedMonthlyExpenses ? 'border-danger' : ''}`}
                        id="estimatedMonthlyExpenses"
                        placeholder="34534534"
                        value={estimatedMonthlyExpenses}
                        onChange={handleestimatedMonthlyExpensesChange}
                        required
                        maxLength="100"
                        onBlur={() => handleBlur('estimatedMonthlyExpenses')}
                      />
                      {errors.estimatedMonthlyExpenses && (
                        <>
                          <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                          <span className='new_error_text ps-2 mb-4'>
                            {errors.estimatedMonthlyExpenses}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
  
                  
                  <div className="col-12">
                    <p className='agrreOnTermsNewText pt-3 '> {t('PACC.S3.p.1')}
                    </p>

                    <div className="mb-4">
                        <label className="sq-radio textinfo" id="termsConditon" value={agreeOnterms}
                            onChange={handleAgreeOnTermsChange}>{t('PACC.S3.lb.7')}
                            <input
                                type="radio"
                                name="radio-one"
                                id="termsConditon"
                            />
                            <span className="checkmark" ></span>
                        </label>
                        <br></br>
                        {errors.agreeOnterms && (
                        <>
                          <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" style={{ width: "14px" }} />
                          <span className='new_error_text ps-2 mb-4'>
                            {errors.agreeOnterms}
                          </span>
                        </>
                      )}
                    </div>
                  </div>


                  <div className="col-lg-6 col-12">
                    <div className="mx-auto mt-3" style={{ width: '80%' }}>
                      <button type="submit" className={`button ${loading ? 'loading' : ''}`} style={{ "border": "none" }} onClick={handleSecondStep}>
                        {loading ? (
                          <div className="spinner"></div>
                        ) : (
                          <span>Submit</span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              ) }
          </div>
        </div>

        
      </div>

      <div className="row px-0 bottom-fixed zIndex w-100">
          <div className='row px-0 '>
            <div className="stepsRow px-0 mb-3 d-flex gap-1 justify-content-center">
              <img loading='lazy' src={process.env.PUBLIC_URL + "/images/check-mark 1.svg"} alt="Logo" width="16" height="16" className="" />
              <h1 className='bottom_steps_h_black mb-0'>
                Account Setup
              </h1>
            </div>
            <div className="stepsRow px-0 mb-3 d-flex gap-1 justify-content-center">
              <img loading='lazy' src={process.env.PUBLIC_URL + "/images/check-mark 1.svg"} alt="Logo" width="16" height="16" className="" />
              <h1 className='bottom_steps_h_black'>
                Bank Account
              </h1>
            </div>
            <div className="stepsRow px-0 mb-3 d-flex gap-1 justify-content-center">
              {accountSetup === "stepOne" ? "" : <img loading='lazy' src={process.env.PUBLIC_URL + "/images/check-mark 1.svg"} alt="Logo" width="16" height="16" className="" /> }
              <h1 className={`${accountSetup === "stepOne" ? "bottom_steps_h_blue" : "bottom_steps_h_black"}`}>
                Personal Information
              </h1>
            </div>

            
            
            {accountSetup === "stepOne" ? "" :
                accountSetup === "stepTwo" ||  accountSetup === "stepThree" || accountSetup === "stepFour" ? 
                <div className="stepsRow px-0 mb-3 d-flex gap-1 justify-content-center">
                {accountSetup === "stepOne"  || accountSetup === "stepTwo"  ? "" :  <img loading='lazy' src={process.env.PUBLIC_URL + "/images/check-mark 1.svg"} alt="Logo" width="16" height="16" className="" /> }
                <h1 className={`${accountSetup === "stepTwo"  ? "bottom_steps_h_blue" : "bottom_steps_h_black"}`}>
                Business Information
                </h1>
              </div> :  "" 
            }
            { accountSetup === "stepThree" || accountSetup === "stepFour" ? 
               <div className="stepsRow px-0 mb-3 d-flex gap-1 justify-content-center">
                  {accountSetup === "stepOne"  || accountSetup === "stepTwo"  ? "" : accountSetup === "stepFour" ? <img loading='lazy' src={process.env.PUBLIC_URL + "/images/check-mark 1.svg"} alt="Logo" width="16" height="16" className="" /> : ""}
                  <h1 className={`${accountSetup === "stepThree"  ? "bottom_steps_h_blue" : "bottom_steps_h_black"}`}>
                        Address Information
                   </h1>
              </div> :  "" } {
                accountSetup === "stepFour" ? 
                  <div className="stepsRow px-0 mb-3 d-flex gap-1 justify-content-center">
                  <h1 className='bottom_steps_h_blue'>
                  Financial Information
                  </h1>
                </div>  : ""
            }
          </div>
          <div className='row px-0 buttom_steps_row'>
            <div className="stepsRow px-0 fill_up">
            </div>
            <div className="stepsRow px-0 fill_up">
            </div>
            <div className="stepsRow px-0 fill_up">
            </div>
            {accountSetup === "stepOne" ? "" :
              <div className="stepsRow px-0 fill_up">
              </div>
            }
            {accountSetup === "stepOne" || accountSetup === "stepTwo" ? "" :
              <div className="stepsRow px-0 fill_up">
              </div>
            }
             {accountSetup === "stepOne" || accountSetup === "stepTwo" || accountSetup === "stepThree" ? "" :
              <div className="stepsRow px-0 fill_up">
              </div>
            }
          </div>
        </div>
    </div>
  );
}

export default StepOne;
