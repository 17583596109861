import React from 'react'
import AnimatedPage from '../AnimatedPage';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ScrollToTop from '../scrollToTop';

export default function RegisrterPersonalAccStepThree({ formData, handleInputChangeInThreeStep, error, errorId }) {
    const { t } = useTranslation()
    //consolelog('Form Data',formData)


    // Custom Select Options |
    const optionList = [
        t('PACC.S3.lb.DD.1'),
        t('PACC.S3.lb.DD.2'),
        t('PACC.S3.lb.DD.3'),
        t('PACC.S3.lb.DD.4'),
        t('PACC.S3.lb.DD.5'),
        t('PACC.S3.lb.DD.6'),
        t('PACC.S3.lb.DD.7'),
        t('PACC.S3.lb.DD.8'),
    ];

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(t('PACC.S3.ph.3'));

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectOption = (option) => {
        formData.citizenship = option
        setSelectedOption(option);
        setIsOpen(false);
    };


    return (

        <AnimatedPage>
            <ScrollToTop />
            <div>
                {/* <div className='container-fluid'>
            <div className='mainContainerSignups'> */}
                <div className='row '   >
                    <div className='d-flex justify-content-center'>
                        <img loading='lazy' alt="Vr Line" src="../../../images/p3.svg" className='imgResponsive px-5 upperImgRegister hrSmHide' />
                    </div>

                    <h2 className='SignupHeadings'>
                        {t('PACC.S3.H')}
                    </h2>
                    <img loading='lazy' alt="Vr Line" src="../../../images/Vector 4.svg" className='imgResponsive' />
                </div>
                <div className='row pt-5'   >
                    <div className='col-12'>
                        <div className="mb-5 px-3">
                            <label htmlFor="email" className="form-label contactusLabels pb-2">{t('PACC.S3.lb.1')}</label>
                            <input
                                type="text"
                                className="form-control formInputs"
                                id="address"
                                placeholder={t('PACC.S3.ph.1')}
                                value={formData.streetAddress}
                                onChange={(e) => handleInputChangeInThreeStep('streetAddress', e.target)}
                                required
                            />

                        </div>
                    </div>


                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                        <div className="mb-5 px-3">
                            <label htmlFor="email" className="form-label contactusLabels pb-2">{t('PACC.S3.lb.2')}</label>
                            <input
                                type="text"
                                className="form-control formInputs"
                                id="city"
                                placeholder={t('PACC.S3.ph.1')}
                                value={formData.city}
                                onChange={(e) => handleInputChangeInThreeStep('city', e.target)}
                                required
                            />
                            {errorId === 'city' ? <span className='inputFiledError pt-1'>{error}</span> : ''}
                        </div>
                    </div>

                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                        <div className="mb-5 px-3">
                            <label htmlFor="email" className="form-label contactusLabels pb-2">{t('PACC.S3.lb.3')}</label>
                            <input
                                type="text"
                                className="form-control formInputs"
                                id="myState"
                                placeholder={t('PACC.S3.ph.1')}
                                value={formData.state}
                                onChange={(e) => handleInputChangeInThreeStep('state', e.target)}
                                required
                            />
                            {errorId === 'myState' ? <span className='inputFiledError pt-1'>{error}</span> : ''}
                        </div>
                    </div>

                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                        <div className="mb-5 px-3">
                            <label htmlFor="email" className="form-label contactusLabels pb-2">{t('PACC.S3.lb.4')}</label>
                            <input
                                type="text"
                                className="form-control formInputs"
                                id="zipCode"
                                placeholder='1234'
                                value={formData.zipCode}
                                onChange={(e) => handleInputChangeInThreeStep('zipCode', e.target)}
                                required
                            />
                            {errorId === 'zipCode' ? <span className='inputFiledError pt-1'>{error}</span> : ''}
                        </div>
                    </div>

                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                        <div className="mb-5 px-3">
                            <label htmlFor="email" className="form-label contactusLabels pb-2">{t('PACC.S3.lb.5')}</label>
                            <input
                                type="text"
                                className="form-control formInputs"
                                id="country"
                                placeholder={t('PACC.S3.ph.2')}
                                value={formData.country}
                                onChange={(e) => handleInputChangeInThreeStep('country', e.target)}
                                required
                            />
                            {errorId === 'country' ? <span className='inputFiledError pt-1'>{error}</span> : ''}
                        </div>
                    </div>

                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                        <div className="mb-5 px-3">
                            <label htmlFor="email" className="form-label contactusLabels pb-2">{t('PACC.S3.lb.6')}</label>
                            <div className='customSelectOptions ' onClick={toggleDropdown}>
                                <p style={{ margin: '0' }} className='formInputsM'>{selectedOption} <i className={`fa fa-caret-${isOpen ? 'up' : 'down'} `}></i></p>
                                <img loading='lazy' alt="Vr Line" src="../../../images/Vectorfill.svg" className='imgResponsive ' />
                            </div>
                            <div className="container d-flex justify-content-end pe-0">
                                {isOpen && (
                                    <div className="options contactusLabels">
                                        {optionList.map((option, index) => (
                                            <div className="option" key={index} onClick={() => selectOption(option)}>
                                                {option}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <p className='textinfo pt-3 ps-4 ms-1 mb-5'> {t('PACC.S3.p.1')}
                    </p>

                    <div className="form-check mb-5">
                        <label className="sq-radio textinfo" id="termsConditon" value={formData.agreeToTerms}
                            onChange={(e) => handleInputChangeInThreeStep('agreeToTerms', e.target)}>{t('PACC.S3.lb.7')}
                            <input
                                type="radio"
                                name="radio-one"
                                id="termsConditon"

                            />
                            <span className="checkmark" ></span>
                        </label>
                    </div>

                    {/* <div className="S5BtnDiv  ps-4 ms-1 mb-5 pb-5">
                      <button className='s5Btn'> <span className='s5Btntxt'> Submit </span> 
                      </button> 
                    </div> */}

                </div>
            </div>
        </AnimatedPage>
        //     </div>
        // </div>

    )
}
