import React, { useState , useRef} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { storeToken } from '../../../../services/LocalStorageService';
import {useRegisterUserMutation , useVerifyEmailMutation ,useResendOtpMutation } from '../../../../services/userAuthApi';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';


function Signup() {

  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [isOtpError, setIsOtpErorr] = useState(false)
  // const [isEmailVerified, setIsEmailVerified] = useState(false)
  // const [isResetFormSubmit, setIsResetFormSubmit] = useState(false)
  const [beforeEmail, setBeforeEmail] = useState(true)
  const [serror, showErorr] = useState('')
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [stepProcess,setStepProcess] = useState('initial')
  const [eyeIcon, setEyeIcon] = useState(true);
  const [eyeIcon1, setEyeIcon1] = useState(true);


  const inputRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  // console.log(email,'email')
// Function to handle input change for each digit
const handleInputChange = (index, event) => {
  const { value } = event.target;
  if (/^\d*$/.test(value) && value.length <= 1) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Move focus to the next input field if input is not empty
      if (index < code.length - 1 && value.length === 1) {
          inputRefs.current[index + 1].current.focus();
      }
  }
  setIsOtpErorr(false);
};

const handleKeyDown = (index, event) => {
  if (event.key === 'Backspace' && code[index] === '') {
      if (index > 0) {
          inputRefs.current[index - 1].current.focus();
      }
  }
};



const [optResendApi ] = useResendOtpMutation() 


const resendOTPHandler = async () => {
  setLoading(true)
  setIsOtpErorr(false)
  const resendOTPData = {'email':beforeEmail}
  const res = await optResendApi(resendOTPData)

  // if (res) {
  //   console.log(res,'res is here')
  // }

  if (res.error){
    setLoading(false)
    showErorr(res.error.data.message)
    setIsOtpErorr(true)
  }else if (res.data){
  toast.success(`${res.data.message}`);
  setCode(['', '', '', '', '', '']);
  showErorr('');
  setIsOtpErorr(false)
  setLoading(false)
  }
   
}


const [activateUser] = useVerifyEmailMutation();

const handleFormSubmitofOTP = async (event) => {
    // console.log(code,'OTO',beforeEmail)

    setIsOtpErorr(false)
    event.preventDefault();

    if (beforeEmail && code){
      // setLoading(true)
      const otpnumber = code.join('')
      if (otpnumber && otpnumber.length === 6 ){
        setLoading(true)
        const data = {'email':beforeEmail, 'otp':String(otpnumber)}
        // console.log(data,'data is here')
        const res = await activateUser(data)
        // if (res){
        //   console.log(res,'res is here')
        // }
        if (res.error){
            setLoading(false)
            // console.log(res.error.data.message,'error is here')
            showErorr(res.error.data.message)
            setIsOtpErorr(true)
        }else if(res.data) {
            // setVerifyProcess(false)
            toast.success(`${res.data.message}`);
            setIsOtpErorr(false)
            setLoading(false)

            // console.log(beforeEmail,'emailsssssss')
            navigate('/account-type/', {
              state: {
                email : beforeEmail,
              },
            });
        }
      }else{
        showErorr('OTP Required')
        setIsOtpErorr(true)
      }
    }else{
      showErorr('OTP Required')
      setIsOtpErorr(true)
    }
};


  const goBackStep = () => {
    setStepProcess('initial')
    setLoading(false)
  }

  const goBack = () => {
    navigate(-1)
  }

  const [registerUser] = useRegisterUserMutation();

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!passwordRegex.test(password)) {
      setPasswordError('The passwords must contain uppercase, lowercase & a special character')
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('The passwords do not match.');
      setLoading(false);
      return;
    }
    // console.log(beforeEmail,email)
    if (email && password) {
      const data = { email, password };
      const res = await registerUser(data);

      // if (res){
      //   console.log(res,'initial res of signup')
      // }

      if (res.error) {
        setError(res.error.data.error);
        setLoading(false);
      } else if (res.data) {
        if (res.data.error === 'Email verified but account type not set.' ){
          navigate('/account-type/', {
            state: {
              email,
            },
          });
        }else{
          setEmail('');
          setPassword('');
          setConfirmPassword('');
          setError('');
          setConfirmPasswordError('')
          setPasswordError('')
          storeToken(res.data);
          toast.success(`${res.data.message}`);
          setBeforeEmail(email)
          setStepProcess('emailVerification')
          
          setLoading(false);
        }


      }
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError('');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError('');
    setPasswordError('')
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setError('');
    setConfirmPasswordError('')
  };

  const eyeIconHandler = () => {
    setEyeIcon(!eyeIcon);
  };

  const eyeIcon1Handler = () => {
    setEyeIcon1(!eyeIcon1);
  };

  return (
    <div>
      <div className='container-fluid LoginMainDiv ps-0 position-relative'>
        <div className='row ps-0'>
          <div className='col-6 px-0 LoginMainDivBg002 d-lg-block d-none ps-0'>
            <div className='d-flex flex-column justify-content-start new_login_main_div h-100'>
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_back_icon.svg"} alt="Back" className="back_icon" onClick={stepProcess === "initial" ? goBack : goBackStep}/>
              <div className='my-auto ps-5 ms-xl-5'>
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_sign_up.svg"} alt="Sign Up" className="new_login_logo01 mb-3" />
                <h1 className='sign_up_new_heading'>
                  Welcome to Evistia - <br />
                  <span className='sign_up_new_heading_b'>
                    The Future of Banking
                  </span>
                </h1>
                <p className='sign_up_new_para'>
                  Let’s start with your account details
                </p>
              </div>
            </div>
          </div>

          {
            stepProcess === 'initial' ? 
              <div className='col-lg-6 col-12 rightSideDivLogin py-md-5 py-0'>
                <div className='d-flex justify-content-center'>
                  <Link to="/" style={{ margin: "auto", textAlign: "center" }}>
                    <img loading='lazy' alt='Logo'  src="../images/ev-logo.png" className='manageLogoW pt-lg-3 pt-3' />
                  </Link>
                </div>
                <h2 className='loginTitle'>
                  {t('navbar.link_6.3')}
                </h2>
                {error && (
                  <div className='error_login_div d-flex gap-3 justify-content-center align-items-center mx-auto mt-3'>
                    <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" />
                    <span className='new_error_text'>
                      {error}
                    </span>
                  </div>
                )}
                <form className='SignInForm  mt-4' onSubmit={handleFormSubmit} autoComplete="off">
                  <div className="mb-4">
                    <label htmlFor="email" className="new_input_feilds_label"> {t('LOGIN.lb.1')}</label>
                    <input
                      type="email"
                      className="new_input_feilds"
                      id="email"
                      placeholder={t('LOGIN.ph.1')}
                      value={email}
                      onChange={handleEmailChange}
                      required
                      maxLength="50"
                      autoComplete="new-email"
                    />
                  </div>
                  
                  <div className={`${passwordError ? "" : "mb-4"}`} style={{ position: "relative" }}>
                    <label htmlFor="password" className="new_input_feilds_label">{t('LOGIN.lb.2')}</label>
                    <input
                      type={eyeIcon ? "password" : "text"}
                      className={`${passwordError ? "border-danger new_input_feilds" : "new_input_feilds"}`}
                      id="password"
                      placeholder={t('LOGIN.ph.2')}
                      value={password}
                      onChange={handlePasswordChange}
                      required
                      maxLength="50"
                      autoComplete="new-password"
                    />
                    <img
                      loading='lazy'
                      src={process.env.PUBLIC_URL + (eyeIcon ? "/images/eye.svg" : "/images/eye-crossed.svg")}
                      alt="Toggle visibility"
                      className="eyeIcon"
                      onClick={eyeIconHandler}
                    />
                  </div>

                  {passwordError && (
                    <>
                    <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon " style={{width:"14px"}} />
                    <span className='new_error_text ps-2 mb-4'>
                      {passwordError}
                    </span>
                    </>
                  )}

                  <div className={`${confirmPasswordError ? "" : "mb-5"}`} style={{ position: "relative" }}>
                    <label htmlFor="confirm-password" className="new_input_feilds_label">{t('PACC.S2.lb.4')}</label>
                    <input
                      type={eyeIcon1 ? "password" : "text"}
                      className={`${confirmPasswordError ? "border-danger new_input_feilds" : "new_input_feilds"}`}
                      id="confirm-password"
                      placeholder={t('LOGIN.ph.2')}
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      required
                      maxLength="50"
                      autoComplete="new-password"
                    />
                    <img
                      loading='lazy'
                      src={process.env.PUBLIC_URL + (eyeIcon1 ? "/images/eye.svg" : "/images/eye-crossed.svg")}
                      alt="Toggle visibility"
                      className="eyeIcon"
                      onClick={eyeIcon1Handler}
                    />
                  </div>
                  {confirmPasswordError && (
                    <>
                    <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon " style={{width:"14px"}} />
                    <span className='new_error_text ps-2 mb-4'>
                      {confirmPasswordError}
                    </span>
                    </>
                  )}

                  <button type="submit" className={`button ${loading ? 'loading' : ''}`} style={{"border":"none"}}>
                    {loading ? (
                      <div className="spinner"></div>
                    ) : (
                      <span>Next</span>
                    )}
                  </button>
                </form>
                  <p className='BottomText mt-3' >
                  Already have an account ? <span ><Link to="/login" style={{cursor:'pointer',color:'#2645E0'}}> Login </Link>  </span> 
                  </p>
              </div> 
            : stepProcess === "emailVerification" ? <div className='col-xxl-6 col-xl-6 col-lg-6  rightSideDivLogin py-md-5 py-0 '  >
            <div className='d-flex justify-content-center '>
                <img loading='lazy' alt='Logo' src={process.env.PUBLIC_URL + "/images/logo.svg" }   className='imgResponsive pt-lg-3 pt-5' onClick={goBackStep}/>
              </div>
              <p className='newOtpHeading mt-lg-5 mt-4'> 
              OTP Verification
              </p>
              <p className='newEmailTxt mt-lg-5 mt-4'>
              A 6-digit OTP has been sent to the email <br className='d-lg-block d-none'></br> {beforeEmail && beforeEmail} 
              </p>

              {isOtpError && (
                  <div className='error_login_div d-flex gap-3 justify-content-center align-items-center mx-auto mt-4'>
                    <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg"} alt="Error" className="back_icon" />
                    <span className='new_error_text'>
                      {serror}
                    </span>
                  </div>
              )}
              <form onSubmit={handleFormSubmitofOTP} className='SignInForm  mt-3' style={{width:"75%",margin:"auto"}}>
                <div className="mb-2">
                    <div className="code-input-container pb-4">
                        {code.map((digit, index) => (
                            <input
                                key={index}
                                ref={inputRefs.current[index]}
                                type="text"
                                value={digit}
                                maxLength="1"
                                onChange={(e) => handleInputChange(index, e)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                className={`${isOtpError ? 'otpInputError border-danger' : ""}`}
                            />
                        ))}
                    </div>
                </div>

      <div className="mx-auto mt-3" style={{ width: '80%' }}>
        <button type="submit" className={`button ${loading ? 'loading' : ''}`} style={{"border":"none"}} onClick={handleFormSubmitofOTP}>
            {loading ? (
              <div className="spinner"></div>
            ) : (
              <span>Verify Email</span>
            )}
          </button>
        
      </div>
    <div className='BottomTextDiv'>
        <p className='BottomText mt-4' style={{ color: '#2645E0' }}>
            <span onClick={resendOTPHandler} style={{ cursor: 'pointer', color: '#2645E0' }}>
                Resend OTP
            </span>
        </p>
        <p className='BottomText'>
            {t('LOGIN.p.2')} <span><Link to="/sign-up" style={{ cursor: 'pointer', color: '#2645E0' }}>{t('LOGIN.p.3')}</Link></span>
        </p>
    </div>
              </form>

            </div>
            : ""
          }

        </div>


        <div className="row px-0 upper_row position-absolute bottom-0 w-100">
          <div className='row px-0 '>
            <div className="col-3 px-0 mb-3">
              <h1 className='bottom_steps_h'>
                Account Setup
              </h1>
            </div>
          </div>
          <div className='row px-0 buttom_steps_row'>
            <div className="col-3 px-0 fill_up">
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
