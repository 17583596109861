import React,{useState,useEffect} from 'react'
import {Link, useNavigate } from 'react-router-dom';
import { usePasswordChangeMutation } from '../../../services/userAuthApi';


export default function SetNewPassword() {
      const [loading , setLoading ] = useState(false)

        const [email,setEmail] = useState()
        const [isErrors, setIsErorr] = useState(false)
        const [resetPassword] = usePasswordChangeMutation();

        const getEmailFromLocalStorage = () => {
          return localStorage.getItem('userEmail');
        };
          
        useEffect(() => {
            const storedEmail = getEmailFromLocalStorage();
            if (storedEmail) {
              setEmail(storedEmail);
            }
        }, [email]);

        const navigate = useNavigate();

        const goBack = () => {
        navigate('/login'); // This navigates back one step in the application's history
        };

        const [password, setPassword] = useState('')
        const [confirmPassword, setconfirmPassword] = useState('')
        const [error, showErorr] = useState('')


        const handlePasswordChange = (e) => {
            setPassword(e.target.value);
            showErorr(''); // Clear the error message when password changes
        setIsErorr(false)

        };

        const handleConfirmPasswordChange = (e) => {
            setconfirmPassword(e.target.value);
        showErorr(''); // Clear the error message when email changes
        setIsErorr(false)

        };
    
        const handleFormSubmit = async (event) => {
          event.preventDefault();
          if (password && confirmPassword){
              setLoading(true)
                if (password !== confirmPassword){
                    showErorr("Password and confirm password doesn't matched ")
                    setIsErorr(true)
                    setLoading(false)

                }else{
                    const resetData = {email:email , new_password:password }
                    // console.log(resetData,'data is here')
                    const res = await resetPassword(resetData)
                    showErorr('')
                    if (res.error){
                      // console.log(res,'res')
                          showErorr(res.error.data.message)
                      setLoading(false)

                    }else {
                        if (res.data.message) {
                      // console.log(res,'res')
                          setLoading(false)

                            navigate('/password-success')
                        }
                        showErorr('')

                      }
                }

            }
            };
       
      return (
        <div>
          <div className='container-fluid LoginMainDiv ps-0' >
            <div className='row ps-0'>
            <div className='col-6 px-0 LoginMainDivBg001 d-lg-block d-none ps-0'  >
              <div className='d-flex flex-column justify-content-between new_login_main_div h-100'>
               
               <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_back_icon.svg" } alt="new IMg" className="back_icon" onClick={goBack}/>


                <div className=''>
                  <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_login_logo.svg" } alt="new IMg" className="new_login_logo mb-3" />

                  <h1 className='new_heading'>
                  The Future of Banking
                  </h1>
                </div>
              </div>


          </div>
              <div className='col-xxl-6 col-xl-6 col-lg-6  rightSideDivLogin py-md-5 py-0 '>
                  <div className='d-flex justify-content-center'  >
                  <Link to="/" style={{ margin: "auto", textAlign: "center" }}>
                    <img loading='lazy' alt='Logo'  src="../images/ev-logo.png" className='manageLogoW pt-lg-3 pt-3' />
                  </Link>
                  </div>
    
                  <h2 className='loginTitle'>
                  Set new password
                  </h2>
                  <p className='subTextInForgotPasswordPage px-5'>
                  Your new password must be different to <br></br> previously used passwords.
                  </p>

                  {
                    isErrors ? 
                      <div className='error_login_div d-flex gap-3 justify-content-start align-items-center mx-auto my-4'>
                      <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg" } alt="new IMg" className="back_icon" />
                        <span className='new_error_text'>
                          {error}
                        </span>
                      </div> :
                      ""
                  }
                  {/* <p className='formError pt-3' >
                    {error}
                  </p>  */}
                <form onSubmit={handleFormSubmit} className='SignInForm'>
                 <div className="mb-5">
                    <label htmlFor="message" className="new_input_feilds_label"> New Password </label>
                    <input
                        type="password"
                        className="new_input_feilds"
                        id="Password"
                        placeholder="***********"
                        value={password}
                        onChange={handlePasswordChange} // Use the new event handler
                        required
                        maxLength="50"
                    />
                </div>

                <div className="mb-5">
                    <label htmlFor="message" className="new_input_feilds_label">Confirm Password</label>
                    <input
                        type="password"
                        className="new_input_feilds"
                        id="Password"
                        placeholder="***********"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange} // Use the new event handler
                        required
                        maxLength="50"
                    />
                </div>
                  <div className="authSubmitBtn mt-5 " style={{width:'100%'}}>
                  <button type="submit" className={`button ${loading ? 'loading' : ''}`} style={{ "border": "none" }} >
                  {loading ? (
                    <div className="spinner"></div>
                  ) : (
                    <span>Reset Password</span>
                  )}
                </button>
                    {/* <button className='s5BtnLogin'> <span className='s5Btntxt'>Reset Password </span> 
                    </button>  */}
                  </div>

                  <div className='d-flex gap-2 align-items-center backToPage ' onClick={goBack}>
                  <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Group 6.svg" } alt="new IMg" className="" />
                  <p className='m-0 backText'>
                  Back to log in
                  </p>
                  </div>
    
                  </form>
              </div>
            </div>
          </div>
    
    
    
        </div>
      ) 
}
