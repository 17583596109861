import React, { useState } from "react"

function DropdownItem({ text, onSelect }) {
    return (
      <button className="dropdown-item" onClick={() => onSelect(text)}>
        {text}
      </button>
    );
  }


function LegalTermsPolicy() {
    const [dropDown,showDropDown] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);

    const dropDownHandle = () => {
        showDropDown(!dropDown)
    }

    const handleOptionSelect = (optionText, ) => {
        setSelectedOption({ text: optionText});
    };




    const data = [
        {
            id: 1,
            title: "Products",
            descritption: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id ipsum eu risus pharetra tristique. Vestibulum eu quam sed mi vestibulum accumsan.</p>",
          },
        {
          id: 2,
          title: "Products",
          descritption: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id ipsum eu risus pharetra tristique. Vestibulum eu quam sed mi vestibulum accumsan.</p>",
        },
        {
            id: 3,
            title: "Products",
            descritption: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id ipsum eu risus pharetra tristique. Vestibulum eu quam sed mi vestibulum accumsan.</p>",
          },
        {
          id: 4,
          title: "Products",
          descritption: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id ipsum eu risus pharetra tristique. Vestibulum eu quam sed mi vestibulum accumsan.</p>",
        },
    ]

    const [active,setActive] = useState(false)
    const handleOnCLick =()=>{
        // setActive(!active)
    }


  return (
    <>
        <div className='homepageContainerP2 py-5 mt-5' >
            <div className='container-fluid mt-5 mb-4'>
                <div className='row jusify-content-center pr_rem4'>
                    <h3 className='detailsPageH text-center '>
                    TERMS & POLICIES
                    </h3>
                </div>
            </div>
        </div>




        <div className='ps-3 pe-md-5 pe-3 mt-2' >
            <div className='container-fluid'>
            <div className='row jusify-content-center '>
            <div className='col-lg-3 col-md-4 col-sm-12 col-12'>
                <div className=' countrySelect  d-flex  align-items-center justify-content-between mb-4' >
                            <div className='d-flex gap-2'>
                                <h3 className='mb-0 swapTitle'>{selectedOption?.text || 'United Kingdom(UK)'}</h3>
                            </div>
                            <img loading='lazy' src="../images/WHPArrowD.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow"/>
                            <div className={`dropdown-menu CSwapDropDown ${dropDown ? 'show' : ''}`}  onMouseLeave={() => showDropDown(false)}>
                            <DropdownItem text="Country 1"  onSelect={handleOptionSelect} />
                            <DropdownItem text="Country 2"  onSelect={handleOptionSelect} />
                            <DropdownItem text="Country 3"  onSelect={handleOptionSelect} />
                            <DropdownItem text="Country 4"  onSelect={handleOptionSelect} />
                            <DropdownItem text="Country 5"  onSelect={handleOptionSelect} />
                            <DropdownItem text="Country 6"  onSelect={handleOptionSelect} />
                            <DropdownItem text="Country 7"  onSelect={handleOptionSelect} />

                        </div>
                </div>
                <div className='sidebarLinkCareers ps-md-2 ps-0'>
                    <div className='sideBar'>
                        <a className={`active`}>
                        All Categories
                    </a>
                    <a className={`${active === true ? 'active' : ""}`} onClick={handleOnCLick}>
                    Personal
                    </a>
                    <a className={`${active === true ? 'active' : ""}`} onClick={handleOnCLick}>
                    Business
                    </a>
                    <a className={`${active === true ? 'active' : ""}`} onClick={handleOnCLick}>
                    Policies
                    </a>
                    <a className={`${active === true ? 'active' : ""}`} onClick={handleOnCLick}>
                    Transparency
                    </a>
                    </div>
                </div>
            </div>
            <div className='col-lg-9 col-md-8 col-sm-12 col-12'>
            {data.map((element) => (
            <div className='row'>
            <img loading='lazy' src="../../images/Vector 5 (1).png" alt="Section One Right Side Images" className="mb-md-3 mb-0"/>
            <div className="accordion " id="accordionPanelsStayOpenExample" key={element.id}>
                <div className="accordion-item" >
                    <h2 className="accordion-header accordionHeader accordionCustomColor "  id={`heading${element.id}`}>
                    <button className="accordion-button careerAccordionButton flex-column align-items-start accordionCustomColor"  type="button" data-bs-toggle="collapse"   
                        data-bs-target={`#collapse${element.id}`}
                        aria-expanded="true"
                        aria-controls={`collapse${element.id}`}>
                    <p className='py-1 accordian_heading'>  
                    {element.title}
                    </p>
                        {/* <p className='jobLocation'>
                            {element.location} {'\u00a0'} - {'\u00a0'} {element.time}
                        </p> */}
                    </button>
                    </h2>
                    <div  className="accordion-collapse collapse" id={`collapse${element.id}`} aria-labelledby={`heading${element.id}`}>
                    <div className="accordion-body accordionCustomColorBody">
                        <row className="accordionBodyTextDiv">
                            <div className='httpTxtDiv' dangerouslySetInnerHTML={{ __html: element.descritption }} />
                        
                            <div className='httpTxtDiv' dangerouslySetInnerHTML={{ __html: element.about }} />
                        </row>
                    </div>
                    </div>
                </div>
            </div>
            </div>
            ))}
            </div>
            </div>
            </div>
        </div>

    </>

  )
}

export default LegalTermsPolicy