import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'bootstrap/dist/css/bootstrap.min.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfUploader({ setbankStatement }) {
  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState(null);

  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      setbankStatement(file); // Assuming setBankStatement is a prop function to lift state up
    }
  };
  const downloadPdf = (event) => {
    // Stop event propagation
    event.stopPropagation();
  
    // Create a URL for the file
    const fileURL = URL.createObjectURL(file);
    // Create a new anchor element
    const link = document.createElement('a');
    // Set the href and download attributes for the anchor element
    link.href = fileURL;
    link.download = file.name; // or any other name you want
    // Append the anchor to the body
    document.body.appendChild(link);
    // Trigger click to download
    link.click();
    // Clean up to avoid memory leaks
    URL.revokeObjectURL(fileURL);
    document.body.removeChild(link);
  };
  
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col text-center">
          <div
            className="d-flex align-items-center justify-content-center pdfViewBox"
            style={{ width: '150px', height: 'auto', cursor: 'pointer', }}
            onClick={() => document.getElementById('fileInput').click()}
          >
            <input
              id="fileInput"
              type="file"
              style={{ display: 'none' }}
              accept=".pdf"
              onChange={onFileChange}
            />
            {!file && (
                 <div className='d-flex flex-column justify-content-center align-items-center gap-3'>
                  <img className='mt-4' loading='lazy' src={process.env.PUBLIC_URL + "/images/upload.svg" } alt="Logo" width="56" height="56" data-bs-toggle="modal" data-bs-target="#exampleModal" style={{cursor:"pointer"}}/>
                    <p className='AcPwLabels'>Select a PDF file</p>
                 </div>
            )}
            {file && (
              <div style={{ textAlign: 'left', width: '230px' }}>
                <div className='pdfViewr'>
                  <img loading='lazy' src={process.env.PUBLIC_URL + "/images/pdf.png" } alt="Logo" width="150" height="150"  style={{cursor:"pointer"}}/>
                </div>
                <div className="file-info bottomDetailDiv">
                  <div className='d-flex justify-content-between'>
                    <div>
                      <p className="file-name m-0 AcPwLabels">{file.name}</p>
                      <p className='mb-0 AcPwLabels'>{Math.round(file.size / 1024)} KB</p>
                    </div>
                    <div className='d-flex align-items-center'>
                    <img
                      loading='lazy'
                      src={process.env.PUBLIC_URL + "/images/download.svg"}
                      alt="Download"
                      width="24"
                      height="24"
                      style={{cursor:"pointer"}}
                      onClick={downloadPdf}  // Add the onClick event handler here
                    />
                  </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
