import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import UpperNav from './UpperNav';
import SearchCompanyForBills from './SearchCompanyForBills';
import ConsumerNumberForBills from './ConsumerNumberForBills';
import FInalBillDetails from './FInalBillDetails';
function BiilsAndPayments() {
    const location = useLocation();

    const [stepCount, setStepCount] = useState(1)
    const [name, setname] = useState()
    const [consumerNumber, setconsumerNumber] = useState()
    const [amount, setamount] = useState(2819)




    const navigate = useNavigate()
    const handleNextStep = (name) => {
        setStepCount(stepCount+1)
        setname(name)
      }

    const paySuccess = () => {
        navigate('/wallet/bill-paid-confirmation', {state : {consumerNumber,name,amount}})
    }
    return (
        <div className='sideBarMainContent '>
        <div className='WMainDiv '>
        <UpperNav />
    
        
        <div className='row mt-5 justify-content-center'>
          <div className='col-xxl-5 col-lg-8 col-12'>
              <div className='fiatTransferCard mt-md-5 mt-2 ' >
                <div className='cardUpperRow mb-3'>
                    <div className='col-12'>
                        <h3>
                        {
                            stepCount === 1 ? 
                                "Utility Bills"
                            :  stepCount === 4 ?
                                "Review Bill Payment"
                            :    
                                name
                        }
                        </h3> 
                    </div>
                </div>
                {
                    stepCount === 1 ? 
                    <div className='row'>
                    <div className='col-4 d-flex justify-content-center mb-4 ' >
                        <div className='paymentCardBills' onClick={()=>{handleNextStep('Electricity')}}>
                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Group 1008.svg" } alt="User Image" className="" />
                            <p> 
                            Electricity
                            </p>
                        </div>
                    </div>
                    <div className='col-4 d-flex justify-content-center mb-4' >
                        <div className='paymentCardBills' onClick={()=>{handleNextStep('Gas')}}>
                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Group 1009.svg" } alt="User Image" className="" />
                            <p> 
                            Gas
                            </p>
                        </div>
                    </div>
                    <div className='col-4 d-flex justify-content-center mb-4' >
                        <div className='paymentCardBills' onClick={()=>{handleNextStep('Water')}}>
                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Group 1010.svg" } alt="User Image" className="" />
                            <p> 
                            Water
                            </p>
                        </div>
                    </div>
                    <div className='col-4 d-flex justify-content-center mb-4' >
                        <div className='paymentCardBills' onClick={()=>{handleNextStep('Internet')}}>
                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Group 1011.svg" } alt="User Image" className="" />
                            <p> 
                            Internet
                            </p>
                        </div>
                    </div>
                    <div className='col-4 d-flex justify-content-center mb-4' >
                        <div className='paymentCardBills' onClick={()=>{handleNextStep('Telephone')}}>
                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Group 1012.svg" } alt="User Image" className="" />
                            <p> 
                            Telephone
                            </p>
                        </div>
                    </div>
                    </div>
                    :  stepCount === 2 ? 
                     <SearchCompanyForBills setStepCount={setStepCount} stepCount={stepCount}/>
                    : stepCount === 3 ?
                    < ConsumerNumberForBills consumerNumber={consumerNumber} setconsumerNumber={setconsumerNumber}/>
                    : stepCount === 4 ?
                    <FInalBillDetails name={name}  consumerNumber={consumerNumber} amount={amount}/> 
                    : ""
                }

                {
                    stepCount === 3 ? 
                        <div className='row justify-content-center'>
                            <div className='col-8'>
                                    <div className='WHPS3bottomDiv row '>
                                    <a onClick={()=>{handleNextStep(name)}}> Next </a>                     
                                    </div>
                            </div>
                        </div>
                    :  stepCount === 4 ? 
                        <div className='row justify-content-center'>
                            <div className='col-8'>
                                <div className='WHPS3bottomDiv row '>
                                <a onClick={paySuccess}> Pay </a>                     
                                </div>
                            </div>
                        </div>
                    : ""
                }
            </div>
                
    
        </div>
    </div>
    </div>
    </div>
      )
}

export default BiilsAndPayments