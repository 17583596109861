import React from 'react'
import { useTranslation } from 'react-i18next'
function SecuirityBugs() {
    const {t} = useTranslation()
    const data = [
        {
            id: `${t('SB.s3.1.id')}`,
            title: `${t('SB.s3.1.title')}`,
            descritption:`${t('SB.s3.1.descritption')}`,
          },
        {
          id:`${t('SB.s3.2.id')}`,
          title: `${t('SB.s3.2.title')}`,
          descritption: `${t('SB.s3.2.descritption')}`,
        },
        {
          id:`${t('SB.s3.3.id')}`,
          title: `${t('SB.s3.3.title')}`,
          descritption: `${t('SB.s3.3.descritption')}`,
          },
        {
          id:`${t('SB.s3.4.id')}`,
          title: `${t('SB.s3.4.title')}`,
          descritption: `${t('SB.s3.4.descritption')}`,
        },
        {
          id:`${t('SB.s3.5.id')}`,
          title: `${t('SB.s3.5.title')}`,
          descritption: `${t('SB.s3.5.descritption')}`,
        },
    ]
  return (
    <div className='homepageContainerP2 py-3 mt-5' >
        <div className='container-fluid mt-5'>
            <div className='row justify-content-center px-0'>
                <h3 className='detailsPageH text-center '>
                {t('SB.s1.h.1')} <br></br> {t('SB.s1.h.2')}
                </h3> 
                
                <div className='col-md-12 col-12 py-3 px-0'>
                    <p className="detailsPageTxt  " >
                    {t('SB.s1.p')}
                    </p>


                    <h3 className='detailsPageHBlack '>
                    {t('SB.s2.h.2')}
                    </h3>


                    <p className="detailsPageTxt  ps-4 pe-2" >
                    <b className="detailsPageTxtb">{t('SB.s2.pb.1')}</b> {t('SB.s2.p.1')}
                    </p>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    <b className="detailsPageTxtb">{t('SB.s2.pb.2')}</b> {t('SB.s2.p.2')}
                    </p>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    <b className="detailsPageTxtb">{t('SB.s2.pb.3')}</b> {t('SB.s2.p.3')}
                    </p>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    <b className="detailsPageTxtb">{t('SB.s2.pb.4')}</b>{t('SB.s2.p.4')}
                    </p>

                    <p className="detailsPageTxt  ps-5 pe-2" >
                      •{t('SB.s2.li.1')} <br></br>
                      •{t('SB.s2.li.2')} <br></br>
                      •{t('SB.s2.li.3')} <br></br>
                      •{t('SB.s2.li.4')} <br></br>
                    </p>


                    <h3 className='detailsPageHBlack '>
                    {t('SB.s2.h.2')}
                    </h3>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    •{"  "} {t('SB.s2.li.5')} <br></br>
                    •{"  "}{t('SB.s2.li.6')} <br></br>
                    {"  "} {t('SB.s2.li.7')} <br></br>
                    </p>


                    <b className="detailsPageTxtb">{t('SB.s2.pb.5')}</b>
                    <p className="detailsPageTxt  ps-5 pe-2" >
                    {t('SB.s2.p.5')}
                    </p>


                    <b className="detailsPageTxtb">{t('SB.s2.pb.6')}</b>
                    <p className="detailsPageTxt  ps-5 pe-2" >                      
                    {t('SB.s2.p.6')}
                    </p>


                    <b className="detailsPageTxtb">{t('SB.s2.pb.7')}</b>
                    <p className="detailsPageTxt  ps-5 pe-2" >
                    {t('SB.s2.p.7')}
                    </p>

                    <p className="detailsPageTxt  mt-3" >
                    {t('SB.s2.p.8')}
                    </p>
                </div>


                <h3 className='detailsPageHBlack text-center mt-md-4 mt-3'>
                {t('SB.s2.pb.8')}
                </h3>

                <div className='col-lg-12 col-md-12 col-sm-12 col-12 my-md-5 my-3 px-0'>
            {data.map((element) => (
            <div className='row'>
           
            <div className="accordion " id="accordionPanelsStayOpenExample" key={element.id}>
                <div className="accordion-item" >
                    <h2 className="accordion-header accordionHeader accordionCustomColor "  id={`heading${element.id}`}>
                    <button className="accordion-button careerAccordionButton flex-column align-items-start accordionCustomColor"  type="button" data-bs-toggle="collapse"   
                        data-bs-target={`#collapse${element.id}`}
                        aria-expanded="true"
                        aria-controls={`collapse${element.id}`}>
                       <p className='py-1 accordian_heading01 mb-0'>  
                       {element.title}
                       </p>
                    </button>
                    </h2>
                    <div  className="accordion-collapse collapse" id={`collapse${element.id}`} aria-labelledby={`heading${element.id}`}>
                    <div className="accordion-body accordionCustomColorBody">
                        <row className="accordionBodyTextDiv">
                            <div className='httpTxtDiv' dangerouslySetInnerHTML={{ __html: element.descritption }} />
                        </row>
                    </div>
                    </div>
                </div>
            </div>
            <img loading='lazy' src="../../images/Vector 5 (1).png" alt="Section One Right Side Images" className="mb-md-3 mb-0"/>
            </div>
            ))}
            </div>
            </div>
        </div>
    </div>

  )
}

export default SecuirityBugs