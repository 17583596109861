import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CurrencyDropdownItems } from "../../constants/AllCurencies";
import { currencyMapping } from "../../constants/currencyMapping";
import { getToken } from "../../services/LocalStorageService";
function DropdownItem({ text, imgSrc, onSelect }) {
  return (
    <button className="dropdown-item" onClick={() => onSelect(text, imgSrc)}>
      <img
        loading="lazy"
        src={imgSrc}
        alt="Currency Image"
        className="currencySwapOptionImg"
      />{" "}
      {text}
    </button>
  );
}

function DropdownItem1({ text, code, imgSrc, onSelect }) {
  return (
    <button
      className="dropdown-item d-flex gap-3 align-items-center"
      onClick={() => onSelect(text, code, imgSrc)}
    >
      <img
        loading="lazy"
        src={process.env.PUBLIC_URL + imgSrc}
        alt="Currency Image"
        className="currencySwapOptionImg"
      />
      <div className="d-flex flex-column">
        {text}
        <span>{code}</span>
      </div>
    </button>
  );
}

export default function BWMainPage() {
  const { access_token } = getToken();
  const [baseCurrencySign, setBaseCurrencySign] = useState("£");
  const [baseCurrency, setBaseCurrency] = useState("eur");
  const [swapClick, setSwapClick] = useState(true);
  const [btcprice, setBtcPrice] = useState(0);
  const [ethprice, setethPrice] = useState(0);
  const [usdtprice, setusdtPrice] = useState(0);
  
  const [binancecoinprice, setbinancecoinPrice] = useState(0);

  const [btcValue, setBtcValue] = useState(0);
  const [ethValue, setethValue] = useState(0);
  const [pusdValue, setpusdValue] = useState(0);
  const [binancecoinValue, setbinancecoinValue] = useState(0);

  const [abAmount, setabAmount] = useState("");

  const [price, setPrice] = useState("");
  const [value, setvalue] = useState("");
  
  const [cryptoForApi, setCryptoForApi] = useState("BNB");
  const [currencyForApi, setCurrencyForApi] = useState("gbp");

  const navigate = useNavigate();
  const [dropDown, showDropDown] = useState(false);
  const [dropDown1, showDropDown1] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [toggleBtn, setToggleBtn] = useState("swap");

  const [cryptoSymbol, setCryptoSymbol] = useState("BNB");
  const [cryptoTotal, setCryptoTotal] = useState("0");
  const [cryptoResultTotal, setCryptoResultTotal] = useState("0");
  const [cryptoIconeUrl, setCryptoIconeUrl] = useState(
    "../images/Group 823.svg"
  );
  
  const [cname, setcname] = useState("BNB");
  const [sign, setsign] = useState();
  const [imgUrl, setimgUrl] = useState("../images/Group 823.svg");

  const [currencySymbol, setCurrencySymbol] = useState("GBP");
  const [currencyTotal, setcurrencyTotal] = useState("0");
  const [currencyResultTotal, setcurrencyResultTotal] = useState("0");
  const [currencyIconUrl, setcurrencyIconUrl] = useState("../images/g.svg");

  const navigateToSendPage = (sign, name, img, availbleAmount) => {
    navigate("/wallet/send-crypto/via/network", {
      state: { name, sign, img, availbleAmount },
    });
  };

  const navigateToPage = (sign, name, img, network) => {
    navigate("/wallet/recieved-crypto", {
      state: { name, sign, img, network },
    });
  };

  const dropDownHandle = () => {
    showDropDown(!dropDown);
  };

  const dropDownHandle1 = () => {
    showDropDown1(!dropDown1);
  };

  const handleOptionSelect = (optionText, optionImgSrc) => {
    setSelectedOption({ text: optionText, imgSrc: optionImgSrc });
    setCryptoSymbol(optionText);
    setCryptoIconeUrl(optionImgSrc);
    setcname(optionText);
    setsign(optionText);
    setimgUrl(optionImgSrc);
    showDropDown(false);

    // Mapping optionText to API identifier
    const apiMapping = {
      BTC: "BTC",
      ETH: "ETH",
      BNB: "BNB",
      USDT: "USDT",
      SOL: "SOL",
      XRP: "XRP",
      // Add more mappings as needed
    };

    setCryptoForApi(apiMapping[optionText] || "BNB");

    if (cryptoSymbol === "BTC") {
      setabAmount(btcprice);
    } else if (cryptoSymbol === "ETH") {
      setabAmount(ethprice);
    } else if (cryptoForApi === "BNB") {
      setabAmount(ethprice);
    } else if (cryptoSymbol === "USDT") {
      setabAmount(usdtprice);
    }
  };

  const [baseCurrencyBalance,setBaseCurrencyBalance] = useState(0)

  const handleOptionSelect1 = (optionText, optionCode, optionImgSrc) => {
    setSelectedOption1({
      text: optionText,
      code: optionCode,
      imgSrc: optionImgSrc,
    });
    setCurrencySymbol(optionCode);
    setcurrencyIconUrl(optionImgSrc);
    showDropDown1(false);

    console.log('here is our option code')
    // Mapping for currency API identifier
    // const currencyMapping = currencyMapping

    setCurrencyForApi(currencyMapping[optionCode] || "gbp");
  };

  const fetchUserProfileData = async () => {
    try {
      // const response = await fetch('http://127.0.0.1:8000/api/wallet/user-balance/', {
      const response = await fetch(
        "https://evistialab.com/api/v1/get-user-profile-data/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (response.status === 401) {
        // If unauthorized, navigate to the login page
        navigate("/login");
        return; // Stop further execution in this block
      }
      const data = await response.json();
      if (data) {
        const currencyCode = data?.base_currency?.toUpperCase();
        setBaseCurrency(currencyCode);
        const detail = currencyMapping[currencyCode];
        if (detail) {
          setBaseCurrencySign(detail?.sign);
        } else {
          setBaseCurrencySign("£");
        }
        console.log(baseCurrency);
      }
    } catch (error) {
      console.error("Error fetching ETH balance:", error);
    }
  };

  useEffect(() => {
    fetchUserProfileData();
  }, [baseCurrency, baseCurrencySign]);



  async function convertFiatToCryptoPrice() {
    const apiUrl = "https://evistialab.com/api/v1/crypto-fiat-price/";

    // Prepare the form data to be sent in the request
    const apiData = {
      direction: "fiat_to_crypto",
      fiat: currencyForApi,
      crypto: cryptoForApi,
      amount: currencyTotal,
    };
    console.log(apiData, "api data before call");
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      body: JSON.stringify(apiData),
    });

    if (response.status === 401) {
      navigate("/login");
    }
    if (response.status === 400) {
      console.log(response, "response");
    }

    const data = await response.json();
    if (data) {
      console.log(data);
      setCryptoResultTotal(data.converted_amount_after_fee);
      setPrice(currencyTotal);
    }
    if (data.error) {
      console.log(data.error);
    }
  }

  async function convertoCryptoToFIatPrice() {
    const apiUrl = "https://evistialab.com/api/v1/crypto-fiat-price/";
    // Prepare the form data to be sent in the request
    const apiData = {
      direction: "crypto_to_fiat",
      fiat: currencyForApi,
      crypto: cryptoForApi,
      amount: cryptoTotal,
    };
    console.log(apiData, "api data before call");
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      body: JSON.stringify(apiData),
    });

    if (response.status === 401) {
      navigate("/login");
    }
    if (response.status === 400) {
      console.log(response, "response");
    }

    const data = await response.json();
    if (data) {
      console.log(data);
      setcurrencyResultTotal(data.converted_amount_after_fee);
      setPrice(cryptoTotal);
    }
    if (data.error) {
      console.log(data.error);
    }
  }

  useEffect(() => {
    if (
      cryptoTotal !== "0" &&
      swapClick === true &&
      cryptoTotal !== "" &&
      cryptoTotal !== undefined
    ) {
      convertoCryptoToFIatPrice();
    }

    if (
      currencyTotal !== "0" &&
      swapClick === false &&
      currencyTotal !== "" &&
      currencyTotal !== undefined
    ) {
      convertFiatToCryptoPrice();
    }

    
  }, [cryptoTotal, currencyTotal, selectedOption, selectedOption1]);

  useEffect(() => {
    const fetchEthBalance = async () => {
      // BTC BALANCE FETHCING
      try {
        const response = await fetch(
          "https://evistialab.com/api/v1/user-balance/?crypto=BTC",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (response.status === 401) {
          // If unauthorized, navigate to the login page
          navigate("/login");
          return; // Stop further execution in this block
        }
        const data = await response.json();
        if (data) {
          setBtcPrice(data?.fiat_balance);
          setBtcValue(data?.amount);
        }
      } catch (error) {
        console.error("Error fetching BTC balance:", error);
      }

      // ETH BALANCE FETCHING
      try {
        const response = await fetch(
          "https://evistialab.com/api/v1/user-balance/?crypto=ETH",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (response.status === 401) {
          // If unauthorized, navigate to the login page
          navigate("/login");
          return; // Stop further execution in this block
        }
        const data = await response.json();
        if (data) {
          setethPrice(data?.fiat_balance);
          setethValue(data?.amount);
        }
      } catch (error) {
        console.error("Error fetching ETH balance:", error);
      }

      // BNB BALANCE FETCHING
      try {
        const response = await fetch(
          "https://evistialab.com/api/v1/user-balance/?crypto=BNB",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (response.status === 401) {
          // If unauthorized, navigate to the login page
          navigate("/login");
          return; // Stop further execution in this block
        }
        const data = await response.json();
        if (data) {
          setbinancecoinPrice(data?.fiat_balance);
          setbinancecoinValue(data?.amount);
        }
      } catch (error) {
        console.error("Error fetching BNB balance:", error);
      }

      // USDT BALANCE FECTHING
      try {
        const response = await fetch(
          "https://evistialab.com/api/v1/user-balance/?crypto=USDT",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (response.status === 401) {
          // If unauthorized, navigate to the login page
          navigate("/login");
          return; // Stop further execution in this block
        }
        const data = await response.json();
        if (data) {
          setusdtPrice(data?.fiat_balance);
          setpusdValue(data?.amount);
        }
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }

      try {
        const response = await fetch(
          "https://evistialab.com/api/v1/user-balance/?crypto=BTC",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (response.status === 401) {
          // If unauthorized, navigate to the login page
          navigate("/login");
          return; // Stop further execution in this block
        }
        const data = await response.json();
        if (data) {
          setBtcPrice(data?.fiat_balance);
          setBtcValue(data?.amount);
        }
      } catch (error) {
        console.error("Error fetching ETH balance:", error);
      }




      // Transaction history 
      // try {
      //   const response = await fetch(
      //     "https://evistialab.com/api/v1/transaction-history/",
      //     {
      //       method: "GET",
      //       headers: {
      //         "Content-Type": "application/json",
      //         Authorization: `Bearer ${access_token}`,
      //       },
      //     }
      //   );
      //   if (response.status === 401) {
      //     // If unauthorized, navigate to the login page
      //     navigate("/login");
      //     return; // Stop further execution in this block
      //   }
      //   const data = await response.json();
      //   if (data) {
      //     console.log('transaction',data)
      //   }
      // } catch (error) {
      //   console.error("Error fetching USDT balance:", error);
      // }
    };



    fetchEthBalance();
  }, []);

  const handleSwapClick = () => {
    setSwapClick(!swapClick);
    setCryptoTotal("0");
    setcurrencyTotal("0");
  };

  const handleSwapNextPage = () => {
    if (cryptoTotal !== "0" || currencyTotal !== "0") {
      navigate("/wallet/swap-confirm", {
        state: {
          cryptoSymbol,
          cryptoTotal,
          cryptoIconeUrl,
          currencySymbol,
          currencyTotal,
          currencyIconUrl,
          price,
          value,
          currencyResultTotal,
          cryptoResultTotal
        },
      });
    }
    // }
  };

  useEffect(() => {
    setBaseCurrencyBalance(
      Number(btcprice) +
      Number(ethprice) +
      Number(binancecoinprice) +
      Number(usdtprice)
    )
  }, [btcprice,ethprice,binancecoinprice,usdtprice])
  

  // Sample data for each card
  const cardData = [
    {
      h2Value: `${btcValue}`,
      h4Value: `${baseCurrencySign}${btcprice}`,
      title: "Bitcoin",
      symbol: "BTC",
      imageSrc: "../images/Group 824.svg",
    },
    {
      h2Value: `${ethValue}`,
      h4Value: `${baseCurrencySign}${ethprice}`,
      title: "Ethereum",
      symbol: "ETH",
      imageSrc: "../images/Group 825.svg",
    },
    {
      h2Value: `${Number(pusdValue).toFixed(2)}`,
      h4Value: `${baseCurrencySign}${usdtprice}`,
      title: "TetherUS",
      symbol: "USDT",
      imageSrc: "../images/Group 826.svg",
    },
    {
      h2Value: `${binancecoinValue}`,
      h4Value: `${baseCurrencySign}${binancecoinprice}`,
      title: "Binance Coin",
      symbol: "BNB",
      imageSrc: "../images/Group 823.svg",
    },
  ];
  //consolelog(cardData);

  const handleDetailsPage = (data) => {
    // Navigate to the details page and pass the clicked card's data as props
    navigate("/wallet/crypto-details", {
      state: {
        title: data.title,
        symbol: data.symbol,
        img: data.imageSrc,
        h2Value: data.h2Value,
        h4Value: data.h4Value,
      },
    });
  };

  const [network, setNetwork] = useState();
  const [stepCount, setStepCount] = useState(1);

  useEffect(() => {
    if (cname === "Binance") {
      setStepCount(2);
      setNetwork("BNB Smart Chain (BEP20)");
    } else if (cname === "Ethereum") {
      setStepCount(2);
      setNetwork("Ethereum (ERC20)");
    } else if (cname === "USDT") {
      setStepCount(2);
      setNetwork("P20 (P-20)");
    }
  }, [stepCount]);
  return (
    <div className="sideBarMainContent ">
      <div className="WHS1MainDiv positionSet">
        <div className="wrapper d-flex justify-content-end">
          <div
            className="notifyIconDiv sidebarToggle cursor-pointer"
            onClick={() => {
              navigate("/wallet/notifications");
            }}
          >
            <img
              loading="lazy"
              src={process.env.PUBLIC_URL + "/images/Group 598.svg"}
              alt="User Image"
              className="notifyIcon"
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div className="wrapperInsideSecondDiv row">
          <div className="col-xxl-1  col-lg-1 col-1"></div>
          <div className="col-xxl-4 col-md-5 col-12 WS1HeadingDiv d-flex flex-column justify-content-center align-items-start pb-5 px-lg-0  px-5">
            <h3 className="py-1">TOTAL CRYPTO ASSETS</h3>
            <div className="WS1HeadingDiv">
              <h1>
                {baseCurrencySign}
                {baseCurrencyBalance.toFixed(2)}{" "}
                {"\u00a0"} {"\u00a0"}{" "}
                <img
                  loading="lazy"
                  src={process.env.PUBLIC_URL + "/images/Vector (6).svg"}
                  alt="settingImg"
                  className="settingImg"
                />
              </h1>
            </div>

            <div className="d-flex gap-3 align-items-center mt-1">
              <a className="WHPS1downBtn">+8.6%</a>
              <div className="WHPS1downBtn1">
                <img
                  loading="lazy"
                  src="../images/arrowupperSide.svg"
                  alt="Section One Right Side Images"
                  className="upperArrowImg img-fluid"
                />
              </div>
            </div>
          </div>

          <div className="WS1BtnDiv col-xxl-7 col-md-6 col-12 d-flex justify-content-xxl-end justify-content-lg-end justify-content-between  ps-5 mt-md-5 mt-0 ">
            <Link className="WS1Btn" to={"/wallet/crypto"}>
              <b>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 13 14"
                  fill="#FFFFFF"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12.6143 6.02831L6.89901 0.314305C6.8474 0.260171 6.78534 0.217077 6.71658 0.187632C6.64783 0.158187 6.57381 0.143005 6.49901 0.143005C6.42422 0.143005 6.3502 0.158187 6.28145 0.187632C6.21269 0.217077 6.15063 0.260171 6.09901 0.314305L0.385681 6.02831L1.18568 6.82831L5.92835 2.08564V13.857H7.07168V2.08631L11.8143 6.82897L12.6143 6.02831Z" />
                </svg>
              </b>
              Send
            </Link>

            <Link className="WS1Btn" to={"/wallet/crypto"}>
              <b>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 13 14"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M11.8143 7.17167L7.07167 11.9143L7.07167 0.143005L5.92833 0.143005L5.92833 11.9143L1.18567 7.17167L0.385666 7.97167L6.09967 13.6857C6.15169 13.7393 6.21384 13.782 6.2825 13.8114C6.35116 13.8408 6.42497 13.8563 6.49967 13.857C6.57436 13.8564 6.64819 13.8409 6.71686 13.8115C6.78552 13.7821 6.84766 13.7393 6.89967 13.6857L12.6143 7.97167L11.8143 7.17167Z" />
                </svg>
              </b>
              Request
            </Link>

            <Link className="WS1Btn" to={"/wallet/swap"}>
              <b>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 12 12"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1.5 5.5H0C0 3.2945 1.7945 1.5 4 1.5H10V0L11.8535 1.8965C12.0485 2.092 12.0485 2.4085 11.8535 2.6035L10 4.5V3H4C2.6215 3 1.5 4.1215 1.5 5.5ZM8 9H2V7.5L0.1465 9.3965C-0.0485 9.592 -0.0485 9.9085 0.1465 10.1035L2 12V10.5H8C10.2055 10.5 12 8.7055 12 6.5H10.5C10.5 7.8785 9.3785 9 8 9Z" />
                </svg>
              </b>
              Swap
            </Link>
          </div>
        </div>
      </div>

      <div className="WHS2MainDiv positionSet row justify-content-center  p-sm-5 p-4 mt-3">
        <div className="col-xxl-11 col-12">
          <div className="row">
            {cardData.map((data, index) => (
              <div
                className="col-xl-3 col-md-6 col-12 mb-xl-0 mb-4"
                onClick={() => handleDetailsPage(data)}
                key={index}
              >
                <div className="WHPS2Card d-flex flex-column gap-1">
                  <div className="d-flex flex-md-column flex-row justify-content-md-start justify-content-between">
                    <div className="upperDiv d-flex gap-3 align-items-center">
                      <img
                        loading="lazy"
                        src={data.imageSrc}
                        alt="Section One Right Side Images"
                        className="img-fluid cardLogo"
                      />
                      <div className="innerTextDiv ">
                        <p className="pt-md-2 pt-0">{data.title}</p>
                        <span>{data.symbol}</span>
                      </div>
                    </div>
                    <div className="centerDiv mt-md-3 mt-0 d-flex flex-column align-items-md-start align-items-end justify-content-md-start justify-content-start">
                      <h2>{data.h2Value}</h2>
                      <h4>{data.h4Value}</h4>
                    </div>
                  </div>
                  <div className="bottomDiv d-flex gap-2 align-items-center">
                    <a className="WHPS2downBtn">+8.6%</a>
                    <div className="WHPS1downBtn1">
                      <img
                        loading="lazy"
                        src="../images/arrowupperSide.svg"
                        alt="Section One Right Side Images"
                        className="upperArrowImg img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="WHS2MainDiv positionSet row justify-content-center  p-sm-5 p-4 pt-0">
        <div className="col-xxl-11 col-12">
          <div className="row ">
            <div className="col-xxl-4 col-xl-5 col-lg-12 col-12 mb-xxl-0 mb-5">
              <div className="WHPS3LCard d-flex flex-column align-items-center ">
                <div className="upperRow d-flex gap-4 justify-content-center">
                  <a
                    className={`navToggler ${
                      toggleBtn === "swap" ? "active" : ""
                    }`}
                    onClick={(e) => {
                      setToggleBtn("swap");
                    }}
                  >
                    Swap
                  </a>
                  <a
                    className={`navToggler ${
                      toggleBtn === "send" ? "active" : ""
                    }`}
                    onClick={(e) => {
                      setToggleBtn("send");
                    }}
                  >
                    Send
                  </a>
                  <a
                    className={`navToggler ${
                      toggleBtn === "receive" ? "active" : ""
                    }`}
                    onClick={(e) => {
                      setToggleBtn("receive");
                    }}
                  >
                    Receive
                  </a>
                </div>

                {toggleBtn === "swap" ? (
                  <>
                    <div
                      className={`centerRow row ${
                        swapClick === true ? "mt-5 mb-3" : "mt-5 mb-3"
                      }`}
                    >
                      {swapClick === true ? (
                        <>
                          <div
                            className="col-6 currencySwapOption d-flex gap-md-3 gap-2 align-items-center"
                            onClick={dropDownHandle}
                          >
                            <img
                              loading="lazy"
                              src={
                                selectedOption?.imgSrc ||
                                "../images/Group 823.svg"
                              }
                              alt="Currency Image"
                              className="currencySwapOptionImg"
                            />
                            <h3>{selectedOption?.text || "BNB"}</h3>
                            <img
                              loading="lazy"
                              src="../images/WHPArrowD.svg"
                              alt="Section One Right Side Images"
                              className="currencySwapOptionArrow"
                            />
                          </div>
                          <div
                            className={`dropdown-menu firstDropDown custom_dropDown_in_WTH ${
                              dropDown ? "show" : ""
                            }`}
                            onMouseLeave={() => showDropDown(false)}
                          >
                            <DropdownItem
                              text="BTC"
                              imgSrc="../images/Group 824.svg"
                              onSelect={handleOptionSelect}
                            />
                            <DropdownItem
                              text="ETH"
                              imgSrc="../images/Group 825.svg"
                              onSelect={handleOptionSelect}
                            />
                            <DropdownItem
                              text="USDT"
                              imgSrc="../images/Group 826.svg"
                              onSelect={handleOptionSelect}
                            />
                            <DropdownItem
                              text="BNB"
                              imgSrc="../images/Group 823.svg"
                              onSelect={handleOptionSelect}
                            />
                          </div>
                          <div className="col-6">
                            <input
                              type="number"
                              className="swapInput"
                              value={cryptoTotal}
                              onChange={(e) => {
                                setCryptoTotal(e.target.value);
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="col-6 currencySwapOption d-flex gap-md-3 gap-2 align-items-center"
                            onClick={dropDownHandle1}
                          >
                            <img
                              loading="lazy"
                              src={
                                selectedOption1?.imgSrc ||
                                "../images/gbpIcon.svg"
                              }
                              alt="Currency Image"
                              className="currencySwapOptionImg"
                            />
                            <h3>{selectedOption1?.text || "GBP"}</h3>
                            <img
                              loading="lazy"
                              src="../images/WHPArrowD.svg"
                              alt="Section One Right Side Images"
                              className="currencySwapOptionArrow"
                            />
                          </div>
                          <div
                            className={`dropdown-menu firstDropDown1  custom_dropDown_in_WTH ${
                              dropDown1 ? "show" : ""
                            }`}
                            onMouseLeave={() => showDropDown1(false)}
                          >
                            <DropdownItem1
                              text="GBP"
                              imgSrc="../images/g.svg"
                              onSelect={handleOptionSelect1}
                            />
                            <DropdownItem1
                              text="EURO"
                              imgSrc="../images/eur.svg"
                              onSelect={handleOptionSelect1}
                            />
                            <DropdownItem1
                              text="AED"
                              imgSrc="../images/aed.svg"
                              onSelect={handleOptionSelect1}
                            />

                            <DropdownItem1
                              text="YEN"
                              imgSrc="../images/yen.svg"
                              onSelect={handleOptionSelect1}
                            />
                            <DropdownItem1
                              text="BHT"
                              imgSrc="../images/bht.svg"
                              onSelect={handleOptionSelect1}
                            />
                            {/* <DropdownItem1 text="RUB" imgSrc="../images/rub.svg" onSelect={handleOptionSelect1} />
                          <DropdownItem text="MDL" imgSrc="../images/mdl.svg" onSelect={handleOptionSelect1} /> */}
                            <DropdownItem1
                              text="PKR"
                              imgSrc="../images/pkr.svg"
                              onSelect={handleOptionSelect1}
                            />
                          </div>
                          <div className="col-6">
                            <input
                              type="number"
                              className="swapInput"
                              value={currencyTotal}
                              onChange={(e) => {
                                setcurrencyTotal(e.target.value);
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>

                    <img
                      loading="lazy"
                      src="../images/Group 611.svg"
                      alt="Section One Right Side Images"
                      className="swapImg"
                      onClick={handleSwapClick}
                    />

                    <div className={`centerRow row `}>
                      {swapClick === !true ? (
                        <>
                          <div
                            className="col-6 currencySwapOption d-flex gap-md-3 gap-2 align-items-center"
                            onClick={dropDownHandle}
                          >
                            <img
                              loading="lazy"
                              src={
                                selectedOption?.imgSrc ||
                                "../images/Group 823.svg"
                              }
                              alt="Currency Image"
                              className="currencySwapOptionImg"
                            />
                            <h3>{selectedOption?.text || "BNB"}</h3>
                            <img
                              loading="lazy"
                              src="../images/WHPArrowD.svg"
                              alt="Section One Right Side Images"
                              className="currencySwapOptionArrow"
                            />
                          </div>
                          <div
                            className={`dropdown-menu firstDropDown custom_dropDown_in_WTH ${
                              dropDown ? "show" : ""
                            }`}
                            onMouseLeave={() => showDropDown(false)}
                          >
                            <DropdownItem
                              text="BTC"
                              imgSrc="../images/Group 824.svg"
                              onSelect={handleOptionSelect}
                            />
                            <DropdownItem
                              text="ETH"
                              imgSrc="../images/Group 825.svg"
                              onSelect={handleOptionSelect}
                            />
                            <DropdownItem
                              text="USDT"
                              imgSrc="../images/Group 826.svg"
                              onSelect={handleOptionSelect}
                            />
                            <DropdownItem
                              text="BNB"
                              imgSrc="../images/Group 823.svg"
                              onSelect={handleOptionSelect}
                            />
                          </div>
                          <div className="col-6">
                            <input
                              type="number"
                              className="swapInput"
                              value={cryptoResultTotal}
                              onChange={(e) => {
                                setCryptoResultTotal(e.target.value);
                              }}
                              readOnly
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="col-6 currencySwapOption d-flex gap-md-3 gap-2 align-items-center"
                            onClick={dropDownHandle1}
                          >
                            <img
                              loading="lazy"
                              src={
                                selectedOption1?.imgSrc ||
                                "../images/gbpIcon.svg"
                              }
                              alt="Currency Image"
                              className="currencySwapOptionImg"
                            />
                            <h3>{selectedOption1?.code || "GBP"}</h3>
                            <img
                              loading="lazy"
                              src="../images/WHPArrowD.svg"
                              alt="Section One Right Side Images"
                              className="currencySwapOptionArrow"
                            />
                          </div>
                          {/* <div className={`dropdown-menu firstDropDown1  custom_dropDown_in_WTH ${dropDown1 ? 'show' : ''}`}  onMouseLeave={() => showDropDown1(false)}>
                          <DropdownItem1 text="GBP" imgSrc="../images/g.svg" onSelect={handleOptionSelect1} />
                          <DropdownItem1 text="EURO" imgSrc="../images/eur.svg" onSelect={handleOptionSelect1} />
                          <DropdownItem1 text="AED" imgSrc="../images/aed.svg" onSelect={handleOptionSelect1} />
                          
                          <DropdownItem1 text="YEN" imgSrc="../images/yen.svg" onSelect={handleOptionSelect1} />
                          <DropdownItem1 text="BHT" imgSrc="../images/bht.svg" onSelect={handleOptionSelect1} />
                          <DropdownItem1 text="RUB" imgSrc="../images/rub.svg" onSelect={handleOptionSelect1} />
                          <DropdownItem1 text="MDL" imgSrc="../images/mdl.svg" onSelect={handleOptionSelect1} />
                          <DropdownItem1 text="PKR" imgSrc="../images/pkr.svg" onSelect={handleOptionSelect1} />
                          </div> */}
                          <div
                            className={`dropdown-menu CurrencySwapDropDown ${
                              dropDown1 ? "show" : ""
                            }`}
                            onMouseLeave={() => showDropDown1(false)}
                            style={{ top: "15rem" }}
                          >
                            {CurrencyDropdownItems.map((item, index) => (
                              <DropdownItem1
                                key={index}
                                text={item.name}
                                code={item.currency}
                                imgSrc={item.imgSrc}
                                onSelect={handleOptionSelect1}
                              />
                            ))}
                          </div>
                          <div className="col-6">
                            <input
                              type="number"
                              className="swapInput"
                              value={currencyResultTotal}
                              onChange={(e) => {
                                setcurrencyResultTotal(e.target.value);
                              }}
                              readOnly
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ) : toggleBtn === "send" ? (
                  <>
                    <div className="centerRow row  mt-4 mb-1">
                      <div
                        className="col-6 currencySwapOption d-flex gap-md-3 gap-2 align-items-center"
                        onClick={dropDownHandle}
                      >
                        <img
                          loading="lazy"
                          src={
                            selectedOption?.imgSrc || "../images/Group 823.svg"
                          }
                          alt="Currency Image"
                          className="currencySwapOptionImg"
                        />
                        <h3>{selectedOption?.text || "BNB"}</h3>
                        <img
                          loading="lazy"
                          src="../images/WHPArrowD.svg"
                          alt="Section One Right Side Images"
                          className="currencySwapOptionArrow"
                        />
                      </div>
                      <div
                        className={`dropdown-menu firstDropDown custom_dropDown_in_WTH ${
                          dropDown ? "show" : ""
                        }`}
                        onMouseLeave={() => showDropDown(false)}
                      >
                        <DropdownItem
                          text="BTC"
                          imgSrc="../images/Group 824.svg"
                          onSelect={handleOptionSelect}
                        />
                        <DropdownItem
                          text="ETH"
                          imgSrc="../images/Group 825.svg"
                          onSelect={handleOptionSelect}
                        />
                        <DropdownItem
                          text="USDT"
                          imgSrc="../images/Group 826.svg"
                          onSelect={handleOptionSelect}
                        />
                        <DropdownItem
                          text="BNB"
                          imgSrc="../images/Group 823.svg"
                          onSelect={handleOptionSelect}
                        />
                      </div>
                      <div className="col-6">
                        <input type="number" className="swapInput" />
                      </div>
                    </div>
                    <div className="col-12 px-0 mt-2">
                      <p className="m-0 ps-2">Receiver wallet address</p>
                      <input className="form-control WSHS2Input" />
                    </div>{" "}
                  </>
                ) : (
                  <>
                    <div className="centerRow1 row  ">
                      <div
                        className="col-12 currencySwapOption d-flex justify-content-between  align-items-center "
                        style={{ borderRight: "unset" }}
                        onClick={dropDownHandle}
                      >
                        <div className="d-flex align-items-center gap-3">
                          <img
                            loading="lazy"
                            src={
                              selectedOption?.imgSrc ||
                              "../images/coins (2) 2.svg"
                            }
                            alt="Currency Image"
                            className="currencySwapOptionImg"
                          />
                          <h3>{selectedOption?.text || "BNB"}</h3>
                        </div>
                        <img
                          loading="lazy"
                          src="../images/WHPArrowD.svg"
                          alt="Section One Right Side Images"
                          className="currencySwapOptionArrow"
                        />
                      </div>
                      <div
                        className={`dropdown-menu firstDropDown2 custom_dropDown_in_WTH1 ${
                          dropDown ? "show" : ""
                        }`}
                        onMouseLeave={() => showDropDown(false)}
                      >
                        <DropdownItem
                          text="BTC"
                          imgSrc="../images/Group 824.svg"
                          onSelect={handleOptionSelect}
                        />
                        <DropdownItem
                          text="ETH"
                          imgSrc="../images/Group 825.svg"
                          onSelect={handleOptionSelect}
                        />
                        <DropdownItem
                          text="USDT"
                          imgSrc="../images/Group 826.svg"
                          onSelect={handleOptionSelect}
                        />
                        <DropdownItem
                          text="BNB"
                          imgSrc="../images/Group 823.svg"
                          onSelect={handleOptionSelect}
                        />
                      </div>
                    </div>
                    {/* <div className='col-12 px-0 my-4 py-3 ' style={{visibility:"hidden"}}>
                         <p className='m-0 ps-2 mt-2 pt-2'>
                            My wallet address
                          </p>
                        <input className='form-control WSHS2Input' value="345sdfjdcvb345sdfh45f345dfg"/>
                      </div> */}
                  </>
                )}

                <div className="container-fluid">
                  <div className="WHPS3bottomDiv row mt-3">
                    {toggleBtn === "swap" ? (
                      <a onClick={handleSwapNextPage}> Swap </a>
                    ) : toggleBtn === "send" ? (
                      <a
                        onClick={() => {
                          navigateToSendPage(cname, sign, imgUrl);
                        }}
                      >
                        {" "}
                        Send{" "}
                      </a>
                    ) : (
                      <a
                        onClick={() =>
                          navigateToPage(cname, sign, imgUrl, network)
                        }
                      >
                        {" "}
                        Receive{" "}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-8 col-xl-7 col-lg-12 col-12 d-md-block d-none">
              <div className="WHPS3RCard d-flex flex-column ">
                <h2 className="my-2 pb-4">Portfolio Details</h2>
                <div className="WHPS3RCardInnerContentDiv row mt-3">
                  <div className="col-4 d-flex gap-2">
                    <img
                      loading="lazy"
                      src={process.env.PUBLIC_URL + "/images/Group 824.svg"}
                      alt="User Image"
                      className="notifyIcon"
                    />
                    <p className="coinName">Bitcoin</p>
                    <p className="coinNameBold">(BTC)</p>
                  </div>
                  <div className="col-3">
                    <p className="coinBalanceIng">{btcValue} BTC</p>
                  </div>
                  <div className="col-3">
                    <p className="coinPriceText">
                      {baseCurrencySign}
                      {btcprice}
                    </p>
                  </div>
                  <div className="col-2 d-flex gap-2 align-items-center justify-content-center">
                    <p className="ProfitLoss">+8.6%</p>
                    <div className="WHPS1downBtn2 mb-3">
                      <img
                        loading="lazy"
                        src="../images/arrowupperSide.svg"
                        alt="Section One Right Side Images"
                        className=""
                      />
                    </div>
                  </div>
                </div>
                <div className="WHPS3RCardInnerContentDiv row">
                  <div className="col-4 d-flex gap-2">
                    <img
                      loading="lazy"
                      src={process.env.PUBLIC_URL + "/images/Group 825.svg"}
                      alt="User Image"
                      className="notifyIcon"
                    />
                    <p className="coinName">Ethereum</p>
                    <p className="coinNameBold">(ETH)</p>
                  </div>
                  <div className="col-3">
                    <p className="coinBalanceIng">{ethValue} ETH</p>
                  </div>
                  <div className="col-3">
                    <p className="coinPriceText">
                      {baseCurrencySign}
                      {ethprice}
                    </p>
                  </div>
                  <div className="col-2 d-flex gap-2 align-items-center justify-content-center">
                    <p className="ProfitLoss">+8.6%</p>
                    <div className="WHPS1downBtn2 mb-3">
                      <img
                        loading="lazy"
                        src="../images/arrowupperSide.svg"
                        alt="Section One Right Side Images"
                        className=""
                      />
                    </div>
                  </div>
                </div>
                <div className="WHPS3RCardInnerContentDiv row">
                  <div className="col-4 d-flex gap-2">
                    <img
                      loading="lazy"
                      src={process.env.PUBLIC_URL + "/images/Group 826.svg"}
                      alt="User Image"
                      className="notifyIcon"
                    />
                    <p className="coinName">TetherUS</p>
                    <p className="coinNameBold">(USDT)</p>
                  </div>
                  <div className="col-3">
                    <p className="coinPriceText">{Number(pusdValue).toFixed(2)} USDT</p>
                  </div>
                  <div className="col-3">
                    <p className="coinBalanceIng">
                      {baseCurrencySign}
                      {usdtprice}
                    </p>
                  </div>
                  <div className="col-2 d-flex gap-2 align-items-center justify-content-center">
                    <p className="ProfitLoss">+8.6%</p>
                    <div className="WHPS1downBtn2 mb-3">
                      <img
                        loading="lazy"
                        src="../images/arrowupperSide.svg"
                        alt="Section One Right Side Images"
                        className=""
                      />
                    </div>
                  </div>
                </div>
                <div className="WHPS3RCardInnerContentDiv row">
                  <div className="col-4 d-flex gap-2">
                    <img
                      loading="lazy"
                      src={process.env.PUBLIC_URL + "/images/Group 823.svg"}
                      alt="User Image"
                      className="notifyIcon"
                    />
                    <p className="coinName">Binance Coin</p>
                    <p className="coinNameBold">(BNB)</p>
                  </div>
                  <div className="col-3">
                    <p className="coinBalanceIng">{binancecoinValue} BNB</p>
                  </div>
                  <div className="col-3">
                    <p className=" coinPriceText">
                      {baseCurrencySign}
                      {binancecoinprice}
                    </p>
                  </div>
                  <div className="col-2 d-flex gap-2 align-items-center justify-content-center">
                    <p className="ProfitLoss">+8.6%</p>
                    <div className="WHPS1downBtn2 mb-3">
                      <img
                        loading="lazy"
                        src="../images/arrowupperSide.svg"
                        alt="Section One Right Side Images"
                        className=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5  px-md-5 px-0">
            <div className="WHPS3RecentActivityDiv col-12 d-flex justify-content-between">
              <h1>Recent Activity</h1>
              <Link to="/wallet/transactions-history">All Transactions</Link>
            </div>
          </div>

          <div className="row my-5 px-md-5 px-0 gap-4">
            <div className="WHPS3RecentActivityDiv col-12 d-flex justify-content-between">
              <div className="d-flex gap-3">
                <img
                  loading="lazy"
                  src="../images/downArrowW.svg"
                  alt="Section One Right Side Images"
                  className="img-fluid "
                />
                <div className="d-flex flex-column justify-content-center ">
                  <p className="mb-1">John Doe</p>
                  <span className="trDates">July 13 9:20PM</span>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-end">
                <p className="mb-1">400 USD</p>
                <span>+ 400 USD</span>
              </div>
            </div>

            <div className="WHPS3RecentActivityDiv col-12 d-flex justify-content-between">
              <div className="d-flex gap-3">
                <img
                  loading="lazy"
                  src="../images/upperArrowW.svg"
                  alt="Section One Right Side Images"
                  className="img-fluid "
                />
                <div className="d-flex flex-column justify-content-center ">
                  <p className="mb-1">Luke Wilson</p>
                  <span className="trDates">July 10 10:56AM</span>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-end">
                <p className="mb-1">700 EUR</p>
                <span>-700 USD</span>
              </div>
            </div>

            <div className="WHPS3RecentActivityDiv col-12 d-flex justify-content-between">
              <div className="d-flex gap-3">
                <img
                  loading="lazy"
                  src="../images/inbox.svg"
                  alt="Section One Right Side Images"
                  className="img-fluid "
                />
                <div className="d-flex flex-column justify-content-center ">
                  <p className="mb-1">NETFLIX Premium Subscription</p>
                  <span className="trDates">Jul 3 12:15PM</span>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-end ">
                <p className="mb-1">10 USD</p>
                <span>-10.00 USD</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
