import React, { useState , useEffect} from 'react'
import UpperNav from './UpperNav'
import { Link, useNavigate } from 'react-router-dom'
import { useGetcryptoPricesQuery } from '../../services/userAuthApi';
import { getToken } from '../../services/LocalStorageService';
import { currencyMapping } from '../../constants/currencyMapping';
export default function WalletCrypto() {

  const [btcprice, setBtcPrice] = useState(0);
  const [ethprice, setethPrice] = useState(0);
  const [usdtprice, setusdtPrice] = useState(0);
  const [binancecoinprice, setbinancecoinPrice] = useState(0);

  const [btcValue, setBtcValue] = useState(0);
  const [ethValue, setethValue] = useState(0);
  const [pusdValue, setpusdValue] = useState(0);
  const [binancecoinValue, setbinancecoinValue] = useState(0);

  const [baseCurrencySign, setBaseCurrencySign] = useState("£");
  const [baseCurrency, setBaseCurrency] = useState("eur");


  const [hover,setHover] = useState(false)
  const {access_token} = getToken()

  useEffect(() => {
    console.log(access_token)
    if(!access_token){
      navigate('/login/')
    }
  }, [access_token])
  

  useEffect(() => {
    
    const fetchEthBalance = async () => {
      try {
        // const response = await fetch('http://127.0.0.1:8000/api/wallet/btc-balance/', {
        const response = await fetch('https://evistialab.com/api/wallet/user-balance/', {
          method: 'GET', 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`,
          },
        });
        const data = await response.json();
        if (response.status === 401) {
          // If unauthorized, navigate to the login page
          navigate('/login');
          return; // Stop further execution in this block
        }
        if (data){
          data?.map((el,index)=>{
            console.log(el.amount,el)
            if (index === 0 ){
              setBtcPrice(el?.fiat_balance)
              setBtcValue(el?.amount)
            }else if(index === 1) {
              setethPrice(el?.fiat_balance)
              setethValue(el.amount)
            }else if(index === 2){
              setbinancecoinPrice(el?.fiat_balance)
              setbinancecoinValue(el?.amount)
            }
          })
        }
      } catch (error) {
        console.error('Error fetching ETH balance:', error);
      }
    };

    fetchEthBalance();
  }, []);



  const fetchUserProfileData = async () => {
    try {
      // const response = await fetch('http://127.0.0.1:8000/api/wallet/user-balance/', {
      const response = await fetch(
        "https://evistialab.com/api/v1/get-user-profile-data/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (response.status === 401) {
        // If unauthorized, navigate to the login page
        navigate("/login");
        return; // Stop further execution in this block
      }
      const data = await response.json();
      if (data) {
        const currencyCode = data?.base_currency?.toUpperCase();
        setBaseCurrency(currencyCode);
        const detail = currencyMapping[currencyCode];
        if (detail) {
          setBaseCurrencySign(detail?.sign);
        } else {
          setBaseCurrencySign("£");
        }
        console.log(baseCurrency);
      }
    } catch (error) {
      console.error("Error fetching ETH balance:", error);
    }
  };

  useEffect(() => {
    fetchUserProfileData();
  }, [baseCurrency, baseCurrencySign]);


  useEffect(() => {
    const fetchEthBalance = async () => {
      // BTC BALANCE FETHCING
      try {
        const response = await fetch(
          "https://evistialab.com/api/v1/user-balance/?crypto=BTC",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (response.status === 401) {
          // If unauthorized, navigate to the login page
          navigate("/login");
          return; // Stop further execution in this block
        }
        const data = await response.json();
        if (data) {
          setBtcPrice(data?.fiat_balance);
          setBtcValue(data?.amount);
        }
      } catch (error) {
        console.error("Error fetching BTC balance:", error);
      }

      // ETH BALANCE FETCHING
      try {
        const response = await fetch(
          "https://evistialab.com/api/v1/user-balance/?crypto=ETH",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (response.status === 401) {
          // If unauthorized, navigate to the login page
          navigate("/login");
          return; // Stop further execution in this block
        }
        const data = await response.json();
        if (data) {
          setethPrice(data?.fiat_balance);
          setethValue(data?.amount);
        }
      } catch (error) {
        console.error("Error fetching ETH balance:", error);
      }

      // BNB BALANCE FETCHING
      try {
        const response = await fetch(
          "https://evistialab.com/api/v1/user-balance/?crypto=BNB",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (response.status === 401) {
          // If unauthorized, navigate to the login page
          navigate("/login");
          return; // Stop further execution in this block
        }
        const data = await response.json();
        if (data) {
          setbinancecoinPrice(data?.fiat_balance);
          setbinancecoinValue(data?.amount);
        }
      } catch (error) {
        console.error("Error fetching BNB balance:", error);
      }

      // USDT BALANCE FECTHING
      try {
        const response = await fetch(
          "https://evistialab.com/api/v1/user-balance/?crypto=USDT",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (response.status === 401) {
          // If unauthorized, navigate to the login page
          navigate("/login");
          return; // Stop further execution in this block
        }
        const data = await response.json();
        if (data) {
          setusdtPrice(data?.fiat_balance);
          setpusdValue(data?.amount);
        }
      } catch (error) {
        console.error("Error fetching USDT balance:", error);
      }

      try {
        const response = await fetch(
          "https://evistialab.com/api/v1/user-balance/?crypto=BTC",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (response.status === 401) {
          // If unauthorized, navigate to the login page
          navigate("/login");
          return; // Stop further execution in this block
        }
        const data = await response.json();
        if (data) {
          setBtcPrice(data?.fiat_balance);
          setBtcValue(data?.amount);
        }
      } catch (error) {
        console.error("Error fetching ETH balance:", error);
      }
    };

    fetchEthBalance();
  }, []);
 
  
  const navigate = useNavigate()

  const navigateToSendPage = (sign,name,img,availbleAmount) => {
    navigate('/wallet/send-crypto/via/network', { state: { name , sign ,img ,availbleAmount } })
  }

  const navigateToPage = (sign,name,img) => {
    navigate('/wallet/recieved-crypto', { state: { name , sign ,img } })
  }

  
  return (
    <div className='sideBarMainContent '>
      <div className='WMainDiv '>
      <UpperNav />

      
      <div className='row mt-5 justify-content-center'>
        <div className='col-xxl-5 col-lg-8 col-12'>
            <div className='fiatTransferCard mt-md-5 mt-2' >
              <p className='cPHeading text-center'>
              Coin List
              </p>
            <div className='col-12'>
            <div className='CPunderRowCard d-flex justify-content-between mb-3'>
                <div className='d-flex gap-2'>
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Bitcoin.png" } alt="User Image" />
                <div className='titleDiv'>
                  <p className='rightHeading my-1'>
                    Bitcoin
                  </p>
                  <p className='rightPara mb-0'>
                    BTC
                  </p>
                </div>
                </div>
              
              
                <div className='leftSideText1 d-none gap-2 mt-md-2 mt-0'>
                   <a className='fDHoverBtnDiv1 mb-1 ' onClick={() => {navigateToSendPage('BTC', 'Bitcoin','Bitcoin.png',btcValue)}}>
                   Send
                  </a> 
                   <a className='fDHoverBtnDiv1 ' onClick={() => {navigateToPage('BTC', 'Bitcoin','Bitcoin.png', btcValue)}}>
                   Receive
                  </a> 
                </div>
              
                <div className='leftSideText d-flex flex-column align-items-end'>
                    <p className='leftHeading mb-1'>
                   {btcValue}
                    </p>
                    <p className='leftPara mb-0'>
                    ≈{baseCurrencySign}{btcprice}
                    </p>
                </div>
              </div>
              <div className='CPunderRowCard d-flex justify-content-between mb-3'>
                <div className='d-flex gap-2'>
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/eth.png" } alt="User Image" />
                <div className='titleDiv'>
                  <p className='rightHeading my-1'>
                  Ethereum
                  </p>
                  <p className='rightPara mb-0'>
                    ETH
                  </p>
                </div>
                </div>
              
              
                <div className='leftSideText1 d-none gap-2 mt-md-2 mt-0'>
                   <a className='fDHoverBtnDiv1 mb-1' onClick={() => {navigateToSendPage('ETH', 'Ethereum','eth.png',ethValue)}}>
                   Send
                  </a> 
                   <a className='fDHoverBtnDiv1 ' onClick={() => {navigateToPage('ETH', 'Ethereum','eth.png',ethValue)}}>
                   Receive
                  </a> 
                </div>
              
                <div className='leftSideText d-flex flex-column align-items-end'>
                    <p className='leftHeading mb-1'>
                    {ethValue}
                    </p>
                    <p className='leftPara mb-0'>
                    ≈{baseCurrencySign}{ethprice}
                    </p>
                </div>
              </div>
              <div className='CPunderRowCard d-flex justify-content-between mb-3' onMouseEnter={() => setHover(true)}  onMouseLeave={() => setHover(false)}>
                <div className='d-flex gap-2'>
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Group 826.svg" } alt="User Image" />
                <div className='titleDiv'>
                  
                  <p className='rightHeading my-1'>
                    TetherUS
                  </p>
                  <p className='rightPara mb-0'>
                  USDT
                  </p>
                </div>
                </div>
              
              
                <div className='leftSideText1 d-none gap-2 mt-md-2 mt-0'>
                   <a className='fDHoverBtnDiv1 mb-1' onClick={() => {navigateToSendPage('USDT', 'USDT' ,'newp20Img.png',pusdValue)}}>
                   Send
                  </a> 
                   <a className='fDHoverBtnDiv1 ' onClick={() => {navigateToPage('USDT', 'USDT' ,'newp20Img.png',pusdValue)}}>
                   Receive
                  </a> 
                </div>
              
                <div className='leftSideText d-flex flex-column align-items-end'>
                    <p className='leftHeading mb-1'>
                    {pusdValue}
                    </p>
                    <p className='leftPara mb-0'>
                    ≈{baseCurrencySign}{usdtprice}
                    </p>
                </div>
              </div>
             
              <div className='CPunderRowCard d-flex justify-content-between mb-3'>
                <div className='d-flex gap-2'>
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/binance.png" } alt="User Image" />
                <div className='titleDiv'>
                  <p className='rightHeading my-1'>
                  BNB-USD
                  </p>
                  <p className='rightPara mb-0'>
                    BNB
                  </p>
                </div>
                </div>
                <div className='leftSideText1 d-none gap-2 mt-md-2 mt-0'>
                   <a className='fDHoverBtnDiv1 mb-1' onClick={() => {navigateToSendPage('BNB', 'Binance','binance.png',binancecoinValue)}}>
                   Send
                  </a> 
                   <a className='fDHoverBtnDiv1 ' onClick={() => {navigateToPage('BNB', 'Binance','binance.png',binancecoinValue)}}>
                   Receive
                  </a> 
              </div>
              
                <div className='leftSideText d-flex flex-column align-items-end'>
                    <p className='leftHeading mb-1'>
                    {binancecoinValue}
                    </p>
                    <p className='leftPara mb-0'>
                    ≈{baseCurrencySign}{binancecoinprice}
                    </p>
                </div>
              </div>
            </div>

      </div>


    </div>
  </div>
  </div>
  </div>
  )
}
