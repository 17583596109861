import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function ApplicationRecevied() {
    const [loading,setLoading] = useState(false)
    const location = useLocation();
    const { email } = location.state || {};
    const navigate = useNavigate()

    // console.log(email,'email')

    useEffect(() => {
      if (!email){
        navigate('/sign-up')
      }
    
    })
    
    const handlePage = () => {
        setLoading(true)
        setTimeout(() => {
            navigate('/')
        }, 1500);
    }
    return (
        <div>
            <nav className="navbar py-0 navbarContainer fixed-top d-flex justify-content-center position-relative">
                <Link className="" to="/">
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="Logo" width="30" height="30" className="navbarLogo00 mx-auto my-lg-0 my-2" />
                </Link>
            </nav>
            <div className="d-flex flex-column justify-content-center aligin-items-center fixedHeight gap-3 px-lg-auto px-4">
                <h1 className="applicationHeading">
                Application Received!
                </h1>
                <p className="applicationText text-center mt-3">
                Please log in to verify your personal information card <br className='d-lg-block d-none' /> and complete your account setup.

                {/* We’ll verify your information and send you  an email when your account is ready to use  */}
                </p>

                <div className="mx-auto manageBtnW" >
                      <button type="submit" className={`button ${loading ? 'loading' : ''}`} style={{ "border": "none" }} onClick={handlePage}>
                        {loading ? (
                          <div className="spinner"></div>
                        ) : (
                          <span>Back to Website</span>
                        )}
                      </button>
                    </div>
            </div>
        </div>
    )
}

export default ApplicationRecevied