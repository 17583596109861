import React , {useState , useRef} from 'react'

export default function FiatTransferStepThree(props) {
    const [code, setCode] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  // Function to handle input change for each digit
  const handleInputChange = (index, event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      props.setOtp(newCode.join(''))
      // Move focus to the next input field
      if (index < code.length - 1 && value.length === 1) {
        inputRefs.current[index + 1].current.focus();
      }
    }
  };
  return (
    <div className='col-12 d-flex flex-column align-items-center justify-content-center stepThreeoContentDiv'>
    <p className='h'>
    Enter OTP
    </p>
    <p className='pb mb-0'>
    A 6-digit is sent to your email address
    </p> 
    <div className="code-input-container">
        {code.map((digit, index) => (
          <input
            key={index}
            ref={inputRefs.current[index]}
            type="text"
            value={digit}
            maxLength="1"
            onChange={(e) => handleInputChange(index, e)}
          />
        ))}
      </div>
      <span className='text-danger mb-2'>
        {
          props.errorText
        }
      </span>
 </div>
  )
}
