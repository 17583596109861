import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import UpperNav from './UpperNav'
import { useGetUserAccountDetailsQuery, usePasswordResetEmailMutation, usePasswordResetMutation } from '../../services/userAuthApi';
import { getToken } from '../../services/LocalStorageService';
import { usePasswordChangeMutation } from '../../services/userAuthApi';
import Swal from 'sweetalert2';
import MobileOTP from './MobileOTP';
import { CurrencyDropdownItems } from '../../constants/AllCurencies';
import { currencyMapping } from '../../constants/currencyMapping';
import { toast } from 'react-toastify';
import ModalComponent from '../ModalComponent';
import PasswordTooltip from '../accounts/PasswordTooltip';
import copy from "copy-to-clipboard";
import QRCode from 'qrcode.react';
import Totp_validation from './Totp_validation';
import { useResetPasswordProcessMutation } from '../../services/userAuthApi';
const QRCodeComponent = ({ qrCodeString }) => {
  return (
    <div>
      <QRCode value={qrCodeString} />
    </div>
  );
};

function DropdownItem({ text, code, imgSrc, onSelect }) {
  return (
    <button className="dropdown-item d-flex gap-3 align-items-center" onClick={() => onSelect(text, code, imgSrc)}>
      <img loading='lazy' src={process.env.PUBLIC_URL + imgSrc} alt="Currency Image" className="currencySwapOptionImg" />
      <div className='d-flex flex-column'>
        {text}
        <span>{code}</span>
      </div>
    </button>
  );
}

export default function Account(props) {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [enableClick, setenableClick] = useState(false)
  const [secretKey, setsecretKey] = useState('')
  const [isTotp, setisTotp] = useState('')
  const [qrCodeString, setQrCodeString] = useState('');

  const [oldpassword, setoldPassword] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, showError] = useState('')
  const [errorText, setErrorText] = useState('')

  const [code, setCode] = useState('')
  const [otp, setotp] = useState('')

  const [currentPage, setCurrentPage] = useState(1);

  const { access_token } = getToken(); // Replace 'auth' with your actual state slice name
  const [baseCurrencySign, setBaseCurrencySign] = useState("£");
  const [baseCurrency, setBaseCurrency] = useState("eur");

  const [changePasswordApi, { isLoading }] = usePasswordChangeMutation()
  // Call the query hook with the access token
  const { data, isFetching, isError } = useGetUserAccountDetailsQuery(access_token);
  const [dropDown, showDropDown] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null);

  const [userData,setUserData] = useState()
  const [baseApi,setbaseApi] = useState(false)

  const [showDetail, setShowDetail] = useState(false)

  const [passwordReset , {Loading} ] = usePasswordResetMutation() 
  const copyToClipboard = () => {
    copy(secretKey);
    // alert(`You have copied "${copyText}"`);
    toast.success(`You have copied ${secretKey}`);
  };

  const [step, setStep] = useState(1)


  const fetchUserProfileData = async () => {
    try {
      // const response = await fetch('http://127.0.0.1:8000/api/wallet/user-balance/', {
      const response = await fetch(
        "https://evistialab.com/api/v1/get-user-profile-data/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
      if (response.status === 401) {
        // If unauthorized, navigate to the login page
        navigate("/login");
        return; // Stop further execution in this block
      }
      const data = await response.json();
      if (data) {
        console.log(data, 'data is here')
        setEmail(data?.email)
        setisTotp(data?.is_totp)
        setUserData(data)
        const currencyCode = data?.base_currency?.toUpperCase();
        setBaseCurrency(currencyCode);
        const detail = currencyMapping[currencyCode];

        if (detail) {
          setBaseCurrencySign(detail?.sign);
        } else {
          setBaseCurrencySign("£");
        }
        console.log(baseCurrency);
      }
    } catch (error) {
      console.error("Error fetching ETH balance:", error);
    }
  };

  useEffect(() => {
    fetchUserProfileData();
    const detail = currencyMapping[baseCurrency.toUpperCase()];
    setSelectedOption({ text: detail?.shortName, code: detail?.apiShortName, imgSrc: detail?.imgSrc });
    showDropDown(false);
    // if (isTotp === false){
    //   setStep(step + 1)
    // }
  }, [baseCurrency, baseCurrencySign,baseApi]);

  
  const enableTotp = async () => {
    console.log('clicked')
            // Fetch API call
            fetch('https://evistialab.com/api/v1/totp/setup/', {
              method: 'GET',  // Assuming it's a POST request
              headers: {
                  'Content-Type': 'application/json',  // Assuming the API expects JSON
                  'Authorization': `Bearer ${access_token}`,  // Include the access token if required
              },  // Convert the JavaScript object to a JSON string
            })
              .then(response => {
                  if (!response.ok) {
                    console.log(response,'response is here')
  
                      // if (response.status === 401) {
                      //     navigate('/login')
                      // }
                  }
                  return response.json();
              })
              .then(data => {
                console.log(data,'data is heressss')
                setenableClick(true)
                setsecretKey(data.secret_key)
                setQrCodeString(data.qr_code)
                  // const formattedAmount = Number(data.converted_amount).toFixed(2);
                  // setapiVal(formattedAmount);
                  // setfeeAmount(data.fee_amount)
                  // setexchangeRat(data.exchange_rate)
                  // setinitial(1)
              })
              .catch(error => console.error('Error fetching data:', error));  
  }
  
  const [totp,settOtp] = useState()


  const dropDownHandle = () => {
    showDropDown(!dropDown)
  }


  const handleOptionSelect = (optionText, optionCode, optionImgSrc) => {
    setSelectedOption({ text: optionText, code: optionCode, imgSrc: optionImgSrc });
    showDropDown(false);
    handleBaseCurrencyChange(optionCode.toLowerCase())
  };

  

  // const passwordChangeHandler = async () => {
  //   if (otp && code.length === 6) {
  //     console.log('clicked yes matched')
  //     const formData = {
  //       otp: code,
  //       current_password: oldpassword,
  //       new_password: password
  //     }
  //     const res = await changePasswordApi(formData)
  //     if (res) {
  //       console.log(res, 'res is here')
  //     }
  //   }

  // }


  console.log(otp,'code is here')

  const handleBaseCurrencyChange = async (currencySign) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${access_token}`);
    myHeaders.append("Cookie", "csrftoken=4tdZZWxBjQiiZ3L6TlOsZN8kweC9kg4tR1QHsAyXijUA9DMSkjk8Bt0e24ynNTBB");

    const raw = JSON.stringify({
      "new_base_currency": currencySign
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    try {
      
      const res = await fetch("https://evistialab.com/api/v1/settings/change-currency/", requestOptions)
      const data = await res.json()
      if (data.status === 401 || data.code === "token_not_valid") {
        navigate('/login');
        return;
      }
      if (data.message) {
        toast.success(`${data.message}`);
      }
      if (data.error) {
        toast.warning(`${data.error}`);
      }
    } catch (error) {
      console.error("error durign handling data", error)
    }
    setbaseApi(!baseApi)
  }

  const [emailResetApi, { isLoadingEmail }] = usePasswordResetEmailMutation()

  const [resetPassword, {isLoadingResetPassword}] = useResetPasswordProcessMutation()
  const [changePasswordBtn,setChangePasswordBtn] = useState(false)

  const handlePasswordChange = async (event) => {

    

    event.preventDefault();

    if (step == 1){
      if (email && password && confirmPassword && password === confirmPassword && oldpassword) { 
        setChangePasswordBtn(true)  
        setStep(step + 1)
      } else if (!oldpassword) {
        showError('Enter your Old Password')
        setChangePasswordBtn(false)
  
      }
      else if (password.length < 8) {
        showError('Your Password does not matched with Password Policy')
        setChangePasswordBtn(false)
  
      } else if (password !== confirmPassword) {
        showError('Password and Confirm Password does not matched')
        setChangePasswordBtn(false)
      }

    }else if (step === 2){
      if (totp && totp.length === 6){
        try {
          // console.log('data for api',formData)
          const response = await fetch(
            "https://evistialab.com/api/v1/totp/verify/",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${access_token}`,
              },
              body : JSON.stringify({otp:String(totp)})
            }
          );
          if (response.status === 401) {
            // If unauthorized, navigate to the login page
            navigate("/login");
            return; // Stop further execution in this block
          }
          const data = await response.json();
          if (data) {
            if (data.message){
              try {
                  const resendOTPData = { 'email': email }
                  const res = await emailResetApi(resendOTPData)
                  if (res.error) {
                    console.log(res.error, 'resend otp error is here', resendOTPData, 'resendOTPData')
                  } else if (res.data) {
                    toast.success(`${res.data.message}`);
                    setStep(step + 1)
                    setErrorText('')
                  }
                  if (res.status === 401) {
                    // If unauthorized, navigate to the login page
                    navigate('/login');
                    return; // Stop further execution in this block
                  }

              } catch (error) {
                console.error("Error ", error);
              }
            }
          }
          if (data.error) {
            setErrorText(data.error)
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
      else{
        setErrorText('Enter Your OTP')
      }
      
    }else if(step === 3){
      console.log(step,otp,'clicked')
      if (otp){
        setErrorText('')
        const formData = {
          'otp': otp,
          'current_password': oldpassword,
          'new_password': password
        }

          try {
        console.log(formData,'formData')

            const response = await resetPassword(JSON.stringify(formData))
            // await fetch('https://evistialab.com/api/v1/settings/update-password/', {
            //         method: 'POST',
            //         headers: {
            //             'Content-Type': 'application/json',
            //             'Authorization': `Bearer ${access_token}`,
            //         },
            //         body: JSON.stringify(formData)
            // });
            console.log(response,'response is here')
            if (response.data){
              toast.success(`${response.data.message}`);
              setCurrentPage(currentPage - 1)
              setStep(step -2)
            }
            if (response.error){
             
              toast.error(`Old Password is Wrong`);
              setStep(step -2)
            }
            if (response.status === 401) {
              navigate("/login");
              return; // Stop further execution in this block
            }
            // if (data) {
            //   if (data.message){
            //     console.log(data)
            //   }
            // }
            // if (data.error) {
            //   console.log(data.error,'error')
            //   setErrorText(data.error)
            // }
          } catch (error) {
            console.error("Error :", error);
          }
      }
      else{
        setErrorText('Please Enter Valid OTP')
      }
      console.log('verify otp')
    }
    else{
      console.log('we are in else')
    }
    console.log('clicked',step)


  }

  // const [showModal, setShowModal] = useState(false);
  return (
    <div className='sideBarMainContent  px-sm-0 px-3'>
      <div className='WMainDiv '>
        <UpperNav />
        {
          step === 1 ?
            <>
              <div className='row mt-4 justify-content-center  mx-1'>
                <div className='d-flex gap-sm-4 gap-3 flex-wrap px-0'>
                  {/* <h2 className={`ACHeading ${currentPage === 1 ? 'active' : ""}  ps-0`} onClick={() => setCurrentPage(1)}>
                    Currency Settings
                  </h2> */}
                  <h2 className={`ACHeading ${currentPage === 1 ? 'active' : ""}  ps-0`} onClick={() => setCurrentPage(1)}>
                    Account Information
                  </h2>
                  <h2 className={`ACHeading ${currentPage === 2 ? 'active' : ""}  ps-0`} onClick={() => setCurrentPage(2)}>
                    Security Information
                  </h2>
                </div>
              </div>

              {
                currentPage === 1 ?
                  <div className='row '>

                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-body modalVerify">
                            <div className='cardUpperRow mb-4'>
                              <div className='col-12 py-2'>
                                <h3>
                                  Verify Phone Number OTP
                                </h3>
                              </div>
                            </div>
                            <MobileOTP />
                            <div className='row justify-content-center mt-4 mb-3'>
                              <div className='col-6'>
                                <div className='WHPS3bottomDiv row '>
                                  <a onClick={() => {
                                    navigate('/wallet/change-Phone-number')
                                  }}> Verify Number </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mt-4 cardDetailsCard'>
                    <div className='col-12 mb-4'>
                              {/* <p className='ddLabel '>
                              
                              </p> */}
                              <label htmlFor="" className="form-label AcPwLabels "> Base Currency</label>
                              <div className='AcCurrencyddDiv  d-flex gap-3 align-items-center justify-content-between' onClick={dropDownHandle}>
                                <div className='d-flex gap-2'>
                                  <img loading='lazy' src={selectedOption?.imgSrc || '../images/eur.svg'} alt="Currency Image" className="currencySwapOptionImg" />
                                  <h3 className='mb-0 swapTitle'>{selectedOption?.text || 'EUR'}</h3>
                                </div>
                                <img loading='lazy' src="../images/WHPArrowD.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow" />

                                {/* <div className={`dropdown-menu CSwapDropDown ${dropDown ? 'show' : ''}`}  onMouseLeave={() => showDropDown(false)}>
                          <DropdownItem text="GBP " imgSrc="../images/g.svg" onSelect={handleOptionSelect} />
                          <DropdownItem text="EURO " imgSrc="../images/eur.svg" onSelect={handleOptionSelect} />
                          <DropdownItem text="AED " imgSrc="../images/aed.svg" onSelect={handleOptionSelect} />
                          <DropdownItem text="YEN " imgSrc="../images/yen.svg" onSelect={handleOptionSelect} />
                          <DropdownItem text="BHT " imgSrc="../images/bht.svg" onSelect={handleOptionSelect} />
                          <DropdownItem text="Rub " imgSrc="../images/rub.svg" onSelect={handleOptionSelect} />
                          <DropdownItem text="Mdl " imgSrc="../images/mdl.svg" onSelect={handleOptionSelect} />
                          <DropdownItem text="PKR " imgSrc="../images/pkr.svg" onSelect={handleOptionSelect} />
                        </div> */}
                                <div className={`dropdown-menu CurrencySwapDropDown007 ${dropDown ? 'show' : ''}`} onMouseLeave={() => showDropDown(false)}>
                                  {CurrencyDropdownItems.map((item, index) => (
                                    <DropdownItem key={index} text={item.name} code={item.currency} imgSrc={item.imgSrc} onSelect={handleOptionSelect} />
                                  ))}
                                </div>
                              </div>
                            </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                          <div className="mb-4">
                            <label htmlFor="email" className="form-label AcPwLabels "> Account Holder Name</label>
                            <input
                              type="text"
                              className="form-control formInputs"
                              id="address"
                              required
                              placeholder='Account Holder Name'
                              value={`${userData?.account?.firstName} ${userData?.account?.lastName}`}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                          <div className="mb-4">
                            <label htmlFor="email" className="form-label AcPwLabels ">Registed Email</label>
                            <div className='modalInput'>
                              <input
                                type="email"
                                className="form-control formInputs"
                                id=""
                                required
                                placeholder='Registed Email'
                                value={`${userData?.email} `}
                                readOnly
                              />
                              <img
                                loading='lazy'
                                src={process.env.PUBLIC_URL + "/images/pencilinInsideBank.svg"}
                                className='pencilImg'
                                alt="Logo"
                                width="16"
                                height="16"
                                data-bs-toggle="modal"
                                data-bs-target="#anotherModal"
                                style={{ cursor: "pointer" }}
                                
                              />
                              <ModalComponent
                                modalId="anotherModal"
                                modalBody={<p className='modalDesc'>Please connect with customer support to change your email or phone number</p>}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                          <div className="mb-4">
                            <label htmlFor="email" className="form-label AcPwLabels ">Registed Phone Number</label>
                            <div className='modalInput'>
                              <input
                                type="email"
                                className="form-control formInputs"
                                id=""
                                required
                                placeholder='Registered Phone Number'
                                value={`${userData?.account?.phoneNumber}`}
                                readOnly
                              />
                              <img
                                loading='lazy'
                                src={process.env.PUBLIC_URL + "/images/pencilinInsideBank.svg"}
                                className='pencilImg'
                                alt="Logo"
                                width="16"
                                height="16"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                style={{ cursor: "pointer" }}
                              />

                              <ModalComponent
                                modalId="exampleModal"
                                modalBody={<p className='modalDesc'>Please connect with customer support to change your email or phone number</p>}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                          <div className="mb-4">
                            <label htmlFor="email" className="form-label AcPwLabels ">Registered Country</label>
                            <input
                              type="email"
                              className="form-control formInputs"
                              id=""
                              required
                              placeholder='Registered Country'
                              value={`${userData?.account?.country}`}
                                readOnly
                            />
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className="mb-4">
                            <label htmlFor="email" className="form-label AcPwLabels ">Billing Address</label>
                            <input
                              type="email"
                              className="form-control formInputs"
                              id=""
                              required
                              placeholder='Billing Address'
                              value={`${userData?.account?.streetAddress}, ${userData?.account?.city}, ${userData?.account?.state}, ${userData?.account?.zipCode}`}
                              readOnly
                            />
                          </div>
                          {/* <div className='row WHPS3bottomDiv'>
                            <a className='btnChange' to="/wallet/index" style={{ cursor: 'pointer', color: '#2645E0' }}>Submit</a>
                          </div> */}

                          
                        </div>
                      </div>
                      
                    
                  </div> :
                  currentPage === 2 ?
                    <>
                      <div className='row mt-3 justify-content-center'>
                        <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 cardDetailsCard px-lg-4 px-4'>
                          <form >
                            <div className='row'>
                              <div className="mb-4">
                                <label htmlFor="email" className="form-label AcPwLabels "> Current Password</label>
                                <input
                                  type="password"
                                  className="form-control formInputs"
                                  id="email"
                                  placeholder='**********'
                                  value={oldpassword}
                                  onChange={(e) => setoldPassword(e.target.value)}
                                  // Use the new event handler
                                  required
                                />
                              </div>

                              <div className="mb-4">
                                <div className='d-flex align-items-center gap-2'>
                                  <label htmlFor="email" className="form-label AcPwLabels "> New Password</label>
                                  <PasswordTooltip />
                                </div>

                                <input
                                  type="password"
                                  className="form-control formInputs"
                                  id="email"
                                  placeholder='**********'
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  // Use the new event handler
                                  required
                                />

                              </div>
                              {/* <div className='d-flex justify-content-end'>
                                <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip" data-bs-placement="top" title=" Password Policy : Password must be Minimum 8 to Maximum 16 characters in length. At least 1 small letter [a-z]. At least 1 number [0-9]. To further strengthen your password, you may use any special character (e.g. @ & # $ % etc)"></i>
                              </div> */}

                              {/* <div className='pwInfoText mb-2'>
                                <p>
                                  Password Policy:
                                </p>

                                <ul>
                                  <li>
                                    Password must be Minimum 8 to Maximum 16 characters in length.
                                  </li>
                                  <li>
                                    At least 1 small letter [a-z]
                                  </li>
                                  <li>
                                    At least 1 number [0-9]
                                  </li>
                                  <li>
                                    To further strengthen your password, you may use any special character (e.g. @ & # $ % etc)
                                  </li>
                                </ul>
                              </div> */}


                              <div className="mb-0">
                                <label htmlFor="email" className="form-label AcPwLabels "> Repeat Password</label>
                                <input
                                  type="password"
                                  className="form-control formInputs"
                                  id="email"
                                  placeholder='**********'
                                  value={confirmPassword}
                                  onChange={(e) => setConfirmPassword(e.target.value)}
                                  // Use the new event handler
                                  required
                                />
                              </div>
                            </div>

                            <p className='text-danger pt-2' >
                              {error}
                            </p>

                            <div className='row WHPS3bottomDiv'>
                              <a 
                                className='btnChange'
                                to="/wallet/index" 
                                style={{ cursor: 'pointer', color: '#2645E0' }}
                                onClick={handlePasswordChange}
                                disabled={changePasswordBtn ? true : false}
                              >
                                Change Password
                                </a>
                            </div>
                          </form>
                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 px-lg-4 px-4 mt-lg-0 mt-5'>
                          <div className="col-12 d-flex justify-content-between align-items-center flex-column">
                            <h3>App Authenticator</h3>
                            <div className='d-flex gap-1 mt-3'>
                              {
                                isTotp === false ? 
                              <button
                                className='enableBtn btn-lg btn-primary' data-bs-toggle="modal" data-bs-target="#exampleModalScan"
                                onClick={enableTotp}
                              >
                                Enable
                              </button>
                              :
                              <>
                              <button
                                className='enableBtn btn btn-danger px-5'
                                data-bs-toggle="modal"
                                data-bs-target="#anotherModals"
                              >
                                Disable
                              </button> 
                              <ModalComponent
                              modalId="anotherModals"
                              modalBody={<p className='modalDesc'>Please connect with customer support to change your Authenticator Settings</p>}
                            />
                            </>
                              }

                              <div className="modal fade" id="exampleModalScan" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered">
                                  <div className="modal-content px-3 py-3 cardDetailsCard cardDetailsCard">
                                    <div className="modal-header border-0">
                                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                      <div className='QrscannerDiv'>
                                        <div className='imgQRScan'>
                                          <>
                                          {/* {qrCodeString ? <QRCodeComponent qrCodeString={qrCodeString} /> : <p>Loading...</p>} */}
                                          {qrCodeString ? <img loading='lazy' src={`data:image/png;base64,${qrCodeString}`} alt="Qr Image" className='qrImg'/>
                                          : <p>Loading...</p>}
                                            {/* <img loading='lazy' src={process.env.PUBLIC_URL + `/images/${props.image}` } alt="settingImg" className="currencyImgOnQr" /> */}
                                          </>
                                        </div>
                                      </div>
                                      <div className='inputDiv'>
                                        <p className='inputTitle'>
                                          Secret Key
                                        </p>
                                        <div className='detailInput ps-3'>
                                          <p>
                                              {secretKey}
                                          </p>
                                          <img loading='lazy' src={process.env.PUBLIC_URL + `/images/Group 1036.svg`} alt="settingImg" className="ps-2" onClick={copyToClipboard} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        {/* <div className='col-12 mt-4 '>
                          <div className='cardDetailsCard'>
                            all details goes here
                            all details goes here
                            all details goes here
                            all details goes here
                            all details goes here
                            all details goes here
                            all details goes here
                            all details goes here
                            all details goes here
                            all details goes here
                            all details goes here
                            all details goes here
                            all details goes here
                            all details goes here
                            all details goes here
                            all details goes here
                            all details goes here
                            all details goes here

                          </div>

                        </div> */}
                      </div>
                    </> :
                    <>
                      
                    </>
              }







            </>
            : step === 2 ? 
            <div className='row mt-5 justify-content-center'>
              <div className='col-xxl-5 col-lg-8 col-12'>
                <div className='fiatTransferCard mt-md-5 mt-2 ' >
                <Totp_validation totp={totp} settOtp={settOtp} errorText={errorText} />
                  <div className='row justify-content-center'>
                    <div className='col-md-6 col-9'>
                      <div className='row WHPS3bottomDiv'>
                        <a className='btnChange' style={{ cursor: 'pointer', color: '#2645E0' }} onClick={handlePasswordChange}>Verify</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            : <div className='row mt-5 justify-content-center'>
              <div className='col-xxl-5 col-lg-8 col-12'>
                <div className='fiatTransferCard mt-md-5 mt-2 ' >
                  <MobileOTP otp={otp} setotp={setotp}  errorText={errorText}/>
                  <div className='row justify-content-center'>
                    <div className='col-md-6 col-9'>
                      <div className='row WHPS3bottomDiv'>
                        <a className='btnChange' style={{ cursor: 'pointer', color: '#2645E0' }} onClick={handlePasswordChange}>Change  Password </a>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
        }


      </div>
    </div>
  )
}
