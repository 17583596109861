import React, { useEffect, useState } from 'react'
import UpperNav from './UpperNav'
import MobileOTP from './MobileOTP'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
function ChangePhNumber() {

    const [currentNumber,setCurrentNumber] = useState()
    const [newNumber,setNewNumber] = useState()
    const [step,setStep] = useState(1)
    const navigate = useNavigate()

    useEffect(() => {
        if (step === 3){
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: "Your Phone Number has been changed successfully", // Change to your data property
                }).then(() => {
                    navigate('/wallet/account-information'); // Redirect to another page
            })
        }
    }, [step])
    
    return (
        <div className='sideBarMainContent '>
        <div className='WMainDiv '>
        <UpperNav title={`Change Phone Number`} symbol={" "}/>
    
        
        <div className='row mt-5 justify-content-center'>
            <div className='col-xxl-5 col-lg-8 col-12'>
                <div className='fiatTransferCard mt-5' >
                   {
                    step === 1 ?
                    <div className='row mt-4 justify-content-center'>
                    <div className='col-10'>
                        <div className="mb-4">
                        <label htmlFor="number" className="form-label contactusLabels pb-2"> Current Phone Number</label>
                        <input
                            type="number"
                            className="form-control changeNumberInput"
                            id="number"
                            placeholder={'Current Phone Number'}
                            value={currentNumber}
                            onChange={(e)=>{setCurrentNumber(e.target.value)}} // Use the new event handler
                            required
                            maxLength="50"
                        />
                        </div>
                        <div className="mb-4">
                        <label htmlFor="number" className="form-label contactusLabels pb-2"> New Phone Number</label>
                        <input
                            type="number"
                            className="form-control changeNumberInput"
                            id="number"
                            placeholder={'New Phone Number'}
                            value={newNumber}
                            onChange={(e)=>{setNewNumber(e.target.value)}} // Use the new event handler
                            required
                            maxLength="50"
                        />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='WHPS3bottomDiv row mt-5'>
                            <a onClick={()=>{
                            setStep(step + 1)
                            }}> Change Number </a>                  
                        </div>
                    </div>
                
                </div> : <>
                            <div className='cardUpperRow mb-4'>
                                <div className='col-12 py-2'>
                                <h3>
                                Verify Phone Number OTP
                                </h3>
                                </div>
                            </div>
                            <MobileOTP />
                            <div className='row justify-content-center mt-4 mb-3'>
                                <div className='col-6'>
                                        <div className='WHPS3bottomDiv row '>
                                            <a onClick={()=>{setStep(step + 1)}}> Change Number </a>                  
                                        </div>
                                </div>
                            </div>
                        </>
                   }
                </div>

                
            </div>
        </div>


    </div>
    </div>
      )
}

export default ChangePhNumber