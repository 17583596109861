import React,{useState} from 'react'
import UpperNav from './UpperNav'
import EditRecipients from './EditRecipients'
import AddRecipients from './AddRecipients'
import AddBillDetails from './AddBillDetails'
import EditBillDetails from './EditBillDetails'

function BillsManagment() {
    const [currentPage,setCurrentStep] = useState('details') 
    const [consumerNumber, setconsumerNumber] = useState('345734656345')
    return (
        <>
        {
            currentPage === 'details' ? 
                <div className='sideBarMainContent '>
                <div className='WMainDiv '>
                <UpperNav title={`Bills Management`} symbol={" "}/>
            
                
                <div className='row mt-5 justify-content-center'>
                <div className='col-xxl-5 col-lg-8 col-12'>
                    <div className='fiatTransferCard mt-md-5 mt-2 ' >
                        <div className='cardUpperRow mb-3'>
                            <div className='col-9'>
                            <h3>
                            Saved
                            </h3>
                            </div>
                            <div className='col-3 d-flex justify-content-end mb-2' style={{cursor:"pointer"}}>
                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/addReciepent.svg" } alt="Logo"  className="" onClick={()=>{setCurrentStep('add')}}/>

                            </div>
                        </div>

                        <div className='reciepentDetails d-flex justify-content-between align-items-center mb-3'>
                            <div className='textDiv'>
                                <h3>
                                Steve Thompson
                                </h3>
                                <p> 
                                Clydesdale Bank
                                </p>
                                <p>
                                23456789876
                                </p>
                            </div>

                            <div className='imgDiv d-flex gap-4 '>
                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/pencil 1.svg" } alt="Logo"  className="" onClick={()=>{setCurrentStep('edit')}}/>

                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/trash (1) 1.svg" } alt="Logo"  className="" />
                            </div>
                        </div>

                        <div className='reciepentDetails d-flex justify-content-between align-items-center mb-3'>
                            <div className='textDiv'>
                                <h3>
                                Steve Thompson
                                </h3>
                                <p> 
                                Clydesdale Bank
                                </p>
                                <p>
                                23456789876
                                </p>
                            </div>

                            <div className='imgDiv d-flex gap-4 '>
                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/pencil 1.svg" } alt="Logo"  className="" onClick={()=>{setCurrentStep('edit')}}/>

                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/trash (1) 1.svg" } alt="Logo"  className="" />
                            </div>
                        </div>


                        <div className='reciepentDetails d-flex justify-content-between align-items-center mb-3'>
                            <div className='textDiv'>
                                <h3>
                                Steve Thompson
                                </h3>
                                <p> 
                                Clydesdale Bank
                                </p>
                                <p>
                                23456789876
                                </p>
                            </div>

                            <div className='imgDiv d-flex gap-4 '>
                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/pencil 1.svg" } alt="Logo"  className="" onClick={()=>{setCurrentStep('edit')}}/>

                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/trash (1) 1.svg" } alt="Logo"  className="" />
                            </div>
                        </div>


                        <div className='reciepentDetails d-flex justify-content-between align-items-center mb-3'>
                            <div className='textDiv'>
                                <h3>
                                Steve Thompson
                                </h3>
                                <p> 
                                Clydesdale Bank
                                </p>
                                <p>
                                23456789876
                                </p>
                            </div>

                            <div className='imgDiv d-flex gap-4 '>
                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/pencil 1.svg" } alt="Logo"  className="" onClick={()=>{setCurrentStep('edit')}}/>

                            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/trash (1) 1.svg" } alt="Logo"  className="" />
                            </div>
                        </div>

                        
            
                </div>
            
            
                </div>
                </div>
                </div>
                </div>
            :   currentPage === 'edit' ?
                    <EditBillDetails
                        currentPage={setCurrentStep}
                        consumerNumber={consumerNumber}
                        setconsumerNumber={setconsumerNumber}
                    /> :
                    currentPage === 'add' ?
                    <AddBillDetails
                    setCurrentStep={setCurrentStep}
                    /> : ""

        }
        </>
      )
}

export default BillsManagment