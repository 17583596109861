import React from 'react'
import AnimatedPage from '../AnimatedPage';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ScrollToTop from '../scrollToTop';


export default function RegisrterPersonalAccStepOne({ formData, handleInputChange ,error , errorId }) {
  const {t} = useTranslation()
    // const [email,setEmail] = useState('')
    //consolelog('Form Data',formData)

    // Custom Select Options |
    const optionList = [t('PACC.S1.lb.DD.1'),t('PACC.S1.lb.DD.2'), t('PACC.S1.lb.DD.3'),t('PACC.S1.lb.DD.4'), t('PACC.S1.lb.DD.5')];

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(t('PACC.S1.ph.3'));
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  
    const selectOption = (option) => {
    //consolelog(option,'Selected Option is Here')
    formData.maritalStatus = option
      setSelectedOption(option);
      setIsOpen(false);
    };


  return (
        // <div className='container-fluid'>
        //     <div className='mainContainerSignups'>
        <AnimatedPage>
          <ScrollToTop/>
        <>
                    <div className='row hrSmHide'   >
                        <div className='d-flex justify-content-center'>
                        <img loading='lazy' alt="Vr Line" src="../../../images/p1.svg" className='imgResponsive px-5 upperImgRegister'/>
                        </div>

                        <h2 className='SignupHeadings'>
                        {t('PACC.S1.H')}
                        </h2>
                        <img loading='lazy' alt="Vr Line" src="../../../images/Vector 4.svg" className='imgResponsive'/>
                    </div>
                    <div className='row pt-5'   >
                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                        <div className="mb-5 px-3">
                        <label htmlFor="email" className="form-label contactusLabels pb-2">{t('PACC.S1.lb.1')}</label>
                        <input
                            
                            type="text"
                            className="form-control formInputs"
                            id="firstName"
                            placeholder={t('PACC.S1.ph.1')}
                            value={formData.firstName}
                            onChange={(e) => handleInputChange('firstName', e.target)}
                            required
                        />
                        {errorId === 'firstName' ? <span className='inputFiledError pt-1'>{error}</span> : ''}
                        </div>
                        </div>

                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                        <div className="mb-5 px-3">
                        <label htmlFor="email" className="form-label contactusLabels pb-2">{t('PACC.S1.lb.2')}</label>
                        <input
                            type="text"
                            className="form-control formInputs"
                            id="lastName"
                            placeholder={t('PACC.S1.ph.2')}
                            value={formData.lastName}
                            onChange={(e) => handleInputChange('lastName', e.target)}
                            required
                        />
                        {errorId === 'lastName' ? <span className='inputFiledError pt-1'>{error}</span> : ''}
                        </div>
                        </div>

                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                        <div className="mb-5 px-3">
                        <label htmlFor="email" className="form-label contactusLabels pb-2">{t('PACC.S1.lb.3')}</label>
                        <input
                            type="date"
                            className="form-control formInputs"
                            id="dob"
                            value={formData.dob}
                            onChange={(e) => handleInputChange('dob', e.target)}
                            required                   
                        />
                        </div>
                        </div>

                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                        <div className="mb-5 px-3">
                        <label htmlFor="email" className="form-label contactusLabels pb-2">{t('PACC.S1.lb.4')}</label>
                        <div className='customSelectOptions ' onClick={toggleDropdown}>
                        <p style={{margin:'0'}} className='formInputsM' >{selectedOption} <input type='hidden' />
                         <i className={`fa fa-caret-${isOpen ? 'up' : 'down'} `}></i></p>
                        <img loading='lazy' alt="Vr Line" src="../../../images/Vectorfill.svg" className='imgResponsive '/>
                        </div>
                        <div className="container d-flex justify-content-end pe-0">
                            {isOpen && (
                                <div className="options contactusLabels">
                                {optionList.map((option, index) => (
                                    <div className="option" key={index} onClick={() => selectOption(option)}>
                                    {option}
                                    </div>
                                ))}
                                </div>
                            )}
                            </div>  
                          </div>
                        </div>

                        {/* <div className="S5BtnDiv  ps-4 ms-1 mb-5 pb-5">
                      <button className='s5Btn' onClick={handleNextStep}> <span className='s5Btntxt'> Next </span> 
                      </button> 
                    </div> */}

                    </div>
            {/* //     </div> */}
            {/* // </div> */}
            </>
          </AnimatedPage>
  )
}
