import React from 'react'
import AnimatedPage from '../AnimatedPage'
import { useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom'
export default function Register() {
    const {t} = useTranslation()
  return (
    <AnimatedPage>
        <div className='container-fluid'>
            <div className='row mainContainer'>
                <div className='headingsdiv'  >
                <h1 className='p10S4Heading' style={{textAlign:'left'}}> {t('RG.h.0')} {'\u00a0'} -
                <br className='d-sm-none d-block'/> 
                    <b className='p8S1Heading' style={{color:'#071EED'}}> 
                    {t('RG.h.1')}
                    </b>
                </h1>
                <p className='authSubHeading '>
                {t('RG.h.2')}
                </p>
                </div>
                <div className='row'  >
                    <div className='col-lg-6 col-12'>
                    <p className='accTypesHeading'>
                    {t('RG.h.3')}
                        </p>
                    </div>
                    <div className='col-6 d-lg-block d-none'>
                    <p className='accTypesHeading2'>
                    {t('RG.h.4')}
                        </p>
                    </div>
                </div>
               
                <div className='accountsTypeDiv' >
                    {/* <div className='col-4' > */}
                        <div className='registertypeCards'>
                            <p className='registertypeCardsHeading'>
                            {t('RG.h.5')}
                            </p>

                            <p className='registertypeCardsPara'>
                            {t('RG.p.1')}
                            </p>

                            <button className='applyNowBtns'>
                                <span >
                               <Link to="/register/personal/Personal-details/" className='gradient-text'>
                               {t("btnING.9")}
                               </Link> 
                                </span>
                            </button>
                        </div>
                    {/* </div> */}
                    {/* <div className='col-4 d-flex ' > */}
                        {/* <div className='registertypeCards'>
                            <p className='registertypeCardsHeading'>
                            {t('RG.h.6')}
                            </p>

                            <p className='registertypeCardsPara'>
                            {t('RG.p.2')}
                            </p>

                            <button className='applyNowBtns'>
                                <span className='gradient-text'>
                                <Link to="/register/savings/savings-details/" className='gradient-text'>
                                {t("btnING.9")}
                               </Link> 
                                </span>
                            </button>
                        </div> */}
                    <img loading='lazy' alt="Vr Line" src="../images/Vector 12.svg" className='imgResponsive px-xxl-5 px-1 d-lg-block d-none'/>
                    {/* </div> */}
                    {/* <div className='col-4' > */}
                    <img loading='lazy' alt="Vr Line" src="../images/vrRegMb.svg" className='imgResponsive px-xxl-5 px-1 d-lg-none d-block'/>
                    <p className='accTypesHeading2 d-lg-none d-block'>
                    {t('RG.h.4')}
                    </p>
                        <div className='registertypeCards'>

                            <p className='registertypeCardsHeading'>
                            {t('RG.h.7')}
                            </p>

                            <p className='registertypeCardsPara1'>
                            {t('RG.p.3')}
                            </p>

                            <button className='applyNowBtns'>
                                <span className='gradient-text'>
                                <Link to="/register/buisness/personal-information/" className='gradient-text'>
                                {t("btnING.9")}
                               </Link> 
                                </span>
                            </button>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    </AnimatedPage>
  )
}
