import React from 'react';

const ModalComponent = ({ modalId, modalTitle, modalBody }) => {
  return (
    <div className="modal fade" id={modalId} tabIndex="-1" aria-labelledby={`${modalId}Label`} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content px-3 py-3 cardDetailsCard">
          <div className="modal-header border-0 p-0">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body pt-3 py-4 px-0">
            {modalBody}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
