import React from 'react'
import AnimatedPage from '../AnimatedPage'
import { useTranslation } from 'react-i18next'
import p6S3RightSideImg from '../../static/images/p6S3RightSideImg.svg'


// CSS 
import '../../static/css/index.css'
import '../../static/css/common.css'
import '../../static/css/navbar.css'
import { Link } from 'react-router-dom'


export default function AboutUs() {
  const {t} = useTranslation()
    return (
      <AnimatedPage>
        <div >
          {/* Section One Start  */}
            {/* <div className='homepageContainerP3 pt-5 mt-5 pb-5' >
                <div className='container-fluid'>
                    <div className='row mt-5'>
                      <div className='col-md-6 col-12 d-flex align-items-center justify-content-md-start justify-content-center'>
                        <h3 className='S1SubHeading05'  >
                        {t('AU.S1.h.1')} <br></br>
                        {t('AU.S1.h.2')}
                        </h3>
                    </div>
                      <div className='col-md-6 col-12 d-flex justify-content-center'  >
                      <img loading='lazy' src="../images/about1.png" alt="Section One Right Side Images" className="rightSideS1Img imgResponsivesWidth mt-md-0 mt-5 pt-md-0 pt-4  AUS1Img manageImage1"/>
                      </div>
                    </div>
                </div>
            </div> */}

          {/* Section One End  */}
    
    
          {/* Section Two Start  */}


            <div className='homepageContainerP3  pt-5 my-5 pb-5' >
            <div className='container-fluid'>
                <div className='row mt-sm-5 mt-0 py-md-5 py-0' >
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 homepageS1MainHeadingDiv '  >
                  <div className='row'>
                  {/* <img loading='lazy' src="../images/Vector 6.svg" alt="Section Two Right Side HR" className="rightSideHrS2 imgResponsive pr_rem2 hrSmHide"/> */}
                  </div>
                   
                    <h3 className='S1SubHeading05'>{t('AU.S2.h.1')}<br></br>
                           <b className='s2HeadingBold'> {t('AU.S2.h.2')}  </b>
                    </h3>
                    <p className='S1Para textCenterMb '>
                    {t('AU.S2.p.1')}
                    </p>

                  </div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  d-flex justify-content-md-center justify-content-center mt-sm-0 mt-5'  >
                  <img loading='lazy' src="../images/aboutusnewImg.png" alt="Section Twp Left Side Images" className=" imgResponsivesWidth manageImage1"/>
                </div>
                {/* <img loading='lazy' src="../images/hrSmShow.svg" alt="Section Two Right Side HR" className="rightSideHrS2 imgResponsive hrSmShow "/> */}
                
                </div>
            </div>
        </div>
          {/* Section Two End  */}
          
          
          {/* Section Three Start  */}
            <div className='homepageContainerP2 ' >
            <div className='container-fluid'>
              <div className='row'>
              <img loading='lazy' src="../images/Vector 7.svg" alt="Section Two END Hr" className="imgResponsive hrSmHide"/>
              </div>
              <div className='reverseColumnS6'>
                <div className='row pt-lg-1 pt-md-5 pt-0'>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 homepageS1MainHeadingDiv mb022' >
                    <h3 className='py-3 pt-sm-5 pt-3'>

                    {t('AU.S3.h.1')} <br></br>
                        {t('AU.S3.h.2')} <b className='s2HeadingBold'> {t('AU.S3.h.3')} </b>
                        </h3>
                        <div className="transparent-gradient-border-btn s5BtnDiv hrSmHide" >
                        <button className='button-content '> <span className='gradient-text' > {t('btnING.5')} {'\u00a0'} > </span> 
                        </button>  
                      </div>
                    
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 homepageS1MainHeadingDiv'  >
                  <p className='homePageS3SubHeading pt-sm-5 pt-0 mt-sm-3 mt-0'>
                  {t('AU.S3.p.1')}
                    </p>
                  
                  <div className="transparent-gradient-border-btn s5BtnDiv hrSmShow" >
                        <button className='button-content '> <span className='gradient-text' > {t('btnING.5')} {'\u00a0'} > </span> 
                        </button>  
                      </div>
                  </div>
                </div>
                <div className='row justify-content-center'>
                <img loading='lazy' src="../images/hrSmShow.svg" alt="Section Two END Hr" className="imgResponsive hrSmShow mt-sm-0 my-4"/>

                <div className='col-12 p-0 AUS4Bg'  >
                  <div className='row AUp6s5PopUpDiv ps-5' >
                    {/* <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-10 '>
                    <div className="S5PopUpBtnDiv">
                    <img loading='lazy' src="../images/AUS5pop.svg" alt="Section One Right Side Images" className="rightSideS1Img imgResponsive"/>
                    </div>
                    </div>    */}
                  </div>

                    
                </div>
                </div>
              </div>
            </div>
          </div>
          {/* Section Three End  */}
    
            {/* Section Sub Three Start  */}
            {/* Section Sub Three End  */}


          {/* Section Four Start  */}
          <div className='homepageContainer pt-lg-5 pt-md-5 pt-3 aboutUsBg' >
            <h3 className='aboutUSNewHeading pb-md-5 pb-3'  >
            {t('AU.S5.h.1')}
            </h3>
            <div className='row justify-content-center'> 
                <div className='col-md-7 col-12'>
                <p className='AUnewPara '>
                {t('AU.S5.h.2')}
                </p>   
                </div>
                
            </div>
            <div className='container-fluid py-md-5 py-2'>
                <div className='row px-5 mt-md-4 '>
                        <div className='col-md-4 col-6 d-flex justify-content-start align-items-center'>
                            <h2 className='p6-gradient-text py-4 ps-3'  >
                            {t('AU.S4.h.1')} <br></br> 
                            <span className='p6-sub-gradient-text my-5'>
                            {t('AU.S4.h.2')} 
                            </span>
                            </h2>
                        </div>

                        <div className='col-md-4 col-6 d-flex justify-content-center  align-items-center'  >
                            <h2 className='p6-gradient-text py-4'>
                            {t('AU.S4.h.3')} 
                            <br></br> 
                            <span className='p6-sub-gradient-text my-5'>
                            {t('AU.S4.h.4')} 
                            </span>
                            </h2>
                        </div>

                        <div className='col-md-4 col-12 d-flex justify-content-md-center justify-content-center align-items-center '  >
                            <h2 className='p6-gradient-text py-4'>
                            {t('AU.S4.h.5')}  <br></br> 
                            <span className='p6-sub-gradient-text my-5'>
                            {t('AU.S4.h.6')} 
                            </span>

                            </h2>
                        </div>
                    </div>
                </div> 
            </div>
          {/* Section Four End  */}
    
    
        {/* Section Five Start  */}
          {/* <div className='homepageContainerP2 S4StartP' >
          <div className='container-fluid'>
            <img loading='lazy' src="../images/hrSmShow.svg" alt="Section Two Right Side HR" className="S4LeftSideImg rightSideHrS2 imgResponsive hrSmShow pb-sm-0 pb-5 " />
            <div className='row justify-content-between '>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 '  >
              <img loading='lazy' src="../images/p6S5RightSideImg.svg" alt="Section Twp Left Side Images" className="S4LeftSideImg imgResponsivesWidth manageImage1"/>

            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 homepageS1MainHeadingDiv'  >
              <div className='row'>
              <img loading='lazy' src="../images/Vector 6.svg" alt="Section Two Right Side HR" className="rightSideHrS2 imgResponsive hrSmHide pr_rem5" />
              </div>
                <h3 className='pt-5 mt-md-2 mt-0'>
                {t('AU.S6.h.1')}
                           <b className='s2HeadingBold '> {t('AU.S6.h.2')} </b> 
                </h3>

                <p className='S4Para '>
                {t('AU.S6.p.1')}
                </p>

                <div className=" ">
                      <button className='s5Btn'> <span className='s5Btntxt'> {t('btnING.2')} </span> 
                      </button> 
                    </div>
              </div> 
            </div>
        </div>
          </div> */}
          {/* Section Five End  */} 
    
    
          {/* Section Six Start  */}
          <div className='homepageContainer pt-5 aboutUsBgS6'>
          <h3 className='p6S7Heading '  >
            {t('AU.S7.h.1')}
            </h3>
            <div className='container-fluid s4BannerP6s3'>
                <div className='row pt-lg-5 pt-md-5 pt-0 px-sm-0 px-3 hrSmHide'  >
                <div className='col-md-4 col-12   s7P2RightsideColumnImgP3'>
                <img loading='lazy' src="../images/p6S7v1.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmHide "/>
                </div>
                <div className='col-md-4 col-12   s7P2RightsideColumnImgP3'>
                <img loading='lazy' src="../images/p6S7v2.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmHide "/>
                </div>
                <div className='col-md-4 col-12   s7P2RightsideColumnImgP3'>
                <img loading='lazy' src="../images/p6S7v3.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmHide "/>
                </div>
                </div>
                <div className='row px-sm-0 px-3'  >
                <div className='col-md-4 col-12 p6s6columnBorder  s7P2RightsideColumnp3'>
                <img loading='lazy' src="../images/p6S7v1.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmShow py-sm-0 py-0 mb-sm-0 mb-4 mt-sm-0 mt-3 "/>
                  <h3 className='p6S6BottomHeading'>
                  {t('AU.S7.hb.1')}
                  </h3>
                <p className='p6s4BottomSubHeading pt-lg-3 pt-md-3 pt-0  '>
                {t('AU.S7.p.1')}
                </p>
                <Link to="/sign-up/">
                  <div className="S4BtnDiv divBtnLarge pt-3">
                    <button className="s4Btn btnLarge">
                      {" "}
                      <span className="gradient-text btnLargeText">
                        {" "}
                        {t("btnING.2")}{" "}
                      </span>
                    </button>
                  </div>
                </Link>
                </div>

                <div className='col-md-4 col-12  p6s6columnBorder s7P2RightsideColumnp3 '>
                <img loading='lazy' src="../images/p6S7v2.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmShow py-sm-0 py-4 mt-sm-0 mt-3 "/>
                  <h3 className='p6S6BottomHeading'>
                  {t('AU.S7.hb.2')}
                  </h3>
                <p className='p6s4BottomSubHeading pt-lg-3 pt-md-3 pt-0 '>
                {t('AU.S7.p.2')}
                </p>
                <Link to="/careers/">
                  <div className="S4BtnDiv divBtnLarge pt-3">
                    <button className="s4Btn btnLarge">
                      {" "}
                      <span className="gradient-text btnLargeText">
                        {" "}
                        {t("btnING.2")}{" "}
                      </span>
                    </button>
                  </div>
                </Link>
                </div>
                <div className='col-md-4 col-12  s7P2RightsideColumnp3 '>
                <img loading='lazy' src="../images/p6S7v3.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmShow py-sm-0 py-1 mt-sm-0 mt-3 "/>
                  <h3 className='p6S6BottomHeading'>
                  {t('AU.S7.hb.3')}
                  </h3>
                <p className='p6s4BottomSubHeading pt-lg-3 pt-md-3 pt-0 '>
                {t('AU.S7.p.3.1')} <br></br>   {t('AU.S7.p.3.2')}
                </p>
                <Link to="/blog/">
                  <div className="S4BtnDiv divBtnLarge pt-3">
                    <button className="s4Btn btnLarge">
                      {" "}
                      <span className="gradient-text btnLargeText">
                        {" "}
                        {t("btnING.2")}{" "}
                      </span>
                    </button>
                  </div>
                </Link>
                </div>

                </div>
              </div>     
            </div>
          {/* Section Six End  */}
        </div>
        </AnimatedPage>
      )
}
