import React from 'react'
import AnimatedPage from '../AnimatedPage'
import { useTranslation } from 'react-i18next';
// import s1 from '../../static/images/p10s1.svg'
// import s2 from '../../static/images/p10s2Bg.svg'
import { t } from 'i18next'
// import s3 from '../../static/images/p10s3.svg'
// import s4 from '../../static/images/p10s4.svg'





export default function DigitalWallet() {
  const { t, i18n } = useTranslation();
  return (
    <AnimatedPage>
    <div>
        {/* Section One Start  */}
        <div className='homepageContainerP3 S1PATopPadding' >
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                    <h3 className=' mt-lg-5 mt-md-5 mt-0 DWS1Heading'  > 
                     {t('DW.S1.h.1')} <br></br> {t('DW.S1.h.2')}.  <b className='DWS1HeadingBold'>{t('DW.S1.h.3')} <br className='d-md-block d-none'></br>{t('DW.S1.h.4')}</b>   
                    </h3>
                    <p className='S1Para '>
                      {t('DW.S1.p')}
                      </p>
                </div>
                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 d-flex justify-content-center'  >
                    <img loading='lazy' src="../images/digitalwalletphonechange.png" alt="Section One Right Side Images" className="rightSideS1Img imgResponsivesWidth DWS1Img manageImage1"/>
                    </div>
                </div>
                <div className='row pt-3 '>
                    <img loading='lazy' src="../images/p7hr.svg" alt="Section Two Right Side HR" className="rightSideHrS2 imgResponsive hrSmHide"/>
                </div>
            </div>
        </div>
          {/* Section One End  */}


        {/* Section Two Start  */}

<div className='homepageContainerP2' >
            <div className='container-fluid'>
              <div className='reverseColumnS6'>
                <div className='row '>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv '>
                    <h3 className='py-lg-3 py-md-3 py-0 pt-lg-0 pt-md-0 pt-5 pb-lg-0 pb-md-0 pb-4   '  >
                    {t('DW.S2.h.1')}    <br></br> <b className='s2HeadingBold'> {t('DW.S2.hb.1')} </b>   

                    </h3>
                    
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv'  >
                  <p className='homePageS3SubHeading pt-0 pb-lg-0 pb-md-0 pb-4'>
                  {t('DW.S2.p.1')}
                    </p>
                  
                  </div>
                </div>
                <div className='row justify-content-center' >
                <img loading='lazy' src="../images/hrSmShow.svg" alt="Section Two END Hr" className="imgResponsive hrSmShow mt-sm-0 mt-5 px-0"/>

                <div className='col-12 p-0 DW4Bg mt-sm-0 mt-4' >
                  <div className='row s5PopUpDiv1 ps-sm-5 ' >
                    {/* <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-5 col-7 hidePopUp'>
                    <div className="S5PopUpBtnDiv">
                      <button className='s5PopUpBtn'> <span className='s5PopUpBtntxt'> {t('popupSS.4')} </span> 
                      </button> 
                    </div>
                    </div> */}
                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-10 col-12 imgCenter'>
                      <img loading='lazy' src="../images/currency.png" alt="Section One Right Side Images" className="rightSideS1Img imgResponsive d-sm-block d-none"/>
                      <img loading='lazy' src="../images/Single card.png" alt="Section One Right Side Images" className="rightSideS1Img imgResponsive d-sm-none d-block mobileRespons"/>
                    </div>
                    {/* <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-5 col-7 hidePopUp'>
                    <div className="S5PopUpBtnDiv">
                      <button className='s5PopUpBtn BAS4PopUp'> <span className='s5PopUpBtntxt '>{t('popupSS.4')} </span> 
                      </button> 
                    </div>
                    </div>

                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-5 col-10'>
                    <div className="S5PopUpBtnDiv">
                      <button className='s5PopUpBtn BAS4PopUp'> <span className='s5PopUpBtntxt '>{t('popupSS.4')} </span> 
                      </button> 
                    </div>
                    </div> */}
                    
                  </div>

                    
                </div>
                </div>
              </div>
            </div>
          </div>
          {/* Section Two End  */}


        {/* Section Three Start  */}
          <div className='homepageContainer  mt-0  pt-0'>
            <div className='container-fluid'>
                <div className='row mt-sm-3 mt-0 pt-0 ' >
                <img loading='lazy' src="../images/hrSmShow.svg" alt="Section Two Right Side HR" className="rightSideHrS2 imgResponsive hrSmShow"/>

                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 d-flex justify-content-md-start justify-content-center'  >
                  <img loading='lazy' src="../images/wallet3.png" alt="Section Twp Left Side Images" className="imgResponsivesWidth mt-5 manageImage1"/>
                </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 homepageS1MainHeadingDiv'  >
                  <div className='row'>
                  <img loading='lazy' src="../images/Vector 6.svg" alt="Section Two Right Side HR" className="rightSideHrS2 imgResponsive pr_rem5 hrSmHide"/>
                  </div>
                   
                    <h3 className='mt-lg-5 mt-md-5 mt-0 pt-3'>  
                    {t('DW.S3.h.1')}
                    </h3>
                    <p className='homePageS2SubHeading pt-lg-2 pt-md-2 pt-4 mt-4'>
                    {t('DW.S3.p.1')}
                    </p>

                  </div>
                
                
                </div>
            </div>
        </div>
          {/* Section Three End  */}

        {/* Section Four Start  */}
        <div className='homepageContainerP3 ' >
            <div className='container-fluid'>
                <div className='row pb-5'>
                <h3 className='p10S4Heading '  > 
                {t('DW.S4.h.1')}
                </h3>
                <p className='p10s4Para pt-3'  > 
                {t('DW.S4.p.1')}
                </p>
                <div className='row mt-5 pt-3'>
                <img loading='lazy' src="../images/walletnew.png" alt="Section One Right Side Images" className="rightSideS1Img imgResponsive manageImage1"  />
                </div>
              </div>
            </div>
        </div>
          {/* Section Four End  */}
          {/* Section Five Start  */}
        <div className="homepageContainerP2 PAS3Bg">
          <div className="container-fluid s3InsidePadding">
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 ">
                <div
                  className="row "
                  
                  
                >
                  <div className="firstDiv">
                    <h3 className=" S3Heading ps-sm-0 ">
                      {t("DW.S8.hb.1")}
                    </h3>
                    <p className="S3Para ">{t("DW.S8.p.1")}</p>
                  </div>
                  <div className="firstDiv">
                    <h3 className="S3Heading ps-sm-0 ">
                      {t("DW.S8.hb.2")}
                    </h3>
                    <p className="S3Para ">{t("DW.S8.p.2")}</p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 PAS3Padding  ">
                <div
                  className="row"
                  
                  
                >
                  <div className="firstDiv ">
                    <h3 className=" S3Heading ">{t("DW.S8.hb.3")}</h3>
                    <p className="S3Para prMb">{t("DW.S8.p.3")}</p>
                  </div>

                  <div className="firstDiv">
                    <h3 className="S3Heading ps-0 mt-sm-5 mt-0 ">{t("DW.S8.hb.4")}</h3>
                    <p className="S3Para prMb">{t("DW.S8.p.4")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section Five End  */}

        {/* Section Six Start  */}
        <div className='homepageContainer pt-lg-5 pt-md-0 pt-0 BABgS5' >
            <h3 className='S3BottomHeading text-center pt_rem3 pb-lg-0 pb-md-0 pb-5'  >
                {t('DW.S9.h.1')}
            </h3>
            <div className='container-fluid s4BannerP4'>
            
                <div className='row pt-5 hrSmHide'  >
                <div className='col-6   s7P2LeftsideColumnImg'>
                <img loading='lazy' src="../images/s7p2c1.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive "/>
                </div>
                <div className='col-6   s7P2RightsideColumnImg'>
                <img loading='lazy' src="../images/s7p2c2.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive "/>
                </div>
                </div>
                <div className='row '  >
                
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 columnBorder  s7P2LeftsideColumn'>
                <img loading='lazy' src="../images/s7p2c1.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmShow mb-sm-0 mb-4"/>
                  <h3 className='S3BottomHeading'>
                  {t('DW.S9.hb.1')}
                  </h3>
                <p className='s4BottomSubHeading pt-lg-3 pt-md-3 pt-1 '>
                {t('DW.S9.p.1')}
                </p>
                </div>

                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  s7P2RightsideColumn '>
                <img loading='lazy' src="../images/s7p2c2.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmShow mb-sm-0 mb-4"/>
                  <h3 className='S3BottomHeading'>
                  {t('DW.S9.hb.2')}
                  </h3>
                <p className='s4BottomSubHeading pt-lg-3 pt-md-3 pt-1 '>
                {t('DW.S9.p.2')}
                </p>
                </div>
                </div>
                <div className='row hrsSmHide'  >
                <div className='col-6   s7P2LeftsideColumnImg'>
                <img loading='lazy' src="../images/s7p2c3.svg"alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmHide"/>
                </div>
                <div className='col-6   s7P2RightsideColumnImg'>
                <img loading='lazy' src="../images/s7p2c4.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmHide"/>
                </div>
                </div>
                <div className='row' >
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  columnBorder s7P2LeftsideColumn'>
                  <img loading='lazy' src="../images/s7p2c3.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmShow mb-sm-0 mb-4"/>
                    <h3 className='S3BottomHeading'>
                    {t('DW.S9.hb.3')}
                    </h3>
                  <p className='s4BottomSubHeading pt-lg-3 pt-md-3 pt-1 '>
                  {t('DW.S9.p.3')}
                  </p>
                  </div>
                  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  s7P2RightsideColumn pb-lg-0 pb-md-0 pb-5'>
                  <img loading='lazy' src="../images/s7p2c4.svg" alt="Section Twp Left Side Images" className="rightSideS1Img imgResponsive hrSmShow mb-sm-0 mb-4"/>
                    <h3 className='S3BottomHeading '>
                    {t('DW.S9.hb.4')}
                    </h3>
                  <p className='s4BottomSubHeading pt-lg-3 pt-md-3 pt-1 '>
                  {t('DW.S9.p.4')}
                  </p>
                  </div>
                </div>
                <p className='takeControlP'>
                {t('DW.S9.p.5')}
                </p>

                
            </div> 
        </div>

          {/* Section Six End  */}
    </div>
    </AnimatedPage>
  )
}
