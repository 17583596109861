import React , {useState,useEffect} from 'react'
function DropdownItem({ text, onSelect }) {
    return (
      <button className="dropdown-item" onClick={() => onSelect(text)}>
{text}
      </button>
    );
  }
  
function Complaints() {
    const [dropDown,showDropDown] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);

    const dropDownHandle = () => {
        showDropDown(!dropDown)
    }

    const handleOptionSelect = (optionText, ) => {
        setSelectedOption({ text: optionText});
    };
  return (
    <div className='homepageContainerP2 py-3 mt-5' >
        <div className='container-fluid mt-5'>
            <div className='row justify-content-center px-md-5 px-2'>
                <h3 className='detailsPageH text-center '>
                complaints policy
                </h3>
                <div className='col-1'>

                </div>
                <div className='col-md-3 col-12 mt-4 countrySelect  d-flex  align-items-center justify-content-between' >
                        <div className='d-flex gap-2'>
                            <h3 className='mb-0 swapTitle'>{selectedOption?.text || 'United Kingdom(UK)'}</h3>
                        </div>
                        <img loading='lazy' src="../images/WHPArrowD.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow"/>
                        <div className={`dropdown-menu CSwapDropDown ${dropDown ? 'show' : ''}`}  onMouseLeave={() => showDropDown(false)}>
                        <DropdownItem text="Country 1"  onSelect={handleOptionSelect} />
                        <DropdownItem text="Country 2"  onSelect={handleOptionSelect} />
                        <DropdownItem text="Country 3"  onSelect={handleOptionSelect} />
                        <DropdownItem text="Country 4"  onSelect={handleOptionSelect} />
                        <DropdownItem text="Country 5"  onSelect={handleOptionSelect} />
                        <DropdownItem text="Country 6"  onSelect={handleOptionSelect} />
                        <DropdownItem text="Country 7"  onSelect={handleOptionSelect} />

                      </div>
                </div>

                <div className='col-md-3 col-12 d-flex align-items-center justify-content-md-start justify-content-end mt-4'>
                    <a className='downloadPdfBtn' >
                    Download PDF
                    </a>
                </div>
                





            </div>

            <div className='row justify-content-start ps-md-3 pe-md-5 ps-2 pe-2'>
                <h3 className='detailsPageHBlack mt-md-5 mt-3'>
                Frequently Asked Questions
                </h3>


                <p className="detailsPageTxt  " >
                Follow this link and fill in our complaint form. Alternatively you can contact our complaints team at formalcomplaints@Evistia.com.
                Once we have received your complaint we will acknowledge this via email. We will then investigate all the details of your complaint, and issue our response within a couple of days, but this can take up to 15 business days. If you are not happy with our resolution you can refer your complaint to the Financial Ombudsman Service.

                <br></br>
                <br></br>

                If you want to make a complaint related to Evistia's trading product, please click here.
                 </p>


                 <h3 className='detailsPageHBlack mt-md-5 mt-3'>
                 Do you want to report Fraud at Evistia?
                </h3>


                <p className="detailsPageTxt  " >
                Contact us through this link if you haven't reported your case before. We will be able to act quicker and have our fraud specialists assist you.
                 </p>

                 <h3 className='detailsPageHBlack mt-md-5 mt-3'>
                 Do you have a different enquiry?
                </h3>


                <p className="detailsPageTxt  " >
                For any other enquiries, please visit our Help page or contact us via in-app chat.
                 </p>

            </div>


        </div>
    </div>
  )
}

export default Complaints