import React from 'react'

function SearchCompanyForBills(props) {
  return (
    <div className='row justify-content-center'>
        <div className='searchSection'>
          <div className="search-container">
              <input type="text" className="form-control search-inputBills" placeholder="Search Service Provider" aria-label="Search" />
              <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Vector (2).svg" } alt="Search Icon" className='search-icon' />
          </div>
        </div>
        <div className='billsRow col-11 d-flex gap-3 align-items-center mb-3' onClick={()=>{props.setStepCount(props.stepCount + 1)}}>
            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Ellipse 101.svg" } alt="User Image" className="billCompanyLogo" />
            <p className='billComapnyTitle mb-0'>
            Loriupsim
            </p>
        </div>
        <div className='billsRow col-11 d-flex gap-3 align-items-center mb-3' onClick={()=>{props.setStepCount(props.stepCount + 1)}}>
            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Ellipse 101.svg" } alt="User Image" className="billCompanyLogo" />
            <p className='billComapnyTitle mb-0'>
            Loriupsim
            </p>
        </div>
        <div className='billsRow col-11 d-flex gap-3 align-items-center mb-3' onClick={()=>{props.setStepCount(props.stepCount + 1)}}>
            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Ellipse 101.svg" } alt="User Image" className="billCompanyLogo" />
            <p className='billComapnyTitle mb-0'>
            Loriupsim
            </p>
        </div>
        <div className='billsRow col-11 d-flex gap-3 align-items-center mb-3' onClick={()=>{props.setStepCount(props.stepCount + 1)}}>
            <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Ellipse 101.svg" } alt="User Image" className="billCompanyLogo" />
            <p className='billComapnyTitle mb-0'>
            Loriupsim
            </p>
        </div>
    </div>
    
  )
}

export default SearchCompanyForBills