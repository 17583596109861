import React from 'react'
import { useState,useRef,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useVerifyEmailMutation, useResendOtpMutation } from '../../services/userAuthApi';
import { storeToken } from '../../services/LocalStorageService';
import { useNavigate } from 'react-router-dom';
import { useActivateUserMutation } from '../../services/userAuthApi';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

function VerifyPassword() {
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const [useremail, setuseremail] = useState();
    const [error, seterror] = useState();


    const inputRefs = useRef([
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef(),
    ]);

    // Function to handle input change for each digit
    const handleInputChange = (index, event) => {
        const { value } = event.target;
        if (/^\d*$/.test(value) && value.length <= 1) {
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        // Move focus to the next input field
        if (index < code.length - 1 && value.length === 1) {
            inputRefs.current[index + 1].current.focus();
        }
        }
    };

    const getEmailFromLocalStorage = () => {
        return localStorage.getItem('userEmail');
    };
      
    useEffect(() => {
        const storedEmail = getEmailFromLocalStorage();
        if (storedEmail) {
          setuseremail(storedEmail);
        }
    }, [useremail]);

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();



    const [verifyEmail , {isLoading} ] = useVerifyEmailMutation() 
    const [resendOtp , {Loading} ] = useResendOtpMutation() 


    const resendOTPHandler = async () => {
      const resendOTPData = {'email':useremail}
        const res = await resendOtp(resendOTPData)
        if (res.error){
          // console.log(res.error.data.message,'resend otp error is here')
          seterror(res.error.data.message)
        }else if (res.data){
        // console.log('response is here',res.data)
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: res.data.message, // Change to your data property
        })
        // Clear the form fields after successful submission
        setCode(['', '', '', '', '', '']);
        seterror('');
        // storeToken(res.data.token)
        // navigate('/account-verification/', { replace: true });
      }
        // if (res){
        //   console.log(res,'resend otp data is here')
        // }
    }
    

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (useremail && code){

      const otpnumber = parseInt(code.join(''), 10);

      const loginData = {'email':useremail, 'otp':String(otpnumber),}

      // console.log('Form submitted with:', loginData);


      const res = await verifyEmail(loginData)

      if (res.error){
          // console.log(res.error.data.message,'error is here')
          seterror(res.error.data.message)
      }else {
        // console.log('response is here',res)
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: res.data.message, // Change to your data property
        }).then(()=>{
          navigate('/login')
        })
        // Clear the form fields after successful submission
        // setEmail('');
        // setPassword('');
        // showErorr('')
        // storeToken(res.data.token)
        // navigate('/account-verification/', { replace: true });
      }
    }
  };


  return (
    <div>
      <div className='container-fluid LoginMainDiv ps-0' >
        <div className='row ps-0'>
          <div className='col-xxl-7 col-xl-7 col-lg-6 LoginMainDivBg d-lg-block d-none ps-0'  >
         
         <img loading='lazy' src={process.env.PUBLIC_URL + "/images/login.png" } alt="new IMg" className="" />

          </div>
          <div className='col-xxl-5 col-xl-5 col-lg-6  rightSideDivLogin py-md-5 py-0'  >
              <div className='d-flex justify-content-center '>
              <Link to="/" style={{margin:"auto",textAlign:"center"}}>
              <img loading='lazy' alt='Logo' style={{width:"65%"}} src="../images/ev-logo.png"  className=' pt-lg-3 pt-3'/>
              </Link>
                </div>
              <h2 className='loginTitle'>
              Email Verify
              </h2>
              <p className='formError pt-3 text-center' >
                {error}
              </p>
              <form onSubmit={handleFormSubmit} className='SignInForm'>
              <div className="mb-5">
              <label htmlFor="message" className="form-label contactusLabels pb-2">Enter Your 6 Digit OTP</label>
                  <div className="code-input-container pb-4">
                        {code.map((digit, index) => (
                        <input
                            key={index}
                            ref={inputRefs.current[index]}
                            type="text"
                            value={digit}
                            maxLength="1"
                            onChange={(e) => handleInputChange(index, e)}
                        />
                        ))}
                    </div>
                </div>

              <div className="authSubmitBtn mt-5 " style={{width:'100%'}}>
                <button className='s5BtnLogin' onClick={handleFormSubmit}> <span className='s5Btntxt'> Submit </span> 
                </button> 
              </div>


              <div className='BottomTextDiv'>
              <p className='BottomText mt-4' style={{color:'#2645E0'}}>
              <span onClick={resendOTPHandler} style={{cursor:'pointer',color:'#2645E0'}}>
              Resend OTP
              </span>
              </p>
              <p className='BottomText' >
              {t('LOGIN.p.2')} <span ><Link to="/sign-up" style={{cursor:'pointer',color:'#2645E0'}}>{t('LOGIN.p.3')}</Link>  </span> 
              </p>
              </div>
              </form>


    
          </div>
        </div>
      </div>



    </div>
  )
}

export default VerifyPassword