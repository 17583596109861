import React,{useState} from 'react'
import WalletSideBar from './WalletSideBar'
import { Link } from 'react-router-dom'
export default function WalletMoibileTabNav({userProfileData}) {

        const [navOpen , setNavOpen] = useState('close')
        const sideBarOpen = () => {
            setNavOpen('open')
        }
        const sideBarClose = () => {
            setNavOpen('close')
        }
      return (
        <>
            < WalletSideBar navOpen={navOpen} userProfileData={userProfileData} />
            <div className='uppderTMb container-fluid'>
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/mbtsidearrow.svg" } alt="User Image" className={`img1 ${navOpen === 'open' ? "d-none" : ""}`} onClick={sideBarOpen} />
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/navX.svg" } alt="User Image" className={`img1 ${navOpen === 'close' ? "d-none" : ""}`} onClick={sideBarClose} />
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/logo.svg" } alt="User Image" className="img2 d-sm-block d-none" />
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/evistia logo@4x.png" } alt="Logo" width="30" height="30" className="navbarLogo1 d-sm-none d-block" />
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Group 598 (1).svg" } alt="User Image" className="img3"  />
            </div>
        </>
      )

  
}
