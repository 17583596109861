import React from 'react'

function ChoseNetwork(props) {
  const handleNextSetp = (network) => {
    props.setStepCount(props.stepCount + 1)
    props.setNetwork(network)
  }
  return (
    <>
      <div className='CPunderRowCard mb-3 py-4' onClick={() => handleNextSetp('Ethereum (ERC20)')}>
        <h5  className='ps-3'>
        Ethereum (ERC20)
        </h5>
        <h6  className='ps-3'>
        6 block confirmation
        </h6>
        <h6  className='ps-3'>
        Min. deposit 0.000001 USDT
        </h6>
        <h6  className='ps-3'>
        Est. arrival 4 mins
        </h6>
    </div>
    <div className='CPunderRowCard mb-3 py-4' onClick={() => handleNextSetp('BNB Smart Chain (BEP20)')}>
        <h5  className='ps-3'>
        BNB Smart Chain (BEP20)
        </h5>
        <h6  className='ps-3'>
        15 block confirmation
        </h6>
        <h6  className='ps-3'>
        Min. deposit 0.000001 USDT
        </h6>
        <h6  className='ps-3'>
        Est. arrival 4 mins
        </h6>
    </div>
    {/* <div className='CPunderRowCard mb-3 py-4' onClick={() => handleNextSetp('P20 (P-20)')}>
        <h5  className='ps-3'>
        P20 (P-20)
        </h5>
        <h6  className='ps-3'>
        6 block confirmation
        </h6>
        <h6  className='ps-3'>
        Min. deposit 0.000001 USDT
        </h6>
        <h6  className='ps-3'>
        Est. arrival 4 mins
        </h6>
    </div> */}

</>
  )
}

export default ChoseNetwork