import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
export default function UpperNav({title, symbol}) {
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = () => {
    navigate(-1); // This navigates back one step in the application's history
  };
  return (
    <div className='container-fluid upperNavFi'>
        <div className='row upperNavMainDiv pb-2'>
            <div className='col-10 d-flex gap-4 align-items-center ps-0'>
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/div.tw-avatar__content.svg" } alt="User Image" className="WBackArrow" onClick={goBack} />
                <h1 className='UpperNavHeading mb-0'>
                {location.pathname === '/wallet/fiat-dashboard' ?
                 'Fiat Breakdown' 
                 : location.pathname === '/wallet/crypto' ? 
                  "Crypto Portfolio"
                : location.pathname === '/wallet/payments' ? 
                  "Payment"
                : location.pathname === '/wallet/fiat-transfer' || location.pathname === '/wallet/payment-confirmation' ? 
                  "Fiat Transfer"
                : location.pathname === '/wallet/swap' ? 
                  "Swap"
                : location.pathname === '/wallet/card-details' ? 
                "Card Details"
                :location.pathname === '/wallet/account-information' ? 
                "Account"
                :location.pathname === '/wallet/recieved-crypto' ? 
                "Receive Crypto"
                :location.pathname === '/wallet/send-crypto' ? 
                "Send Crypto"
                :location.pathname === '/wallet/bills-and-payments' ? 
                "Bills & Payments"
                :location.pathname === '/wallet/change-pin' ? 
                "Change Card Pin"
                :
                  `${title} ${symbol}`}
                </h1> 
            </div> 
            <div className='col-2 d-flex justify-content-end' onClick={()=>{navigate('/wallet/notifications')}}>
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Group 598 (1).svg" } alt="User Image" className="WNotifyIcon d-lg-block d-none" />
            </div>
        </div>
    </div>
  )
}
