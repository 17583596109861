import React, { useEffect } from 'react';
import WalletSideBar from './WalletSideBar';
import { Outlet, useNavigate } from 'react-router-dom';
import WalletMoibileTabNav from './WalletMoibileTabNav';
import useAuthStatus from '../Authentication/useAuthStatus';

export default function WalletLayout() {
  const { userProfileData, isFetching, isError } = useAuthStatus();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to login if there's an error (assuming it's due to an expired token)
    if (isError) {
      navigate('/login'); // Replace '/login' with your actual login route
    }
  }, [isError, navigate]);

  if (isFetching) {
    return <div>Loading...</div>; // Or some other loading indicator
  }

  return (
    <div className='container-fluid px-0 d-flex positionSet'>
      <WalletMoibileTabNav userProfileData={userProfileData} />
      <Outlet />
    </div>
  );
}
