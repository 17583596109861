import React,{useState} from 'react'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import UpperNav from './UpperNav';
function SendCrypto() {
    const location = useLocation();
    const name = location?.state?.name;
    const sign = location.state.sign;
    const image = location.state.img;

    const [stepCount, setStepCount] = useState(1)

    const navigate = useNavigate()
    const handleNextStep = (pageName) => {
        pageName === 'Send Via Crypto' ?
        navigate('/wallet/send-crypto/via/network', { state: { pageName ,name, sign ,image } })
        : 
        navigate('/wallet/send-crypto/via/others', { state: { pageName ,name, sign ,image } })
      }
    return (
        <div className='sideBarMainContent '>
        <div className='WMainDiv '>
        <UpperNav />
    
        
        <div className='row mt-5 justify-content-center'>
          <div className='col-xxl-5 col-lg-8 col-12'>
              <div className='fiatTransferCard mt-md-5 mt-2 ' >
              {
                stepCount === 3 ? "" :
                <div className='cardUpperRow mb-3'>
                        <div className='col-12'>
                        <h3>
                       {
                        stepCount === 1 ? `Send ${sign}` :
                        stepCount === 2 ? "Choose Network" 
                        : ""
                       }
                        </h3>
                        </div>
                    </div>
              }
              <div className='col-12' >
               {
                stepCount === 1 ? 
                <>
                <div className='CPunderRowCard mb-4' onClick={() => handleNextStep('Send Via Crypto')}>
                    <h5  className='ps-3'>
                    Send via Crypto Network
                    </h5>
                    <h6  className='ps-3 mb-0'>
                    Send to known crypto address via crypto network.
                    </h6>
                </div>
                <div className='CPunderRowCard mb-4' onClick={() => handleNextStep('Send Crypto')}>
                    <h5  className='ps-3'>
                    Send via Email / Phone / ID
                    </h5>
                    <h6  className='ps-3  mb-0'>
                    Send to Binance user. Easy, Instant, 0 fee.
                    </h6>
                </div>
                </> :
                stepCount === 2 ? 
                ""
                : "<RecievedCryptoDetails network={network} name={name} sign={name} image={image}/>"
               }
              </div>
    
        </div>
    
    
      </div>
    </div>
    </div>
    </div>
      )
}

export default SendCrypto