import React, { useState, useRef , useEffect} from 'react';
import AnimatedPage from '../AnimatedPage';
import { useContactUsRequestMutation } from '../../services/userAuthApi';
import { getToken } from '../../services/LocalStorageService';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useGetLoggedUserQuery } from '../../services/userAuthApi';
import { useTranslation } from 'react-i18next';



const ContactUs = () => {
  const {t} = useTranslation()
  const {access_token} = getToken()
  // Logged IN User History 
  const { data, isError } = useGetLoggedUserQuery(access_token);
  const [userId, setUserId] = useState('');
  useEffect(() => {

    //consolelog('Data:', data);
     // Check if data is available
    if (data) {
      setUserId(data.id)
    }
  }, [data]);





  const [selectedFile, setSelectedFile] = useState(null);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [emailError, showEmailErorr] = useState('')
  const [messageError, showMessageErorr] = useState('')
  const [SLError, showSLErorr] = useState('')

  const [submitrequest,{isLoading, isSuccess}] = useContactUsRequestMutation()
  const navigate = useNavigate()
  const fileInputRef = useRef(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    // event.target.parentNode.style.padding = '1rem';
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    //consolelog('my event', event.dataTransfer.files)
    const droppedFile = event.dataTransfer.files[0];
    setSelectedFile(droppedFile);
    
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDropZoneClick = () => {
    fileInputRef.current.click();
  };


  const handleOnChangeInput = (e) =>{
    //consolelog(e)
    showEmailErorr('')
    showMessageErorr('')
    showSLErorr('')

    if (e.id === 'email'){
    setEmail(e.value)
    //consolelog(e, ' we will handle input is here')
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(e.value);
      if (emailPattern === false && e.value){
        e.classList.add('error')
        // showEmailErorr('Email is Not Valid')
    }else{
      // showEmailErorr('')
      e.classList.remove('error')
    }
  } 
}
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (email === ''){
      showEmailErorr('Email is Not Valid')
      //consolelog(' we will handle email is here',email)
    } else if(message === ''){
      showMessageErorr('Enter the details of your request')
    } else {
      event.target.reset()
      // Check if a file is selected
      let imageBase64 = '';
      if (selectedFile) {
        // Convert the selected file to base64
        const reader = new FileReader();
        reader.onload = (selectedFile) => {
          imageBase64 = selectedFile.target.result;
        }
      }

      //consolelog('Form submitted with:' , selectedFile.name,  {
      //   email,
      //   message,
      //   selectedOption,
      //   selectedFile
      // });
      const form_data = new FormData();
        form_data.append('image', selectedFile);
        form_data.append('email', email);
        form_data.append('discription',message);
        form_data.append('options',selectedOption);
        form_data.append('user',userId);

      // const formData = {
      //   email : email,
      //   discription : message ,
      //   options : selectedOption ,
      //   image : selectedFile,
      //   userId : userId,
      // }
      const res = await submitrequest(form_data)
      console.log(res, 'my Api res is Here')
      if (await res.data){
        setEmail('')
        setMessage('')
        setSelectedFile('')
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Your Request Has Been Submitted Successfully', // Change to your data property
            }).then(() => {
              navigate('/contact-us'); // Redirect to another page
            })
      }
    }
  };

  return (
    <AnimatedPage>
    {/* {access_token ?  */}
    <div>
    <div className='homepageContainerP3 S1PATopPaddingP3 contactUsBg pt-5 pb-2 mt-5' >
        <div className='container-fluid'>
        <p className='contactusupperTxt'  >
        {t("CU.H.1")} {'\u00a0'} {'\u00a0'}  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.971855 -4.24811e-08L-4.82055e-07 0.971857L5.0247 6L-4.24811e-08 11.0281L0.971855 12L6.9753 6L0.971855 -4.24811e-08Z" fill="#D9D9D9"/>
        </svg> {'\u00a0'} {'\u00a0'}
        {t("CU.H.2")}
        </p>
        <h2 className='p8S1Heading'  >{t("CU.H.3")}</h2>
        </div>
    </div>
    <div className='container-fluid'>
        <div className='row'>
        <img loading='lazy' src={process.env.PUBLIC_URL + "/images/hrcontactus.svg"} alt="Section One Right Side Images" className=" imgResponsive hrSmHide"/>
        <img loading='lazy' src={process.env.PUBLIC_URL + "/images/hrSmShow.svg"} alt="Section Two Right Side HR" className="rightSideHrS2 imgResponsive hrSmShow px-4" />
        </div>
    </div>




    <div className="container mt-md-5 mt-4 pb-5 ">
      <div className='row px-sm-0 px-md-4 px-3' >
      <form onSubmit={handleFormSubmit} >
      <div className="mb-5">
          <label htmlFor="email" className="form-label contactusLabels">{t('CU.lb.1')}</label>
          <input
            type="email"
            className="form-control formInputs"
            id="email"
            placeholder={t('CU.ph.1')}
            value={email}
            onChange={(e) => handleOnChangeInput(e.target)}
            required
            maxLength="100"
          />
          {emailError ? <span className='inputFiledError pt-1'>{emailError}</span> : ''}
          
        </div>

        <div className="mb-5">
          <label htmlFor="message" className="form-label contactusLabels">{t('CU.lb.2')}</label>
          <textarea
            className="form-control formInputs"
            id="message"
            rows="7"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            maxLength="500"
          />
          {messageError ? <span className='inputFiledError py-1'>{messageError}</span> : ''}
        <p className='textinfo pt-3'>{t('CU.p.1')} </p>
        </div>
        {/* <div className="mb-5">
          <label htmlFor="option" className="form-label contactusLabels">{t('CU.lb.3')}</label>
          <select
            className="form-select formInputs"
            id="option"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            required
          >
            <option value="">{t('CU.ph.2.1')}</option>
            <option value="option1">{t('CU.ph.2.2')}</option>
            <option value="option2">{t('CU.ph.2.3')}</option>
            Add more options as needed
          </select>
          {SLError ? <span className='inputFiledError py-1'>{SLError}</span> : ''}
        </div> */}

        <div className="mb-5">
          <div
            className="file-drop-container d-flex justify-content-center align-items-center"
            onDrop={handleFileDrop}
            onDragOver={handleDragOver}
            onClick={handleDropZoneClick}
          >
            <input
              type="file"
              accept="image/*"
              className="d-none"
              name='img'
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            {selectedFile ? (
              <img loading='lazy'
                src={URL.createObjectURL(selectedFile)}
                alt="Dropped"
                style={{ maxWidth: '100%', width:'100%',borderRadius:'10px' }}
              />
            ) : (
              <p><span style={{color:'#2645E0'}}> {t('CU.p.2')} </span> {t('CU.p.3')} </p>
            )}
          </div>
        </div>

        <div className="S5BtnDiv mt-md-5 mt-0 ">
            <button className='s5Btn' type='submit'> <span className='s5Btntxt'> {t('btnING.10')} </span> 
            </button> 
        </div>
      </form>
      </div>
    </div>
    </div>
    {/* //  :  */}
     {/* <div className="S5BtnDiv mt-5 d-flex justify-content-center w-100"> 
        <span className='contactusLabels text-center'> 
        Please Login Before Submit Request
        </span>
      </div> */}
      {/* // } */}
    </AnimatedPage>
  );
};

export default ContactUs;
