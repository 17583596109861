import React,{useState,useEffect} from 'react'
import { json, useLocation } from 'react-router-dom';
import UpperNav from './UpperNav';
import { useNavigate } from 'react-router-dom';
import FiatTransferStepThree from './FiatTransferStepThree';
import MobileOTP from './MobileOTP';
import PaymentConfirmation from './PaymentConfirmation';
import Swal from 'sweetalert2';
import { getToken } from '../../services/LocalStorageService';
import Totp_validation from './Totp_validation';

function DropdownItem({ text, imgSrc, onSelect }) {
    return (
      <button className="dropdown-item" onClick={() => onSelect(text)}>
        {text}
      </button>
    );
  }
  



function SendViaCryptoNetwork() {
    const {access_token} = getToken()
    const location = useLocation();
    const name = location?.state?.name;
    const sign = location?.state?.sign;
    const image = location?.state?.img;
    let availbleAmount = location?.state?.availbleAmount;
    const cname = location?.state?.cname;
    
    const [otp,setOtp] = useState()
    const [totp,settOtp] = useState()
    const [isTotp,setIsTOtp] = useState()


    const [network,setNetwork] = useState()
    const [apiCrypto,setApiCrypto] = useState()
    const [btnDisabled,setBtnDisabled] = useState(false)
    useEffect(() => {
      if  (name === 'Binance' || name === 'BNB' || name === undefined || name === 'Binance Coin'){
        setNetwork('BNB Smart Chain (BEP20)')
        setApiCrypto('BNB')
      }else if (name === 'Ethereum' || name === 'ETH'){
        setNetwork('Ethereum (ERC20)')
        setApiCrypto('ETH')
      }else if (name === 'USDT' || name === 'USDT'){
        setNetwork('TetherUS')
        setApiCrypto('USDT')
      }else if(name === 'Bitcoin' || name === 'BTC'){
        setNetwork('Bitcoin (BTC)')
        setApiCrypto('BTC')
      }
      if ( availbleAmount === undefined ){
        availbleAmount = 0
      }
    },) 

    const fetchUserProfileData = async () => {
      try {
        // const response = await fetch('http://127.0.0.1:8000/api/wallet/user-balance/', {
        const response = await fetch(
          "https://evistialab.com/api/v1/get-user-profile-data/",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        if (response.status === 401) {
          // If unauthorized, navigate to the login page
          navigate("/login");
          return; // Stop further execution in this block
        }
        const data = await response.json();
        if (data) {
          setIsTOtp(data?.is_totp)
        }
      } catch (error) {
        console.error("Error fetching ETH balance:", error);
      }
    };
  
    useEffect(() => {
      fetchUserProfileData();  
    }, [access_token]);



    
    const pageName = 'Crypto'
    const  navigate = useNavigate()

    const [stepCount, setStepCount] = useState(1)
    const [address, setaddress] = useState('')
    const [amount, setamount] = useState(Number())
    const [errorText,setErrorText] = useState()
    const [amountErrorText,setAmountErrorText] = useState()


    // Example usage:
    // const ethAddress = "0x323b5d4c32345ced77393b3530b1eed0f346429d";
    // const btcAddress = "1BoatSLRHtKNngkdXEeobR76b53LETtpyT";
    // const btcAddressBech32 = "bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq";

    // console.log("Is Ethereum Address:", isEthereumAddress(ethAddress)); // true
    // console.log("Is Bitcoin Address (P2PKH):", isBitcoinAddress(btcAddress)); // true
    // console.log("Is Bitcoin Address (Bech32):", isBitcoinAddress(btcAddressBech32)); // true

    function isEthereumAddress(address) {
      console.log('eth checking',/^0x[a-fA-F0-9]{40}$/.test(address))
      return /^0x[a-fA-F0-9]{40}$/.test(address);
    }

    function isBitcoinAddress(address) {
      // This regex covers basic scenarios and is not exhaustive. For more comprehensive validation, use a dedicated library.
      const btcRegex = /^(1|3)[a-zA-Z\d]{24,34}$|^bc1[a-z0-9]{25,39}$/;
      console.log('btc checking',btcRegex.test(address))
      return btcRegex.test(address);
    }
    console.log('checking',stepCount)
    const handleNextStep = async () => {
      
        if (stepCount === 1){
          if (address === '' || address === undefined){

            console.log('we will handle error here')
            setErrorText('Paste Wallet Address *') 
         }else if(amount === undefined || Number(amount) === 0 || amount === ''){
  
          console.log(amount,'handle amount realated error here')
          setAmountErrorText('Enter Amount *')
  
        }

        else{
          if (isTotp === true){
            setStepCount(stepCount + 1)
          }else{
            setStepCount(stepCount + 2)
          }
        }
        }else if (stepCount === 2) {
          console.log('making api call',totp)
          setErrorText('')
          const formData = {otp:Number(totp)}
          if (totp && totp.length === 6){
            try {
              console.log('data for api',formData)
              const response = await fetch(
                "https://evistialab.com/api/v1/totp/verify/",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${access_token}`,
                  },
                  body : JSON.stringify({otp:String(totp)})
                }
              );
              if (response.status === 401) {
                // If unauthorized, navigate to the login page
                navigate("/login");
                return; // Stop further execution in this block
              }
              const data = await response.json();
              if (data) {
                if (data.message){
                  setStepCount(stepCount + 1)
                  try {
                    const response = await fetch(
                      "https://evistialab.com/api/v1/withdraw/otp/",
                      {
                        method: "GET",
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${access_token}`,
                        },
                      }
                    );
                    if (response.status === 401) {
                      // If unauthorized, navigate to the login page
                      navigate("/login");
                      return; // Stop further execution in this block
                    }
                    const data = await response.json();
                    if (data) {
                      console.log('api data now should show otp enter screen ',data)
                      
                    }
                  } catch (error) {
                    console.error("Error fetching USDT balance:", error);
                  }
                }
              }
              if (data.error) {
                setErrorText(data.error)
              }
            } catch (error) {
              console.error("Error fetching USDT balance:", error);
            }
          }
          else{
            setErrorText('Enter Your OTP')
          }
        }
        else if (stepCount === 3){
          // console.log(otp,'our otp')
          setErrorText('')
          const formData = {otp_code:Number(otp)}
          if (otp && otp.length === 6){
            try {
              console.log('data for api',formData)
              const response = await fetch(
                "https://evistialab.com/api/v1/withdraw/otp/",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${access_token}`,
                  },
                  body : JSON.stringify({otp_code:String(otp)})
                }
              );
              if (response.status === 401) {
                // If unauthorized, navigate to the login page
                navigate("/login");
                return; // Stop further execution in this block
              }
              const data = await response.json();
              if (data) {
                if (data.message){
                  setStepCount(stepCount + 1)
                  const myHeaders = new Headers();
                  myHeaders.append("Content-Type", "application/json");
                  myHeaders.append("Authorization", `Bearer ${access_token}`);
            
                  const raw = JSON.stringify({
                    "currency": sign,
                    "to_address": address,
                    "amount": Number(amount)
                  });
      
                  const requestOptions = {
                    method: "POST", 
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow"
                  };
      
            try { 
              console.log(address,'address',amount,'amount',name,'requestOptions',requestOptions)
              // const response  = await fetch('http://127.0.0.1:8000/api/wallet/btc-balance/', {
              const response = await fetch('https://evistialab.com/api/v1/send/', 
              requestOptions);
              console.log(response,'response')
              const data = await response.json();
              if (response.status === 401) {
                // If unauthorized, navigate to the login page
                navigate('/login');
                return; // Stop further execution in this block
              }
                if (data){
                  console.log('data is here',data)
                    if (data.transaction_hash){
                      setStepCount(stepCount - 2)
                        Swal.fire({
                          icon: 'warning',
                          title: 'warning',
                          text: `${data.transaction_hash}`, 
                        })

                      }
                    else if (data.error){
                      setStepCount(stepCount - 2)
                        Swal.fire({
                          icon: 'error',
                          title: 'Error',
                          text: `${data.error}`, 
                        })
                    }else if(data.message) {
                      setStepCount(stepCount + 1)
                    }

                    console.log(data,'here we go')
                    // if (data.message === "Transaction successful") {
                    //   setStepCount(stepCount + 1)
                    // }
                  }
                
              } catch (error) {
                console.error('Error :', error);
                setStepCount(stepCount - 2)
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: `${error}`, 
                })
              }
                }
              }
              if (data.error) {
                setErrorText(data.error)
              }
            } catch (error) {
              console.error("Error fetching USDT balance:", error);
            }
          }
          else{
            setErrorText('Enter Your OTP')
          }
          // setStepCount(stepCount + 1)
        }else if(stepCount === 4){
            console.log('we are in step 4')
        }
      }

    const [dropDown,showDropDown] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);


    const dropDownHandle = () => {
        showDropDown(!dropDown)
    }

    const handleOptionSelect = (optionText) => {
    setSelectedOption({ text: optionText});
    showDropDown(false);
    };
    return (
        <div className='sideBarMainContent '>
        <div className='WMainDiv '>
        <UpperNav title={`Send ${pageName}`} symbol={" "}/>
    
        
        <div className='row mt-5 justify-content-center'>
          <div className='col-xxl-5 col-lg-8 col-12'>
              <div className='fiatTransferCard mt-md-5 mt-2 ' >
              <div className='cardUpperRow mb-3'>
                    <div className='col-12'>
                    <h3>
                    Send {sign}
                    </h3>
                    </div>
                </div>

              <div className='col-12 px-5 ' >
               {
                stepCount === 1 ? 
                <>
               <div className='FDInputDiv mb-4'>
                    <h6 className='ps-1 inputTitle mb-3'>
                    Address
                    </h6>
                    <input type='text' 
                    placeholder='Paste Address' 
                    className='form-control fTransferInput' 
                    value={address} 
                    onChange={(e)=>{setaddress(e.target.value)}}
                    onBlur={()=>{setErrorText('')}}
                    />
                    <span className='inputTitle' style={{color:"red"}}>
                      {errorText}
                    </span>
                </div>
                {/* <div className='inputDiv my-4'>
                    <p className='inputTitle'>
                    Network
                    </p>
                    <div className='detailInput'>
                        <p>
                        {props.network}
                        </p>
                    </div>
                </div> */}
                <div className='FDInputDiv mb-1'>
                    <h6 className='ps-1 inputTitle mb-2'>
                    Network
                    </h6>
                    {/* <input type='text' placeholder='Automatically match the network' className='form-control fTransferInput' value={network} onChange={()} readOnly/> */}
                    <div className='FDetailsDiv mb-4'>
                    <span className='networkText'>                  {network}
                    </span>
                    </div>
                </div>
                
                {/* <div className='fTransferddDiv d-flex gap-3 align-items-center justify-content-between mb-4' onClick={dropDownHandle}>
              <div className='d-flex gap-2'>
                {selectedOption?.text || 'Select Your Netwrok'}
              </div>
              <img loading='lazy' src={process.env.PUBLIC_URL + "/images/WHPArrowD.svg" } alt="Section One Right Side Images" className="currencySwapOptionArrow"/>

                <div className={`dropdown-menu CSwapDropDownSC ${dropDown ? 'show' : ''}`}  onMouseLeave={() => showDropDown(false)}>
                    <DropdownItem text="ERC-20"  onSelect={handleOptionSelect} />
                    <DropdownItem text="BEP-20"  onSelect={handleOptionSelect} />
                    <DropdownItem text="P-20 "  onSelect={handleOptionSelect} />
                </div>
                </div> */}
                <div className='FDInputDiv mb-2'>
                    <h6 className='ps-1 inputTitle mb-2'>
                     Amount
                    </h6>
                   
                    <input type='number' placeholder='Minimum 1' className='form-control fTransferInput' onChange={(e)=>setamount(e.target.value)} value={amount}  onBlur={()=>{setAmountErrorText('')}}/>
                    <span className='inputTitle' style={{color:"red"}}>
                      {amountErrorText}
                    </span>
                </div>
                <div className='recievedDetailDiv d-flex justify-content-between '>
                    <h6>
                    Available
                    </h6>
                    <h5>
                    {availbleAmount ? availbleAmount : 0} {sign}
                    </h5>
                </div>
                <p className='SVCNBottomText mt-5'>
                Do not send directly to a crowdfund or ICO. We will no credit your  account with tokens from that sale.
                </p>
                </> :
                stepCount === 2 ? 
                <Totp_validation totp={totp} settOtp={settOtp} errorText={errorText} /> :
                stepCount === 3 || stepCount === 4 ? 
                <FiatTransferStepThree otp={otp} setOtp={setOtp} errorText={errorText} />
                // : stepCount === 3 ? 
                // <MobileOTP />
                : stepCount === 5 ?  navigate('/wallet/payment-confirmation', {state : {amount,sign,pageName,address}})
                : ""
               }
              </div>

              <div className='row justify-content-center'>
                <div className='col-8'>
                        <div className='WHPS3bottomDiv row '>              
                            <a onClick={handleNextStep} > Next </a>
                        </div>
                </div>
            </div>
    
        </div>
    
    
      </div>
    </div>
    </div>
    </div>
      )
}

export default SendViaCryptoNetwork