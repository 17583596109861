import React from 'react'
import UpperNav from './UpperNav'
function EditRecipients(props) {
    return (
        <div className='sideBarMainContent '>
        <div className='WMainDiv '>
        <UpperNav title={`Edit Recipients`} symbol={" "}/>
    
        
        <div className='row mt-5 justify-content-center'>
          <div className='col-xxl-5 col-lg-8 col-12'>
              <div className='fiatTransferCard mt-md-5 mt-2 ' >
                <div className='cardUpperRow mb-3'>
                    <div className='col-12'>
                    <h3>
                   {props.accountHolderName}
                    </h3>
                    </div>
                </div>

                <div className='col-12 newPaymentDiv mt-4'>
                    <div className='FDInputDiv mb-4'>
                        <h6 className='ps-1 inputTitle mb-3'>
                        Bank Name
                        </h6>
                        <input type='text' placeholder='Bank Name' className='form-control fTransferInput' value={props.bankName} onChange={(e)=>{props.setbankName(e.target.value)}}/>
                    </div>
                    <div className='FDInputDiv mb-4'>
                        <h6 className='ps-1 inputTitle mb-3'>
                        Recipients Name
                        </h6>
                        <input type='text' placeholder='Steve Thompson' className='form-control fTransferInput' value={props.accountHolderName} onChange={(e)=>{props.setaccountHolderName(e.target.value)}}/>
                    </div>
                    <div className='FDInputDiv mb-4'>
                        <h6 className='ps-1 inputTitle mb-3'>
                        Account Number
                        </h6>
                        <input type='number' placeholder='Account Number' className='form-control fTransferInput' value={props.acoountNumber} onChange={(e)=>{props.setacoountNumber(e.target.value)}}/>
                    </div>
                    <div className='FDInputDiv mb-4'>
                        <h6 className='ps-1 inputTitle mb-3'>
                        Sort Code
                        </h6>
                        <input type='number' placeholder='123' className='form-control fTransferInput' value={props.sortCode} onChange={(e)=>{props.setsortCode(e.target.value)}}/>
                    </div>
                    <div className='FDInputDiv '>
                        <h6 className='ps-1 inputTitle mb-3'>
                        IBAN
                        </h6>
                        <input type='number' placeholder='345511000111123214' className='form-control fTransferInput' value={props.iban} onChange={(e)=>{props.setiban(e.target.value)}}/>
                    </div>
                </div>


              <div className='row justify-content-center mt-4'>
                <div className='col-8'>
                        <div className='WHPS3bottomDiv row '>              
                            <a onClick={()=>{props.currentPage('details')}}> Save Changes </a>
                        </div>
                </div>
            </div>
    
        </div>
    
    
      </div>
    </div>
    </div>
    </div>
      )
}

export default EditRecipients