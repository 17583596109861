import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useActivateUserMutation } from '../../services/userAuthApi';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';



const AccountActivation = () => {
  const navigate = useNavigate()
  const { id, token } = useParams();
  const [activationStatus, setActivationStatus] = useState(null);
  const [activateUser, { isLoading, isError , isSuccess }] = useActivateUserMutation();
  //consolelog(id, token)
  useEffect( () => {
    if (id && token) {
      activateUser({ id, token })
        .then(async (response) => {
          // Handle the successful activation response
          if (await response.data.detail){
            //consolelog(response,'success response is here')
              // API call was successful
           Swal.fire({
            icon: 'success',
            title: 'Success',
            text: "Your Account Has Been Activated Successfully", // Change to your data property
              }).then(() => {
                navigate('/login'); // Redirect to another page
              })
            return;
          
        }else if(await response.data.error){
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: "Your Account Has Been Activated Successfully", // Change to your data property
              }).then(() => {
                navigate('/login'); // Redirect to another page
              })
        }
        else {
          navigate('/login')
        }
        })
    }
  }, []);

  return (
    <div>
    </div>
  );
};

export default AccountActivation;
