import React,{useState} from 'react'
import {Link, useNavigate } from 'react-router-dom';
export default function PasswordSuccessView() {
  const [loading , setLoading ] = useState(false)

    const navigate = useNavigate();

    const goBack = () => {
        setLoading(true)

        setTimeout(() => {
          navigate('/login'); // This navigates back one step in the application's history
          setLoading(false)
        }, 1500);
      };
    
    

      return (
        <div>
          <div className='container-fluid LoginMainDiv ps-0' >
            <div className='row ps-0'>
            <div className='col-6 px-0 LoginMainDivBg001 d-lg-block d-none ps-0'  >
              <div className='d-flex flex-column justify-content-between new_login_main_div h-100'>
               
               <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_back_icon.svg" } alt="new IMg" className="back_icon" onClick={goBack}/>


                <div className=''>
                  <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_login_logo.svg" } alt="new IMg" className="new_login_logo mb-3" />

                  <h1 className='new_heading'>
                  The Future of Banking
                  </h1>
                </div>
              </div>


          </div>
              <div className='col-xxl-6 col-xl-6 col-lg-6  rightSideDivLogin py-md-5 py-0 '  >
                  <div className='d-flex justify-content-center '>
                  <Link to="/" style={{ margin: "auto", textAlign: "center" }}>
                    <img loading='lazy' alt='Logo'  src="../images/ev-logo.png" className='manageLogoW pt-lg-3 pt-3' />
                  </Link>
                  </div>
    
                  <h2 className='loginTitle'>
                  Password reset
                  </h2>
                  <p className='subTextInForgotPasswordPage px-5'>
                  Your new password has been successfully reset <br className='d-lg-block d-none'></br>
                click below to login.
                  </p>
                <div className='SignInForm'>
                  <div className="authSubmitBtn mt-5 " style={{width:'100%'}} >
                  <button type="submit" className={`button ${loading ? 'loading' : ''}`} style={{ "border": "none" }} onClick={goBack}>
                  {loading ? (
                    <div className="spinner"></div>
                  ) : (
                    <span>Back to login</span>
                  )}
                </button>
                    {/* <button className='s5BtnLogin' onClick={goBack}> <span className='s5Btntxt'>Back to login </span> 
                    </button>  */}
                  </div>
                </div>

              </div>
            </div>
          </div>
    
    
    
        </div>
      ) 
}
