import React from 'react'
import UpperNav from './UpperNav'
import { useLocation } from 'react-router-dom';

export default function FiatDetails() {
  const location = useLocation()
  const { h2Value, balance, title, symbol, img } = location.state;
  return (
    <div className='sideBarMainContent '>
    <div className='WMainDiv '>
      <UpperNav title={title} symbol="" />
      <div className='row mt-5 WCDUpperRow'>
        <div className='col-lg-4 col-md-3 col-sm-4 col-12  d-flex flex-column gap-1'>
            <div className='upperDiv d-flex gap-3 align-items-center'>
              <img loading='lazy' src={img} width="19" height="19" alt="Section One Right Side Images" className="img-fluid "/>
              <div className='innerTextDiv '>
                  <p>
                  {title}
                  </p>
              </div>
            </div>
            <div className='centerDiv '>
                <h2 className='ps-1'>
                {balance}
                </h2>
              </div>
        </div>
        <div className='col-lg-8 col-md-9 col-sm-8 col-12'>
        <div className='statementDiv01 statementDiv02 mt-sm-0 gap-2'>
                    <div>
                        <h6 className='ps-1'> Start Date </h6>
                        <input type='date' className='dateInput01 dateInput02'/>
                    </div>
                    <div>
                        <h6 className='ps-1'> End Date </h6>
                        <input type='date' className='dateInput01 dateInput02'/>
                    </div>
                   <div>
                    
                    <a > 
                            <button className='downloadBtn01 downloadBtn02'>Download E-Statement</button>
                        </a>
                   </div>
                </div>
        </div>
        <img loading='lazy' src={process.env.PUBLIC_URL + "/images/BottomHr.svg" } alt="settingImg" className="wdBtnIcon mt-5 pt-3" />

          <div className='row mt-5 '>
            <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                <h1>
                Transaction History
                </h1>
                <a className='customBtnWD d-flex gap-2'>
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Group 617.svg" } alt="settingImg" className="wdBtnIco" />
                Filter
                </a>
            </div>
          </div>
          <div className='row my-5  gap-4'>
            <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                <div className='d-flex gap-3'>
                  <img loading='lazy' src="../images/downArrowW.svg" alt="Section One Right Side Images" className="img-fluid "/>
                  <div className='d-flex flex-column justify-content-center '>
                    <p className='mb-1'>
                    John Doe
                    </p>
                    <span>
                    July 13 9:20PM
                    </span>
                  </div>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-end'>
                    <p className='mb-1'>
                    400 USD
                    </p>
                    <span>
                    + 400 USD
                    </span>
                </div>
            </div>

            <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                <div className='d-flex gap-3'>
                  <img loading='lazy' src="../images/upperArrowW.svg" alt="Section One Right Side Images" className="img-fluid "/>
                  <div className='d-flex flex-column justify-content-center '>
                    <p className='mb-1'>
                    Luke Wilson
                    </p>
                    <span>
                    July 10 10:56AM
                    </span>
                  </div>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-end'>
                    <p className='mb-1'>
                    700 EUR
                    </p>
                    <span>
                    -700 USD
                    </span>
                </div>
            </div>

            <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                <div className='d-flex gap-3'>
                  <img loading='lazy' src="../images/inbox.svg" alt="Section One Right Side Images" className="img-fluid "/>
                  <div className='d-flex flex-column justify-content-center '>
                    <p className='mb-1'>
                    NETFLIX Premium Subscription
                    </p>
                    <span>
                    Jul 3 12:15PM
                    </span>
                  </div>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-end '>
                    <p className='mb-1'>
                    10 USD
                    </p>
                    <span>
                    -10.00 USD
                    </span>
                </div>
            </div>
            <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                <div className='d-flex gap-3'>
                  <img loading='lazy' src="../images/downArrowW.svg" alt="Section One Right Side Images" className="img-fluid "/>
                  <div className='d-flex flex-column justify-content-center '>
                    <p className='mb-1'>
                    John Doe
                    </p>
                    <span>
                    July 13 9:20PM
                    </span>
                  </div>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-end'>
                    <p className='mb-1'>
                    400 USD
                    </p>
                    <span>
                    + 400 USD
                    </span>
                </div>
            </div>

            <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                <div className='d-flex gap-3'>
                  <img loading='lazy' src="../images/upperArrowW.svg" alt="Section One Right Side Images" className="img-fluid "/>
                  <div className='d-flex flex-column justify-content-center '>
                    <p className='mb-1'>
                    Luke Wilson
                    </p>
                    <span>
                    July 10 10:56AM
                    </span>
                  </div>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-end'>
                    <p className='mb-1'>
                    700 EUR
                    </p>
                    <span>
                    -700 USD
                    </span>
                </div>
            </div>

            <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                <div className='d-flex gap-3'>
                  <img loading='lazy' src="../images/inbox.svg" alt="Section One Right Side Images" className="img-fluid "/>
                  <div className='d-flex flex-column justify-content-center '>
                    <p className='mb-1'>
                    NETFLIX Premium Subscription
                    </p>
                    <span>
                    Jul 3 12:15PM
                    </span>
                  </div>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-end '>
                    <p className='mb-1'>
                    10 USD
                    </p>
                    <span>
                    -10.00 USD
                    </span>
                </div>
            </div>

            <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                <div className='d-flex gap-3'>
                  <img loading='lazy' src="../images/inbox.svg" alt="Section One Right Side Images" className="img-fluid "/>
                  <div className='d-flex flex-column justify-content-center '>
                    <p className='mb-1'>
                    NETFLIX Premium Subscription
                    </p>
                    <span>
                    Jul 3 12:15PM
                    </span>
                  </div>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-end '>
                    <p className='mb-1'>
                    10 USD
                    </p>
                    <span>
                    -10.00 USD
                    </span>
                </div>
            </div>


            <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                <div className='d-flex gap-3'>
                  <img loading='lazy' src="../images/inbox.svg" alt="Section One Right Side Images" className="img-fluid "/>
                  <div className='d-flex flex-column justify-content-center '>
                    <p className='mb-1'>
                    NETFLIX Premium Subscription
                    </p>
                    <span>
                    Jul 3 12:15PM
                    </span>
                  </div>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-end '>
                    <p className='mb-1'>
                    10 USD
                    </p>
                    <span>
                    -10.00 USD
                    </span>
                </div>
            </div>


          </div>
          
      </div>


    </div>
  </div>
  )
}
