import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function NotAccessPage() {
    const navigate = useNavigate()
  return (
    <div className="not-found-container">
    <h2 className="not-found-heading">404 Not Found</h2>
    <p className="not-found-message">You Have Not Access For The requested page.</p>
    <p className="not-found-message">Please Complete Your KYC.</p>
        <button className='s5Btn mt-5 formBTn' onClick={()=>{navigate('/account-verification/')}}> Veirfy Now
        </button> 
  </div>
  )
}
