import React,{useState} from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { usePasswordResetEmailMutation, usePasswordResetMutation } from '../../../services/userAuthApi';
import { useRef } from 'react';
import SetNewPassword from './SetNewPassword';
import { toast } from 'react-toastify';


export default function ForgotPassword() {
  const [loading , setLoading ] = useState(false)
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('')
  const [error, showErorr] = useState('')
  const [isError, setIsErorr] = useState(false)
  const [isOtpError, setIsOtpErorr] = useState(false)
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [beforeEmail, setBeforeEmail] = useState(true)
  const [verifyProcess,setVerifyProcess] = useState(true)

  const inputRefs = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);


// Function to handle input change for each digit
const handleInputChange = (index, event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value) && value.length <= 1) {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    // Move focus to the next input field
    if (index < code.length - 1 && value.length === 1) {
        inputRefs.current[index + 1].current.focus();
    }
  }
  setIsOtpErorr(false)
};


const [emailResetApi ] = usePasswordResetEmailMutation() 
const [passwordReset ] = usePasswordResetMutation() 



const resendOTPHandler = async () => {
  setLoading(true)

  const resendOTPData = {'email':email}
    const res = await emailResetApi(resendOTPData)
    if (res.error){
      // console.log(res.error.data.message,'resend otp error is here')
      showErorr(res.error.data.message)
      setIsOtpErorr(true)
    setLoading(false)

    }else if (res.data){
    // console.log('response is here',res.data)
    // Swal.fire({
    //   icon: 'success',
    //   title: 'Success',
    //   text: res.data.message, // Change to your data property
    // })
    toast.success(`${res.data.message}`);
    // Clear the form fields after successful submission
    setCode(['', '', '', '', '', '']);
    showErorr('');
    setIsOtpErorr(false)
    // storeToken(res.data.token)
    // navigate('/account-verification/', { replace: true });
    setLoading(false)

  }
    // if (res){
    //   console.log(res,'resend otp data is here')
    // }
}


const handleFormSubmitofOTP = async (event) => {
setIsOtpErorr(false)
event.preventDefault();
if (email && code){
  setLoading(true)
  const otpnumber = code.join('')

  // console.log(otpnumber,'OTO')
  const loginData = {'email':email, 'otp':String(otpnumber)}

  // console.log('Form submitted with:', loginData);


  const res = await passwordReset(loginData)
  // if (res){
  //   console.log(res,'res is here')
  // }
  if (res.error){
  setLoading(false)
      // console.log(res.error.data.message,'error is here')
      showErorr(res.error.data.message)
      setIsOtpErorr(true)
  }else if(res.data) {
      setVerifyProcess(false)
      setIsOtpErorr(false)
  setLoading(false)

  }
}
};



  const goBack = () => {
    navigate(-1); // This navigates back one step in the application's history
  };
  const saveEmailToLocalStorage = (email) => {
    localStorage.setItem('userEmail', email);
  };

  


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    showErorr(''); // Clear the error message when email changes
    setIsErorr(false)
  };

  

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (email){
  setLoading(true)
      saveEmailToLocalStorage(email)
      const resetEmail = {email: email}
      const res = await emailResetApi(resetEmail)
      if (res.error){

        showErorr(res.error.data.message)
        setIsErorr(true)
  setLoading(false)

      }else {
        setBeforeEmail(false)
        setIsEmailVerified(true)
        
        setLoading(false)

      }
      // if (res.data.error){
      //   if (res.data.error){
      //     showErorr(res.data.error)
      //     setIsErorr(true)
      //   }
      // }else {
      //   setBeforeEmail(false)
      // }
    }
    };

    
  return (
    <div>
      {
        verifyProcess ? 
        <div className='container-fluid LoginMainDiv ps-0' >
        <div className='row ps-0'>
        <div className='col-6 px-0 LoginMainDivBg001 d-lg-block d-none ps-0'  >
              <div className='d-flex flex-column justify-content-between new_login_main_div h-100'>
               
               <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_back_icon.svg" } alt="new IMg" className="back_icon" onClick={goBack}/>


                <div className=''>
                  <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_login_logo.svg" } alt="new IMg" className="new_login_logo mb-3" />

                  <h1 className='new_heading'>
                  The Future of Banking
                  </h1>
                </div>
              </div>


          </div>
          {
            isEmailVerified ? <>
            <div className='col-xxl-6 col-xl-6 col-lg-6  rightSideDivLogin py-md-5 py-0 '  >
            <div className='d-flex justify-content-center '>
                  <Link to="/" style={{ margin: "auto", textAlign: "center" }}>
                    <img loading='lazy' alt='Logo'  src="../images/ev-logo.png" className='manageLogoW pt-lg-3 pt-3' />
                  </Link>
              </div>
              <p className='newOtpHeading mt-lg-5 mt-4'> 
              OTP Verification
              </p>
              <p className='newEmailTxt mt-lg-5 mt-4'>
              A 6-digit OTP has been sent to the email {beforeEmail === true ? "" : email}
              </p>
              <form onSubmit={handleFormSubmitofOTP} className='SignInForm  mt-3'>
                  <div className="mb-2">
                      <div className="code-input-container pb-4">
                            {code.map((digit, index) => (
                            <input
                                key={index}
                                ref={inputRefs.current[index]}
                                type="text"
                                value={digit}
                                maxLength="1"
                                onChange={(e) => handleInputChange(index, e)}
                                className={`${isOtpError ? 'otpInputError' : ""}`}
                            />
                            ))}
                        </div>
                    </div>
                    {
                      isOtpError ?   <p className='otpError mx-auto mb-2'>
                      {error}
                      </p> : ""
                    }
                  
                  <div className="mx-auto mt-3 " style={{width:'70%'}}>
                  <button type="submit" className={`button ${loading ? 'loading' : ''}`} style={{ "border": "none" }} onClick={handleFormSubmitofOTP}>
                  {loading ? (
                    <div className="spinner"></div>
                  ) : (
                    <span>Submit</span>
                  )}
                </button>
                    {/* <button className='s5BtnLogin' onClick={handleFormSubmitofOTP}> <span className='s5Btntxt'> Submit </span> 
                    </button>  */}
                  </div>
    
    
                  <div className='BottomTextDiv'>
                  <p className='BottomText mt-4' style={{color:'#2645E0'}}>
                  <span onClick={resendOTPHandler} style={{cursor:'pointer',color:'#2645E0'}}>
                  Resend OTP
                  </span>
                  </p>
                  <p className='BottomText' >
                  {t('LOGIN.p.2')} <span ><Link to="/sign-up" style={{cursor:'pointer',color:'#2645E0'}}>{t('LOGIN.p.3')}</Link>  </span> 
                  </p>
                  </div>
              </form> 
            </div>
            
            </> : 
         
          <div className='col-xxl-6 col-xl-6 col-lg-6  rightSideDivLogin py-md-5 py-0 '  >
          {/* <p className='formError pt-3 text-center' >
                {error}
              </p>  */}
              <div className='d-flex justify-content-center '>
                <Link to="/">
                <img loading='lazy' alt='Logo' src={process.env.PUBLIC_URL + "/images/logo.svg" }   className='imgResponsive pt-lg-3 pt-5'/>
                </Link>
              </div>

              <h2 className='loginTitle'>
                Recover Your Password
              </h2>
              
                  <p className='subTextInForgotPasswordPage px-5'>
                  Enter the email that you used when register to <br className='d-lg-block d-none'></br> recover your password. We will notify to you <br className='d-lg-block d-none'></br> on your registerd email. 
                  </p> 
               
              <p className='emailForgot'>
                {beforeEmail === true ? "" : email}
              </p>
              {
                isError ? 
                  <div className='error_login_div d-flex gap-3 justify-content-start align-items-center mx-auto my-4'>
                  <img loading='lazy' src={process.env.PUBLIC_URL + "/images/new_error.svg" } alt="new IMg" className="back_icon" />
                    <span className='new_error_text'>
                      {error}
                    </span>
                  </div> :
                  ""
              }
              <form onSubmit={handleFormSubmit} className='SignInForm'>
              <div className="mb-5">
                  <label htmlFor="email" className="new_input_feilds_label"> {t('LOGIN.lb.1')}</label>
                  <input
                    type="email"
                    className={`new_input_feilds`}
                    id="email"
                    placeholder="Enter Your Email"
                    value={email}
                    onChange={handleEmailChange} // Use the new event handler
                    required
                    maxLength="50"
                  />
                </div> 
              <div className="authSubmitBtn mt-5 " style={{width:'100%'}}>
                <button type="submit" className={`button ${loading ? 'loading' : ''}`} style={{ "border": "none" }} >
                  {loading ? (
                    <div className="spinner"></div>
                  ) : (
                    <span>{t('LOGIN.lb.4')}</span>
                  )}
                </button>
{/*                 
                <button className='s5BtnLogin' disabled={isResetFormSubmit} > <span className='s5Btntxt' > Reset Password  </span> 
                </button>  */}
              </div>
              
             
              <div className='d-flex gap-2 align-items-center backToPage ' onClick={goBack}>
              <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Group 6.svg" } alt="new IMg" className="" />
              <p className='m-0 backText'>
              Back to log in
              </p>
              </div>

              </form>
          </div>
           }

        </div>
        </div> : 
       <SetNewPassword />
      }



    </div>
  ) 
}
