import React from 'react'
import { useLocation ,Link } from 'react-router-dom';
import UpperNav from './UpperNav';
function SwapSuccess() {
    const location = useLocation();
    const cryptoSymbol = location.state?.cryptoSymbol;
    const currencySymbol = location.state?.currencySymbol;
    const message = location.state?.message;
    const fee = location.state?.fee;
    const amount_credit = location.state?.amount_credit;

    return (
      <div className='sideBarMainContent '>
      <div className='WMainDiv '>
        <UpperNav title={"Swap Complete"} symbol={""}/>
  
        
        <div className='row mt-5 justify-content-center '>
              <div className='col-xl-6 col-lg-8 col-12 mt-5'>
                  <div className='paymentConfirmationCard d-flex align-items-center justify-content-around gap-4'>
                  <img loading='lazy' src={process.env.PUBLIC_URL + "/images/swapsuccess.svg" } alt="User Image" className="" />
  
                      <div className='d-flex flex-column align-items-center'>
                      <p className='title mt-3'>
                      Swap Complete
                      </p>
                        <p className='amount mb-0'>
                        from {cryptoSymbol} to {currencySymbol}
                        </p>
                      </div>
                  </div>
                  <p className='tid text-center pt-5'>
                  {message} <br></br>
                  Fiat Amount Credit {amount_credit} with {fee} fees. <br></br> <br></br>
                  We'll send you an email of confirmation. It may take a few 
                   minutes to see the payment in your Transactions page.
                  </p>
                  <p className='text-center pageLink mt-5'>
                      <Link to="/wallet/payments">
                      Make Another Payment
                      </Link>
                  </p>
              </div>
  
              
        </div>
      </div>
    </div>
    )
}

export default SwapSuccess