import React , {useState,useEffect} from 'react'
import UpperNav from './UpperNav'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useConvertCryptoToFiatMutation ,useConvertFiatToCryptoMutation } from '../../services/userAuthApi';
import { CurrencyDropdownItems } from '../../constants/AllCurencies';
import { currencyMapping } from '../../constants/currencyMapping';
import { getToken } from '../../services/LocalStorageService';




function DropdownItem({ text, imgSrc, onSelect }) {
    return (
      <button className="dropdown-item" onClick={() => onSelect(text, imgSrc)}>
        <img loading='lazy' src={imgSrc} alt="Currency Image" className="currencySwapOptionImg" /> {text}
      </button>
    );
  }
  function DropdownItem1({ text,code, imgSrc, onSelect }) {
    return (
      <button className="dropdown-item d-flex gap-3 align-items-center" onClick={() => onSelect(text,code, imgSrc)}>
        <img loading='lazy' src={process.env.PUBLIC_URL + imgSrc} alt="Currency Image" className="currencySwapOptionImg" />
        <div className='d-flex flex-column'>
        {text} 
        <span>{code}</span>
        </div>
      </button>
    );
  }







export default function Swap() {
  
  const {access_token} = getToken();
  const [price, setPrice] = useState('');
  const [cryptoForApi, setCryptoForApi] = useState('BNB');
  const [currencyForApi, setCurrencyForApi] = useState('gbp');



  const [hover,setHover] = useState(false)
  



    const navigate = useNavigate()
    const [swapClick, setSwapClick] = useState(true);

    const handleSwapClick = () => {
      setSwapClick(!swapClick)
      setCryptoTotal('0')
      setcurrencyTotal('0')
      setcurrencyResultTotal('0')
      setCryptoResultTotal('0')
    };



    const [dropDown,showDropDown] = useState(false)
    const [dropDown1,showDropDown1] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOption1, setSelectedOption1] = useState(null);

    const [cryptoSymbol, setCryptoSymbol] = useState('BNB')
    const [cryptoTotal, setCryptoTotal] = useState()
    const [cryptoResultTotal, setCryptoResultTotal] = useState('0')
    const [cryptoIconeUrl, setCryptoIconeUrl] = useState('../images/Group 823.svg')

    const [currencySymbol, setCurrencySymbol] = useState('GBP')
    const [currencyResultTotal, setcurrencyResultTotal] = useState('0')
    const [currencyTotal, setcurrencyTotal] = useState()

    const [currencyIconUrl, setcurrencyIconUrl] = useState('../images/g.svg')
    const [direction,setDirection] = useState()
    const [stepCount, setstepCount] = useState(1)

    const dropDownHandle = () => {
        showDropDown(!dropDown)
    }
    const dropDownHandle1 = () => {
        showDropDown1(!dropDown1)
    }

    const handleOptionSelect = (optionText, optionImgSrc) => {
      setSelectedOption({ text: optionText, imgSrc: optionImgSrc });
      setCryptoSymbol(optionText);
      setCryptoIconeUrl(optionImgSrc);
      showDropDown(false);
  
      // Mapping optionText to API identifier
      const apiMapping = {
        BTC: "BTC",
        ETH: "ETH",
        BNB: "BNB",
        USDT: "USDT",
        SOL: "SOL",
        XRP: "XRP",
        // Add more mappings as needed
      };
  
      setCryptoForApi(apiMapping[optionText] || 'BNB');
  };








  const handleOptionSelect1 = (optionText, optionCode ,optionImgSrc) => {
    setSelectedOption1({ text: optionText,code:optionCode, imgSrc: optionImgSrc });
    setCurrencySymbol(optionCode)
    setcurrencyIconUrl(optionImgSrc)
    showDropDown1(false);

    // Mapping for currency API identifier
    console.log('here is our option code',currencyMapping[optionCode])
    // Mapping for currency API identifier
    // const currencyMapping = currencyMapping

    // setCurrencyForApi(currencyMapping[optionCode] || "gbp")

    setCurrencyForApi(currencyMapping[optionCode]?.apiShortName || 'gbp');

  };


  async function convertFiatToCryptoPrice() {
    const apiUrl = "https://evistialab.com/api/v1/crypto-fiat-price/";

    // Prepare the form data to be sent in the request
    const apiData = {
      direction: "fiat_to_crypto",
      fiat: currencyForApi,
      crypto: cryptoForApi,
      amount: currencyTotal,
    };
    console.log(apiData, "api data before call");
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      body: JSON.stringify(apiData),
    });

    if (response.status === 401) {
      navigate("/login");
    }
    if (response.status === 400) {
      console.log(response, "response");
    }

    const data = await response.json();
    if (data) {
      console.log(data);
      setCryptoResultTotal(data.converted_amount_after_fee);
      setPrice(currencyTotal);
      setDirection('fiat_to_crypto')
    }
    if (data.error) {
      console.log(data.error);
    }
  }

  async function convertoCryptoToFIatPrice() {
    const apiUrl = "https://evistialab.com/api/v1/crypto-fiat-price/";
    // Prepare the form data to be sent in the request
    const apiData = {
      direction: "crypto_to_fiat",
      fiat: currencyForApi,
      crypto: cryptoForApi,
      amount: cryptoTotal,
    };
    console.log(apiData, "api data before call");
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      body: JSON.stringify(apiData),
    });

    if (response.status === 401) {
      navigate("/login");
    }
    if (response.status === 400) {
      console.log(response, "response");
    }

    const data = await response.json();
    if (data) {
      console.log(data);
      setcurrencyResultTotal(data.converted_amount_after_fee);
      setPrice(cryptoTotal);
      setDirection('crypto_to_fiat')
    }
    if (data.error) {
      console.log(data.error);
    }
  }

  useEffect(() => {
    if (
      cryptoTotal !== "0" &&
      swapClick === true &&
      cryptoTotal !== "" &&
      cryptoTotal !== undefined
    ) {
      convertoCryptoToFIatPrice();
    }

    if (
      currencyTotal !== "0" &&
      swapClick === false &&
      currencyTotal !== "" &&
      currencyTotal !== undefined
    ) {
      convertFiatToCryptoPrice();
    }
  }, [cryptoTotal, currencyTotal, selectedOption, selectedOption1]);


      
    const handleSwapNextPage = () => {
      if (cryptoTotal !== undefined || currencyTotal !== undefined){
        if (direction === 'crypto_to_fiat'){
          navigate('/wallet/swap-confirm',{
            state:{
              cryptoSymbol,
              cryptoTotal,
              cryptoIconeUrl,
              currencySymbol,
              // currencyTotal,
              currencyIconUrl,
              // price,
              // swapClick,
              // cryptoResultTotal,
              currencyResultTotal,
              direction
            }})
        }else{
          navigate('/wallet/swap-confirm',{
            state:{
              cryptoSymbol,
              // cryptoTotal,
              cryptoIconeUrl,
              currencySymbol,
              currencyTotal,
              currencyIconUrl,
              // price,
              // swapClick,
              cryptoResultTotal,
              direction
            }})
        }
      }
    }
  return (
   
    <div className='sideBarMainContent '>
    <div className='WMainDiv '>
      <UpperNav />
      <div className='row mt-5 justify-content-center'>
        <div className='col-lg-9 col-12 '>
            <div className='swapCard'>
            <div
                className={`swap-row `}
            >
                <div className='CSwapLabelDiv mb-3 d-flex justify-content-between'>
                    <p className='mb-0'>
                    Swap From
                    </p>
                    <div className='gradiantBtn'>
                        <div className='button-content d-flex justify-content-center'>
                            <a className='sidebar-gradient-text '>
                          {swapClick === true ? 'Crypto' : 'Fiat'}
                            </a>
                        </div>
                    </div>
                </div>

                {
                  swapClick === true ?
                    <div className='row  CSwapDiv addMargin'>
                   <div className='col-sm-8 col-6 cSwapRightSide  d-flex gap-3 align-items-center justify-content-between' onClick={dropDownHandle}>
                        <div className='d-flex gap-2'>
                        <img loading='lazy' src={selectedOption?.imgSrc || '../images/Group 823.svg'} alt="Currency Image" className="currencySwapOptionImg" />
                        <h3 className='mb-0 swapTitle'>{selectedOption?.text || 'BNB'}</h3>
                        </div>
                        <img loading='lazy' src="../images/WHPArrowD.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow"/>

                        <div className={`dropdown-menu CSwapDropDown ${dropDown ? 'show' : ''}`}  onMouseLeave={() => showDropDown(false)}>
                        <DropdownItem text="BTC" imgSrc="../images/Group 824.svg" onSelect={handleOptionSelect} />
                      <DropdownItem text="ETH" imgSrc="../images/Group 825.svg" onSelect={handleOptionSelect} />
                      <DropdownItem text="USDT" imgSrc="../images/Group 826.svg" onSelect={handleOptionSelect} />
                      <DropdownItem text="BNB" imgSrc="../images/Group 823.svg" onSelect={handleOptionSelect} />
  
                      </div>
                   </div>
                   <div className='col-sm-4 col-6'>
                        <input type='number' className='form-control cInput' placeholder='0'  value={cryptoTotal} onChange={(e)=>{setCryptoTotal(e.target.value)}}/>
                   </div>
                    </div>
                  : <div className={`row CSwapDiv ${swapClick === false ? "addMargin" : ""}`}>
                      <div className='col-sm-8 col-6 cSwapRightSide  d-flex gap-3 align-items-center justify-content-between' onClick={dropDownHandle1}>
                          <div className='d-flex gap-2'>
                          { selectedOption1?.imgSrc ?
                           <img src={process.env.PUBLIC_URL + selectedOption1?.imgSrc ||  process.env.PUBLIC_URL + '/images/eur.svg'} alt="Currency Image" className="cLogo" /> : 
                           <img loading='lazy' src={'../images/g.svg'} alt="Currency Image" className="cLogo" /> 
                        }
                          <h3 className='mb-0 swapTitle mt-1'>{selectedOption1?.code || 'GBP'}</h3>
                          </div>
                          <img loading='lazy' src="../images/WHPArrowD.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow"/>

                          
                            <div className={`dropdown-menu CSwapDropDown ${dropDown1 ? 'show' : ''}`} onMouseLeave={() => showDropDown1(false)}>
                            {CurrencyDropdownItems.map((item, index) => (
                                <DropdownItem1 key={index} text={item.name} code={item.currency} imgSrc={item.imgSrc} onSelect={handleOptionSelect1} />
                            ))}
                            </div>
                      </div>
                      
                      <div className='col-sm-4 col-6'>
                          <input type='number' className='form-control cInput' placeholder='0'   value={currencyTotal} onChange={(e)=>{setcurrencyTotal(e.target.value)}}/>
                      </div>
                  </div>
                }


                </div>

                <div className='row  swapBtnRow justify-content-center'>
                <img loading='lazy'
                    src="../images/Group 680.svg"
                    alt="Section One Right Side Images" 
                    className=" swapIcon" 
                    onClick={handleSwapClick}
                />
                </div>

                <div
                    className={`swap-row `}
                >
                <div className='CSwapLabelDiv mt-5 mb-3 d-flex justify-content-between'>
                    <p className='mb-0'>
                    Recieve
                    </p>
                    <div className='gradiantBtn'>
                        <div className='button-content d-flex justify-content-center'>
                            <a className='sidebar-gradient-text '>
                            {swapClick === false ? 'Crypto' : 'Fiat'}
                            </a>
                        </div>
                    </div>
                </div>


                {
                  swapClick === !true ?
                    <div className={`row  CSwapDiv ${swapClick === !false ? "addMargin" : ""}`}>
                   <div className='col-sm-8 col-6 cSwapRightSide  d-flex gap-3 align-items-center justify-content-between' onClick={dropDownHandle}>
                        <div className='d-flex gap-2'>
                        <img loading='lazy' src={selectedOption?.imgSrc || '../images/Group 823.svg'} alt="Currency Image" className="currencySwapOptionImg" />
                        <h3 className='mb-0 swapTitle'>{selectedOption?.text || 'BNB'}</h3>
                        </div>
                        <img loading='lazy' src="../images/WHPArrowD.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow"/>

                        <div className={`dropdown-menu CSwapDropDown ${dropDown ? 'show' : ''}`}  onMouseLeave={() => showDropDown(false)}>
                        <DropdownItem text="BTC" imgSrc="../images/Group 824.svg" onSelect={handleOptionSelect} />
                      <DropdownItem text="ETH" imgSrc="../images/Group 825.svg" onSelect={handleOptionSelect} />
                      <DropdownItem text="USDT" imgSrc="../images/Group 826.svg" onSelect={handleOptionSelect} />
                      <DropdownItem text="BNB" imgSrc="../images/Group 823.svg" onSelect={handleOptionSelect} />
  
                      </div>
                   </div>
                   <div className='col-sm-4 col-6'>
                        <input 
                          type='number' 
                          className='form-control cInput' 
                          placeholder='0'  
                          value={cryptoResultTotal} 
                          onChange={(e)=>{setCryptoResultTotal(e.target.value)}}
                          readOnly
                          />
                   </div>
                    </div>
                  : <div className={`row CSwapDiv `}>
                      <div className='col-sm-8 col-6 cSwapRightSide  d-flex gap-3 align-items-center justify-content-between' onClick={dropDownHandle1}>
                          <div className='d-flex gap-2'>
                          { selectedOption1?.imgSrc ?
                           <img src={process.env.PUBLIC_URL + selectedOption1?.imgSrc ||  process.env.PUBLIC_URL + '/images/eur.svg'} alt="Currency Image" className="cLogo" /> : 
                           <img loading='lazy' src={'../images/g.svg'} alt="Currency Image" className="cLogo" /> 
                        }
                          <h3 className='mb-0 swapTitle mt-1'>{selectedOption1?.code || 'GBP'}</h3>
                          </div>
                          <img loading='lazy' src="../images/WHPArrowD.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow"/>

                          {/* <div className={`dropdown-menu CSwapDropDown ${dropDown1 ? 'show' : ''}`}  onMouseLeave={() => showDropDown1(false)}>
                          <DropdownItem1 text="GBP" imgSrc="../images/g.svg" onSelect={handleOptionSelect1} />
                      <DropdownItem1 text="EURO" imgSrc="../images/eur.svg" onSelect={handleOptionSelect1} />
                      <DropdownItem1 text="AED" imgSrc="../images/aed.svg" onSelect={handleOptionSelect1} />
                      <DropdownItem1 text="YEN" imgSrc="../images/yen.svg" onSelect={handleOptionSelect1} />
                      <DropdownItem1 text="BHT" imgSrc="../images/bht.svg" onSelect={handleOptionSelect1} />
                      <DropdownItem1 text="RUB" imgSrc="../images/rub.svg" onSelect={handleOptionSelect1} />
                      <DropdownItem1 text="MDL" imgSrc="../images/mdl.svg" onSelect={handleOptionSelect1} />
                      <DropdownItem1 text="PKR" imgSrc="../images/pkr.svg" onSelect={handleOptionSelect1} />
    
                        </div> */}
                          <div className={`dropdown-menu CSwapDropDown ${dropDown1 ? 'show' : ''}`} onMouseLeave={() => showDropDown1(false)}>
                            {CurrencyDropdownItems.map((item, index) => (
                                <DropdownItem1 key={index} text={item.name} code={item.currency} imgSrc={item.imgSrc} onSelect={handleOptionSelect1} />
                            ))}
                        </div>
                      </div>
                      <div className='col-sm-4 col-6'>
                          <input 
                            type='number' 
                            className='form-control cInput' 
                            placeholder='0'   
                            value={currencyResultTotal} 
                            onChange={(e)=>{setcurrencyResultTotal(e.target.value)}} 
                            readOnly
                             />
                      </div>
                  </div>
                }


                </div>

                <div className='row'>
                    <p className='swapTotal mt-3'>
                    {
                      swapClick === true ? 
                      cryptoTotal 
                      : currencyTotal 
                    }
                    {'\u00a0'}
                     {
                      swapClick === true ? 
                      cryptoSymbol 
                      : currencySymbol 
                    }
                    {'\u00a0'}
                       ≈ 
                    {'\u00a0'}
                     {
                      swapClick === true ? 
                      currencyResultTotal
                      : cryptoResultTotal
                    }
                    {'\u00a0'}
                     {
                      swapClick === true ? 
                      currencySymbol 
                      : cryptoSymbol 
                    }
                         
                      
                    
                    </p>
                    
                </div>
                <div className='WHPS3bottomDiv row'>
                    <a onClick={handleSwapNextPage}> Swap </a>                  
                 </div>
            </div>
        </div>
          
      </div>


    </div>
  </div>
  )
}
