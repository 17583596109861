import React,{useState}from 'react'
import UpperNav from './UpperNav'
import copy from "copy-to-clipboard";
import { toast } from 'react-toastify';


function RecievedCryptoDetails(props) {
    const [showDetail,setShowDetail] = useState(false)


    const [copyText, setCopyText] = useState(props.publicAddress);
    
    const copyToClipboard = () => {
        copy(props.publicAddress);
        // alert(`You have copied "${copyText}"`);
        toast.success(`You have copied ${props.publicAddress}`);
    };

  return (
    <>

    
    <div className='row mt-5 justify-content-center'>
        <div className='QrscannerDiv'>
            <div className='imgQRScan'>
                {/* {
                    props.qrCode ? */}
                    <>
                    <img loading='lazy' src={props.qrCode} alt="settingImg" className="" width="230" height="230" />
                    {/* <img loading='lazy' src={process.env.PUBLIC_URL + `/images/${props.image}` } alt="settingImg" className="currencyImgOnQr" /> */}
                    </>
                {/* //     :
                //     <>
                //     <img loading='lazy' src={process.env.PUBLIC_URL + `/images/qrscanner.svg` } alt="settingImg" className="" width="200" height="200" />
                //     <img loading='lazy' src={process.env.PUBLIC_URL + `/images/${props.image}` } alt="settingImg" className="currencyImgOnQr" />
                //     </>
                // } */}
            
            </div>
        </div>
        <div className='inputDiv my-4'>
            <p className='inputTitle'>
            Network
            </p>
            <div className='detailInput'>
                <p>
                {props.network}
                </p>
            </div>
        </div>
        <div className='inputDiv'>
            <p className='inputTitle'>
            My Wallet Address
            </p>
            <div className='detailInput ps-3'>
                <p>
                {props.publicAddress}
                </p>
                <img loading='lazy' src={process.env.PUBLIC_URL + `/images/Group 1036.svg` } alt="settingImg" className="ps-2" onClick={copyToClipboard}/>
            </div>
        </div>
        {/* {
           showDetail === false ? "" 
           : <>
           <div className='recievedDetailDiv d-flex justify-content-between pt-3'>
            <h6>
            Deposit to
            </h6>
            <p>
            1 BNB = 42.85 GBP
            </p>
            </div>
        <div className='recievedDetailDiv d-flex justify-content-between '>
            <h6>
            Minimum deposit
            </h6>
            <p>
            0.00004$
            </p>
        </div>
        <div className='recievedDetailDiv d-flex justify-content-between '>
            <h6>
            Rate
            </h6>
            <p>
            1 BNB = 42.85 GBP
            </p>
        </div>
        <div className='recievedDetailDiv d-flex justify-content-between '>
            <h6>
            Network Fee
            </h6>
            <p>
            0.00004$
            </p>
        </div>
        <div className='recievedDetailDiv d-flex justify-content-between '>
            <h6>
            Rate
            </h6>
            <p>
            1 BNB = 42.85 GBP
            </p>
        </div>
        <div className='recievedDetailDiv d-flex justify-content-between '>
            <h6>
            Network Fee
            </h6>
            <p>
            0.00004$
            </p>
        </div>
           </> 
        } */}
        {/* <p className='moreDetailsText mt-3 ' onClick={() => {setShowDetail(!showDetail)}}>
            More Details
            {
           showDetail === false ?
            <img loading='lazy' src={process.env.PUBLIC_URL + `/images/Vector 11.svg` } alt="settingImg" className="ps-2 arrow" />    
            : 
            <img loading='lazy' src={process.env.PUBLIC_URL + `/images/Vector 11 (1).svg` } alt="settingImg" className="ps-2 arrow" /> 
            
            }
        </p> */}
    </div>
    </>

  )
}

export default RecievedCryptoDetails