import React, { useState,useEffect } from 'react'
import UpperNav from './UpperNav'
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../services/LocalStorageService';
import { CurrencyDropdownItems } from '../../constants/AllCurencies';
import { currencyMapping } from '../../constants/currencyMapping';
export default function FiatDashboard() {
    const [fiatTotal, setFiatTotal] = useState(0);
    const [userBaseCurrencySign, setuserBaseCurrencySign] = useState('£');
    const [userBaseCurrencyName, setuserBaseCurrencyName] = useState('EUR');

    const [cardData, setCardData] = useState([]);
    const navigate = useNavigate(); // Declare this at the top

    const { access_token } = getToken(); // Make sure this doesn't change unexpectedly

    useEffect(() => {
        cardData?.map(item => { 
            (
                // console.log(item)
                setFiatTotal(fiatTotal + Number(item.balance_in_base_currency))
            )
        })
    
    }, [cardData])
    
    useEffect(() => {
       
        async function fetchFiatTotal() {
            try {
                const response = await fetch('https://evistialab.com/api/v1/fiat-balance/', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${access_token}`,
                    },
                });

                if (response.status === 401) {
                    navigate('/login');
                    return;
                }

                const data = await response.json();
                if (data) {
                    let mergedItems = CurrencyDropdownItems.map(item => {
                        const matchingBalance = data.find(balance => balance.currency_code === item.currency);
                        return matchingBalance ? {
                            h2Value: `${item.currency} Balance`,
                            balance: matchingBalance.balance,
                            balance_in_base_currency: matchingBalance.base_currency_balance,
                            title: `${item.currency} Balance`,
                            symbol: matchingBalance.currency_name,
                            imageSrc: item.imgSrc,
                        } : null;
                       
                    }).filter(item => item !== null);

                    setCardData(mergedItems);
                }
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }



            try {
                const response = await fetch('https://evistialab.com/api/v1/get-user-profile-data/', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${access_token}`,
                    },
                });

                if (response.status === 401) {
                    navigate('/login');
                    return;
                }

                const data = await response.json();
                if (data) {
                    
                    const uppercaseKey = data.base_currency.toUpperCase();
                    // Access the object using the uppercase key
                    const currencyDetails = currencyMapping[uppercaseKey];
                    setuserBaseCurrencySign(currencyDetails.sign)
                    setuserBaseCurrencyName(currencyDetails.shortName)

                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
           

        }
        
        fetchFiatTotal();
    }, [access_token, navigate]); // Include dependencies that might affect the useEffect

    const handleDetailsPage = (data) => {
        navigate('/wallet/fiat-details', {
            state: {
                title: data.title,
                symbol: data.symbol,
                img: data.imageSrc,
                h2Value: data.h2Value,
                balance: data.balance
            }
        });
    };
  return (
    <div className='sideBarMainContent '>
      <div className='WMainDiv '>
        <UpperNav  />
        <div className='walletInsideMainContainer'>
            <div className='FS1Carddiv'>
                <div className='row mt-4'>
                    <div className='col-12 '>
                        <h5 className='ps-1 mb-0'>
                        Total Fiat Balance
                        </h5>
                    </div>
                    <div className='col-6 d-flex justify-content-start align-items-center gap-3'>
                        <h1 className='mb-0'>
                        {userBaseCurrencySign}{fiatTotal.toFixed(2)}
                        </h1>
                        <img loading='lazy' src={process.env.PUBLIC_URL + "/images/settingIcon.svg" } alt="settingImg" className="wdBtnIco" />
                    </div>
                    <div className='d-md-flex d-none WS1BtnDiv  col-6  justify-content-end align-items-center'>
                        <a className='WDS1Btn active' onClick={()=>{navigate('/wallet/fiat-transfer')}}>
                        <svg width="13" height="15" viewBox="0 0 13 15" fill="#00051A" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.2287 6.52837L6.51333 0.814367C6.46172 0.760232 6.39966 0.717138 6.3309 0.687693C6.26215 0.658249 6.18813 0.643066 6.11333 0.643066C6.03854 0.643066 5.96452 0.658249 5.89577 0.687693C5.82701 0.717138 5.76495 0.760232 5.71333 0.814367L0 6.52837L0.8 7.32837L5.54267 2.5857V14.357H6.686V2.58637L11.4287 7.32903L12.2287 6.52837Z" />
                        </svg>
                        Send
                        </a>

                        <a className='WDS1Btn' onClick={()=>{navigate('/wallet/fiat-swap')}}>
                        <svg width="16" height="16" viewBox="0 0 12 12" fill="" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 5.5H0C0 3.2945 1.7945 1.5 4 1.5H10V0L11.8535 1.8965C12.0485 2.092 12.0485 2.4085 11.8535 2.6035L10 4.5V3H4C2.6215 3 1.5 4.1215 1.5 5.5ZM8 9H2V7.5L0.1465 9.3965C-0.0485 9.592 -0.0485 9.9085 0.1465 10.1035L2 12V10.5H8C10.2055 10.5 12 8.7055 12 6.5H10.5C10.5 7.8785 9.3785 9 8 9Z" />
                            </svg>
                        Convert
                        </a>
                    </div>
                </div>
                

                <div className='row mt-md-4 mt-1 pt-4'>
                    <div className='col-lg-4 col-6 mb-4 '>
                        <b>
                        <h6 className='ps-1'>
                            Account Type
                        </h6>
                        </b>
                        <div className='FDetailsDiv'>
                        <p className='mb-0'>
                            Evistia Personal Account
                        </p>
                        </div>
                    </div>

                    <div className='col-lg-4 col-6 mb-4 '>
                        <h6 className='ps-1'>
                            Account Holder Name
                        </h6>
                        <div className='FDetailsDiv'>
                        <p className='mb-0'>
                            Johathan Something
                        </p>
                        </div>
                    </div>

                    <div className='col-lg-4 col-6 mb-4 '>
                        <h6 className='ps-1'>
                         Account Number
                        </h6>
                        <div className='FDetailsDiv'>
                            <p className='mb-0'>
                            657578797090875
                            </p>
                        </div>
                    </div>

                    <div className='col-lg-4 col-6 mb-4 '>
                        <b>
                        <h6 className='ps-1'>
                        Account Status
                        </h6>
                        </b>
                        <div className='FDetailsDiv'>
                        <p className='mb-0'>
                        Active
                        </p>
                        </div>
                    </div>

                    <div className='col-lg-4 col-6 mb-4 '>
                        <h6 className='ps-1'>
                        Debit Card
                        </h6>
                        <div className='FDetailsDiv'>
                        <p className='mb-0'>
                        Standard Blue Card
                        </p>
                        </div>
                    </div>

                    <div className='col-lg-4 col-6 mb-4 '>
                        <h6 className='ps-1'>
                        IBAN
                        </h6>
                        <div className='FDetailsDiv'>
                            <p className='mb-0'>
                            EV12UK657578797090875
                            </p>
                        </div>
                    </div>
                    <div className='WS1BtnDiv col-12 d-md-none d-flex mt-3 justify-content-center align-items-center'>
                        <a className='WDS1Btn active ms-4' onClick={()=>{navigate('/wallet/fiat-transfer')}}>
                        <svg width="13" height="15" viewBox="0 0 13 15" fill="#00051A" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.2287 6.52837L6.51333 0.814367C6.46172 0.760232 6.39966 0.717138 6.3309 0.687693C6.26215 0.658249 6.18813 0.643066 6.11333 0.643066C6.03854 0.643066 5.96452 0.658249 5.89577 0.687693C5.82701 0.717138 5.76495 0.760232 5.71333 0.814367L0 6.52837L0.8 7.32837L5.54267 2.5857V14.357H6.686V2.58637L11.4287 7.32903L12.2287 6.52837Z" />
                        </svg>
                        Send
                        </a>

                        {/* <a className='WDS1Btn' onClick={()=>{navigate('/wallet/fiat-transfer')}}>
                        <svg width="13" height="15" viewBox="0 0 13 15" fill="#00051A" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.6573 7.67173L6.91467 12.4144L6.91467 0.643066L5.77134 0.643066L5.77134 12.4144L1.02867 7.67173L0.228668 8.47173L5.94267 14.1857C5.9947 14.2393 6.05684 14.2821 6.1255 14.3115C6.19416 14.3409 6.26798 14.3564 6.34267 14.3571C6.41737 14.3564 6.49119 14.3409 6.55986 14.3115C6.62852 14.2821 6.69066 14.2394 6.74267 14.1857L12.4573 8.47173L11.6573 7.67173Z" />
                        </svg>
                        Request
                        </a> */}
                    </div>
                </div>
                <h5 className='mt-4'>Account Statement</h5>
                <div className='statementDiv01'>
                    <div>
                        <h6 className='ps-1'> Start Date </h6>
                        <input type='date' className='dateInput01'/>
                    </div>
                    <div>
                        <h6 className='ps-1'> End Date </h6>
                        <input type='date' className='dateInput01'/>
                    </div>
                   <div>
                    
                    <a> 
                            <button className='downloadBtn01'>Download E-Statement</button>
                        </a>
                   </div>
                </div>
            </div>

            <div className='row mt-5'>
            {cardData.map((data, index) => (
                <div className='col-xl-3 col-md-6 col-12 mb-4' key={index}>
                    <div className='WFDS2Card d-flex flex-sm-column flex-row justify-content-lg-start justify-content-between gap-lg-2 gap-1'>
                    <div className='upperDiv '  onClick={() => handleDetailsPage(data)}>
                        <img loading='lazy' src={data.imageSrc} alt="Section One Right Side Images" className="img-fluid cardLogo"/>
                        <h2 className='ps-1 pt-2'>
                        {data.title}
                        </h2>
                    </div>
                    <div className='bottomDiv pt-3 '>
                        <h1 className='ps-1 pt-2 mb-1'>
                        {data.balance}
                        </h1>
                        <p className='ps-1 mb-0'>
                        {data.symbol}
                        </p>
                    </div>
                    <div className='hoverBtnRow  ' >
                        <div className='col-md-4 col-6 px-2 mb-2 ' >
                            <a className='fDHoverBtnDiv active' onClick={()=>{navigate('/wallet/fiat-transfer')}}>
                                Send
                            </a>
                        </div>
                        {/* <div className='col-md-4 col-6 px-2 mb-2' >
                            <a className='fDHoverBtnDiv ' onClick={()=>{navigate('/wallet/fiat-transfer')}}>
                                Receive
                            </a>
                        </div> */}
                        <div className='col-md-4 col-6 px-2 mb-2 ' >
                            <a className='fDHoverBtnDiv ' onClick={()=>{navigate('/wallet/swap')}}>
                                Convert
                            </a>
                        </div>
                    </div>
                    </div>
                </div>
            ))}


                <div className='col-xl-3 col-md-6 col-12'>
                    <div className='addCurrencyCardWraper d-flex flex-column gap-2'>
                        <div className='addCurrencyCard'>
                            <img loading='lazy' src="../images/addCurrencyIcon.svg" alt="Section One Right Side Images" className="img-fluid cardLogo mt-2"/>
                            <p className='text-center mt-2'>
                            Add Currency
                            </p>
                        </div>
                         
                    </div>
                </div>

                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/BottomHr.svg" } alt="settingImg" className="wdBtnIcon mt-5" />
            </div> 


            <div className='row mt-5  '>
              <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                  <h1>
                  Recent Activity
                  </h1>
                  <a style={{color:"#FFFFFF"}}>
                  All Transactions
                  </a>
              </div>
            </div>
            <div className='row my-5  gap-4'>
              <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                  <div className='d-flex gap-3'>
                    <img loading='lazy' src="../images/downArrowW.svg" alt="Section One Right Side Images" className="img-fluid "/>
                    <div className='d-flex flex-column justify-content-center '>
                      <p className='mb-1'>
                      John Doe
                      </p>
                      <span>
                      July 13 9:20PM
                      </span>
                    </div>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-end'>
                      <p className='mb-1'>
                      400 {userBaseCurrencyName}
                      </p>
                      <span>
                      + 400 {userBaseCurrencyName}
                      </span>
                  </div>
              </div>

              <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                  <div className='d-flex gap-3'>
                    <img loading='lazy' src="../images/downArrowW.svg" alt="Section One Right Side Images" className="img-fluid "/>
                    <div className='d-flex flex-column justify-content-center '>
                      <p className='mb-1'>
                      John Doe
                      </p>
                      <span>
                      July 13 9:20PM
                      </span>
                    </div>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-end'>
                      <p className='mb-1'>
                      400 {userBaseCurrencyName}
                      </p>
                      <span>
                      + 400 {userBaseCurrencyName}
                      </span>
                  </div>
              </div>

              <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                  <div className='d-flex gap-3'>
                    <img loading='lazy' src="../images/upperArrowW.svg" alt="Section One Right Side Images" className="img-fluid "/>
                    <div className='d-flex flex-column justify-content-center '>
                      <p className='mb-1'>
                      Luke Wilson
                      </p>
                      <span>
                      July 10 10:56AM
                      </span>
                    </div>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-end'>
                      <p className='mb-1'>
                      700 {userBaseCurrencyName}
                      </p>
                      <span>
                      -700 {userBaseCurrencyName}
                      </span>
                  </div>
              </div>

              <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                  <div className='d-flex gap-3'>
                    <img loading='lazy' src="../images/upperArrowW.svg" alt="Section One Right Side Images" className="img-fluid "/>
                    <div className='d-flex flex-column justify-content-center '>
                      <p className='mb-1'>
                      Luke Wilson
                      </p>
                      <span>
                      July 10 10:56AM
                      </span>
                    </div>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-end'>
                      <p className='mb-1'>
                      700 EUR
                      </p>
                      <span>
                      -700 {userBaseCurrencyName}
                      </span>
                  </div>
              </div>

              <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                  <div className='d-flex gap-3'>
                    <img loading='lazy' src="../images/inbox.svg" alt="Section One Right Side Images" className="img-fluid "/>
                    <div className='d-flex flex-column justify-content-center '>
                      <p className='mb-1'>
                      NETFLIX Premium Subscription
                      </p>
                      <span>
                      Jul 3 12:15PM
                      </span>
                    </div>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-end '>
                      <p className='mb-1'>
                      10 {userBaseCurrencyName}
                      </p>
                      <span>
                      -10.00 {userBaseCurrencyName}
                      </span>
                  </div>
              </div>

              <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                  <div className='d-flex gap-3'>
                    <img loading='lazy' src="../images/inbox.svg" alt="Section One Right Side Images" className="img-fluid "/>
                    <div className='d-flex flex-column justify-content-center '>
                      <p className='mb-1'>
                      NETFLIX Premium Subscription
                      </p>
                      <span>
                      Jul 3 12:15PM
                      </span>
                    </div>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-end '>
                      <p className='mb-1'>
                      10 {userBaseCurrencyName}
                      </p>
                      <span>
                      -10.00 {userBaseCurrencyName}
                      </span>
                  </div>
              </div>
            </div>
      </div>
    </div>
    </div>
  )
}
