import React, { useState } from 'react'
import UpperNav from './UpperNav'
import Switch from '@mui/material/Switch';
import { useNavigate } from 'react-router-dom';
export default function CardDetails() {
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const [active,setAtive] = useState('settings')
    const navigate = useNavigate()
  return (
    <div className='sideBarMainContent '>
    <div className='WMainDiv '>
      <UpperNav />

      <div className='row  justify-content-center'>
        <div className='col-xxl-11 col-12 '>
            <div className='cardDetailsCard mt-5' >
                <div className='cardUpperRow mb-3'>
                    <div className='col-md-8 col-7'>
                    <h1>
                    Standard Blue Card
                    </h1>
                    </div>
                    <div className='col-md-4 col-5 d-flex gap-md-5 gap-3'>
                    <p className={`${active === 'settings' ? 'active' : ''}`} onClick={(e)=> {setAtive('settings')}}>
                        Settings
                        </p>
                        <p className={`${active === 'details' ? 'active' : ''}`} onClick={(e)=> {setAtive('details')}}>
                            Details
                        </p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xl-4 col-12 cardRightSide'>
                        <img loading='lazy' src="../images/bluecardnewfrontplain 2.png" alt="Section One Right Side Images" className="cardImg"/>
                        <div className='WHPS3bottomDiv row mt-5 d-xl-flex d-none'>
                        { 
                        active === 'settings' ?  
                          <a onClick={()=>{navigate('/wallet/change-pin')}}> Change Pin </a>  
                        : 
                        <a onClick={()=>{navigate('/wallet/apply-for-upgrade')}}> Apply for upgrade </a>  
                        }                
                        </div>
                    </div>
                    <div className='col-xl-1 col-12'> </div>


                  { 
                  active === 'settings' ? 
                    <> 
                    <div className='col-xl-7 col-12 d-flex flex-column justify-content-start align-items-end cardLeftSide'>
                            <div className='row mt-xxl-5 mt-lg-0 mt-5 pt-3'>
                                <div className='col-md-6 col-12 d-flex flex-column mb-5'>
                                    <label className='ps-2'> Allow International Use </label>
                                    <Switch  defaultChecked  />
                                </div> 
                                <div className='col-md-6 col-12 d-flex flex-column mb-5'>
                                    <label className='ps-2'> Allow ATM Usage </label>
                                    <Switch defaultChecked />
                                </div> 
                                <div className='col-md-6 col-12 d-flex flex-column mb-xl-0 mb-5'>
                                    <label className='ps-2'> Allow International Use </label>
                                    <Switch  {...label}   />
                                </div> 
                                <div className='col-md-6 col-12 d-flex flex-column'>
                                    <label className='ps-2'> Allow ATM Usage </label>
                                    <Switch  />
                                </div> 
                            </div>
                    </div>
                    <div className='WHPS3bottomDiv row mt-5 d-xl-none d-flex'>
                        { 
                        active === 'settings' ?  
                          <a onClick={()=>{navigate('/wallet/change-pin')}}> Change Pin </a>  
                        : 
                        <a onClick={()=>{navigate('/wallet/apply-for-upgrade')}}> Apply for upgrade </a>  
                        }                
                    </div>
                    </> 
                    : 
                    <>
                    <div className='col-xl-7 col-12 d-flex flex-column justify-content-start align-items-start cardLeftSide'>
                        <div className='row mt-4 setRowWidth'>
                            <div className='col-12'>
                                <h4>
                                Order Details
                                </h4>
                            </div>
                            <div className='col-lg-6 col-12  mb-3 mt-lg-0 mt-3'>
                                <p>
                                    <b>
                                        Ordered on:
                                    </b>
                                    Sep 23, 2022
                                </p>
                            </div> 
                            <div className='col-lg-6 col-12  mb-3'>
                                <p>  
                                    <b>
                                    Active since:
                                    </b>
                                    Sep 25, 2022
                                </p>
                            </div> 
                        </div>
                        <div className='row  setRowWidth'>
                            <div className='col-12 d-flex align-items-center gap-4'>
                                <h4 className='mb-0'>
                                Card Details 
                                </h4>
                                <img loading='lazy' width="25px" height="15.50px" src={process.env.PUBLIC_URL + "/images/Group 645.svg" } alt="settingImg" className=" " />
                            </div>  
                        </div>
                        <div className='row mt-4 setRowWidth'>
                            <div className='col-lg-6 col-12  mb-3'>
                                <b>
                                    <h6 className='ps-1'>
                                    Cardholder Name
                                    </h6>
                                </b>
                                <div className='FDetailsDiv'>
                                   <span className='info'>
                                    John
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12  mb-3'>
                                <b>
                                    <h6 className='ps-1'>
                                    Card Number
                                    </h6>
                                </b>
                                <div className='FDetailsDiv'>
                                   <span className='info'>
                                    1234567890081
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12  mb-3'>
                                <b>
                                    <h6 className='ps-1'>
                                    CVC
                                    </h6>
                                </b>
                                <div className='FDetailsDiv'>
                                   <span className='info'>
                                    134
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12  mb-3'>
                                <b>
                                    <h6 className='ps-1'>
                                    Expiry Date
                                    </h6>
                                </b>
                                <div className='FDetailsDiv'>
                                   <span className='info'>
                                    Sep 25, 2027
                                    </span>
                                </div>
                            </div>
                        </div>
                            
                        
                    </div>

                    <div className='WHPS3bottomDiv row mt-5 d-xl-none d-flex'>
                        { 
                        active === 'settings' ?  
                          <a onClick={()=>{navigate('/wallet/change-pin')}}> Change Pin </a>  
                        : 
                        <a onClick={()=>{navigate('/wallet/apply-for-upgrade')}}> Apply for upgrade </a>  
                        }                
                    </div>
                    </>
                    }
                </div>
            </div>
        </div>
      </div>


    </div>
  </div>
  )
}
