import React from 'react'
import UpperNav from './UpperNav'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
export default function PaymentConfirmation(props) {
  const location = useLocation();
  const amount = location.state?.amount;
  const sign = location.state?.sign;
  const pageName = location.state?.pageName;
  const accName = location.state?.accountHolderName;
  const address = location.state?.address;


  
  return (
    <div className='sideBarMainContent '>
    <div className='WMainDiv '>
      <UpperNav title={pageName === "Crypto" ? `${pageName} Transfer` : `Fiat Transfer`} symbol={""}/>

      
      <div className='row mt-5 justify-content-center '>
            <div className='col-12 mt-5'>
                <div className='paymentConfirmationCard d-flex align-items-center justify-content-around gap-3'>
                <img loading='lazy' src={process.env.PUBLIC_URL + "/images/finished-q8r56SOUy8.svg" } alt="User Image" className="" />

                    <div className='d-flex flex-column align-items-center'>
                    <p className='title mt-3'>
                    Payment Successful!
                    </p>
                        <p className='amount text-center mb-0'>
                        {amount} {sign} sent to <br></br> {address} <span> {accName} </span>
                        </p>
                    </div>
                </div>
                <p className='tid text-center my-5 pt-3'>
                Transaction ID : 4567865434
                </p>
                <p className='tid text-center pt-3'>
                We'll send you an email of confirmation. It may take a few
                 minutes to see the payment in your Transactions page.
                </p>
                <p className='text-center pageLink mt-5'>
                    <Link to="/wallet/payments">
                    Make Another Payment
                    </Link>
                </p>
            </div>

            
      </div>
    </div>
  </div>
  )
}
