import React from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import AnimatedPage from "../AnimatedPage";

export default function RegisrterBuisnessAccStepOne({
  formData,
  handleInputChange,
  error,
  errorId,
}) {
  const { t } = useTranslation();
  //consolelog(formData,'Form Data',error ,'error',errorId,'errorId')

  // Custom Select Options |
  const optionList = [
    t("BACC.S1.lb.DD.1"), 
    t("BACC.S1.lb.DD.2"),
    t("BACC.S1.lb.DD.3"),
    t("BACC.S1.lb.DD.4"),
    t("BACC.S1.lb.DD.5"),
    t("BACC.S1.lb.DD.6"),
    t("BACC.S1.lb.DD.7"),
    t("BACC.S1.lb.DD.8"),
];
  // Custom Select Options |
  const optionList01 = [
    t("BACC.S1.lb.GE.1"), 
    t("BACC.S1.lb.GE.2"),
    t("BACC.S1.lb.GE.3"),
];
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(t("BACC.S1.ph.3"));

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    formData.nationolity = option;
    setSelectedOption(option);
    setIsOpen(false);
  };

  const [isOpen01, setIsOpen01] = useState(false);
  const [selectedOption01, setSelectedOption01] = useState(t("BACC.S1.ph.2"));

  const toggleDropdown01 = () => {
    setIsOpen01(!isOpen01);
  };

  const selectOption01 = (option) => {
    formData.gender = option;
    setSelectedOption01(option);
    setIsOpen01(false);
  };
  const [eyeIcon, setEyeIcon] = useState(true)
  const eyeIconHandler = () => {
      setEyeIcon(!eyeIcon)
  }
  const [eyeIcons, setEyeIcons] = useState(true)
  const eyeIconsHandler = () => {
      setEyeIcons(!eyeIcons)
  }
  return (
    <AnimatedPage>
      <div>
        <div className="row ">
          <div className="d-flex justify-content-center">
            <img
              loading="lazy"
              alt="Vr Line"
              src="../../../images/b1.svg"
              className="imgResponsive upperImgRegister px-5 hrSmHide"
            />
          </div>

          <h2 className="SignupHeadings">{t("BACC.S1.H")}</h2>
          <img
            loading="lazy"
            alt="Vr Line"
            src="../../../images/Vector 4.svg"
            className="imgResponsive"
          />
        </div>
        <div className="row pt-5">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-12">
            <div className="mb-5 px-3">
              <label
                htmlFor="email"
                className="form-label contactusLabels pb-2"
              >
                {t("BACC.S1.lb.1")}
              </label>
              <input
                type="text"
                className="form-control formInputs"
                id="firstName"
                placeholder={t("BACC.S1.ph.1")}
                value={formData.firstName}
                onChange={(e) => handleInputChange("firstName", e.target)}
                required
              />
              {errorId === "firstName" ? (
                <span className="inputFiledError pt-1">{error}</span>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="col-xxl-6 col-xl-6 col-lg-6 col-12">
            <div className="mb-5 px-3">
              <label
                htmlFor="email"
                className="form-label contactusLabels pb-2"
              >
                {t("BACC.S1.lb.2")}
              </label>
              <input
                type="date"
                className="form-control formInputs"
                id="dob"
                // placeholder='Cena'
                value={formData.dob}
                onChange={(e) => handleInputChange("dob", e.target)}
                required
              />
            </div>
          </div>

          <div className="col-xxl-6 col-xl-6 col-lg-6 col-12">
            <div className="mb-5 px-3">
              <label
                htmlFor="email"
                className="form-label contactusLabels pb-2"
              >
                {" "}
                {t("BACC.S1.lb.3")}
              </label>
             {/* <input
                type="text"
                className="form-control formInputs"
                id="gender"
                placeholder={t("BACC.S1.ph.2")}
                value={formData.gender}
                onChange={(e) => handleInputChange("gender", e.target)}
                required
              />*/}
               <div className="customSelectOptions " onClick={toggleDropdown01}>
                <p style={{ margin: "0" }} className="formInputsM">
                  {selectedOption01}{" "}
                  <i className={`fa fa-caret-${isOpen ? "up" : "down"} `}></i>
                </p>
                <img
                  loading="lazy"
                  alt="Vr Line"
                  src="../../../images/Vectorfill.svg"
                  className="imgResponsive "
                />
              </div>
              <div className="container d-flex justify-content-end pe-0">
                {isOpen01 && (
                  <div className="options contactusLabels">
                    {optionList01.map((option, index) => (
                      <div
                        className="option"
                        key={index}
                        onClick={() => selectOption01(option)}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {errorId === "gender" ? (
                <span className="inputFiledError pt-1">{error}</span>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="col-xxl-6 col-xl-6 col-lg-6 col-12">
            <div className="mb-5 px-3">
              <label
                htmlFor="email"
                className="form-label contactusLabels pb-2"
              >
                {" "}
                {t("BACC.S1.lb.4")}{" "}
              </label>
              <div className="customSelectOptions " onClick={toggleDropdown}>
                <p style={{ margin: "0" }} className="formInputsM">
                  {selectedOption}{" "}
                  <i className={`fa fa-caret-${isOpen ? "up" : "down"} `}></i>
                </p>
                <img
                  loading="lazy"
                  alt="Vr Line"
                  src="../../../images/Vectorfill.svg"
                  className="imgResponsive "
                />
              </div>
              <div className="container d-flex justify-content-end pe-0">
                {isOpen && (
                  <div className="options contactusLabels">
                    {optionList.map((option, index) => (
                      <div
                        className="option"
                        key={index}
                        onClick={() => selectOption(option)}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-xxl-6 col-xl-6 col-lg-6 col-12">
            <div className="mb-5 px-3">
              <label
                htmlFor="email"
                className="form-label contactusLabels pb-2"
              >
                {t("BACC.S1.lb.5")}
              </label>
              <input
                type="text"
                className="form-control formInputs"
                id="email"
                placeholder={t("BACC.S1.ph.4")}
                value={formData.email}
                onChange={(e) => handleInputChange("email", e.target)}
                required
              />
              {errorId === "email" ? (
                <span className="inputFiledError pt-1">{error}</span>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="col-xxl-6 col-xl-6 col-lg-6 col-12">
            <div className="mb-5 px-3">
              <label
                htmlFor="email"
                className="form-label contactusLabels pb-2"
              >
                {t("BACC.S1.lb.6")}
              </label>
              <input
                type="text"
                className="form-control formInputs"
                id="phoneNo"
                placeholder="12345678"
                value={formData.phoneNo}
                onChange={(e) => handleInputChange("phoneNo", e.target)}
                required
              />
              {errorId === "phoneNo" ? (
                <span className="inputFiledError pt-1">{error}</span>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="col-xxl-6 col-xl-6 col-lg-6 col-12">
            <div className="mb-5 px-3" style={{position:"relative"}}>
              <label
                htmlFor="email"
                className="form-label contactusLabels pb-2"
              >
                {" "}
                {t("BACC.S1.lb.7")}
              </label>
              <input
                 type={`${eyeIcon === true ? "password" : "text"}`}
                className="form-control formInputs"
                id="password"
                placeholder="***********"
                value={formData.password}
                onChange={(e) => handleInputChange("password", e.target)}
                required
              />
               {
                  eyeIcon === true ? 
                  <img loading='lazy' src={process.env.PUBLIC_URL + "/images/eye.svg" } alt="new IMg" className="eyeIcons" onClick={eyeIconHandler}/>
                  : 
                  <img loading='lazy' src={process.env.PUBLIC_URL + "/images/eye-crossed.svg" } alt="new IMg" className="eyeIcons" onClick={eyeIconHandler} />
                }
            </div>
              {errorId === "password" ? (
                <span className="inputFiledError pt-1 px-3" style={{wordBreak:"break-all",position:"relative",bottom:"20%"}}>{error}</span>
              ) : (
                ""
              )}
          </div>

          <div className="col-xxl-6 col-xl-6 col-lg-6 col-12">
            <div className="mb-5 px-3" style={{position:"relative"}}>
              <label
                htmlFor="email"
                className="form-label contactusLabels pb-2"
              >
                {" "}
                {t("BACC.S1.lb.8")}{" "}
              </label>
              <input
                type={`${eyeIcons === true ? "password" : "text"}`}
                className="form-control formInputs"
                id="confirmPassword"
                placeholder="***********"
                value={formData.confirmPassword}
                onChange={(e) => handleInputChange("confirmPassword", e.target)}
                required
              />
              {
                  eyeIcons === true ? 
                  <img loading='lazy' src={process.env.PUBLIC_URL + "/images/eye.svg" } alt="new IMg" className="eyeIcons" onClick={eyeIconsHandler}/>
                  : 
                  <img loading='lazy' src={process.env.PUBLIC_URL + "/images/eye-crossed.svg" } alt="new IMg" className="eyeIcons" onClick={eyeIconsHandler} />
                }
            </div>
              {errorId === "confirmPassword" ? (
                <span className="inputFiledError pt-1 px-3" style={{wordBreak:"break-all",position:"relative",bottom:"20%"}} > {error}</span>
              ) : (
                ""
              )}
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
}
