import React from 'react'
import UpperNav from './UpperNav'
import { useLocation, useNavigate } from 'react-router-dom';
export default function CryptoDetails() {
  const location = useLocation();
  const { h2Value, h4Value, title, symbol, img } = location.state;
  const name = title
  const sign = symbol
  const availbleAmount = h2Value
  const navigate = useNavigate()
  return (
    <div className='sideBarMainContent '>
      <div className='WMainDiv '>
        <UpperNav title={title} symbol={`(${symbol})`}/>
        <div className='row mt-5 WCDUpperRow'>
          <div className='col-xxl-6 col-md-4  col-12  d-flex flex-column gap-md-3 gap-0'>
              <div className='upperDiv d-flex gap-3 align-items-center'>
                <img loading='lazy' src={img} alt="Section One Right Side Images" className="img-fluid cardLogo"/>
                <div className='innerTextDiv mt-2'>
                    <p>
                    {title} 
                    </p>
                    <span >
                   {symbol}
                    </span>
                </div>
              </div>
              <div className='centerDiv mt-md-0 mt-4 mb-md-0 mb-2'>
                <h2>
                {h4Value}
                </h2>
              </div>
              <div className='bottomDiv d-flex gap-2 align-items-center'>
                <a className='WHPS2downBtn'>
                  +8.6%
                </a>
                <div className='WHPS1downBtn1'>
                  <img loading='lazy' src="../images/arrowupperSide.svg" alt="Section One Right Side Images" className="upperArrowImg img-fluid"/>
                </div>
              </div>
          </div>
          <div className='WS1BtnDiv col-xxl-6 col-md-8  col-12 d-flex justify-content-md-end justify-content-between align-items-center pt-4 mt-md-0 mt-4'>
                <a className='WDS1Btn active' onClick={()=>{navigate('/wallet/send-crypto/via/network', { state: { name , sign ,img ,availbleAmount} })}}>
                <svg width="9" height="13" viewBox="0 0 13 15" fill="#00051A" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.2287 6.52837L6.51333 0.814367C6.46172 0.760232 6.39966 0.717138 6.3309 0.687693C6.26215 0.658249 6.18813 0.643066 6.11333 0.643066C6.03854 0.643066 5.96452 0.658249 5.89577 0.687693C5.82701 0.717138 5.76495 0.760232 5.71333 0.814367L0 6.52837L0.8 7.32837L5.54267 2.5857V14.357H6.686V2.58637L11.4287 7.32903L12.2287 6.52837Z" />
                </svg>
                  Send
                </a>

                <a className='WDS1Btn ' onClick={()=>{navigate('/wallet/recieved-crypto', { state: { name , sign ,img , h2Value } })}}>
                <svg width="9" height="13" viewBox="0 0 13 15" fill="#00051A" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.6573 7.67173L6.91467 12.4144L6.91467 0.643066L5.77134 0.643066L5.77134 12.4144L1.02867 7.67173L0.228668 8.47173L5.94267 14.1857C5.9947 14.2393 6.05684 14.2821 6.1255 14.3115C6.19416 14.3409 6.26798 14.3564 6.34267 14.3571C6.41737 14.3564 6.49119 14.3409 6.55986 14.3115C6.62852 14.2821 6.69066 14.2394 6.74267 14.1857L12.4573 8.47173L11.6573 7.67173Z" />
                </svg>
                Request
                </a>

                <a className='WDS1Btn' onClick={()=>{navigate('/wallet/swap')}}>
                <svg width="11" height="15" viewBox="0 0 22 13" fill="#00051A" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.14267 11.8144L2.40001 7.07174L14.1713 7.07174L14.1713 5.92841L2.40001 5.92841L7.14267 1.18574L6.34267 0.385742L0.628673 6.09974C0.575078 6.15177 0.532338 6.21391 0.502928 6.28257C0.473518 6.35124 0.458023 6.42505 0.45734 6.49974C0.457986 6.57444 0.473465 6.64826 0.502877 6.71693C0.532289 6.7856 0.575048 6.84774 0.628673 6.89974L6.34267 12.6144L7.14267 11.8144Z" />
                  <path d="M14.486 1.18584L19.2287 5.9285L7.45735 5.9285L7.45735 7.07184L19.2287 7.07184L14.486 11.8145L15.286 12.6145L21 6.9005C21.0536 6.84848 21.0964 6.78633 21.1258 6.71767C21.1552 6.64901 21.1707 6.5752 21.1714 6.5005C21.1707 6.42581 21.1552 6.35198 21.1258 6.28331C21.0964 6.21465 21.0536 6.15251 21 6.1005L15.286 0.385836L14.486 1.18584Z" />
                </svg>
                Swap
                </a>
          </div>
          <img loading='lazy' src={process.env.PUBLIC_URL + "/images/BottomHr.svg" } alt="settingImg" className="wdBtnIcon mt-4" />
          <div className='col-md-6 col-3 d-flex flex-column gap-4 justify-content-end'>
              <h1 className='SummaryTxt'>
                Summary
              </h1>
          </div>
          <div className='WS1BtnDiv col-md-6 col-9 d-flex justify-content-end align-items-center gap-md-3 gap-1 pt-4'>
                <a className='WDS2Btn'>
                  24H
                </a>

                <a className='WDS2Btn'>
                1W
                </a>

                <a className='WDS2Btn active'>
                1M
                </a>

                <a className='WDS2Btn'>
                3M
                </a>

                <a className='WDS2Btn'>
                6M
                </a>
          </div>
          <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Untitled (5).svg" } alt="settingImg" className="wdBtnIcon mt-4" />
            <div className='row mt-5'>
              <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                  <h1>
                  Recent Activity
                  </h1>
                  <a className='customBtnWD d-flex align-items-center gap-2 mt-1' style={{color:"#FFFFFF"}}>
                  <img loading='lazy' src={process.env.PUBLIC_URL + "/images/Group 617.svg" } alt="settingImg" className="wdBtnIco" />
                  Filter
                  </a>
              </div>
            </div>
            <div className='row my-5  gap-4'>
              <div className='WHPS3RecentActivityDiv historyDiv col-12 d-flex justify-content-between'>
                  <div className='d-flex gap-3'>
                    <img loading='lazy' src="../images/downArrowW.svg" alt="Section One Right Side Images" className="img-fluid "/>
                    <div className='d-flex flex-column justify-content-center '>
                      <p className='mb-1'>
                      John Doe
                      </p>
                      <span className='trDates'>
                      July 13 9:20PM
                      </span>
                    </div>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-end'>
                      <p className='mb-1'>
                      400 USD
                      </p>
                      <span>
                      + 400 USD
                      </span>
                  </div>
              </div>

              <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                  <div className='d-flex gap-3'>
                    <img loading='lazy' src="../images/upperArrowW.svg" alt="Section One Right Side Images" className="img-fluid "/>
                    <div className='d-flex flex-column justify-content-center '>
                      <p className='mb-1'>
                      Luke Wilson
                      </p>
                      <span className='trDates'>
                      July 10 10:56AM
                      </span>
                    </div>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-end'>
                      <p className='mb-1'>
                      700 EUR
                      </p>
                      <span>
                      -700 USD
                      </span>
                  </div>
              </div>

              <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                  <div className='d-flex gap-3'>
                    <img loading='lazy' src="../images/inbox.svg" alt="Section One Right Side Images" className="img-fluid "/>
                    <div className='d-flex flex-column justify-content-center '>
                      <p className='mb-1'>
                      NETFLIX Premium Subscription
                      </p>
                      <span className='trDates'>
                      Jul 3 12:15PM
                      </span>
                    </div>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-end '>
                      <p className='mb-1'>
                      10 USD
                      </p>
                      <span>
                      -10.00 USD
                      </span>
                  </div>
              </div>
              <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                  <div className='d-flex gap-3'>
                    <img loading='lazy' src="../images/downArrowW.svg" alt="Section One Right Side Images" className="img-fluid "/>
                    <div className='d-flex flex-column justify-content-center '>
                      <p className='mb-1'>
                      John Doe
                      </p>
                      <span className='trDates'>
                      July 13 9:20PM
                      </span>
                    </div>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-end'>
                      <p className='mb-1'>
                      400 USD
                      </p>
                      <span >
                      + 400 USD
                      </span>
                  </div>
              </div>

              <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                  <div className='d-flex gap-3'>
                    <img loading='lazy' src="../images/upperArrowW.svg" alt="Section One Right Side Images" className="img-fluid "/>
                    <div className='d-flex flex-column justify-content-center '>
                      <p className='mb-1'>
                      Luke Wilson
                      </p>
                      <span className='trDates'>
                      July 10 10:56AM
                      </span>
                    </div>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-end'>
                      <p className='mb-1'>
                      700 EUR
                      </p>
                      <span >
                      -700 USD
                      </span>
                  </div>
              </div>

              <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between'>
                  <div className='d-flex gap-3'>
                    <img loading='lazy' src="../images/inbox.svg" alt="Section One Right Side Images" className="img-fluid "/>
                    <div className='d-flex flex-column justify-content-center '>
                      <p className='mb-1'>
                      NETFLIX Premium Subscription
                      </p>
                      <span className='trDates'>
                      Jul 3 12:15PM
                      </span>
                    </div>
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-end '>
                      <p className='mb-1'>
                      10 USD
                      </p>
                      <span>
                      -10.00 USD
                      </span>
                  </div>
              </div>


            </div>
            
        </div>


      </div>
    </div>
  )
}
