import React from 'react'
import AnimatedPage from '../AnimatedPage'
import { useTranslation } from 'react-i18next'

export default function PrivacyPolicy() {
    const {t} = useTranslation()
    return (
        <AnimatedPage>
<div className='homepageContainerP2 py-3 mt-5' >
        <div className='container-fluid mt-5'>
            <div className='row justify-content-center px-0'>
                <h3 className='detailsPageH text-center '>
                     {t('PP.h.3')}
                </h3> 
                <b className="detailsPageTxtb text-center ps-4 pe-md-5 pe-0 mt-md-4 mt-0"> {t('PP.h.4')}</b>

            <div className='col-md-12 col-12 py-3 ps-0 pe-md-5 pe-1'>
                    <p className="detailsPageTxt  " >
                    
                    </p>


                   

                    <h3 className='detailsPageHBlack '>
                    {t('PP.hb.1')} 
                    </h3>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    {t('PP.p.1.1')} <br></br> 
                    {t('PP.p.1.2')} 
                    <br></br> 
                    {t('PP.p.1.3')} 
                    </p>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    <b className="detailsPageTxtb">{t('PP.hb.2')} :</b> {t('PP.p.2.1')}
                    {t('PP.p.2.2')} 
                    </p>

                    

                    <h3 className='detailsPageHBlack '>
                    {t('PP.hb.3')} 
                    </h3>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    {t('PP.p.3.1')} <br></br>
                    {t('PP.p.3.2')} 
                    </p>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    <b className="detailsPageTxtb">{t('PP.hb.4')} :</b> {t('PP.p.4')}
                    </p>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    <b className="detailsPageTxtb">{t('PP.hb.5')} :</b> {t('PP.p.5')}
                    </p>
                    <p className="detailsPageTxt  ps-4 pe-2" >
                    <b className="detailsPageTxtb">{t('PP.hb.6')} :</b> {t('PP.p.6')}
                    </p>
                    <p className="detailsPageTxt  ps-4 pe-2" >
                    <b className="detailsPageTxtb">{t('PP.hb.7')} :</b> {t('PP.p.7')}
                    </p>

                    

                    <h3 className='detailsPageHBlack '>
                    {t('PP.hb.8')} 
                    </h3>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    {t('PP.p.8')}
                    </p>

                    <h3 className='detailsPageHBlack '>
                    {t('PP.hb.9')} 
                    </h3>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    {t('PP.p.9')}
                    </p>
                    <p className="detailsPageTxt  ps-4 pe-2" >
                    <b className="detailsPageTxtb">{t('PP.hb.10')} :</b> {t('PP.p.10')}
                    </p>
                    <p className="detailsPageTxt  ps-4 pe-2" >
                    <b className="detailsPageTxtb">{t('PP.hb.11')} :</b> {t('PP.p.11')}
                    </p>

                    <h3 className='detailsPageHBlack '>
                    {t('PP.hb.12')} 
                    </h3>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    {t('PP.p.12')}
                    </p>

                    <h3 className='detailsPageHBlack '>
                    {t('PP.hb.13')} 
                    </h3>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    {t('PP.p.13.1')} <br></br>
                    {t('PP.p.13.2')} 
                    </p>



                    


                    <p className="detailsPageTxt  ps-4 pe-2" >
                    <b className="detailsPageTxtb">{t('PP.hb.14')} :</b> {t('PP.p.14')}
                    </p>

                    <p className="detailsPageTxt  ps-4 pe-2" >
                    <b className="detailsPageTxtb">{t('PP.hb.15')} :</b> {t('PP.p.15')}
                    </p>

                   


                </div>



        </div>

        </div>
        </div>
        </AnimatedPage>
      )
}
