export const currencyMapping = {
    GBP: { sign: '£', shortName: 'GBP', apiShortName: 'gbp', imgSrc: '/images/g.svg', country: "United Kingdom" },
    EUR: { sign: '€', shortName: 'EUR', apiShortName: 'eur', imgSrc: '/images/eur.svg', country: "European Union" },
    AED: { sign: 'د.إ', shortName: 'AED', apiShortName: 'aed', imgSrc: '/images/aed.svg', country: "United Arab Emirates" },
    JPY: { sign: '¥', shortName: 'JPY', apiShortName: 'jpy', imgSrc: '/images/yen.svg', country: "Japan" },
    THB: { sign: '฿', shortName: 'THB', apiShortName: 'thb', imgSrc: '/images/bht.svg', country: "Thailand" },
    // PKR: { sign: '₨', shortName: 'PKR', apiShortName: 'pkr', imgSrc: '/images/pkr.svg', country: "Pakistan" },
    RUB: { sign: '₽', shortName: 'RUB', apiShortName: 'rub', imgSrc: '/images/rub.svg', country: "Russia" },
    MDL: { sign: 'L', shortName: 'MDL', apiShortName: 'mdl', imgSrc: '/images/flag-for-moldova-svgrepo-com.svg', country: "Moldova" },
    // LAK: { sign: '₭', shortName: 'LAK', apiShortName: 'lak', imgSrc: '/images/Laos.svg', country: "Laos" },
    // XOF: { sign: 'CFA', shortName: 'XOF', apiShortName: 'xof', imgSrc: '/images/Togo.svg', country: "Togo" },
    // SLL: { sign: 'Le', shortName: 'SLL', apiShortName: 'sll', imgSrc: '/images/Sierra Leone.svg', country: "Sierra Leone" },
     CHF: { sign: 'CHF', shortName: 'CHF', apiShortName: 'chf', imgSrc: '/images/Switzerland.svg', country: "Switzerland" },
    // PGK: { sign: 'K', shortName: 'PGK', apiShortName: 'pgk', imgSrc: '/images/Papua New Guinea.svg', country: "Papua New Guinea" },
     ILS: { sign: '₪', shortName: 'ILS', apiShortName: 'ils', imgSrc: '/images/Israel.svg', country: "Israel" },
    // HNL: { sign: 'L', shortName: 'HNL', apiShortName: 'hnl', imgSrc: '/images/Honduras.svg', country: "Honduras" },
    // TJS: { sign: 'SM', shortName: 'TJS', apiShortName: 'tjs', imgSrc: '/images/Tajikistan.svg', country: "Tajikistan" },
     HUF: { sign: 'Ft', shortName: 'HUF', apiShortName: 'huf', imgSrc: '/images/Hungary.svg', country: "Hungary" },
    // AZN: { sign: '₼', shortName: 'AZN', apiShortName: 'azn', imgSrc: '/images/Azerbaijan.svg', country: "Azerbaijan" },
    SEK: { sign: 'kr', shortName: 'SEK', apiShortName: 'sek', imgSrc: '/images/Sweden.svg', country: "Sweden" },
    // DOP: { sign: 'RD$', shortName: 'DOP', apiShortName: 'dop', imgSrc: '/images/Dominican Republic.svg', country: "Dominican Republic" },
     CZK: { sign: 'Kč', shortName: 'CZK', apiShortName: 'czk', imgSrc: '/images/Czech republic.svg', country: "Czech Republic" },
    // BIF: { sign: 'FBu', shortName: 'BIF', apiShortName: 'bif', imgSrc: '/images/Burundi.svg', country: "Burundi" },
    // CUP: { sign: '₱', shortName: 'CUP', apiShortName: 'cup', imgSrc: '/images/Cuba.svg', country: "Cuba" },
    // BOB: { sign: 'Bs.', shortName: 'BOB', apiShortName: 'bob', imgSrc: '/images/Bolivia.svg', country: "Bolivia" },
    // TND: { sign: 'د.ت', shortName: 'TND', apiShortName: 'tnd', imgSrc: '/images/Tunisia.svg', country: "Tunisia" },
    // RWF: { sign: 'FRw', shortName: 'RWF', apiShortName: 'rwf', imgSrc: '/images/Rwanda.svg', country: "Rwanda" },
    // SSP: { sign: 'SS£', shortName: 'SSP', apiShortName: 'ssp', imgSrc: '/images/South Sudan.svg', country: "South Sudan" },
    // GNF: { sign: 'FG', shortName: 'GNF', apiShortName: 'gnf', imgSrc: '/images/Guinea.svg', country: "Guinea" },
    // ZWL: { sign: '$', shortName: 'ZWL', apiShortName: 'zwl', imgSrc: '/images/Zimbabwe.svg', country: "Zimbabwe" },
    // SOS: { sign: 'Sh.So.', shortName: 'SOS', apiShortName: 'sos', imgSrc: '/images/Somalia.svg', country: "Somalia" },
    // XAF: { sign: 'FCFA', shortName: 'XAF', apiShortName: 'xaf', imgSrc: '/images/Chad.svg', country: "Chad" },
    // KHR: { sign: '៛', shortName: 'KHR', apiShortName: 'khr', imgSrc: '/images/Cambodia.svg', country: "Cambodia" },
    // SYP: { sign: '£S', shortName: 'SYP', apiShortName: 'syp', imgSrc: '/images/Syria.svg', country: "Syria" },
    // RON: { sign: 'lei', shortName: 'RON', apiShortName: 'ron', imgSrc: '/images/Romania.svg', country: "Romania" },
     CLP: { sign: '$', shortName: 'CLP', apiShortName: 'clp', imgSrc: '/images/Chile.svg', country: "Chile" },
    // KZT: { sign: '₸', shortName: 'KZT', apiShortName: 'kzt', imgSrc: '/images/Kazakhstan.svg', country: "Kazakhstan" },
    // MWK: { sign: 'MK', shortName: 'MWK', apiShortName: 'mwk', imgSrc: '/images/Malawi.svg', country: "Malawi" },
    LKR: { sign: '₨', shortName: 'LKR', apiShortName: 'lkr', imgSrc: '/images/Sri Lanka.svg', country: "Sri Lanka" },
    // IRR: { sign: '﷼', shortName: 'IRR', apiShortName: 'irr', imgSrc: '/images/Iran.svg', country: "Iran" },
    TRY: { sign: '₺', shortName: 'TRY', apiShortName: 'try', imgSrc: '/images/Turkey.svg', country: "Turkey" },
    // CDF: { sign: 'FC', shortName: 'CDF', apiShortName: 'cdf', imgSrc: '/images/Democratic republic of congo.svg', country: "Democratic Republic of Congo" },
    VND: { sign: '₫', shortName: 'VND', apiShortName: 'vnd', imgSrc: '/images/Vietnam.svg', country: "Vietnam" },
    // EGP: { sign: '£', shortName: 'EGP', apiShortName: 'egp', imgSrc: '/images/Egypt.svg', country: "Egypt" },
    PHP: { sign: '₱', shortName: 'PHP', apiShortName: 'php', imgSrc: '/images/Philippines.svg', country: "Philippines" },
    // ETB: { sign: 'Br', shortName: 'ETB', apiShortName: 'etb', imgSrc: '/images/Ethiopia.svg', country: "Ethiopia" },
    MXN: { sign: '$', shortName: 'MXN', apiShortName: 'mxn', imgSrc: '/images/Mexico.svg', country: "Mexico" },
     BDT: { sign: '৳', shortName: 'BDT', apiShortName: 'bdt', imgSrc: '/images/Bangladesh.svg', country: "Bangladesh" },
    NGN: { sign: '₦', shortName: 'NGN', apiShortName: 'ngn', imgSrc: '/images/Nigeria.svg', country: "Nigeria" },
     BRL: { sign: 'R$', shortName: 'BRL', apiShortName: 'brl', imgSrc: '/images/Brazil.svg', country: "Brazil" },
     IDR: { sign: 'Rp', shortName: 'IDR', apiShortName: 'idr', imgSrc: '/images/Indonesia.svg', country: "Indonesia" },
     INR: { sign: '₹', shortName: 'INR', apiShortName: 'inr', imgSrc: '/images/India.svg', country: "India" },
     CNY: { sign: '¥', shortName: 'CNY', apiShortName: 'cny', imgSrc: '/images/China.svg', country: "China" },
    // AFN: { sign: '؋', shortName: 'AFN', apiShortName: 'afn', imgSrc: '/images/Afghanistan.svg', country: "Afghanistan" },
    // IQD: { sign: 'ع.د', shortName: 'IQD', apiShortName: 'iqd', imgSrc: '/images/Iraq.svg', country: "Iraq" },
    UAH: { sign: '₴', shortName: 'UAH', apiShortName: 'uah', imgSrc: '/images/Ukraine.svg', country: "Ukraine" },
    // SDG: { sign: '£', shortName: 'SDG', apiShortName: 'sdg', imgSrc: '/images/Sudan.svg', country: "Sudan" },
    // DZD: { sign: 'د.ج', shortName: 'DZD', apiShortName: 'dzd', imgSrc: '/images/Algeria.svg', country: "Algeria" },
     ARS: { sign: '$', shortName: 'ARS', apiShortName: 'ars', imgSrc: '/images/Argentina.svg', country: "Argentina" },
    // KES: { sign: 'Sh', shortName: 'KES', apiShortName: 'kes', imgSrc: '/images/Kenya.svg', country: "Kenya" },
    // COP: { sign: '$', shortName: 'COP', apiShortName: 'cop', imgSrc: '/images/Colombia.svg', country: "Colombia" },
    KRW: { sign: '₩', shortName: 'KRW', apiShortName: 'krw', imgSrc: '/images/South Korea.svg', country: "South Korea" },
     MMK: { sign: 'K', shortName: 'MMK', apiShortName: 'mmk', imgSrc: '/images/Myanmar.svg', country: "Myanmar" },
    ZAR: { sign: 'R', shortName: 'ZAR', apiShortName: 'zar', imgSrc: '/images/South Africa.svg', country: "South Africa" },
    // CAD: { sign: '$', shortName: 'CAD', apiShortName: 'cad', imgSrc: '/images/Canada.svg', country: "Canada" },
    PLN: { sign: 'zł', shortName: 'PLN', apiShortName: 'pln', imgSrc: '/images/Poland.svg', country: "Poland" },
    MYR: { sign: 'RM', shortName: 'MYR', apiShortName: 'myr', imgSrc: '/images/Malaysia.svg', country: "Malaysia" },
    // AOA: { sign: 'Kz', shortName: 'AOA', apiShortName: 'aoa', imgSrc: '/images/Angola.svg', country: "Angola" },
    // PEN: { sign: 'S/.', shortName: 'PEN', apiShortName: 'pen', imgSrc: '/images/Peru.svg', country: "Peru" },
    // UZS: { sign: 'UZS', shortName: 'UZS', apiShortName: 'uzs', imgSrc: '/images/Uzbekistan.svg', country: "Uzbekistan" },
    SAR: { sign: 'ر.س', shortName: 'SAR', apiShortName: 'sar', imgSrc: '/images/Saudi Arabia.svg', country: "Saudi Arabia" },
    // MAD: { sign: 'د.م.', shortName: 'MAD', apiShortName: 'mad', imgSrc: '/images/Morocco.svg', country: "Morocco" },
    // MGA: { sign: 'Ar', shortName: 'MGA', apiShortName: 'mga', imgSrc: '/images/Madagascar.svg', country: "Madagascar" },
    // VES: { sign: 'Bs.S', shortName: 'VES', apiShortName: 'ves', imgSrc: '/images/Venezuela.svg', country: "Venezuela" },
    // YER: { sign: '﷼', shortName: 'YER', apiShortName: 'yer', imgSrc: '/images/Yemen.svg', country: "Yemen" },
    // GHS: { sign: '₵', shortName: 'GHS', apiShortName: 'ghs', imgSrc: '/images/Ghana.svg', country: "Ghana" },
    // MZN: { sign: 'MT', shortName: 'MZN', apiShortName: 'mzn', imgSrc: '/images/Mozambique.svg', country: "Mozambique" },
};
