import React from 'react'
import { useTranslation } from 'react-i18next';
import AnimatedPage from '../AnimatedPage'
import ScrollToTop from '../scrollToTop';
export default function RegisrterBuisnessAccStepTwo({formData, handleInputChange,error,errorId}) {
    const {t} = useTranslation()

  return (
    <AnimatedPage>
        <ScrollToTop/>
    <div>
        {/* <div className='container-fluid'>
            <div className='mainContainerSignups'> */}
                    <div className='row '   >
                        <div className='d-flex justify-content-center'>
                        <img loading='lazy' alt="Vr Line" src="../../../images/b2.svg" className='imgResponsive upperImgRegister px-5 hrSmHide'/>
                        </div>

                        <h2 className='SignupHeadings'>
                            {t('BACC.S2.H')}
                        </h2>
                        <img loading='lazy' alt="Vr Line" src="../../../images/Vector 4.svg" className='imgResponsive'/>
                    </div>
                    <div className='row pt-5'   >
                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                        <div className="mb-5 px-3">
                        <label htmlFor="email" className="form-label contactusLabels pb-2"> {t('BACC.S2.lb.1')} </label>
                        <input
                            type="text"
                            className="form-control formInputs"
                            id="businessName"
                            placeholder='XYZ'
                            value={formData.businessName}
                            onChange={(e) => handleInputChange('businessName', e.target)}
                            required
                        />
                        {errorId === 'businessName' ? <span className='inputFiledError pt-1'>{error}</span> : ''}
                        </div>
                    </div>

 
                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                        <div className="mb-5 px-3">
                        <label htmlFor="email" className="form-label contactusLabels pb-2"> {t('BACC.S2.lb.2')} </label>
                        <input
                            type="text"
                            className="form-control formInputs"
                            id="businessType"
                            placeholder='XYZ'
                            value={formData.businessType}
                            onChange={(e) => handleInputChange('businessType', e.target)}
                            required
                        />
                        {errorId === 'businessType' ? <span className='inputFiledError pt-1'>{error}</span> : ''}
                        </div>
                    </div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                <div className="mb-5 px-3">
                <label htmlFor="email" className="form-label contactusLabels pb-2">{t('BACC.S2.lb.3')}</label>
                <input
                    type="text"
                    className="form-control formInputs"
                    id="businessRegistrationNumber"
                    placeholder='12456789'
                    value={formData.businessRegistrationNumber}
                            onChange={(e) => handleInputChange('businessRegistrationNumber', e.target)}
                            required
                />
                {errorId === 'businessRegistrationNumber' ? <span className='inputFiledError pt-1'>{error}</span> : ''}
                </div>
                </div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                <div className="mb-5 px-3">
                <label htmlFor="email" className="form-label contactusLabels pb-2"> {t('BACC.S2.lb.4')} </label>
                <input
                    type="date"
                    className="form-control formInputs"
                    id="dateOfEstablishment"
                    // placeholder='12345678'
                    value={formData.dateOfEstablishment}
                    onChange={(e) => handleInputChange('dateOfEstablishment', e.target)}
                    required
                />
                </div>
                </div>

                <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                <div className="mb-5 px-3">
                <label htmlFor="email" className="form-label contactusLabels pb-2"> {t('BACC.S2.lb.5')} </label>
                <input
                    type="text"
                    className="form-control formInputs"
                    id="businessWebsite"
                    placeholder={t('BACC.S2.ph.1')}
                    value={formData.businessWebsite}
                    onChange={(e) => handleInputChange('businessWebsite', e.target)}
                    required
                />
                </div>
                </div>


             </div>
    </div>
    </AnimatedPage>

  )
}
