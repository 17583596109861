import React, { useState } from 'react'
import UpperNav from './UpperNav'
function AddRecipients(props) {

    const [step,setStep] = useState(1)

    return (
        <div className='sideBarMainContent '>
        <div className='WMainDiv '>
        <UpperNav title={`Add Recipients`} symbol={" "}/>
    
        
        <div className='row mt-5 justify-content-center'>
          <div className='col-xxl-5 col-lg-8 col-12'>
              <div className='fiatTransferCard mt-md-5 mt-2 ' >
                <div className='cardUpperRow mb-3'>
                    <div className='col-12'>
                    <h3>
                   {
                    step === 1 ? 
                    "Enter Recipient details"
                    : "Confirm Details"
                   }
                    </h3>
                    </div>
                </div>

                {
                    step === 1 ? 
                        <div className='col-12 newPaymentDiv mt-4'>
                            <div className='FDInputDiv mb-4'>
                                <h6 className='ps-1 inputTitle mb-3'>
                                Bank Name
                                </h6>
                                <input type='text' placeholder='Bank Name' className='form-control fTransferInput' value={props.bankName} onChange={(e)=>{props.setbankName(e.target.value)}}/>
                            </div>
                            <div className='FDInputDiv mb-4'>
                                <h6 className='ps-1 inputTitle mb-3'>
                                Recipients Name
                                </h6>
                                <input type='text' placeholder='Steve Thompson' className='form-control fTransferInput' value={props.accountHolderName} onChange={(e)=>{props.setaccountHolderName(e.target.value)}}/>
                            </div>
                            <div className='FDInputDiv mb-4'>
                                <h6 className='ps-1 inputTitle mb-3'>
                                Account Number
                                </h6>
                                <input type='number' placeholder='Account Number' className='form-control fTransferInput' value={props.acoountNumber} onChange={(e)=>{props.setacoountNumber(e.target.value)}}/>
                            </div>
                            <div className='FDInputDiv mb-4'>
                                <h6 className='ps-1 inputTitle mb-3'>
                                Sort Code
                                </h6>
                                <input type='number' placeholder='123' className='form-control fTransferInput' value={props.sortCode} onChange={(e)=>{props.setsortCode(e.target.value)}}/>
                            </div>
                            <div className='FDInputDiv '>
                                <h6 className='ps-1 inputTitle mb-3'>
                                IBAN
                                </h6>
                                <input type='number' placeholder='345511000111123214' className='form-control fTransferInput' value={props.iban} onChange={(e)=>{props.setiban(e.target.value)}}/>
                            </div>
                        </div>
                    : <>
                    <div className='recievedDetailDiv d-flex justify-content-between pt-3'>
                        <h6>
                        Bank Name:
                        </h6>
                        <p>
                        {props.bankName}
                        </p>
                    </div>  
                    <div className='recievedDetailDiv d-flex justify-content-between pt-3'>
                        <h6>
                        Sending To
                        </h6>
                        <p>
                        {props.accountHolderName}
                        </p>
                    </div>  
                    <div className='recievedDetailDiv d-flex justify-content-between pt-3'>
                        <h6>
                        Account Number
                        </h6>
                        <p>
                        {props.acoountNumber}
                        </p>
                    </div>  
                    <div className='recievedDetailDiv d-flex justify-content-between pt-3'>
                        <h6>
                        Sort Code
                        </h6>
                        <p>
                        {props.sortCode}
                        </p>
                    </div> 
                    <div className='recievedDetailDiv d-flex justify-content-between pt-3'>
                        <h6>
                        IBAN
                        </h6>
                        <p>
                        {props.iban}
                        </p>
                    </div> 
                    </>

                }


              <div className='row justify-content-center mt-4'>
                <div className='col-8'>
                        <div className='WHPS3bottomDiv row '>    
                            {
                                step === 1 ? 
                                <a onClick={()=>{setStep(step + 1)}}> Next </a>
                                : <a onClick={()=>{props.currentPage('details')}}> Save Recipient </a> 
                            }          
                        </div>
                </div>
            </div>
    
        </div>
    
    
      </div>
    </div>
    </div>
    </div>
      )
}

export default AddRecipients