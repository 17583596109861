import React from 'react'
import { useTranslation } from 'react-i18next';
import AnimatedPage from '../AnimatedPage'
import ScrollToTop from '../scrollToTop';
export default function RegisrterBuisnessAccStepThree({formData ,handleInputChange,error,errorId}) {
    const {t} = useTranslation()
  return (
    <AnimatedPage>
        <ScrollToTop/>
    <div>
                    <div className='row '   >
                        <div className='d-flex justify-content-center'>
                        <img loading='lazy' alt="Vr Line" src="../../../images/b3.svg" className='imgResponsive px-5 upperImgRegister hrSmHide'/>
                        </div>

                        <h2 className='SignupHeadings'>
                        {t('BACC.S3.H')}
                        </h2>
                        <img loading='lazy' alt="Vr Line" src="../../../images/Vector 4.svg" className='imgResponsive'/>
                    </div>
                    <div className='row pt-5'   >
  
                    <div className='col-12'>
                        <div className="mb-5 px-3">
                        <label htmlFor="email" className="form-label contactusLabels pb-2">{t('BACC.S3.lb.1')}</label>
                        <input
                            type="text"
                            className="form-control formInputs"
                            id="address"
                            placeholder='Xyz'
                            value={formData.address}
                            onChange={(e) => handleInputChange('address', e.target)}
                            required
                        />
                        {errorId === 'address' ? <span className='inputFiledError pt-1'>{error}</span> : ''}
                        </div>
                    </div>


                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                        <div className="mb-5 px-3">
                        <label htmlFor="email" className="form-label contactusLabels pb-2"> {t('BACC.S3.lb.2')} </label>
                        <input
                            type="text"
                            className="form-control formInputs"
                            id="city"
                            placeholder='xyz'
                            value={formData.city}
                            onChange={(e) => handleInputChange('city', e.target)}
                            required
                        />
                        {errorId === 'city' ? <span className='inputFiledError pt-1'>{error}</span> : ''}
                        </div>
                    </div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                <div className="mb-5 px-3">
                <label htmlFor="email" className="form-label contactusLabels pb-2"> {t('BACC.S3.lb.3')} </label>
                <input
                    type="text"
                    className="form-control formInputs"
                    id="state"
                    placeholder='Xyz'
                    value={formData.state}
                    onChange={(e) => handleInputChange('state', e.target)}
                    required
                />
                {errorId === 'state' ? <span className='inputFiledError pt-1'>{error}</span> : ''}
                </div>
                </div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                <div className="mb-5 px-3">
                <label htmlFor="email" className="form-label contactusLabels pb-2">{t('BACC.S3.lb.4')}</label>
                <input
                    type="text"
                    className="form-control formInputs"
                    id="zipCode"
                    placeholder='1234 '
                    value={formData.zipCode}
                    onChange={(e) => handleInputChange('zipCode', e.target)}
                    required
                />
                {errorId === 'zipCode' ? <span className='inputFiledError pt-1'>{error}</span> : ''}
                </div>
                </div>



                <div className='col-xxl-6 col-xl-6 col-lg-6 col-12'>
                <div className="mb-5 px-3">
                <label htmlFor="email" className="form-label contactusLabels pb-2">{t('BACC.S3.lb.5')}</label>
                <input
                    type="text"
                    className="form-control formInputs"
                    id="country"
                    placeholder={t('BACC.S3.ph.1')}
                    value={formData.country}
                    onChange={(e) => handleInputChange('country', e.target)}
                    required
                />
                {errorId === 'country' ? <span className='inputFiledError pt-1'>{error}</span> : ''}
                </div>
                </div>

                    </div>
    </div>
    </AnimatedPage>
  )
}
