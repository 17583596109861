import React,{useState,useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function BlogDetailsStructure() {
    const navigate = useNavigate()
    const location = useLocation();
    const blogObj = location.state.blogObj;
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [latestBlog, setlatestBlog] = useState([]);
    const [blogDetailSubTitle, setBlogDetailSubTitle] = useState()

    useEffect(() => {
        const fetchBlogs = async () => {
            setIsLoading(true);
            setIsError(false);
            try {
                const response = await fetch('https://p-20.org/api/v1/evistia-news/all-news/');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setlatestBlog(data.slice(0,4));
            } catch (error) {
                setIsError(true);
                console.error('Fetch error:', error);
            }
            setIsLoading(false);
        };

        fetchBlogs();
    }, []);

    useEffect(() => {
        latestBlog.map((element) => (
            setBlogDetailSubTitle(element.sub_title)
         ))
    }, [latestBlog]);
    const BlogDetailsHandle = (blogObj) => {
        navigate(`/blog-details/${blogObj.title}`, { state: { blogObj } });
    };

  return (
    <div className='homepageContainerP2 mt-5 pt-5 p-0 ' >
    <div className='container-fluid '>
        <div className='row jusify-content-center px-md-5 px-3'>

            <div className='col-md-8 col-12 blogMintDetailsDiv'>
                <p className='blogMintDetails mb-2'>
                {blogObj.category.name} • {blogObj.estiamte_reading_time} min read
                </p>
                <h3 className='blogHeadnigDetails mb-4'>
                {blogObj.title}
                </h3>
                <p className='blogDate my-2'>
                {blogObj.author} • {blogObj.published_date}
                </p>
                <img loading='lazy' src={blogObj.image} alt="Blog IMG" className="blogMainImg10 my-3"/>
                <p className='blogInfoHeading03 mb-3'>
                                    {blogObj.sub_title}
                                </p>
                <div className='blogPara' style={{overflow:"auto"}} dangerouslySetInnerHTML={{ __html: blogObj?.content }} />
                {/* {blogObj.content} */}
            </div>
            <div className='col-md-4 col-12 d-md-block d-none'>
                <p className='blogInfoHeading mb-4'>
                Popular posts
                </p>


                {latestBlog ? latestBlog.map((element) => {
                        if (blogDetailSubTitle === element.sub_title) {
                            return null; }
                        return (
                            <div className='blogIngoDiv mb-4' key={element.id} onClick={() => BlogDetailsHandle(element)}>
                                <p className='blogInfoHeading01 mb-0'>
                                    {element.category.name} • {element.estimate_reading_time} {/* Assuming 'estiamte_reading_time' was a typo */}
                                </p>
                                <p className='blogInfoHeading02'>
                                    {element.sub_title}
                                </p>
                            </div>
                        );
                    }) : null}

            </div>


            <div className='row mt-5 justify-content-between'>
                <div className='col-md-6 col-12'>
                    <h3 className='bSubH101'>
                    Related Blog
                    </h3>
                    <p className='blogSubPara '>
                    We share common trends, strategies ideas, opinions, short & long stories from the team behind company.
                    </p>
                </div>

                {/* <div className='col-md-6 col-12 d-flex justify-content-end align-items-center pe-0'>
                    <div className='blogUpperBtnRow1'>
                        <button>
                            <span className='gradient-text'>
                                View all
                            </span>
                            <img loading='lazy' src="../../../images/Icon.svg" alt="arrow" className="mt-1"/>
                        </button>
                    </div>
                </div> */}
            </div>

            <div className='row mt-4'>
                { latestBlog ? latestBlog.map((element) => ( 
                    <div className='col-md-4 col-12 mb-4 ' key={element.id}>
                        <img loading='lazy' src={element.image} alt="Blog IMG" className="blogMainImg01 mt-4"/>
                        <p className='blogDate my-2'>
                        {element.author} • {element.published_date}
                        </p>

                        <p className='blogSubHeading mb-1'>
                        {element.title}
                        </p>

                        <div className='blogSubPara pe-2' dangerouslySetInnerHTML={{ __html: element?.sub_title }} />
                        <a className='blogBtn mt-3' onClick={()=>BlogDetailsHandle(element)}>
                        Read more
                        </a>
                    </div>
                )) : null }


            </div>

        </div>
    </div>
</div>
  )
}

export default BlogDetailsStructure