import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import UpperNav from './UpperNav';
export default function BillSuccess(props)  {
    const location = useLocation();
    const refrenceNumber = location.state?.consumerNumber;
    const companeName = location.state?.name;
    const amount = location.state?.amount;
    
    return (
      <div className='sideBarMainContent '>
      <div className='WMainDiv '>
        <UpperNav title={"Bill Paid"} symbol={""}/>
  
        
        <div className='row mt-5 justify-content-center '>
              <div className='col-xl-6 col-lg-8 col-12 mt-5'>
                  <div className='paymentConfirmationCard d-flex align-items-center justify-content-around gap-4'>
                  <img loading='lazy' src={process.env.PUBLIC_URL + "/images/finished-q8r56SOUy8.svg" } alt="User Image" className="" />
  
                      <div className='d-flex flex-column align-items-center'>
                      <p className='title mt-3'>
                      Payment Successful!
                      </p>
                        <p className='amount mb-0'>
                        {companeName} bill {amount}.00 paid with consumer number<span>  {refrenceNumber} </span>
                        </p>
                      </div>
                  </div>
                  <p className='tid text-center my-5 pt-3'>
                  Transaction ID : 4567865434
                  </p>
                  <p className='tid text-center pt-3'>
                  We'll send you an email of confirmation. It may take a few
                   minutes to see the payment in your Transactions page.
                  </p>
                  <p className='text-center pageLink mt-5'>
                      <Link to="/wallet/payments">
                      Make Another Payment
                      </Link>
                  </p>
              </div>
  
              
        </div>
      </div>
    </div>
    )
}
