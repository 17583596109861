import React,{useState} from 'react'
import UpperNav from './UpperNav'
function DropdownItem({ text, onSelect }) {
    return (
      <button className="dropdown-item" onClick={() => onSelect(text)}>
        {text}
      </button>
    );
  }
function Notifications() {
    const [dropDown,showDropDown] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
  
    const dropDownHandle = () => {
        showDropDown(!dropDown)
    }

    const handleOptionSelect = (optionText, ) => {
    setSelectedOption({ text: optionText,  });
    showDropDown(false);
    };
  return (
    <div className='sideBarMainContent '>
        <div className='WMainDiv '>
        <UpperNav title={`Notifications`} symbol={" "}/>
    
        
        <div className='row mt-5 justify-content-center'>
            <div className='col-11  '>
                <h2 className='notifyHeading mb-1'>
                Notifications
                </h2>
                <span className='notifyTxt'>
                    You’ve got 6 new notifcations
                </span>
            </div>


            <div className='col-xxl-11 col-lg-11 col-12'>
                <div className='fiatTransferCard mt-md-5 mt-2 ' >
                    <div className='cardUpperRow mb-3'>
                        <div className='col-xl-10 col-md-9 col-6'>
                        <h3>Notifications
                        </h3>
                        </div>
                        <div className='col-xl-2 col-md-3 col-6 col-6 d-flex justify-content-end mb-2' style={{cursor:"pointer"}}>
                            <div className='blogddDiv1  d-flex gap-3 align-items-center justify-content-between' onClick={dropDownHandle}>
                                <div className='d-flex gap-2'>
                                    <h4 className='mb-0'>{selectedOption?.text || 'Announcement'}</h4>
                                </div>
                                <img loading='lazy' src="../images/Vector 26.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow"/>

                                <div className={`dropdown-menu blogwapDropDown ${dropDown ? 'show' : ''}`}  onMouseLeave={() => showDropDown(false)}>
                                   
                                    <DropdownItem text="Announcement"  onSelect={handleOptionSelect} />
                                    <DropdownItem text="System Messages"  onSelect={handleOptionSelect} />
                                    <DropdownItem text="Wallet"  onSelect={handleOptionSelect} />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/t1.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            Updates on Minimum Order Size for Spot and Margin Trading Pairs
                            </p>
                            <span>
                            Fellow evistia, In order to make trading more accessible and improve trading experience,  will reduce the minimum order
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            Jul 3 2024
                            </p>
                            <span>
                            12:15PM
                            </span>
                        </div>
                    </div>

                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/t1.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            Updates on Minimum Order Size for Spot and Margin Trading Pairs
                            </p>
                            <span>
                            Fellow evistia, In order to make trading more accessible and improve trading experience,  will reduce the minimum order
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            Jul 3 2024
                            </p>
                            <span>
                            12:15PM
                            </span>
                        </div>
                    </div>

                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/t1.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            Updates on Minimum Order Size for Spot and Margin Trading Pairs
                            </p>
                            <span>
                            Fellow evistia, In order to make trading more accessible and improve trading experience,  will reduce the minimum order
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            Jul 3 2024
                            </p>
                            <span>
                            12:15PM
                            </span>
                        </div>
                    </div>

                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/t1.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            Updates on Minimum Order Size for Spot and Margin Trading Pairs
                            </p>
                            <span>
                            Fellow evistia, In order to make trading more accessible and improve trading experience,  will reduce the minimum order
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            Jul 3 2024
                            </p>
                            <span>
                            12:15PM
                            </span>
                        </div>
                    </div>


                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/t1.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            Updates on Minimum Order Size for Spot and Margin Trading Pairs
                            </p>
                            <span>
                            Fellow evistia, In order to make trading more accessible and improve trading experience,  will reduce the minimum order
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            Jul 3 2024
                            </p>
                            <span>
                            12:15PM
                            </span>
                        </div>
                    </div>


                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/t1.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            Updates on Minimum Order Size for Spot and Margin Trading Pairs
                            </p>
                            <span>
                            Fellow evistia, In order to make trading more accessible and improve trading experience,  will reduce the minimum order
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            Jul 3 2024
                            </p>
                            <span>
                            12:15PM
                            </span>
                        </div>
                    </div>


                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/t1.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            Updates on Minimum Order Size for Spot and Margin Trading Pairs
                            </p>
                            <span>
                            Fellow evistia, In order to make trading more accessible and improve trading experience,  will reduce the minimum order
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            Jul 3 2024
                            </p>
                            <span>
                            12:15PM
                            </span>
                        </div>
                    </div>


                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/t1.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            Updates on Minimum Order Size for Spot and Margin Trading Pairs
                            </p>
                            <span>
                            Fellow evistia, In order to make trading more accessible and improve trading experience,  will reduce the minimum order
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            Jul 3 2024
                            </p>
                            <span>
                            12:15PM
                            </span>
                        </div>
                    </div>

                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/t1.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            Updates on Minimum Order Size for Spot and Margin Trading Pairs
                            </p>
                            <span>
                            Fellow evistia, In order to make trading more accessible and improve trading experience,  will reduce the minimum order
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            Jul 3 2024
                            </p>
                            <span>
                            12:15PM
                            </span>
                        </div>
                    </div>

                    <div className='WHPS3RecentActivityDiv col-12 d-flex justify-content-between mb-3'>
                        <div className='d-flex gap-3'>
                            <img loading='lazy' src="../images/t1.svg" alt="Section One Right Side Images" className="img-fluid "/>
                            <div className='d-flex flex-column justify-content-center '>
                            <p className='mb-1'>
                            Updates on Minimum Order Size for Spot and Margin Trading Pairs
                            </p>
                            <span>
                            Fellow evistia, In order to make trading more accessible and improve trading experience,  will reduce the minimum order
                            </span>
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-end '>
                            <p className='mb-1'>
                            Jul 3 2024
                            </p>
                            <span>
                            12:15PM
                            </span>
                        </div>
                    </div>

                    
        
            </div>
        
        
            </div>
    </div>
    </div>
    </div>
  )
}

export default Notifications