import React, { useState , useEffect} from 'react'
import UpperNav from './UpperNav'
import { useLocation } from 'react-router-dom';
import ChoseNetwork from './ChoseNetwork';
import RecievedCryptoDetails from './RecievedCryptoDetails';
import { getToken } from '../../services/LocalStorageService';
import { useNavigate } from 'react-router-dom';

export default function RecievedCrypto() {
  // const response = await fetch(`https://evistialab.com/api/wallet/wallets/?currency=${apiCurrency}`, {


    const [qrCode,setQrode] = useState()
    const location = useLocation();
    const navigate = useNavigate();
    const name = location?.state?.name;
    const sign = location.state.sign;
    const image = location.state.img;
    const [apiCurrency, setApiCurrency] = useState();
    const [publicAddress, setPublicAddress] = useState();
    const [network, setNetwork] = useState();
    const [stepCount, setStepCount] = useState(1);
    const { access_token } = getToken();

    // Determine network and apiCurrency based on the name
    useEffect(() => {
        let newNetwork = '';
        let newApiCurrency = '';
        let newStepCount = 2; // Assuming we want to set this to 2 in all cases here

        switch (name) {
            case 'Binance':
            case 'BNB':
            case 'Binance Coin':
                newNetwork = 'BNB Smart Chain (BEP20)';
                newApiCurrency = 'ETH';
                break;
            case 'Ethereum':
            case 'ETH':
                newNetwork = 'Ethereum (ERC20)';
                newApiCurrency = 'ETH';
                break;
            case 'USDT':
                newNetwork = 'TetherUS';
                newApiCurrency = 'ETH';
                break;
            case 'BTC':
            case 'Bitcoin':
                newNetwork = 'Bitcoin (BTC)';
                newApiCurrency = 'BTC';
                break;
            default:
                newStepCount = 1; // If none of the above, we don't proceed to step 2
                break;
        }

        setNetwork(newNetwork);
        setApiCurrency(newApiCurrency);
        setStepCount(newStepCount);
    }, [name]);

    // Fetch wallets only if apiCurrency is set

    const fetchWallets = async () => {
        try {
            const apiData =  {network:sign}
            console.log(apiData,'api data is here')
            const response = await fetch(
              "https://evistialab.com/api/v1/wallet/get/",
              {
                method: "POST",
                body : JSON.stringify(apiData),
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${access_token}`,
                },
              }
            );
            if (response.status === 401) {
              // If unauthorized, navigate to the login page
              navigate("/login");
              return; // Stop further execution in this block
            }
            const data = await response.json();
            if (data) {
                console.log(data,'data is hree')
                setPublicAddress(data.address)
                setQrode(data.address_qr_code_url)
            }
          } catch (error) {
            console.error("Error fetching BTC balance:", error);
          }
    }




    useEffect(() => {



       




        // if (!apiCurrency) return; // Exit if apiCurrency is not set

        // const fetchWallets = async () => { 
        //     try {
        //         const apiData =  {network:name}

        //         // const response = await fetch(`http://127.0.0.1:8000/api/wallet/wallets/?currency=${apiCurrency}`, {
        //         const response = await fetch(`https://evistialab.com/api/v1/wallet/get/`, {
        //             method: 'POST',
        //             headers: {
        //                 'Content-Type': 'application/json',
        //                 'Authorization': `Bearer ${access_token}`
        //             },
        //             body : JSON.stringify(apiData)
        //         });

        //         if (!response.ok) {
        //             throw new Error('Failed to fetch wallets');
        //         }

        //         const data = await response.json();
        //         if (data){
        //             console.log(data,'netwrok data is here')
        //             setPublicAddress(data[0].address);
        //         }
        //     } catch (error) {
        //         console.error(error);
        //     }
        // };

        fetchWallets();
    }, [apiCurrency, access_token]); // Depends on apiCurrency and access_token


    
  return (
    <div className='sideBarMainContent '>
    <div className='WMainDiv '>
    <UpperNav />

    
    <div className='row mt-5 justify-content-center'>
      <div className='col-xxl-5 col-lg-8 col-12'>
          <div className='fiatTransferCard mt-md-5 mt-2 ' >
          {
            stepCount === 3 ? "" :
            <div className='cardUpperRow mb-3'>
                    <div className='col-12'>
                    <h3>
                   {
                   stepCount === 1 ? `Receive ${sign} (Chose Network)` : `Receive ${sign}`
                   }
                    </h3>
                    </div>
                </div>
          }
          <div className='col-12' >
           {
            // stepCount ===  ? 
            // <div className='CPunderRowCard mb-3' onClick={() => setStepCount(stepCount + 1)}>
            //     <h5  className='ps-3'>
            //     Network
            //     </h5>
            //     <h6  className='ps-3'>
            //     Please choose network first
            //     </h6>
            // </div> :
            stepCount === 1 ? 
            <ChoseNetwork setStepCount={setStepCount} stepCount={stepCount} setNetwork={setNetwork}/>
            : <RecievedCryptoDetails network={network} name={name} sign={name} image={image} publicAddress={publicAddress} qrCode={qrCode}/>
           }
          </div>

    </div>


  </div>
</div>
</div>
</div>
  )
}
