import React, { useState, useEffect } from 'react'
import UpperNav from './UpperNav';
import { CurrencyDropdownItems } from '../../constants/AllCurencies';
import { currencyMapping } from '../../constants/currencyMapping';
import { useNavigate, useLocation } from 'react-router-dom';
import { getToken } from '../../services/LocalStorageService';
import MobileOTP from './MobileOTP';
import Swal from 'sweetalert2';




function DropdownItem({ text, code, imgSrc, onSelect }) {
    return (
        <button className="dropdown-item d-flex gap-3 align-items-center" onClick={() => onSelect(text, code, imgSrc)}>
            <img loading='lazy' src={imgSrc} alt="Currency Image" className="currencySwapOptionImg" />
            <div className='d-flex flex-column'>
                {text}
                <span>{code}</span>
            </div>
        </button>
    );
}

function DropdownItem1({ text, code, imgSrc, onSelect }) {
    return (
        <button className="dropdown-item d-flex gap-3 align-items-center" onClick={() => onSelect(text, code, imgSrc)}>
            <img loading='lazy' src={imgSrc} alt="Currency Image" className="currencySwapOptionImg" />
            <div className='d-flex flex-column'>
                {text}
                <span>{code}</span>
            </div>
        </button>
    );
}
function FiatSwap() {
    const { access_token } = getToken();
    const navigate = useNavigate()
    const location = useLocation()
    const [apiVal, setapiVal] = useState()
    const [stepCount, setStepCount] = useState(1)

    const [apiError, setApiError] = useState()

    const [initial, setinitial] = useState(0)
    const [feeAmount, setfeeAmount] = useState(0)
    const [exchangeRat, setexchangeRat] = useState(0)


    const [from_currency, setfromCurrecny] = useState(0)
    const [to_currency, setto_currency] = useState(0)
    const [fee, setfee] = useState(0)
    const [original_amount, setoriginal_amount] = useState(0)
    const [converted_amount, setconverted_amount] = useState(0)





    const [secondApiVal, setSecondApiVal] = useState()
    const [firstCurrencySign, setFirstCurrencySign] = useState('GBP')
    const [secondCurrencySign, setSecondCurrencySign] = useState("EUR")

    const [firstCurrencyVal, setFirstCurrencyVal] = useState(0)
    const [secondCurrencyVal, setSecondCurrencyVal] = useState(0)


    const [firstCurrency, setFirstCurrency] = useState('gbp')
    const [secondCurrency, setSecondCurrency] = useState('eur')

    const shortName1 = location?.state?.first?.shortName
    const shortName2 = location?.state?.second?.shortName


    const firstLogo = location?.state?.first?.imgSrc
    const secondLogo = location?.state?.second?.imgSrc

    const firstCurrencyNames = location?.state?.first?.currency
    const secondCurrencyNames = location?.state?.second?.currency

    const firstCurrencysigns = location?.state?.first?.sign
    const secondCurrencysigns = location?.state?.second?.sign





    const handleFirstCurrencyInput = (e) => {
        setFirstCurrencyVal(e)
    }
    const handleSecondCurrencyInput = (e) => {
        setSecondCurrencyVal(e)
    }



    const [dropDown, showDropDown] = useState(false)
    const [dropDown1, showDropDown1] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOption1, setSelectedOption1] = useState(null);




    const dropDownHandle = () => {
        showDropDown(!dropDown)
    }
    const dropDownHandle1 = () => {
        showDropDown1(!dropDown1)
    }

    const handleOptionSelect = (optionText, optionCode, optionImgSrc) => {
        setSelectedOption({ text: optionText, code: optionCode, imgSrc: optionImgSrc });
        showDropDown(false);
        setSecondCurrency(optionCode.toLowerCase())
    };

    const handleOptionSelect1 = (optionText, optionCode, optionImgSrc) => {
        setSelectedOption1({ text: optionText, code: optionCode, imgSrc: optionImgSrc });
        showDropDown1(false);
        setFirstCurrency(optionCode.toLowerCase())
    };


    useEffect(() => {


        if (firstCurrencyVal !== 0) {
            // Prepare the form data to be sent in the request
            const apiData = { from_currency: firstCurrency.toUpperCase(), to_currency: secondCurrency.toUpperCase(), amount: Number(firstCurrencyVal) }
            setApiError(``)
            console.log('conersion api data', apiData)
            // Fetch API call
            fetch('https://evistialab.com/api/v1/convert-fiat-price/', {
                method: 'POST',  // Assuming it's a POST request
                headers: {
                    'Content-Type': 'application/json',  // Assuming the API expects JSON
                    'Authorization': `Bearer ${access_token}`,  // Include the access token if required
                },
                body: JSON.stringify(apiData)  // Convert the JavaScript object to a JSON string
            })
                .then(response => {
                    if (!response.ok) {

                        if (response.status === 401) {
                            navigate('/login')
                        }
                    }
                    return response.json();
                })
                .then(data => {
                    const formattedAmount = Number(data.converted_amount).toFixed(2);
                    setapiVal(formattedAmount);
                    setfeeAmount(data.fee_amount)
                    setexchangeRat(data.exchange_rate)
                    setinitial(1)
                })
                .catch(error => console.error('Error fetching data:', error));
        }

    }, [dropDown, dropDown1, selectedOption, selectedOption1, firstCurrencyVal]);



    const fiatSwapHandler = async () => {

        try {
            if (firstCurrencyVal !== 0) {
                // setStepCount(stepCount+2)
                // Prepare the form data to be sent in the request
                const apiData = { from_currency: firstCurrency, to_currency: secondCurrency, amount: firstCurrencyVal }
                console.log(apiData, 'api data before call')
                const response = await fetch('https://evistialab.com/api/v1/fiat-to-fiat/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${access_token}`,
                    },
                    body: JSON.stringify(apiData)
                });
                setApiError(``)
                if (response.status === 401) {
                    setApiError('ERROR ACCURR')
                }
                if (response.status === 400) {
                    console.log(response, 'response')
                }


                const data = await response.json();
                if (data) {
                    console.log(data)
                    setStepCount(stepCount + 1)
                    setApiError(``)
                    setfromCurrecny(data.from_currency)
                    setto_currency(data.to_currency)
                    setfee(data.fee)
                    setoriginal_amount(data.original_amount)
                    setconverted_amount(data.converted_amount)
                }
                if (data.error) {
                    setApiError(`${data.error}`)
                }
            }
        } catch (error) {
            setApiError(`ERROR ${error}`)
            console.error('Error fetching data:', error);
        }

    }







    useEffect(() => {
        if (stepCount === 2) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: `Fiat Swap Has Been Completed Successfully ${original_amount} ${from_currency} converted to ${converted_amount} ${to_currency} with ${fee} fees.`, // Change to your data property
            }).then(() => {
                navigate('/wallet/fiat-dashboard'); // Redirect to another page
            })
        }
    }, [stepCount])






  return (
    <div className='sideBarMainContent '>
        <div className='WMainDiv '>
        <UpperNav title={'Convert Fiat'} symbol={''}  />
            <div className='row mt-5 justify-content-center'>
                <div className='col-lg-9 col-12'>
                    <div className='exCard setTransform' style={{position:"unset",width:"100%",     height:"auto"}}>
                    {/* {
                        stepCount === 1 ?  */}
                        <div className='p-4'>
                            <span style={{color:'red',textAlign:"center"}}>
                                    {
                                        apiError
                                    }
                            </span>
                            <div className='detailRow w-100 mb-5 p-3'>
                                <p>
                                Amount
                                </p>
                                <div className='row w-100'>
                                    <div className=' col-6 exSwap  d-flex gap-3 align-items-start justify-content-start' onClick={dropDownHandle1} >
                                        <div className='d-flex align-items-start gap-2'>
                                            {/* <img loading='lazy' src={process.env.PUBLIC_URL + selectedOption1?.imgSrc || process.env.PUBLIC_URL + '/images/g.svg'} alt="Currency Image" className="currencyLogo" /> */}
                                            { selectedOption1?.imgSrc ?
                                                <img src={process.env.PUBLIC_URL + selectedOption1?.imgSrc ||  process.env.PUBLIC_URL + '/images/eur.svg'} alt="Currency Image" className="currencyLogo" /> : 
                                                <img loading='lazy' src={'../images/g.svg'} alt="Currency Image" className="currencyLogo" /> 
                                                }



                                            <h3 className='mb-0 gradinatTxt mt-1'>{selectedOption1?.code || 'GBP'}</h3>
                                            <img loading='lazy' src="../../../../images/WHPArrowD.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow mt-2 "/>
                                        </div>
                                        <div className={`dropdown-menu CurrencySwapDropDown005 ${dropDown1 ? 'show' : ''}`} onMouseLeave={() => showDropDown1(false)}>
                                            {CurrencyDropdownItems.map((item, index) => (
                                                <DropdownItem1 key={index} text={item.name} code={item.currency} imgSrc={item.imgSrc}  onSelect={handleOptionSelect1} />
                                            ))}
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <input type='number' className='form-control exInput' placeholder='0' value={firstCurrencyVal} onChange={(e) => { handleFirstCurrencyInput(e.target.value) }} />
                                    </div>
                                </div>
                            </div>

                           

                            <div className='detailRow w-100 mb-5 p-3'>
                                <p>
                                    Converted to
                                </p>
                                <div className='row w-100'>
                                    <div className=' col-6 exSwap  d-flex gap-3 align-items-start justify-content-start' onClick={dropDownHandle}>
                                        <div className='d-flex align-items-start gap-2'>
                                            {selectedOption?.imgSrc ?
                                                <img src={process.env.PUBLIC_URL + selectedOption?.imgSrc || process.env.PUBLIC_URL + '/images/eur.svg'} alt="Currency Image" className="currencyLogo" /> :
                                                <img loading='lazy' src={'../images/eur.svg'} alt="Currency Image" className="currencyLogo" />
                                            }
                                            <h3 className='mb-0 gradinatTxt mt-1'>{selectedOption?.code || 'EUR'}</h3>
                                            <img loading='lazy' src="../../../../images/WHPArrowD.svg" alt="Section One Right Side Images" className="currencySwapOptionArrow mt-2" />
                                        </div>

                                        <div className={`dropdown-menu CurrencySwapDropDown005 ${dropDown ? 'show' : ''}`} onMouseLeave={() => showDropDown(false)}>
                                            {CurrencyDropdownItems.map((item, index) => (
                                                <DropdownItem key={index} text={item.name} code={item.currency} imgSrc={item.imgSrc} onSelect={handleOptionSelect} />
                                            ))}
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <input type='number' className='form-control exInput' placeholder='0' value={apiVal} onChange={(e) => {
                                            handleSecondCurrencyInput(e.target.value)
                                        }}
                                            readOnly />
                                    </div>
                                </div>
                            </div>

                                   

                            <div className='detailRow  w-100 mb-5 p-3'>
                                <div className='d-flex justify-content-between w-100'>
                                    <h5 className='feeHeading'>
                                        Our current rate
                                    </h5>
                                    <h6 className='feeTxt'>
                                        {firstCurrency.toUpperCase} {initial} = {secondCurrency.toUpperCase} {exchangeRat}
                                    </h6>
                                </div>

                                <div className='d-flex justify-content-between w-100'>
                                    <h5 className='feeHeading mb-0'>
                                        No fees
                                    </h5>
                                    <h6 className='feeTxt mb-0'>
                                        £0.00
                                    </h6>

                                </div>
                            </div>
                            <div className='px-4 pb-4'>
                                <a onClick={()=>{fiatSwapHandler()}} className='convertBtn '>
                                    Convert
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default FiatSwap