import React, { useEffect , useState} from 'react';
import { getToken } from '../../services/LocalStorageService';
import { useGetLoggedUserQuery } from '../../services/userAuthApi';
import { useGetTokenAfterExpiredMutation } from '../../services/userAuthApi';
import { useVerifiedStatusUpdateMutation } from '../../services/userAuthApi';
import { useNavigate } from 'react-router-dom';
import SumsubWebSdk from '@sumsub/websdk-react'



const AccountVerification = () => {
  const navigate = useNavigate()
  const [kycToken, setKycToken] = useState('');
  const [userId, setUserId] = useState('');
  const [verifyStatus, setVerifyStatus] = useState('');


  const { access_token } = getToken();
  const { data, isLoading, isError } = useGetLoggedUserQuery(access_token);
  const [getKycToken] = useGetTokenAfterExpiredMutation();
  const [updateStatusVerified] = useVerifiedStatusUpdateMutation();


  const callApiForverificationUpdate = async () => {
    //consolelog('function is called')
    const verifiedResponse = await updateStatusVerified(userId);
    if (verifiedResponse.data.detail === 'verification Process Complete!') {
    navigate('/')
    }
  }

 


  useEffect(() => {
    if (data) {
      //consolelog('Setting kycToken:', data);
      setKycToken(data?.kyc_token);
      setUserId(data?.id)
      setVerifyStatus(data?.is_verified)
    }
  }, [data]);


  if (isLoading) {
    return <div style={{marginTop:"10rem",margin:"auto"}}><button type="submit" className={`button loading }`} style={{ "border": "none" }} >
  </button></div>;
  }

  if (isError) {
    // console.log('there is an error',isError)
    navigate('/login')
  }
  if (verifyStatus === true){
    //consolelog('user already verified',userId,isAccesed)
    navigate('/wallet/index')
    // if(isAccesed === true){
    // }else{
    //   return(
    //     <h1  style={{paddingTop:"10rem",textAlign:"center"}}>
    //         Commng Soon!
    //     </h1>
    //   )
    // }
  }
  else if(kycToken)  {
        //consolelog('user is not saved as a verified in DB')

        // Define your expiration handler.
        const accessTokenExpirationHandler = async () => {
          //consolelog(userId,'user id is here')
          try {
            const userData = {  id : userId }
             const res = await getKycToken({userData , access_token})
            // const res = await generateToken()
            //consolelog(res,'my generateToken Function reswponse is Here')
            if (res){
              //consolelog('we will update kycToken here')
              setKycToken(res)
            }
          } catch (error) {
            //consoleerror('Error:', error);
            throw error; // Rethrow the error so it can be caught elsewhere if needed.
          }
        };
  
      // Define your config and options.
      const config = {
        // Define your config options here.
      };
  
      const options = {
        // Define your options here.
      };
  
      // Define your message handler.
      const messageHandler = async (event) => {
        // Handle messages from Sumsub Web SDK here.
        const responseOnSending = await event;
        //consolelog('Received message from Sumsub:', responseOnSending, 'my verification Status is here :' , );
        
        if (responseOnSending === 'idCheck.applicantReviewComplete') {
          //consolelog('Verification process is complete; we will make a request to update the DB');
          
          callApiForverificationUpdate()
          // Make sure updateStatusVerified returns a Promise.
          // const verifiedResponse = await updateStatusVerified(userId);
          
          
        }
      };
  
      // Define your error handler.
      const errorHandler = async (e) => {
        // Handle errors from Sumsub Web SDK here.
        //consoleerror('Sumsub error:',e);
      };
  
  
  
  
      return (
        <>
        <SumsubWebSdk style={{paddingTop:"10rem"}}
          accessToken={kycToken}
          expirationHandler={accessTokenExpirationHandler}
          config={config}
          options={options}
          onMessage={messageHandler}
          onError={errorHandler}
        />
  
          {/* <div style={{ marginTop: '15rem' }}>{kycToken}</div> */}
        </>
      );
      // //consolelog('KYC Token Has been Set and Show');

  }
  return (
    <>
      {/* <div style={{ marginTop: '15rem' }}>{kycToken}</div> */}
    </>
  );
};

export default AccountVerification;

